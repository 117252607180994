import React from 'react';
import helpIcon from '../../assets/images/help-circle.svg';
import errorIcon from '../../assets/images/alert-circle.svg';

interface Props {
  placeholder: string,
  isError?: boolean,
  hasBeforeIcon?: boolean,
  disabled?: boolean,
  hasHelpIcon?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string,
  fullWidth?: boolean,
  setShow?: () => void,
  onFocus?: () => void
}

export default function PasswordInput({
  placeholder,
  disabled,
  fullWidth,
  hasHelpIcon,
  isError,
  onChange,
  value,
  onFocus,
  setShow
}: Props) {
  return (
    <div 
      autoFocus
      onBlur={setShow}
      onFocus={(e) => {
        e.preventDefault()
        onFocus && onFocus()
      }}
      className={`input ${isError ? 'input-error' : (disabled ? 'input-disabled' : '')} ${fullWidth ? 'fullWidth' : ''}`} 
      tabIndex={0}
    >
      <div className='inputWrapper'>
        <input
          type='password'
          placeholder={placeholder}
          className="text-md"
          onChange={onChange}
          value={value}
          tabIndex={1}
        />
      </div>
      {hasHelpIcon ? <img src={isError ? errorIcon : helpIcon} alt="help" /> : null}
    </div>
  )
}
