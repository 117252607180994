import { useState, useEffect, useContext } from 'react';
import alertIcon from '../../../assets/images/alert-circle-lg.svg';
import closeIcon from '../../../assets/images/x-close-black.svg';
import { createPortal } from 'react-dom';
import saveIcon from '../../../assets/images/save-01.svg';
import closeIconAlert from '../../../assets/images/x-close-alert.svg';
import { FormInput } from '../../../components/Inputs/FormInput';
import DatePickerModal from '../../../components/DatePickers/DatePickerModal';
import TimePicker from '../../../components/TimePicker/TimePicker';
import { Button } from '../../../components/Buttons/Button';
import { CustomIcon } from '../../../components/CustomIcons/CustomIcon';
import { LocationSearch } from '../../../components/LocationSearch/LocationSearch';
import ZonePicker from '../../../components/ZonePicker/ZonePicker';
import DatePicker from '../../../components/DatePickers/Date';
import { DropdownMenu } from '../../../components/Dropdown/DropdownMenu';
import ProgressSteps from '../../../components/ProgressSteps/ProgressSteps';
import ModalNotification from '../../../components/Modal/ModalNotification';
import pencilIcon from '../../../assets/images/pencil-02.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { EventInfoContext } from '../../../utilities/eventInfoContext';
import ModalSaveAsDraft from '../../../components/Modal/ModalSaveAsDraft';
import searchIcon from '../../../assets/images/search-lg-gray.svg';
import { editEvent, fetchAttendeesNetwork, fetchEvent, fetchLocations, fetchMyData, postEvent, postUserCompleteTutorial } from '../../../httpQueries/http';
import { getTimeInMinutes } from '../../../utilities/getTimeInMinutes';
import moment from 'moment';
import { getUTCTimeFromMinutes } from '../../../utilities/getUTCTimeFromMinutes';
import { useDispatch } from '../../../store/hooks';
import { recentlyCreatedEventActions } from '../../../store/recentlyCreatedEvent';
import { supabase } from '../../../utilities/supabaseClient';
import { currentUserActions } from '../../../store/currentUser';
import avatar from '../../../assets/images/Avatar-olivia.svg';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface Props {
  isDesktopSize: boolean,
  setSelectedStartTime: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  } | null>>,
  setSelectedEndTime: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  } | null>>,
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  setDescriptionInputValue: React.Dispatch<React.SetStateAction<string>>,
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>,
  setEventIds: React.Dispatch<React.SetStateAction<{
    eventId: number;
    eventDayId: number;
  } | null>>,
  setSelectedLocation: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    supportText?: string | undefined;
    lat?: string,
    lng?: string
  } | null>>,
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>,
  setSelectedZone: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    timezone?: string,
  } | null>>,
  isEdit?: boolean,
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>,
  currentLastStep: number,
  eventSlug: string,
  setSlug: React.Dispatch<React.SetStateAction<string>>
}

export default function EventDetails({ 
  isDesktopSize, 
  setSelectedStartTime, 
  setSelectedEndTime,
  setFirstDate,
  setDescriptionInputValue,
  setNameInputValue,
  setSelectedLocation,
  setLocationNotes,
  setSelectedZone,
  setEventIds,
  isEdit,
  setCurrentLastStep,
  currentLastStep,
  eventSlug,
  setSlug
}: Props) {
  const currentStep = 1;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const [showZonePicker, setShowZonePicker] = useState(false);
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const [contentIsScrolled, setContentIsScrolled] = useState(false);
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [showError, setShowError] = useState<{ name: string, description: string } | null>(null);
  const [isNameError, setIsNameError] = useState<string | null>(null);
  const [isDescriptionError, setIsDescriptionError] = useState<string | null>(null);
  const [isLocationError, setIsLocationError] = useState<string | null>(null);
  const [isStartTimeError, setIsStartTimeError] = useState<boolean>(false);
  const [timeZoneError, setTimeZoneError] = useState(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{name: string, description: string, firstButtonName: string, secondButtonName: string, onFirstButtonClick: () => void, onSecondButtonClick: () => void} | null>(null);
  const [isContinue, setIsContinue] = useState(false);
  const [isSavedAsDraft, setIsSavedAsDraft] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    eventDate: firstDate,
    startTime: selectedStartTime,
    endTime: selectedEndTime,
    eventDescription: descriptionInputValue,
    eventName: nameInputValue,
    location: selectedLocation,
    locationNotes,
    timeZone: selectedZone,
    eventIds
  } = useContext(EventInfoContext);
  const token = localStorage.getItem('token');
  const slug = useParams().slug;
  const [locations, setLocations] = useState<{name: string, lng: number, lat: number}[] | undefined>([]);
  let timer = setTimeout(() => {}, 300);
  const [startData, setStartData] = useState<
    {
      name: string,
      description: string,
      startDate: null | moment.Moment,
      startTime: string,
      endTime: string | null,
      zone: string | null,
      location: string,
      notes: string,
    }
  >({
    name: '',
    description: '',
    startDate: null,
    startTime: '',
    endTime: null,
    zone: null,
    location: '',
    notes: '',
  })

  async function loadEditableEventData() {
    const data = await fetchEvent({ slug, token});

    return data;
  }

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(async () => {
      const response = await fetchLocations({ search: inputLocationValue, token });

      setLocations(response);
    }, 400);

    return () => clearTimeout(timer);
  }, [inputLocationValue])

  useEffect(() => {
    if (isEdit) {
      loadEditableEventData().then(data => {
        if (data) {
          setFirstDate(moment(data!.startDate));
          setDescriptionInputValue(data!.description);
          setNameInputValue(data!.name);
          setSelectedEndTime(data?.endTime ? { id: 1, name: getUTCTimeFromMinutes(data!.endTime) } : null)
          setSelectedLocation({ id: 1, name: `${data!.location}`, supportText: '', lat: data.locationLatitude!, lng: data.locationLongitude! })
          setLocationNotes(data?.locationNote || '')
          setSelectedStartTime({ id: 1, name: getUTCTimeFromMinutes(data!.startTime) })
          setSelectedZone(data?.timezone ? {id: 1, name: data.timezone, timezone: data.timezoneName } : null)
          setEventIds({eventId: data!.id, eventDayId: data!.days[0].id});

          setStartData({
            name: data!.name,
            description: data!.description,
            startDate: moment(data!.startDate),
            startTime: getUTCTimeFromMinutes(data!.startTime),
            endTime: data?.endTime ? getUTCTimeFromMinutes(data!.endTime) : null,
            zone: data?.timezone ? data.timezone : null,
            location: data!.location,
            notes: data?.locationNote || '',
          })
        } else {
          navigate('/events?filter=organizer')
        }
      })
    }
  }, [isEdit])

  useEffect(() => {
    if (document) {
      document.body.style.overflow = showSelectLocation ? 'hidden' : 'auto'
    }
  }, [showSelectLocation]);

  async function postEventDetails() {
    const res = await postEvent(
      {
        body: {
          startTime: getTimeInMinutes(selectedStartTime!.name),
          endTime: selectedEndTime ? getTimeInMinutes(selectedEndTime!.name) : undefined,
          name: nameInputValue!,
          description: descriptionInputValue!,
          location: `${selectedLocation!.name} ${selectedLocation!.supportText}`,
          locationLatitude: selectedLocation?.lat ? `${selectedLocation?.lat}` : null,
          locationLongitude: selectedLocation?.lng ? `${selectedLocation?.lng}` : null,
          locationNote: locationNotes ? locationNotes : undefined,
          timezone: selectedZone ? selectedZone!.name : '',
          timezoneName: selectedZone ? selectedZone!.timezone : '',
          startDate: firstDate!.utcOffset(0, true).toISOString(),
          endDate: firstDate!.utcOffset(0, true).toISOString(),
        },
        token
      }
    );

    await postUserCompleteTutorial({ token });

    fetchMyData({ token }).then(
      (response) => {
        if (response) {
          dispatch(currentUserActions.setCurrentUser({fullName: response.fullName, email: response.email, profileImage: avatar, completeTutorial: response.isTutorialCompleted}));
        }
      }
    )

    dispatch(recentlyCreatedEventActions.setRecentlyCreatedEvent({ name: res.name, slug: res.slug }))

    return res;
  }

  async function editEventDetails() {
    const res = await editEvent(
      {
        body: {
          startTime: getTimeInMinutes(selectedStartTime!.name),
          endTime: selectedEndTime ? getTimeInMinutes(selectedEndTime!.name) : undefined,
          name: nameInputValue!,
          description: descriptionInputValue!,
          location: `${selectedLocation!.name} ${selectedLocation!.supportText}`,
          locationLatitude: selectedLocation?.lat ? `${selectedLocation?.lat}` : null,
          locationLongitude: selectedLocation?.lng ? `${selectedLocation?.lng}` : null,
          locationNote: locationNotes ? locationNotes : undefined,
          timezone: selectedZone ? selectedZone!.name : '',
          timezoneName: selectedZone ? selectedZone!.timezone : '',
          startDate: firstDate!.utcOffset(0, true).toISOString(),
          endDate: firstDate!.utcOffset(0, true).toISOString(),
        },
        token,
        id: eventIds!.eventId
      }
    )

    return res;
  }
  
  useEffect(() => {
    if (isContinue === true) {
      if (!isNameError && !isDateError && !isDescriptionError && !isLocationError && !isStartTimeError) {
        if (!isEdit) {
          postEventDetails().then((response) => {
            setSlug(response.slug)
            setEventIds({eventId: response.id, eventDayId: response.days[0].id});

            navigate(`/createEvent/agenda`);
          }).catch(async (errorPost: AxiosError<{ message: string[] }>) => {
            const errorMessage = errorPost.response?.data?.message;
            
            if (errorMessage?.includes('this name is already taken')) {
              setIsNameError('This event name already exists.');
              setShowError({ name: 'Event name not unique', description: 'This event name already exists.' });
            }

            if (errorMessage?.includes('timezoneName must be a valid IANA time-zone')) {
              setTimeZoneError(true);
              setShowError({ name: 'Time zone is required', description: 'Please, select a time zone' });
            }

            if (errorPost.response?.status === 403) {
              const { error } = await supabase().auth.signOut();
              localStorage.clear()
              localStorage.clear();

              navigate('/login');
            }
          });
        }

        if (isEdit) {
          if (
            startData.name !== nameInputValue 
              || startData.description !== descriptionInputValue 
              || startData.endTime !== (selectedEndTime?.name ? selectedEndTime.name : null)
              || startData.location !== (selectedLocation?.name || '')
              || startData.notes !== locationNotes
              || startData.startDate?.valueOf() !== firstDate?.valueOf()
              || startData.startTime !== (selectedStartTime?.name || '')
              || startData.zone !== selectedZone
          ) {
            editEventDetails().then((response) => {
              setEventIds({eventId: response.id, eventDayId: response.days[0].id});
              if (currentStep + 1 >= currentLastStep && !isEdit) {
                navigate(`/createEvent/agenda`);
              } else {
                navigate(`/edit/${slug}/agenda`);
              }
            }).catch(async (error) => {
              if (error.response?.status === 403) {
                const { error } = await supabase().auth.signOut();
                localStorage.clear()
                localStorage.clear();
    
                navigate('/login')
              }
            });
          } else {
            if (currentStep + 1 >= currentLastStep && !isEdit) {
              navigate(`/createEvent/agenda`);
            } else {
              navigate(`/edit/${slug}/agenda`);
            }
          }
        }
      }

      setIsContinue(false);
    }
  }, [isContinue]);

  const onContinue = async () => {
    setIsContinue(true);

    if (!nameInputValue!.trim().length) {
      setIsNameError('Field is required');
      setShowError({ name: 'Missing information', description: 'Please fill in all required fields before submitting.' });
    }

    if (nameInputValue!.trim().length) {
      setIsNameError(null)
    }

    if (!descriptionInputValue!.trim().length) {
      setIsDescriptionError('Field is required');
      setShowError({ name: 'Missing information', description: 'Please fill in all required fields before submitting.' });
    }

    if (descriptionInputValue!.trim().length) {
      setIsDescriptionError(null)
    }

    if (!selectedLocation) {
      setIsLocationError('Field is required');
      setShowError({ name: 'Missing information', description: 'Please fill in all required fields before submitting.' });
    }

    if (selectedLocation) {
      setIsLocationError(null);
    }

    if (!selectedStartTime) {
      setIsStartTimeError(true);
    }

    if (selectedStartTime) {
      setIsStartTimeError(false);
    }

    if (!firstDate) {
      setIsDateError(true)
    }

    if (firstDate) {
      setIsDateError(false)
    }
  }

  useEffect(() => {
    if (isSavedAsDraft === true) {
      setTimeout(() => setIsSavedAsDraft(false), 5000)
    }
  }, [isSavedAsDraft]);

  return (
    <div className="eventDetails_main">
      {modalNotificationIsOpen 
        ? createPortal(<ModalNotification 
            setIsOpen={setModalNotificationIsOpen} 
            name={modalNotificationIsOpen.name} 
            description={modalNotificationIsOpen.description} 
            onFirstButton={modalNotificationIsOpen.onFirstButtonClick} 
            onSecondButton={modalNotificationIsOpen.onSecondButtonClick} 
            firstButtonName={modalNotificationIsOpen.firstButtonName} 
            secondButtonName={modalNotificationIsOpen.secondButtonName} 
          />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      {isSavedAsDraft
        ? <ModalSaveAsDraft
            setIsOpen={setIsSavedAsDraft}
          />
        : null
      }
      <div className={`eventDetails_main-header ${showError ? 'eventDetails_main-header-error' : ''} ${contentIsScrolled ? 'eventDetails_main-header-scrolled' : ''}`}>
        <div className="eventDetails_circles">
          <div className="eventDetails-circle eventDetails-circle-fifth">
            <div className="eventDetails-circle eventDetails-circle-fourth">
              <div className="eventDetails-circle eventDetails-circle-third">
                <div className="eventDetails-circle eventDetails-circle-second">
                  <div className="eventDetails-circle eventDetails-circle-first">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showError 
          ? (
            <div className='eventDetails_main-error'>
              <div className='eventDetails_main-error-firstCircle'>
                <div className='eventDetails_main-error-secondCircle'>
                  <img src={alertIcon} alt="alert" />
                </div>
              </div>
              <div>
                <h3>{showError.name}</h3>
                <p>{showError.description}</p>
              </div>
              <img 
                src={closeIconAlert} 
                alt="close icon" 
                className='eventDetails_main-close' 
                onClick={() => setShowError(null)}
              />
            </div>
          )
          : null
        }
        {isDesktopSize 
          ? <ProgressSteps slug={eventSlug} current={currentStep} /> 
          : <CustomIcon
              icon={pencilIcon}
              title='Event details'
              subtitle='Set up the basics'
            />
        }
        {!isDesktopSize 
          ? <img
              src={closeIcon} 
              alt="close icon" 
              className={`eventDetails_main-close ${showError ? 'eventDetails_main-close-error' : ''}`}
              onClick={() => setModalNotificationIsOpen({ name: 'Delete event', description: 'You have not saved this event yet. If you leave now, your event will be deleted. Are you sure you want to continue?', firstButtonName: 'Cancel', secondButtonName: 'Delete event', onFirstButtonClick: () => setModalNotificationIsOpen(null), onSecondButtonClick: () => navigate('/')})}
            /> 
          : null
        }
      </div>
      <div className='eventDetails_content' id='mobileEventDetailsContent' onScroll={() => setContentIsScrolled((document.getElementById('mobileEventDetailsContent') as HTMLElement).scrollTop >= 32)}>
        <div className='eventDetails_main-content'>
          <FormInput
            label='Event name*'
            placeholder='What is your event name?'
            type='Text'
            isError={!!isNameError}
            errorMessage={!!isNameError ? isNameError : undefined}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setNameInputValue(event.target.value)}
            hasHelpIcon={!!isNameError}
            value={nameInputValue || ''}
          />
          <FormInput
            type='TextArea'
            label='Description*'
            placeholder="What is this event for? Recommended: include your goals, hopes and dreams for this event. We'll share this description with your attendees."
            isError={!!isDescriptionError}
            errorMessage={!!isDescriptionError ? isDescriptionError : ''}
            hasHelpIcon={!!isDescriptionError}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDescriptionInputValue(event.target.value)}
            value={descriptionInputValue || ''}
          />
          <div className='eventDetails_main-content-pickers'>
            {
              isDesktopSize
                ? <DatePicker
                    type='smallWithTodayFeat'
                    top={'-225%'}
                    left={'0'}
                    placeholder='Select date*'
                    isError={isDateError}
                    firstDate={firstDate}
                    setFirstDate={setFirstDate}
                    setIsOpen={setShowDatePicker}
                    isOpen={showDatePicker}
                    setShowOtherPickers={() => {
                      setShowEndTimePicker(false);
                      setShowSelectLocation(false);
                      setShowStartTimePicker(false);
                      setShowZonePicker(false);
                    }}
                    selectedDayOnlyFromToday={true}
                  />
                : <DatePickerModal
                    show={showDatePicker}
                    type='smallWithTodayFeat'
                    setShow={setShowDatePicker}
                    firstDate={firstDate}
                    setFirstDate={setFirstDate}
                    selectedDayOnlyFromToday={true}
                  />
            }
            <TimePicker
              setShow={
                () => {
                  setShowStartTimePicker(prevState => !prevState);
                  setShowSelectLocation(false);
                  setShowDatePicker(false);
                  setShowEndTimePicker(false);
                  setShowZonePicker(false);
                }
              }
              time={selectedStartTime}
              setTime={setSelectedStartTime}
              show={showStartTimePicker}
              placeholder='Start time*'
              startOfPhrase='Starts'
              isDesktopSize={isDesktopSize}
              isError={isStartTimeError}
            />
            <TimePicker
              setShow={
                () => {
                  setShowEndTimePicker(prevState => !prevState);
                  setShowSelectLocation(false);
                  setShowDatePicker(false);
                  setShowStartTimePicker(false);
                  setShowZonePicker(false);
                }
              }
              time={selectedEndTime}
              setTime={setSelectedEndTime}
              show={showEndTimePicker}
              placeholder='End time'
              startOfPhrase='Ends'
              isDesktopSize={isDesktopSize}
              minValue={selectedStartTime}
              disabled={!selectedStartTime}
            />
            <ZonePicker
              setShow={() => {
                setShowZonePicker(prevState => !prevState);
                setShowSelectLocation(false);
                setShowDatePicker(false);
                setShowStartTimePicker(false);
                setShowEndTimePicker(false);
              }}
              setZone={setSelectedZone}
              show={showZonePicker}
              zone={selectedZone}
              isDesktopSize={isDesktopSize}
              isError={timeZoneError}
            />
          </div>
          <div 
            onClick={() => {
              setShowDatePicker(false);
              setShowEndTimePicker(false);
              setShowStartTimePicker(false);
              setShowZonePicker(false);
            }}
          >
            {
              !showSelectLocation && selectedLocation
                ? <div className='eventDetails_content-location-container' onClick={() => setShowSelectLocation(true)}>
                    <h3 className='eventDetails_content-location-title'>Location*</h3>
                    <div className='eventDetails_content-location'>
                      <img src={searchIcon} alt="" />
                      <p className='eventDetails_content-location-main'>{selectedLocation?.name}</p>
                      <p className='eventDetails_content-location-support'>{selectedLocation?.supportText}</p>
                    </div>
                  </div>
                : <FormInput
                    placeholder={selectedLocation?.name ? `${selectedLocation?.name}, ${selectedLocation?.supportText}` : 'Search'}
                    type='SearchGray'
                    label='Location*'
                    isError={!!isLocationError}
                    errorMessage={!!isLocationError ? isLocationError : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setInputLocationValue(event.target.value)}
                    value={inputLocationValue}
                    hasHelpIcon={!!isLocationError}
                    hasIconBefore
                    setShow={() => isDesktopSize ? setShowSelectLocation(false) : () => {}}
                    onFocus={() => {
                      setShowSelectLocation(true)
                    }}
                  />
            }

            {showSelectLocation && isDesktopSize && inputLocationValue.length && locations
              ? <div>
                <DropdownMenu
                  content={locations.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}`, lat: location.lat, lng: location.lng }))}
                  selectedItem={selectedLocation}
                  setSelectedItem={setSelectedLocation}
                  setShow={() => {
                    setShowSelectLocation(false);
                  }}
                  withSupportText
                  size='lg'
                  heightSize='sm'
                />
              </div>
              : null
            }
          </div>
          {showSelectLocation && !isDesktopSize
            ? createPortal(<LocationSearch
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                setShowSelectLocation={setShowSelectLocation}
                locations={locations?.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}`, lat: location.lat, lng: location.lng }))}
                inputValue={inputLocationValue}
                setInputValue={setInputLocationValue}
              />, document.getElementById('modal') as HTMLElement)
            : null
          }
          <FormInput
            label='Location Notes'
            placeholder='Add some notes'
            type='TextArea'
            value={locationNotes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setLocationNotes(e.target.value)}
          />
        </div>
        <div className='eventDetails_main-footer'>
          <Button
            buttonType='regular'
            hierarchy='secondaryGray'
            size='lg'
            fullWidth
            onClick={() => setIsSavedAsDraft(true)}
          >
            <div className='eventDetails_main-footer-buttonSaveAsDraft'>
              <img src={saveIcon} alt="save Icon" />
              <p>Save as draft</p>
            </div>
          </Button>
          <Button
            size='lg'
            buttonType='regular'
            hierarchy='primary'
            fullWidth
            contentCenter
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
