import React, { useState } from 'react';
import { fetchEvents } from '../../httpQueries/http';
import { useQuery } from '@tanstack/react-query';
import { getTimeFromMinutes } from '../../utilities/getTimeFromMinutes';
import Loader from '../Loader';
import { logoColor } from '../../types/logoColor';

interface Props {
  day: string,
  borderBottomLeft?: string,
  borderBottomRight?: string,
  disabled?: boolean,
  events: {slug: string, name: string, startTime: string, color: logoColor | null}[] | undefined,
  isLoading: boolean,
  dayIsToday?: boolean,
  setIsReviewEventModalOpen: React.Dispatch<React.SetStateAction<string>>,
}

export default function CustomScheduleDay({ day, borderBottomLeft, borderBottomRight, disabled, events, isLoading, dayIsToday, setIsReviewEventModalOpen }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div 
      className={`customScheduleDay ${isOpen ? 'customScheduleDay-showAll' : ''} ${disabled ? 'customScheduleDay-disabled' : ''} ${dayIsToday ? 'customScheduleDay-today' : ''}`}
      style={{
        borderBottomLeftRadius: borderBottomLeft ? borderBottomLeft : undefined, 
        borderBottomRightRadius: borderBottomRight ? borderBottomRight : undefined
      }}
      onClick={() => setIsOpen(false)}
    >
      <h3 className='customScheduleDay-date'>{day}</h3>
      {
        isLoading
          ? <Loader size='xl' />
          : null
      }
      {
        !disabled && events?.length
          ? <div className='customScheduleDay-event-container'>
              {
                events.slice(0, isOpen ? events.length + 1 : 2).map(event => (
                  <div className='customScheduleDay-event' key={event.slug} onClick={() => setIsReviewEventModalOpen(prevState => prevState.length ? '' : event.slug)}>
                    <div className='customScheduleDay-event-name'>
                      <div 
                        className={`
                          customScheduleDay-event-name-circle
                          ${event.color === logoColor.BLUELIGHT ? 'customScheduleDay-event-name-circle-blueLight' : ''}
                          ${event.color === logoColor.GREEN ? 'customScheduleDay-event-name-circle-success' : ''}
                          ${event.color === logoColor.ORANGE ? 'customScheduleDay-event-name-circle-warning' : ''}
                          ${event.color === logoColor.PURPLE ? 'customScheduleDay-event-name-circle-purple' : ''}
                          ${event.color === logoColor.RED ? 'customScheduleDay-event-name-circle-error' : ''}
                          ${event.color === logoColor.YELLOW ? 'customScheduleDay-event-name-circle-yellow' : ''}
                        `}
                      ></div>
                      <p 
                        className={`
                          customScheduleDay-event-name-name
                          ${event.color === logoColor.BLUELIGHT ? 'customScheduleDay-event-name-name-blueLight' : ''}
                          ${event.color === logoColor.GREEN ? 'customScheduleDay-event-name-name-success' : ''}
                          ${event.color === logoColor.ORANGE ? 'customScheduleDay-event-name-name-warning' : ''}
                          ${event.color === logoColor.PURPLE ? 'customScheduleDay-event-name-name-purple' : ''}
                          ${event.color === logoColor.RED ? 'customScheduleDay-event-name-name-error' : ''}
                          ${event.color === logoColor.YELLOW ? 'customScheduleDay-event-name-name-yellow' : ''}
                        `}
                      >
                        {event.name}
                      </p>
                    </div>
                    <p className='customScheduleDay-event-time'>{getTimeFromMinutes(event.startTime)}</p>
                  </div>
                ))
              }
              {
                events.length > 2 && !isOpen
                  ? <p className='customScheduleDay-event-moreEvents' onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true)
                  }}>+{events.length - 2} more</p>
                  : null
              }
            </div>
          : null
      }
    </div>
  )
}
