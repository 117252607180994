import React, { useEffect, useState } from 'react'
import { Button } from '../../Buttons/Button'
import { restaurantSortTypes } from '../../../types/restaurantSortTypes';
import { Dropdown } from '../../Dropdown/Dropdown';
import { useQuery } from '@tanstack/react-query';
import { fetchCustomRestaurants, fetchRestaurants } from '../../../httpQueries/http';
import RestaurantItem from './RestaurantItem';
import { FormInput } from '../../Inputs/FormInput';
import markerIcon from '../../../assets/images/marker-pin-01-gray.svg';
import arrowLeft from '../../../assets/images/arrow-narrow-left.svg';
import FeaturedIcon from '../../FeaturedIcons/FeaturedIcon';
import searchIcon from '../../../assets/images/search-lg-gray.svg';
import plusIcon from '../../../assets/images/plus-primary.svg';
import Loader from '../../Loader';
import HorizontalTabs from '../../Tabs/HorizontalTabs';
import { AttendeeRSVP } from '../../../types/attendeeRSVP';
import { MealBlockChoice, MealBlockType } from '../../../types/meals';
import { logoImage } from '../../../types/logoImage';
import { AgendaBlockType } from '../../../types/agendaBlockType';
import { userRestaurantResponseType } from '../../../types/userRestaurantResponse';
import UserRestaurantItem from './UserRestaurantItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import filterIcon from '../../../assets/images/FilterIcon.svg';
import { createPortal } from 'react-dom';
import FilterModal from '../../Modal/FilterModal';

interface Props {
  longitude: number,
  latitude: number,
  location: string,
  setSelectedRestaurant: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null>>
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentStep: React.Dispatch<React.SetStateAction<"Restaurant" | "Meals">>,
  setIsPickup: React.Dispatch<React.SetStateAction<boolean>>,
  isPickup: boolean,
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null,
  dayId: number,
  agendaBlockStartTime: number,
  setShowAddNewRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>,
  isDesktopSize: boolean,
  showSelectRestaurantModal: boolean,
  searchValue: string,
  agendaBlockId: number,
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[],
  setRestaurantsForOrders: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[]>>,
}

export default function RestaurantSelect({ 
  longitude, 
  latitude, 
  location, 
  selectedRestaurant, 
  setCurrentStep, 
  setSelectedRestaurant, 
  setShowSelectRestaurantModal, 
  setIsPickup, 
  isPickup, 
  agendaBlockStartTime, 
  dayId, 
  setShowAddNewRestaurantModal, 
  isDesktopSize, 
  showSelectRestaurantModal, 
  searchValue, 
  setSearchValue, 
  restaurantsForOrders,
  setRestaurantsForOrders,
  agendaBlockId
}: Props) {
  const token = localStorage.getItem('token');
  const [restaurants, setRestaurants] = useState<{
    stores: {
      _id: string,
      name: string,
      phone_number: number,
      address: {
        street_addr: string,
        city: string,
        state: string,
        zipcode: string,
        country: string,
        street_addr_2: string,
        latitude: number,
        longitude: number
      },
      description: string,
      local_hours: {
        operational: {
          Monday: string,
          Tuesday: string,
          Wednesday: string,
          Thursday: string,
          Friday: string,
          Saturday: string,
          Sunday: string
        },
        delivery: {
          Monday: string,
          Tuesday: string,
          Wednesday: string,
          Thursday: string,
          Friday: string,
          Saturday: string,
          Sunday: string
        },
        pickup: {
          Monday: string,
          Tuesday: string,
          Wednesday: string,
          Thursday: string,
          Friday: string,
          Saturday: string,
          Sunday: string
        },
        dine_in: {
          Monday: string,
          Tuesday: string,
          Wednesday: string,
          Thursday: string,
          Friday: string,
          Saturday: string,
          Sunday: string
        }
      },
      cuisines: string[],
      food_photos: string[],
      logo_photos: string[],
      store_photos: string[],
      dollar_signs: number,
      pickup_enabled: boolean,
      delivery_enabled: boolean,
      is_open: boolean,
      offers_first_party_delivery: boolean,
      offers_third_party_delivery: boolean,
      quote_ids: string[],
      miles: number,
      weighted_rating_value: number,
      aggregated_rating_count: number,
      supports_upc_codes: boolean,
      type: "restaurant"
    }[],
    next_page: number
  } | undefined>();
  const [customRestaurants, setCustomRestaurants] = useState<userRestaurantResponseType[] | undefined>()
  const [selectedSortType, setSelectedSortType] = useState<{ id: number, name: string, sortBy?: restaurantSortTypes } | null>({ id: 1, name: 'Relevance', sortBy: restaurantSortTypes.RELEVANCE });
  let timer = setTimeout(() => {}, 300);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<{ id: number, name: string } | null>({ id: 1, name: 'Gatherwise restaurants' });
  const [page, setPage] = useState(1);
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

  useEffect(() => {
    if (searchValue) {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        setIsLoading(true);
        
        if (currentTab?.id === 1) {
          fetchRestaurants({
            token,
            search: searchValue,
            isPickup,
            isOpen: false,
            location,
            latitude,
            longitude,
            agendaBlockStartTime,
            dayId,
            page
          }).then((res) => {
            setRestaurants(res);
            setIsLoading(false);
          });
        }

        if (currentTab?.id === 2) {
          fetchCustomRestaurants({
            token,
            search: searchValue,
            skip: 0,
            take: 10,
            latitude: `${latitude}`,
            longitude: `${longitude}`,
          }).then((res) => {
            setCustomRestaurants(res);
            setIsLoading(false);
          });
        }
      }, 400);
  
      return () => clearTimeout(timer);
    }
  }, [searchValue]);

  useEffect(() => {
    setIsLoading(true);
    if (currentTab?.id === 1) {
      fetchRestaurants({
        token,
        search: searchValue,
        isPickup,
        isOpen: false,
        location,
        latitude,
        longitude,
        agendaBlockStartTime,
        dayId,
        page
      }).then((res) => {
        setRestaurants(res);
        setIsLoading(false);
      });
    }

    if (currentTab?.id === 2) {
      fetchCustomRestaurants({
        token,
        search: searchValue,
        skip: 0,
        take: 10,
        latitude: `${latitude}`,
        longitude: `${longitude}`,
      }).then((res) => {
        setCustomRestaurants(res);
        setIsLoading(false);
      });
    }
  }, [isPickup, location, latitude, longitude, token, currentTab]);

  return (
    <div className='selectRestaurant'>
      <div className='selectRestaurant-main'>
        <header className='selectRestaurant-main-header'>
          <div className='selectRestaurant-main-header-circles'>
            <div className="eventDetails_circles">
              <div className="eventDetails-circle eventDetails-circle-fifth">
                <div className="eventDetails-circle eventDetails-circle-fourth">
                  <div className="eventDetails-circle eventDetails-circle-third">
                    <div className="eventDetails-circle eventDetails-circle-second">
                      <div className="eventDetails-circle eventDetails-circle-first">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: 'relative', zIndex: '30' }}>
            <Button 
              size='lg' 
              hierarchy={'secondaryGray'}
              buttonType='small'
              onClick={() => setShowSelectRestaurantModal(false)}
              buttonImage={arrowLeft}
            />
          </div>
          <div className='selectRestaurant-main-header-info'>
            <h3 className='selectRestaurant-main-header-info-text'>Add restaurant</h3>
            <div className='selectRestaurant-main-info-location'>
              <img src={markerIcon} alt="" />
              <p>{location}</p>
            </div>
          </div>
        </header>
        <main className='selectRestaurant-main-info'>
          <HorizontalTabs 
            tabs={[{ id: 1, name: 'Gatherwise restaurants', onSelect: () => setPage(1) }, { id: 2, name: 'Added by you', onSelect: () => setPage(1) }]} 
            current={currentTab} 
            setCurrent={setCurrentTab} 
            type={'withIllumination'} 
          />
          <div className='flex fullWidth gap-12 items-end'>
            <FormInput 
              type='SearchGray'
              placeholder={'Search for a place'}       
              label='Restaurant'   
              hasIconBefore
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {
              !isDesktopSize
                ? <Button
                    buttonType='small'
                    size='lg'
                    buttonImage={filterIcon}
                    hierarchy='secondaryGray'
                    height='60%'
                    onClick={() => setFilterModalIsOpen(true)}
                  />
                : null
            }
            {
              filterModalIsOpen
                ? createPortal(<FilterModal isPickup={isPickup} setIsPickup={setIsPickup} selectedSortType={selectedSortType} setSelectedSortType={setSelectedSortType} setShow={setFilterModalIsOpen} />, document.getElementById('modal') as HTMLElement) 
                : null
            }
          </div>
          {
            isDesktopSize
              ? <div className='selectRestaurant-main-info-filters'>
                  <Dropdown 
                    content={[
                      { id: 1, name: 'Relevance', sortBy: restaurantSortTypes.RELEVANCE },
                      { id: 2, name: 'Cheapest', sortBy: restaurantSortTypes.CHEAPEST },
                      { id: 3, name: 'Distance', sortBy: restaurantSortTypes.DISTANCE },
                      { id: 4, name: 'Fastest', sortBy: restaurantSortTypes.FASTEST },
                      { id: 5, name: 'Rating', sortBy: restaurantSortTypes.RATING }
                    ]} 
                    reactToFocus
                    id={'selectRestaurant'}
                    currentItem={selectedSortType} 
                    setCurrentItem={setSelectedSortType} 
                    withBorder 
                    top='312px'
                  />
                  <div className='flex items-center gap-4'>
                    <Button 
                      size='lg' 
                      hierarchy={!isPickup ? 'primary' : 'secondaryGray'}
                      buttonType='regular'        
                      onClick={() => setIsPickup(false)}
                    >
                      Delivery
                    </Button>
                    <Button 
                      size='lg' 
                      hierarchy={isPickup ? 'primary' : 'secondaryGray'}
                      buttonType='regular'    
                      onClick={() => setIsPickup(true)}    
                    >
                      PickUp
                    </Button>
                  </div>
                </div>
              : null
          }
          {
            isLoading
              ? <div className='flex items-center fullWidth justifyCenter'>
                  <Loader size={'xl'}  />
                </div>
              : <>
                  <p>{currentTab?.id === 1 ? restaurants?.stores.length : customRestaurants?.length} results nearby</p>
                  {
                    (currentTab?.id === 1 ? !restaurants?.stores.length : !customRestaurants?.length) && searchValue.length
                      ? <div className='flex items-center gap-2'>
                          <Button 
                            size='lg' 
                            hierarchy='linkColor'
                            buttonType='regular'
                            paddingNone
                            onClick={() => setShowAddNewRestaurantModal(true)}
                          >
                            <div className='flex items-center gap-4'>
                              <img src={plusIcon} alt="" />
                              <p>Add</p>
                            </div>
                          </Button>
                          <p>"{searchValue}" on your own</p>
                        </div>
                      : null
                  }
                  <div className='selectRestaurant-main-info-restaurants' id='scrollableDiv'>
                  {
                    currentTab?.id === 1
                      ? restaurants?.stores.length
                        ? <InfiniteScroll
                            dataLength={restaurants.stores.length}
                            next={async () => {
                              const res = await fetchRestaurants({
                                token,
                                search: searchValue,
                                isPickup,
                                isOpen: false,
                                location,
                                latitude,
                                longitude,
                                agendaBlockStartTime,
                                dayId,
                                page
                              })

                              if (res) {
                                setRestaurants(prevState => {
                                  const combinedRestaurants = [
                                    ...prevState!.stores,
                                    ...res!.stores
                                  ]
                                  return ({
                                    stores: combinedRestaurants,
                                    next_page: res!.next_page
                                  })
                                });
                              }

                              setPage(prevState => prevState + 1);
                            }}
                            hasMore={page < restaurants.next_page}
                            loader={<div className='flex fullWidth justifyCenter'>
                              <Loader size={'xl'}  />
                            </div>}
                            scrollableTarget="scrollableDiv"
                          >
                            <div className='flex flex-column gap-16'>
                              {
                                restaurants?.stores.map(restaurant => <RestaurantItem 
                                  id={restaurant._id} 
                                  name={restaurant.name} 
                                  status={restaurant.is_open} 
                                  location={restaurant.address.street_addr} 
                                  distance={restaurant.miles} 
                                  cuisines={restaurant.cuisines} 
                                  image={restaurant.logo_photos[0]} 
                                  setSelectedRestaurant={setSelectedRestaurant} 
                                  selectedRestaurantId={selectedRestaurant?.id}
                                  key={restaurant._id}
                                  restaurantsForOrders={restaurantsForOrders}
                                  setRestaurantsForOrders={setRestaurantsForOrders}
                                  agendaBlockId={agendaBlockId}
                                />)
                              }
                            </div>
                          </InfiniteScroll>
                        : <div className='modalInvite-noResult-container'>
                              <div className='modalInvite-noResult-content'>
                                <FeaturedIcon
                                  icon={searchIcon}
                                  size='lg'
                                  type='gray'
                                />
                                <div className='modalInvite-noResult-content-info'>
                                  <p className='modalInvite-noResult-content-info-title'>No results found</p>
                                  {
                                    searchValue.length
                                      ? <p className='modalInvite-noResult-content-info-description'>{`Your search “${searchValue}” did not match any restaurant. Please try again or add a new restaurant.`}</p>
                                      : null
                                  }
                                </div>
                              </div>
                          </div>
                      : customRestaurants?.length
                          ? <InfiniteScroll
                              dataLength={customRestaurants.length}
                              next={async () => {
                                const res = await fetchCustomRestaurants({
                                  token,
                                  search: searchValue,
                                  skip: page * 10,
                                  take: 10,
                                  latitude: `${latitude}`,
                                  longitude: `${longitude}`,
                                });
                              
                                if (res) {
                                  setCustomRestaurants(prevState => {
                                    const combinedRestaurants = [
                                      ...prevState!,
                                      ...res
                                    ];
                                    
                                    return combinedRestaurants
                                  });
                                }

                                setPage(prevState => prevState + 1);
                              }}
                              hasMore={customRestaurants.length < 11}
                              loader={<div className='flex fullWidth justifyCenter'>
                                <Loader size={'xl'}  />
                              </div>}
                              scrollableTarget="scrollableDiv"
                            >
                              <div className='flex flex-column gap-16'>
                                {
                                  customRestaurants.map(restaurant => <UserRestaurantItem 
                                    id={restaurant.id} 
                                    name={restaurant.name} 
                                    location={restaurant.location} 
                                    setSelectedRestaurant={setSelectedRestaurant} 
                                    selectedRestaurantId={selectedRestaurant?.id}                            
                                  />)
                                }
                              </div>
                            </InfiniteScroll>
                          : null
                  }
                  </div>
                </>
          }
        </main>
      </div>
      <footer className='selectRestaurant-footer'>
          {
            restaurants?.stores.length
              ? <Button 
                  size='lg' 
                  hierarchy={'primary'}
                  buttonType='regular'        
                  onClick={() => setCurrentStep('Meals')}
                >
                  Select meals
                </Button>
            : null
          }
      </footer>
    </div>
  )
}
