import React from 'react';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import { Button } from '../Buttons/Button';
import arrowRight from '../../assets/images/arrow-right-primary.svg';

interface Props {
  icon: string,
  title: string,
  description: string,
  actionText: string,
  action: () => void,
}

export default function InfoBlock({ icon, title, description, actionText, action }: Props) {

  return (
    <div className='contactBlock'>
      <img src={icon} alt="" />
      <div className='contactBlock-content'>
        <div>
          <h3 className='contactBlock-content-title'>{title}</h3>
          <p className='contactBlock-content-description'>{description}</p>
        </div>
        <Button 
          size='lg' 
          hierarchy='linkColor'
          buttonType='regular'
          paddingNone  
          onClick={action}
        >
          <div className='flex items-center gap-8'>
            <p>{actionText}</p>
            <img src={arrowRight} alt="" />
          </div>
        </Button>
      </div>
    </div>
  )
}
