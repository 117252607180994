export const durations = [
    {
      id: 1,
      name: '15 min',
      value: 15
    },
    {
      id: 2,
      name: '30 min',
      value: 30
    },
    {
      id: 4,
      name: '45 min',
      value: 45
    },
    {
      id: 5,
      name: '1 hr',
      value: 60
    },
    {
      id: 6,
      name: '1 hr 15 min',
      value: 75
    },
    {
      id: 7,
      name: '1 hr 30 min',
      value: 90
    },
    {
      id: 8,
      name: '1 hr 45 min',
      value: 105
    },
    {
      id: 9,
      name: '2 hr',
      value: 120
    },
    {
      id: 10,
      name: '2 hr 15 min',
      value: 135
    },
    {
      id: 11,
      name: '2 hr 30 min',
      value: 150
    },
    {
      id: 12,
      name: '2 hr 45 min',
      value: 165
    },
    {
      id: 13,
      name: '3 hr',
      value: 180
    }
  ]