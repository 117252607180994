import React, { useEffect, useState } from 'react'
import { FormInput } from '../../../components/Inputs/FormInput'
import CountryDropdown from '../../../components/Dropdown/CountryDropdown'
import avatar from '../../../assets/images/Avatar-olivia.svg';
import { Button } from '../../../components/Buttons/Button';
import Upload from '../../../components/UploadFile/Upload';
import TimeZoneDropdown from '../../../components/Dropdown/TimeZoneDropdown';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { fetchMyData, patchUserSettings } from '../../../httpQueries/http';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../../utilities/supabaseClient';
import { UserImagePlaceholder } from '../../../components/userProfileImagePlaceholder/userImagePlaceholder';
import { User } from '../../../types/userForAddType';
import DropdownWithAction from '../../../components/Dropdown/DropdownWithAction';
import { countriesList } from '../../../utilities/countriesList';
import { timeZones } from '../../../utilities/defaultTimeZonesArray';
import DropdownWithRadioButtons from '../../../components/Dropdown/DropdownWithRadioButtons';

interface Props {
  setNotification: React.Dispatch<React.SetStateAction<{
    isError: boolean;
    title: string;
  } | null>>
}

export default function MyDetails({ setNotification }: Props) {
  const [userImage, setUserImage] = useState<string>('');
  const token = window.localStorage.getItem('token');
  const navigate = useNavigate();
  const { data: user } = useQuery({
    queryKey: ['userData'],
    queryFn: () => fetchMyData({ token })
  });
  const [userFirstName, setUserFirstName] = useState(user?.fullName ? user?.fullName.split(' ')[0] : '');
  const [userLastName, setUserLastName] = useState(user?.fullName ? user?.fullName.split(' ')[1] : '');
  const [userEmail, setUserEmail] = useState(user?.email || '');
  const [timeZone, setTimeZone] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>(timeZones.find(zone => zone.name === user?.timezone || '') || null);
  const [country, setCountry] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    emoji?: string,
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>(countriesList.find(listItem => listItem.name === user?.location || '') || null);
  const [foodPreferencesList, setFoodPreferencesList] = useState<User[]>([{ id: 1, img: '', name: 'Gluten free' }, { id: 2, img: '', name: 'Keto/low carb' }, { id: 3, img: '', name: 'Vegan' }, { id: 4, img: '', name: 'Vegetarian' }]);
  const [isFoodPreferencesDropdownOpen, setIsFoodPreferencesDropdownOpen] = useState(false);
  const [allergiesList, setAllergiesList] = useState<User[]>([{ id: 1, img: '', name: 'Peanuts' }, { id: 2, img: '', name: 'Tree nuts (Specify)' }, { id: 3, img: '', name: 'Soy' }, { id: 4, img: '', name: 'Dairy' }, { id: 5, img: '', name: 'Eggs' }]);
  const [isAlergiesDropdownOpen, setIsAlergiesDropdownOpen] = useState(false);
  const [pronounsList, setPronounsList] = useState<User[]>([{ id: 1, img: '', name: 'She/her' }, { id: 2, img: '', name: 'He/him' }, { id: 3, img: '', name: 'They/them' }]);
  const [isPronounsDropdownOpen, setIsPronounsDropdownOpen] = useState(false);
  const [selectedPronoun, setSelectedPronoun] = useState<User | null>(null)
  const [allergies, setAllergies] = useState<User[]>(user?.allergies?.map(allergy => ({ img: '', name: allergy })) || []);
  const [foodPreferences, setFoodPreferences] = useState<User[]>(user?.foodPreferences?.map(preference => ({ img: '', name: preference })) || []);

  useEffect(() => {
    setUserFirstName(user?.fullName ? user?.fullName.split(' ')[0] : '');
    setUserLastName(user?.fullName ? user?.fullName.split(' ')[1] : '');
    setUserEmail(user?.email || '');
    setTimeZone(timeZones.find(zone => zone.name === user?.timezone || '') || null)
    setCountry(countriesList.find(listItem => listItem.name === user?.location || '') || null)
    setFoodPreferences(user?.foodPreferences?.map(preference => foodPreferencesList.find(pref => pref.name === preference) || { img: '', name: preference }) || []);
    setAllergies(user?.allergies?.map(allergy => allergiesList.find(allrg => allrg.name === allergy) || { img: '', name: allergy }) || [])
    setSelectedPronoun(user?.pronouns ? pronounsList.find(item => item.name === user?.pronouns) || { img: '', name: user?.pronouns } : null)
  }, [user])

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear()
    localStorage.clear();

    navigate('/login');
    
    return null
  };

  return (
    <div 
      className='eventDetails_content myDetails' 
    >
      <div className='myDetails-content'>
        <div className='myDetails-content-container'>
          <div className='myDetails-content-name'>
            <FormInput 
              placeholder='First name'
              type='Text'
              label='First name'
              fullWidth
              value={userFirstName}
              onChange={(e) => {
                setUserFirstName(e.target.value)
              }}
              onFocus={() => {
                setIsAlergiesDropdownOpen(false);
                setIsFoodPreferencesDropdownOpen(false);
              }}
            />
            <FormInput 
              placeholder='Last name'
              type='Text'
              label='Last name'
              fullWidth
              value={userLastName}
              onChange={(e) => {
                setUserLastName(e.target.value)
              }}
              onFocus={() => {
                setIsAlergiesDropdownOpen(false);
                setIsFoodPreferencesDropdownOpen(false);
              }}
            />
          </div>
          <FormInput 
            placeholder='Email address'
            type='EmailWithIconBefore'
            label='Email address'
            value={userEmail}
            onChange={(e) => {
              setUserEmail(e.target.value)
            }}
            onFocus={() => {
              setIsAlergiesDropdownOpen(false);
              setIsFoodPreferencesDropdownOpen(false);
            }}
          />
          {/* <div className='myDetails-content-dropIcon'>
            {
              userImage.length
                ? <img src={userImage} alt="user photo" className='myDetails-content-dropIcon-icon' />
                : <UserImagePlaceholder name={user?.fullName || ''} verified={false} />
            }
            <Upload setSelectedFileUrl={setUserImage} fileTypesInText='SVG, PNG, JPG or GIF (max. 800x400px)' fileTypes='image/*' />
          </div> */}
          <CountryDropdown selectedCountry={country} setSelectedCountry={setCountry} />
          <TimeZoneDropdown selectedTimeZone={timeZone} setSelectedTimeZone={setTimeZone} />
          <DropdownWithRadioButtons 
            content={pronounsList} 
            setContent={setPronounsList} 
            isDropdownOpen={isPronounsDropdownOpen}
            setIsDropdownOpen={setIsPronounsDropdownOpen} 
            label={'My pronouns'} 
            placeholder={'Pronoun'} 
            onFocus={() => {
              setIsAlergiesDropdownOpen(false);
            }} 
            selectedItem={selectedPronoun} 
            setSelectedItem={setSelectedPronoun} 
          />
          <DropdownWithAction  
            placeholder='Manage your food preferences' 
            label='Manage food preferences' 
            content={foodPreferencesList} 
            setContent={setFoodPreferencesList} 
            isDropdownOpen={isFoodPreferencesDropdownOpen} 
            setIsDropdownOpen={setIsFoodPreferencesDropdownOpen} 
            onFocus={() => {
              setIsAlergiesDropdownOpen(false);
            }}
            alreadyAddedItems={foodPreferences}
            setAlreadyAddedItems={setFoodPreferences}
          />
          <DropdownWithAction 
            placeholder='Manage your allergies' 
            label='Manage allergies' 
            content={allergiesList} 
            setContent={setAllergiesList} 
            isDropdownOpen={isAlergiesDropdownOpen} 
            setIsDropdownOpen={setIsAlergiesDropdownOpen} 
            onFocus={() => {
              setIsFoodPreferencesDropdownOpen(false);
            }}
            alreadyAddedItems={allergies}
            setAlreadyAddedItems={setAllergies}
          />
        </div>
        <div className='myDetails-footer'>
          <Button
            buttonType='regular'
            hierarchy='secondaryGray'
            size='md'
            contentCenter
          >
            Cancel
          </Button>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='md'
            contentCenter
            disabled={
              !(user?.fullName !== `${userFirstName} ${userLastName}` ||
              user?.location !== country?.name ||
              user?.allergies.length !== allergies.length ||
              user?.foodPreferences.length !== foodPreferences.length ||
              user?.timezone !== timeZone?.name || 
              user.pronouns !== selectedPronoun?.name)
            }
            asyncOnClick={async () => {
             const response = await patchUserSettings({ token, body: {
                fullName: `${userFirstName} ${userLastName}`,
                location: country?.name,
                allergies: allergies.map(item => item.name),
                foodPreferences: foodPreferences.map(item => item.name),
                pronouns: selectedPronoun?.name,
                timezone: timeZone?.name
              } });

              if (response) {
                setNotification({isError: false, title: 'Personal info updated.'})
              }
            }}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  )
}
