import React, { useState } from 'react'
import { Button } from '../../../components/Buttons/Button'
import { FormInput } from '../../../components/Inputs/FormInput'
import { supabase } from '../../../utilities/supabaseClient';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../store/currentUser';
import { updatePassword } from '../../../httpQueries/http';

interface Props {
  setNotification: React.Dispatch<React.SetStateAction<{
    isError: boolean;
    title: string;
  } | null>>
}

export default function MyPassword({ setNotification }: Props) {
  const token = localStorage.getItem('token');
  const currentUser = useSelector(currentUserSelector);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPasswordFirstTry, setNewPasswordFirstTry] = useState('');
  const [newPasswordSecondTry, setNewPasswordSecondTry] = useState('');
  const [currentPasswordInputError, setCurrentPasswordInputError] = useState('');
  const [newPasswordFirstTryInputError, setNewPasswordFirstTryInputError] = useState('');
  const [newPasswordSecondTryInputError, setNewPasswordSecondTryInputError] = useState('');

  const onUpdatePassword = async () => {
    if (!newPasswordFirstTry.length) {
      setNewPasswordFirstTryInputError('Please enter a valid password')
    }

    if (!currentPassword.length) {
      setCurrentPasswordInputError('Please enter a valid password')
    }

    if (!newPasswordSecondTry.length) {
      setNewPasswordSecondTryInputError('Please enter a valid password')
    }

    if (newPasswordFirstTry !== newPasswordSecondTry) {
      setNewPasswordSecondTryInputError('Passwords must match')
    }

    if (currentPassword.length && newPasswordFirstTry.length && newPasswordSecondTry.length && newPasswordFirstTry === newPasswordSecondTry) {
      const { data: loginResponse, error } = await supabase().auth.signInWithPassword({
        email: currentUser.email,
        password: currentPassword,
      });
  
      if (error) {
        setCurrentPasswordInputError('Invalid password')

        return;
      }

      if (loginResponse.user) {
        const updatePasswordResponse = await updatePassword({token, body: { password: newPasswordFirstTry }});

        if (updatePasswordResponse.status >= 200 && updatePasswordResponse.status < 300) {
          setNotification({ isError: false, title: 'Password updated.' })
        }

        setCurrentPassword('');
        setNewPasswordFirstTry('')
        setNewPasswordSecondTry('');
        setCurrentPasswordInputError('');
        setNewPasswordFirstTryInputError('');
        setNewPasswordSecondTryInputError('')
      }
    }
  }

  return (
    <div className='eventDetails_content myDetails'>
      <div className='myDetails-info'>
        <h3 className='myDetails-info-title'>Password</h3>
        <p className='myDetails-info-description'>Please enter your current password to change your password.</p>
      </div>
      <div className='myDetails-content'>
        <div className='myDetails-content-container'>
          <FormInput 
            placeholder='••••••••'
            type='Password'
            label='Current password'
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value)
            }}
            errorMessage={currentPasswordInputError}
            isError={!!currentPasswordInputError.length}
          />
          <FormInput 
            placeholder='••••••••'
            type='Password'
            label='New password'
            value={newPasswordFirstTry}
            onChange={(e) => {
              setNewPasswordFirstTry(e.target.value)
            }}
            errorMessage={newPasswordFirstTryInputError}
            isError={!!newPasswordFirstTryInputError.length}
          />
          <FormInput 
            placeholder='••••••••'
            type='Password'
            label='Confirm new password'
            value={newPasswordSecondTry}
            onChange={(e) => {
              setNewPasswordSecondTry(e.target.value)
            }}
            errorMessage={newPasswordSecondTryInputError}
            isError={!!newPasswordSecondTryInputError.length}
          />
        </div>
        <div className='myDetails-footer'>
          <Button
            buttonType='regular'
            hierarchy='secondaryGray'
            size='md'
            contentCenter
          >
            Cancel
          </Button>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='md'
            contentCenter
            asyncOnClick={onUpdatePassword}
          >
            Update password
          </Button>
        </div>
      </div>
    </div>
  )
}
