import React, { useContext, useEffect, useState } from 'react';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import userPlusIcon from '../../assets/images/user-plus-01.svg';
import { Button } from '../Buttons/Button';
import { FormInput } from '../Inputs/FormInput';
import { DropdownMenu } from '../Dropdown/DropdownMenu';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import { editAttendee, fetchLocations, postAttendee, postAttendeeToAttendeesNetwork } from '../../httpQueries/http';
import { createPortal } from 'react-dom';
import { LocationSearch } from '../LocationSearch/LocationSearch';
import { validateEmail } from '../../utilities/validateEmail';

interface Props {
  setShow: () => void,
  userFullName: string,
  setUserFullName: React.Dispatch<React.SetStateAction<string>>,
  userEmail: string,
  setUserEmail: React.Dispatch<React.SetStateAction<string>>,
  userPhoneNumber: string,
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string>>,
  userBusinessName: string,
  setUserBusinessName: React.Dispatch<React.SetStateAction<string>>,
  userTitle: string,
  setUserTitle: React.Dispatch<React.SetStateAction<string>>,
  eventId: number,
  setNewUserAdded: React.Dispatch<React.SetStateAction<boolean>>,
  isContactsPage?: boolean,
  isEdit?: number | null,
  setUserUpdated?: React.Dispatch<React.SetStateAction<number | null>>,
  isDesktopSize: boolean
}

export default function ModalAddNewAttende({
  setShow, 
  userFullName, 
  setUserFullName, 
  userEmail, 
  setUserEmail, 
  setUserPhoneNumber, 
  userPhoneNumber,
  setUserBusinessName,
  userBusinessName,
  setUserTitle,
  userTitle,
  eventId,
  setNewUserAdded,
  isContactsPage,
  isEdit,
  setUserUpdated,
  isDesktopSize
}: Props) {
  const [isNameError, setIsNameError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isAskForAdd, setIsAskForAdd] = useState(false);
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const token = localStorage.getItem('token');
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<{id: number, name: string, supportText?: string | undefined, lng?: string, lat?: string} | null>(null)
  const [locations, setLocations] = useState<{name: string, lng: number, lat: number}[] | undefined>([]);
  let timer = setTimeout(() => {}, 300);
  

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById('modal')?.classList.add('modal-main-container');
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto';
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(async () => {
      const response = await fetchLocations({ search: inputLocationValue, token });
      setLocations(response);
    }, 400);

    return () => clearTimeout(timer);
  }, [inputLocationValue])

  useEffect(() => {
    if (!isEmailError && !isNameError && userFullName.length && userEmail.length && isAskForAdd) {
      if (!!isEdit) {
        if (!setUserUpdated) {
          setShow()
          return;
        }

        editAttendee({
          body: {
            email: userEmail,
            businessName: userBusinessName,
            address: '',
            title: userTitle,
            fullName: userFullName,
            phone: userPhoneNumber,
          },
          token,
          id: isEdit
        }).then(() => {
          setNewUserAdded(true);
          setUserUpdated(null);
        });
      } else if (isContactsPage) {
        postAttendeeToAttendeesNetwork({
          body: {
            email: userEmail,
            businessName: userBusinessName,
            address: '',
            title: userTitle,
            fullName: userFullName,
            phone: userPhoneNumber,
          },
          token
        }).then(() => setNewUserAdded(true));
      } else {
        postAttendee({
          body: {
            email: userEmail,
            businessName: userBusinessName,
            address: '',
            title: userTitle,
            eventId: eventId,
            fullName: userFullName,
            phone: userPhoneNumber,
          },
          token
        }).then(() => setNewUserAdded(true));
      }
 
      setUserFullName('');
      setUserBusinessName('');
      setUserEmail('');
      setUserPhoneNumber('');
      setUserTitle('');
      setShow();
    }

    setIsAskForAdd(false);
  }, [isAskForAdd]);

  const onBackClick = () => {
    setUserBusinessName('');
    setUserEmail('');
    setUserFullName('');
    setUserPhoneNumber('');
    setUserTitle('');
    setShow();
  };

  const onAddUser = () => {
    if (!userFullName.trim().length) {
      setIsNameError(true);
    }

    if (userFullName.trim().length) {
      setIsNameError(false);
    }

    if (!userEmail.trim().length || !validateEmail(userEmail.trim())) {
      setIsEmailError(true);
    }

    if (userEmail.trim().length && validateEmail(userEmail.trim())) {
      setIsEmailError(false);
    }

    setIsAskForAdd(true);
  }

  return (
    <div className='modalNotification'>
      <div className='modalNotification-container modalNotification-container-inviteAttendees'>
      <div className='modalNotification-circles-container'>
        <div className='modalNotification-circles'>
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className='modalNotification-header'>
          <div className='modalNotification-header-actions'>
            <FeaturedIcon icon={userPlusIcon} size='lg' type='gray' />
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={onBackClick}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>{isEdit ? 'Edit attendee' : 'Add new attendee'}</h3>
          </div>
        </div>
        <div className='modalNotification-main'>
          <FormInput
            label={userFullName.length ? 'Full name*' : undefined}
            placeholder='Full name*'
            type='Text'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUserFullName(event.target.value)}
            value={userFullName}
            isError={isNameError}
          />
          <FormInput 
            label={userEmail.length ? 'Email address*' : undefined}
            placeholder='Email address*'
            type='Email'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUserEmail(event.target.value)}
            value={userEmail}
            isError={isEmailError}
          />
          <FormInput 
            label={userPhoneNumber.length ? 'Phone number' : undefined}
            placeholder='Phone number'
            type='Phone'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUserPhoneNumber(event.target.value)}
            value={userPhoneNumber}
          />
          <div >
            {
              !showSelectLocation && selectedLocation
                ? <div className='eventDetails_content-location-container' onClick={() => setShowSelectLocation(true)}>
                    <h3 className='eventDetails_content-location-title'>Location</h3>
                    <div className='eventDetails_content-location'>
                      <p className='eventDetails_content-location-main'>{selectedLocation?.name}</p>
                      <p className='eventDetails_content-location-support'>{selectedLocation?.supportText}</p>
                    </div>
                  </div>
                : <FormInput
                    placeholder={selectedLocation?.name ? `${selectedLocation?.name}, ${selectedLocation?.supportText}` : 'Address'}
                    type='SearchGray'
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setInputLocationValue(event.target.value)}
                    value={inputLocationValue}
                    setShow={() => isDesktopSize ? setShowSelectLocation(false) : () => {}}
                    onFocus={() => {
                      setShowSelectLocation(true)
                    }}
                  />
            }

            {showSelectLocation && isDesktopSize && inputLocationValue.length && locations
              ? <div>
                <DropdownMenu
                  content={locations.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}`, lat: location.lat, lng: location.lng }))}
                  selectedItem={selectedLocation}
                  setSelectedItem={setSelectedLocation}
                  setShow={() => {
                    setShowSelectLocation(false);
                  }}
                  withSupportText
                  size='lg'
                  heightSize='xs'
                  fullWidth
                />
              </div>
              : null
            }
          </div>
          {showSelectLocation && !isDesktopSize
            ? createPortal(<LocationSearch
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                setShowSelectLocation={setShowSelectLocation}
                locations={locations?.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}` }))}
                inputValue={inputLocationValue}
                setInputValue={setInputLocationValue}
              />, document.getElementById('modal') as HTMLElement)
            : null
          }
          <FormInput 
            label={userBusinessName.length ? 'Business name' : undefined}
            placeholder='Business name'
            type='Text'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUserBusinessName(event.target.value)}
            value={userBusinessName}
          />
          <FormInput 
            label={userTitle.length ? 'Title' : undefined}
            placeholder='Title'
            type='Text'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUserTitle(event.target.value)}
            value={userTitle}
          />
        </div>
        <div className='modalNotification-actions modalNotification-actions-flex-end' style={{marginLeft: 'auto'}}>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            fullWidth={!isDesktopSize}
            onClick={onAddUser}
          >
            {isEdit ? 'Save' : 'Add user'}
          </Button>
        </div>
      </div>
    </div>
  )
}
