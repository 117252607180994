import { AttendeeRSVP } from '../../types/attendeeRSVP';
import userRsvpSuccess from '../../assets/images/userRsvpSuccess.svg';
import userRsvpError from '../../assets/images/userRsvpError.svg';
import userRsvpMaybe from '../../assets/images/userRsvpMaybe.svg';

interface Props {
  eventName: string,
  notifications: {
    type: AttendeeRSVP,
    name: string
  }[],
  onScroll: () => void
}

export default function NotificationsPage({ eventName, notifications, onScroll }: Props) {
  return (
    <div className='eventDetailsWelcomePage' onScroll={onScroll}>
      {
        notifications.map(notification => (
            <div className='agendaItem-info-title'>
              <img src={
                notification.type === AttendeeRSVP.Yes
                  ? userRsvpSuccess
                  : notification.type === AttendeeRSVP.No
                      ? userRsvpError
                      : userRsvpMaybe
              } alt="user rsvp" className='eventDetailsWelcomePage-userRSVPImage' />
              <div>
                <h3 
                  className='agendaItem-info-title-text'
                >
                  {notification.name} 
                  {
                    notification.type === AttendeeRSVP.Yes
                      ? ' will be attending'
                      : notification.type === AttendeeRSVP.No
                          ? ' will not be attending'
                          : ' may attend'
                  }
                </h3>
              </div>
            </div>
        ))
      }
    </div>
  )
}

