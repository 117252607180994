import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import Navigation from "../../components/Navigation/SideBarNavigation";
import oliviaPhoto from '../../assets/images/Avatar-olivia.svg';
import HorizontalTabs from '../../components/Tabs/HorizontalTabs';
import ContactBlock from '../../components/Blocks/ContactBlock';
import messageIcon from '../../assets/images/message-chat-circle.svg';
import phoneIcon from '../../assets/images/phone.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchSupportInfoData } from '../../httpQueries/http';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../utilities/supabaseClient';

export function Support() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const navigate = useNavigate();

  const token = window.localStorage.getItem('token')

  const { data } = useQuery({
    queryKey: ['support data', token],
    queryFn: () => fetchSupportInfoData({ token })
  })

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear()
    localStorage.clear();

    navigate('/login');
    
    return null
  }

  return (
    <div className={`eventDetails_container`}>
      {sizeIsDesktop
        ? <Navigation
            hierarchy='primary'
            currentItem='Support'
          />
        : null
      }
      <div className='eventDetails_main-container'>
        <div className="eventDetails_main">
          <div className={`eventDetails_main-header px-32`}>
            <div className='eventsPage-header'>
              <div className='eventsPage-header-info'>
                <h3 className='eventsPage-header-title'>Support</h3>
                <p>Our friendly team is always here to chat.</p>
              </div>
            </div>
          </div>
          <div className='eventDetails_content flex flex-column gap-20 px-32'>
            <div className={`flex gap-24 halfWidth ${sizeIsDesktop ? 'flex-row' : 'flex-column'}`}>
              <ContactBlock 
                icon={messageIcon}
                title='Let us know how we can help.'
                description="Send us an email and we'll get right back to you!"
                action='email'
                actionText={data?.Email || ''}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
