import { useEffect, useState } from 'react';
import { Button } from "../Buttons/Button";
import { FormInput } from "../Inputs/FormInput";
import arrowLeftIcon from '../../assets/images/arrow-left.svg';
import { DropdownMenu } from "../Dropdown/DropdownMenu";

interface Props {
  selectedLocation: {id: number, name: string} | null,
  setSelectedLocation: React.Dispatch<React.SetStateAction<{id: number, name: string} | null>>,
  setShowSelectLocation: React.Dispatch<React.SetStateAction<boolean>>,
  locations?: any[],
  inputValue: string,
  setInputValue: React.Dispatch<React.SetStateAction<string>>
}

export function LocationSearch({ selectedLocation, setSelectedLocation, setShowSelectLocation, locations, inputValue, setInputValue  }: Props) {
  return (
    <div className='location'>
      <div className='location_header'>
        <h3 className='location_header-title'>Location</h3>
        <FormInput
          placeholder="Where will you meet?"
          type='SearchGray'
          hasIconBefore
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setInputValue(event.target.value)}
        />
        <div className='location_header-backButton'>
          <Button
            buttonType='regular'
            hierarchy='linkGray'
            size='md'
            onClick={() => setShowSelectLocation(false)}
          >
            <div style={{display: 'flex', gap: '8px'}}>
              <img src={arrowLeftIcon} alt="arrow left" />
              <p>Back</p>
            </div>
          </Button>
        </div>
      </div>
      <div className='location_main'>
        <DropdownMenu
          content={locations || []}
          selectedItem={selectedLocation}
          setSelectedItem={setSelectedLocation}
          withSupportText
          fullHeight
          size='base'
          setShow={() => setShowSelectLocation(prevState => !prevState)}
        />
      </div>
    </div>
  )
}
