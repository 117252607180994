import React from 'react';
import { logoColor } from '../../types/logoColor';

interface Props {
  image: string,
  size: 'sm' | 'md' | 'lg',
  height?: string | undefined,
  position?: 'absolute' | undefined,
  positionTop?: string | undefined,
  color?: logoColor | 'pastEvent',
  opacity?: number
 }

export default function LogoRepeat({ image, size, height, position, positionTop, color, opacity }: Props) {
  return (
    <div 
      className={`
        logoRepeat 
        ${size === 'sm' ? 'logoRepeat-sm' : ''}
        ${size === 'md' ? 'logoRepeat-md' : ''}
        ${size === 'lg' ? 'logoRepeat-lg' : ''}
        ${color === logoColor.YELLOW ? 'logoRepeat-yellow' : ''}
        ${color === logoColor.BLUELIGHT ? 'logoRepeat-blueLight' : ''}
        ${color === logoColor.RED ? 'logoRepeat-error' : ''}
        ${color === logoColor.PURPLE ? 'logoRepeat-purple' : ''}
        ${color === logoColor.GREEN ? 'logoRepeat-success' : ''}
        ${color === logoColor.ORANGE ? 'logoRepeat-warning' : ''}
        ${color === 'pastEvent' ? 'logoRepeat-pastEvent' : ''}
      `}
      style={{height: height , position: position, top: positionTop, opacity: opacity}}
    >
      <div className='logoRepeat-content'>
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
      </div>
      <div className='logoRepeat-content logoRepeat-content-second'>
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
      </div>
      <div className='logoRepeat-content'>
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image ${size === 'lg' ? 'logoRepeat-content-image-lg' : ''}`} />
        <img src={image} alt="" className={`logoRepeat-content-image-second ${size === 'lg' ? 'logoRepeat-content-image-second-lg' : ''}`} />
      </div>
    </div>
  )
}
