import React from 'react'

interface Props {
  icon: string,
  type: 'gray' | 'primary' | 'warning' | 'success' | 'primaryColor' | 'grayColor' | 'secondaryColor',
  size: 'sm' | 'md' | 'lg',
  positionAbsolute?: boolean,
  onClick?: () => void
}

export default function FeaturedIcon({ icon, type, size, positionAbsolute, onClick }: Props) {
  return (
    <img
      src={icon} 
      alt="" 
      className={`
        progressSteps-item-icon 
        ${size === 'sm' ? 'progressSteps-item-icon-sm' : ''} 
        ${size === 'md' ? 'progressSteps-item-icon-md' : ''} 
        ${size === 'lg' ? 'progressSteps-item-icon-lg' : ''} 
        ${type === 'primary' ? 'progressSteps-item-icon-current' : ''}
        ${type === 'warning' ? 'progressSteps-item-icon-warning' : ''}
        ${type === 'success' ? 'progressSteps-item-icon-success' : ''}
        ${type === 'primaryColor' ? 'progressSteps-item-icon-primary' : ''}
        ${type === 'grayColor' ? 'progressSteps-item-icon-grayColor' : ''}
        ${type === 'secondaryColor' ? 'progressSteps-item-icon-secondaryColor' : ''}
        ${positionAbsolute ? 'modalInvite-stepIcon' : ''}
      `}
      onClick={onClick ? onClick : undefined}
    />
  )
}
