import React from 'react';
import Counter from '../../Counter/Counter';

interface Props {
  image: string,
  title: string, 
  description: string,
  price: string,
  onIcnrease: () => void,
  onDescrease: () => void,
  count: number
}

export default function SimpleAddMeal({ image, title, description, price, onDescrease, onIcnrease, count }: Props) {
  return (
    <div className={`restaurantDetails-info-restaurant`}>
      <img src={image} alt="restaurant logo" className='restaurantDetails-image' />
      <div className='restaurantDetails-info-restaurant-info'>
        <h3 className='restaurantDetails-info-restaurant-info-name'>
          {title}
        </h3>
        <p className='restaurantDetails-info-restaurant-info-text'>{description}</p>
        <div className='flex gap-8 items-center'>
          <p className='text-md font-medium'>{price}</p>
          <Counter 
            onIncrease={onIcnrease} onDescrease={onDescrease} count={count} onInputChange={() => {}} />
        </div>
      </div>
    </div>
  )
}
