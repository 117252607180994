import React from 'react'
import { Button } from './Buttons/Button';
import arrowRightUp from '../assets/images/arrow-up-right.svg';

export default function LocationButton({ lng, lat, name }: { lng: string | null, lat: string | null, name: string }) {
  return (
    <Button
      buttonType='regular'
      hierarchy='linkColor'
      size='lg'
      paddingNone
      fullWidth
      onClick={() => window.open(`http://maps.google.co.uk/maps?q=${lat}, ${lng}`, '_blank', 'noreferrer')}
    >
      <div className='flex gap-5 fullWidth'>
        <p className='reviewDetailsSection-button-content'>{`${name}`}</p>
        <img src={arrowRightUp} alt="" />
      </div>
    </Button>
  )
}
