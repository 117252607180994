import regularClose from '../../assets/images/x-close.svg';
import regularCloseLarge from '../../assets/images/x-close-lg.svg';
import whiteClose from '../../assets/images/x-close-white.svg';
import whiteCloseLarge from '../../assets/images/x-close-white-lg.svg';
import grayClose from '../../assets/images/x-close-gray.svg';
import grayCloseLarge from '../../assets/images/x-close-gray-lg.svg';

interface Props {
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  hierarchy:
    'primary' |
    'secondaryGray' |
    'secondaryColor' |
    'tertiaryGray' |
    'tertiaryColor' |
    'linkGray' |
    'linkColor'|
    'error' |
    'errorSecondaryGray' |
    'errorSecondaryColor' |
    'errorTertiaryGray' |
    'errorLinkGray' |
    'closePrimary' |
    'closePrimaryBlackTheme' |
    'closeGray' |
    'closeGrayBlackTheme';
  buttonType: 'regular' | 'isOnline' | 'small' | 'close';
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e?: any) => void;
  asyncOnClick?: () => Promise<void>;
  fullWidth?: boolean,
  buttonImage?: string,
  contentCenter?: boolean,
  paddingNone?: boolean,
  pointerEventsNone?: boolean,
  fullHeight?: boolean,
  halfHeight?: boolean,
  height?: string
}

export function Button({ 
  size, 
  hierarchy, 
  buttonType, 
  disabled, 
  children, 
  onClick, 
  fullWidth, 
  buttonImage, 
  contentCenter, 
  paddingNone, 
  pointerEventsNone,
  asyncOnClick,
  fullHeight,
  halfHeight,
  height
}: Props) {
  // for default return medium size
  function getButtonSize() {
    switch(size) {
      case 'sm':
        return (buttonType === 'close' || buttonType === 'small' ? 'button_square-sm' : 'button-sm text-sm');
      case 'lg':
        return (buttonType === 'close' || buttonType === 'small' ? 'button_square-lg' : 'button-lg text-md');
      case 'xl':
        return (buttonType === 'close' || buttonType === 'small' ? 'button_square-xl' : 'button-xl text-md');
      case '2xl':
        return (buttonType === 'close' || buttonType === 'small' ? 'button_square-2xl' : 'button-2xl text-lg');
      default:
        return (buttonType === 'close' || buttonType === 'small' ? 'button_square-md' : 'button-md text-sm');
    }
  };

  function getColorHierarchy() {
    switch(hierarchy) {
      case 'primary':
        return 'button_primary textPrimary border';
      
      case 'secondaryGray':
        return 'button_secondaryGray textSecondaryGray border';
      
      case 'secondaryColor':
        return 'button_secondaryColor textSecondaryColor border';

      case 'tertiaryGray':
        return 'button_tertiaryGray textTertiaryGray border-none';

      case 'tertiaryColor':
        return 'button_tertiaryColor textTertiaryColor border-none';
      
      case 'linkGray':
        return 'textLinkGray border-none';

      case 'linkColor':
        return 'textLinkColor border-none';
      
      case 'error':
        return 'button_error textPrimary border';

      case 'errorSecondaryGray':
        return 'button_errorSecondaryGray textError border';

      case 'errorSecondaryColor':
        return 'button_errorSecondaryColor textError border';
      
      case "errorTertiaryGray":
        return 'button_errorTertiaryGray textError border-none';

      case "errorLinkGray":
        return 'textError border-none';

      case 'closePrimary':
        return 'button_close-primary button_close border-none';

      case 'closePrimaryBlackTheme':
        return 'button_close-primary-blackTheme button_close border-none';

      case 'closeGray':
        return 'button_close-gray button_close border-none';

      case 'closeGrayBlackTheme':
        return 'button_close-gray-blackTheme button_close border-none';
    }
  };

  function getButtonIsOnlineCircleColor() {
    switch(hierarchy) {
      case 'primary':
        return 'buttonIsOnlineCircle_primary';
      
      case 'secondaryGray':
        return 'buttonIsOnlineCircle_success';
      
      case 'secondaryColor':
        return 'buttonIsOnlineCircle_success';

      case 'tertiaryGray':
        return 'buttonIsOnlineCircle_success';

      case 'tertiaryColor':
        return 'buttonIsOnlineCircle_success';
      
      case 'linkGray':
        return 'buttonIsOnlineCircle_success';

      case 'linkColor':
        return 'buttonIsOnlineCircle_success';
      
      case 'error':
        return 'buttonIsOnlineCircle_primary';

      case 'errorSecondaryGray':
        return 'buttonIsOnlineCircle_success';

      case 'errorSecondaryColor':
        return 'buttonIsOnlineCircle_success';
      
      case "errorTertiaryGray":
        return 'buttonIsOnlineCircle_success';

      case "errorLinkGray":
        return 'buttonIsOnlineCircle_success';
    }
  }

  function getButtonIsOnlineDisabledClasses() {
    switch(hierarchy) {
      case 'primary':
        return '';
      
      case 'secondaryGray':
        return 'buttonIsOnlineCircle_success-disabled';
      
      case 'secondaryColor':
        return 'buttonIsOnlineCircle_secondaryColor-disabled';

      case 'tertiaryGray':
        return 'buttonIsOnlineCircle_success-disabled';

      case 'tertiaryColor':
        return 'buttonIsOnlineCircle_success-disabled';
      
      case 'linkGray':
        return 'buttonIsOnlineCircle_success-disabled';

      case 'linkColor':
        return 'buttonIsOnlineCircle_secondaryGray-disabled';
      
      case 'error':
        return '';

      case 'errorSecondaryGray':
        return 'buttonIsOnlineCircle_error-disabled';

      case 'errorSecondaryColor':
        return 'buttonIsOnlineCircle_error-disabled';
      
      case "errorTertiaryGray":
        return 'buttonIsOnlineCircle_error-disabled';

      case "errorLinkGray":
        return 'buttonIsOnlineCircle_error-disabled';
    }
  }

  function getCloseIcon() {
    switch(hierarchy) {
      case 'closePrimary':
        return (size === '2xl' ? regularCloseLarge : regularClose);
      
      case 'closePrimaryBlackTheme':
        return (size === '2xl' ? whiteCloseLarge : whiteClose);

      case 'closeGray':
        return (size === '2xl' ? grayCloseLarge : grayClose);

      case 'closeGrayBlackTheme':
        return (size === '2xl' ? whiteCloseLarge : whiteClose);
    }
  }

  return (
    <button 
      className={
        `button
          font-semibold ${getButtonSize()} 
          ${getColorHierarchy()} 
          ${pointerEventsNone ? 'pointerEventsNone' : ''}
          ${fullWidth ? 'button-fullWidth' : ''} 
          ${fullHeight ? 'button-fullHeight' : ''} 
          ${contentCenter ? 'button-contentCenter' : ''}
          ${paddingNone ? 'button_paddingNone' : ''}
          ${pointerEventsNone ? 'pointerEventsNone' : ''}
          ${halfHeight ? 'halfHeight' : ''}
        `
      }
      style={{ height: height }}
      disabled={disabled}
      onClick={onClick ? onClick : asyncOnClick}
    >
      {buttonType === 'small'
      && (hierarchy !== 'linkColor' && hierarchy !== 'linkGray' && hierarchy !== 'errorLinkGray')
        ? <img src={buttonImage} alt="" />
        : null
      }

      {buttonType === 'close'
        ? <img
            src={getCloseIcon()}
            alt="close"
          />
        : null
      }

      {buttonType === 'isOnline' || buttonType === 'regular'
        ? <>
            {
              buttonType === 'isOnline'
                ? <div
                    className={`buttonIsOnlineCircle ${getButtonIsOnlineCircleColor()} ${disabled ? getButtonIsOnlineDisabledClasses() : ''}`}
                  />
                : null
            }
            <div className='button-content'>{children}</div>
          </>
        : null
      }
    </button>
  )
}
