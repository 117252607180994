import React, { useEffect, useState } from 'react';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import faceHappy from '../../assets/images/face-happy-sm.svg';
import presentationChart from '../../assets/images/presentation-chart-01-sm.svg';
import clockIcon from '../../assets/images/clock-sm.svg';
import { Button } from '../Buttons/Button';
import { createPortal } from 'react-dom';
import ModalReviewAgenda from '../Modal/ModalReviewAgenda';
import { useWindowSize } from '@uidotdev/usehooks';
import ReviewAgenda from '../ReviewAgenda/ReviewAgenda';
import AgendaItem from '../Agenda/AgendaItem';
import { AgendaBlockType } from '../../types/agendaBlockType';

interface Props {
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number
  }[],
}

export default function ReviewAgendaSection({ agenda }: Props) {
  const [isReviewAgendaOpen, setIsReviewAgendaOpen] = useState(false);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 

  return (
    <div className='flex flex-column gap-20 fullWidth'>
      {
        agenda.map(item => <AgendaItem {...item} height='max' key={item.id} />)
      }
    </div>
  )
}
