export enum MealBlockType {
    Delivery = 'delivery',
    Pickup = 'pickup',
}

export enum MealBlockChoice {
    Free = 'free',
    Organizer = 'organizer',
    PreSelected = 'pre-selected',
    // BringYourOwn = 'bring-your-own', // Not implemented yet
}

export enum MealBlockState {
    Open = 'open',
    PreSelected = 'pre-selected',
    Locked = 'locked',
    Paid = 'paid',
    Delivered = 'delivered',
}