import React from 'react';
import pencilIcon from '../../assets/images/pencil-02.svg';
import flagIcon from '../../assets/images/flag-05.svg';
import usersAddIcon from '../../assets/images/users-plus.svg';
import starsIcon from '../../assets/images/stars-02.svg';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import checkIcon from '../../assets/images/check-circle.svg';
import { useNavigate } from 'react-router-dom';

const DUMMY_STEPS = [
  {
    id: 1,
    img: pencilIcon,
    title: 'Event details',
    subtitle: 'Set up the basics',
    navigatePath: (slug: string) => `/edit/${slug}/details`
  },
  {
    id: 2,
    img: flagIcon,
    title: 'Agenda',
    subtitle: 'A few details about your event',
    navigatePath: (slug: string) => `/edit/${slug}/agenda`
  },
  {
    id: 3,
    img: usersAddIcon,
    title: 'Invite attendees',
    subtitle: 'Start collaborating',
    navigatePath: (slug: string) => `/edit/${slug}/inviteAttendees`
  },
  {
    id: 4,
    img: starsIcon,
    title: 'Review and submit',
    subtitle: 'Review and submit',
    navigatePath: (slug: string) => `/edit/${slug}/reviewAndSubmit`
  },
]

interface Props {
  current: number,
  slug: string,
}

export default function ProgressSteps({ current, slug }: Props) {
  const navigate = useNavigate();

  return (
    <div className='progressSteps'>
      <div className='progressSteps-container'>
        {DUMMY_STEPS.map((step, i) => {
          return (
            <React.Fragment key={step.id}>
              <div 
                className={`progressSteps-item ${(current >= step.id) ? 'progressSteps-item-passed' : ''}`}
                onClick={() => {
                  if (current >= step.id) {
                    const navigatePath = step.navigatePath(slug);
                    navigate(navigatePath);
                  }
                }}
              >
                <FeaturedIcon 
                  icon={current > step.id ? checkIcon : step.img} 
                  type={current > step.id ? 'success' : (current <= step.id) && current === step.id ? 'primary' : 'gray'} 
                  size='lg' 
                />
                <div className={`progressSteps-item-info ${current === step.id ? 'progressSteps-item-info-current' : ''}`}>
                  <h3>{step.title}</h3>
                  <p>{step.subtitle}</p>
                </div>
              </div>
              {i + 1 !== DUMMY_STEPS.length ? <div className={`progressSteps-line progressSteps-line-${i + 1} ${(current === 1 && step.id === 1) || current === step.id + 1 ? 'progressSteps-line-current' : ''}`}></div> : null}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
