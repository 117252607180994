import checkIcon from '../../assets/images/check.svg';
import { useState, useEffect } from 'react';
import { restaurantSortTypes } from '../../types/restaurantSortTypes';

interface Props {
  selectedItem: {id: number, name: string | restaurantSortTypes, img?: string, supportText?: string, value?: number, timezone?: string, lat?: string, lng?: string, emoji?: string,} | null,
  setSelectedItem: React.Dispatch<React.SetStateAction<{id: number, name: string | restaurantSortTypes, img?: string, supportText?: string, value?: number, timezone?: string, lat?: string, lng?: string, emoji?: string,} | null>>,
  content: any[],
  withSupportText?: boolean,
  positionBottom?: string | null,
  positionTop?: string | null,
  positionLeft?: string | null,
  positionRight?: string | null,
  fullHeight?: boolean,
  setShow: () => void,
  size: 'base' | 'lg' | 'max-content',
  heightSize?: 'sm' | 'auto' | 'xs',
  minValue?: {id: number, name: string} | null,
  reactToFocus?: boolean,
  fullWidth?: boolean
}

export function DropdownMenu(
  { 
    selectedItem, 
    setSelectedItem, 
    content, 
    withSupportText, 
    positionBottom = null, 
    positionTop = null, 
    positionLeft = null, 
    positionRight = null, 
    fullHeight,
    setShow,
    size,
    heightSize,
    minValue, 
    reactToFocus, 
    fullWidth
  }: Props
) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    isSelected === true && setShow();
  }, [isSelected]);

  useEffect(() => {
    const formElement = document.getElementById('formElementDropdownMenu');

    if (reactToFocus && formElement) {
      formElement?.focus(); 
      formElement.style.zIndex = '100000'
    }
  }, []);

  return (
    <form 
      onMouseDown={(e) => e.preventDefault()}
      className={`
        timePicker 
        ${fullHeight ? 'timePicker-fullHeight' : ''} 
        ${size === 'lg' ? 'timePicker-lg' : ''} 
        ${size === 'max-content' ? 'heightMaxContent maxWidth-80' : ''}
        ${heightSize === 'sm' ? 'timePicker-heightSm' : ''}
        ${heightSize === 'xs' ? 'timePicker-heightXs' : ''}
        ${heightSize === 'auto' ? 'timePicker-heightAuto' : ''}
        ${fullWidth ? 'fullWidth' : ''}
      `}
      style={{bottom: `${positionBottom}`, top: `${positionTop}`, left: `${positionLeft}`, right: `${positionRight}`}}
      autoFocus
      tabIndex={0}
      id='formElementDropdownMenu'
      onBlur={() => {

        if (reactToFocus) {
          setShow()
        }
      }}
    >
      <ul className='timePicker-timeContainer'>
        {content.map(item => {
          return (
            <div 
              className={`timePicker-timeContainer-time ${selectedItem?.id === item.id ? 'timePicker-time-selected' : ''} ${minValue && minValue.id >= item.id ? 'timePicker-time-disabled' : ''}`} 
              key={item.id}
            >
              <div
                className='timePicker-timeContainer-info overflowXScroll'
                onClick={() => {
                  setSelectedItem(item);
                  setIsSelected(true);
                  item.onSelect && item.onSelect()
                }}
              >
                {
                  item.img
                    ? <img src={item.img} alt="" />
                    : null
                }
                {
                  item.emoji 
                    ? <p>{item.emoji}</p>
                    : null
                }
                <li
                  className={`timePicker-time ${minValue && minValue.id >= item.id ? 'timePicker-time-disabled' : ''}`}
                >
                  {item.name}
                </li>
                {withSupportText ? <p className='timePicker-time-supportText'>{item.supportText}</p> : null}
              </div>
              {selectedItem?.id === item.id ? <img src={checkIcon} alt="selected icon" /> : null}
            </div>
          )
        })}
      </ul>
    </form>
  )
}
