import React, { useEffect, useState } from 'react'
import EventsGallery from '../../components/Gallery/EventsGallery';
import buttonPlusIcon from '../../assets/images/ButtonPlusImg.svg';
import Navigation from "../../components/Navigation/SideBarNavigation";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { fetchDocument, fetchEvents, fetchMeals, fetchRestaurantMenu, fetchSpecificRestaurant, fetchTodoItems, postAttendeeRSVP, postMealsSelections, queryClient } from '../../httpQueries/http';
import Todo from '../../components/Todo/Todo';
import { TodoItemType } from '../../types/todoItem';
import { Button } from '../../components/Buttons/Button';
import { createPortal } from 'react-dom';
import PreviewPreselectedMealsInviteesMeals from '../ReviewEventsDetailsPage/SelectCustomizationAttendeesView/PreviewPreselectedMealsForAttendees';
import { MealBlockType } from '../../types/meals';
import PaymentCheckout from '../../components/PaymentModal/PaymentCheckout';
import RSVP from '../../components/RSVP/RSVP';
import { markTodoAsCompleted } from '../../utilities/markTodoAsCompleted';
import { CustomIcon } from '../../components/CustomIcons/CustomIcon';
import listIcon from '../../assets/images/listIcon.svg';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/currentUser/selectors';
import { EventsFilter } from '../../types/eventsFilter';
import TodoPreview from '../../components/Todo/TodoPreview';
import { useWindowSize } from '@uidotdev/usehooks';

export default function HomePage({ refetchEvents }: { refetchEvents: boolean }) {
  const navigate = useNavigate();
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const currentUser = useSelector(getCurrentUser);
  const today = new Date();
  const token = localStorage.getItem('token');
  const [page, setPage] = useState<number>(0);
  const { data: todoItems } = useQuery({
    queryKey: ['home', refetchEvents],
    queryFn: () => fetchTodoItems({ token, take: 10, skip: page * 10 })
  })
  const date = new Date(); 
  const formatter = new Intl. DateTimeFormat('en-US', { day: '2-digit', month: 'long', year: 'numeric', weekday: 'long' }); 
  const formattedDate = formatter. format(date);
  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close" | undefined;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
  } | null>(null);
  const [eventAgendaMealBlock, setEventAgendaMealBlock] = useState<{
    count: number;
    name: string;
    price: number;
    qty_available: null;
    unit_size: null;
    unit_of_measurement: string;
    description: string;
    is_available: boolean;
    image: string;
    customizations: [
        {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
                {
                    name: string;
                    price: number;
                    customizations: any[];
                    min_qty: number;
                    max_qty: number;
                    conditional_price: {};
                    formatted_price: string;
                    default_qty: number;
                    option_id: string;
                }
            ];
            customization_id: string;
        }
    ];
    min_price: number;
    original_price: number;
    formatted_price: string;
    attributes: [];
    product_id: string;
    thumbnail_image: string;
    should_fetch_customizations: boolean;
    supports_image_scaling: boolean;
  }[]>([]);
  const [mealBlockId, setMealBlockId] = useState<number | undefined>();
  const [preOrder, setPreOrder] = useState<{
    customizations: {
        customizationId: string,
        optionId: string,
        markedPrice: number
      }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  } | undefined>();
  const [helloTextsArray, setHelloTextsArray] = useState<{ id: number, text: string }[]>([]);
  const [selectMealsModalIsOpen, setSelectMealsModalIsOpen] = useState(false);
  const [currentHelloText, setCurrentHelloText] = useState({id: 0, text: ''});

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    const timer = setInterval(() => {
        if (helloTextsArray.length) {
            setCurrentHelloText(prevState => {
                if (prevState) {
                  if (prevState.id + 1 > helloTextsArray.length - 1 && prevState) {
                    return helloTextsArray[0];
                  } else {
                    return helloTextsArray[prevState.id + 1]
                  }
                } else {
                  return {id: 0, text: ''}
                }
              })
        }
    }, 5000);

    // return clearInterval(timer);
  }, [helloTextsArray]);

  useEffect(() => {
    if (!!currentUser && !!todoItems) {
      setHelloTextsArray([{id: 0, text: `Hello ${currentUser.fullName}`}, { id: 1, text: `Today you have ${todoItems?.total} upcoming tasks` }])
    }
  }, [currentUser, todoItems])

  return (
    <div className={`eventDetails_container homePage`}>
      {
        createPortal(<PreviewPreselectedMealsInviteesMeals 
          selectedRestaurant={selectedRestaurant} 
          showSelectMealModal={selectMealsModalIsOpen} 
          menu={eventAgendaMealBlock} 
          preOrder={preOrder} 
          setPreOrder={setPreOrder} 
          mealBlockId={mealBlockId} 
          setSelectMealsModalIsOpen={setSelectMealsModalIsOpen} 
          asyncOnAddMeal={async () => {
            if (mealBlockId && preOrder) {
              await postMealsSelections({
                id: mealBlockId,
                body: {
                  customizations: preOrder.customizations,
                  productId: preOrder.productId,
                  markedPrice: preOrder.markedPrice,
                  notes: preOrder.notes,
                },
                token
              })

              setSelectMealsModalIsOpen(false)
            }
          }} 
          isLoading={false}          
        />, document.getElementById('modal') as HTMLElement)
      }
      <Navigation
        hierarchy='primary'
        currentItem={'Home'}
      />
      <div className={`overflowYScroll fullWidth ${sizeIsDesktop ? 'eventDetails_main-container' : ''}`}>
        <div className="eventDetails_main homePage-main">
          <div className={`eventDetails_main-header homePage-header`}>
            <div className='homePage-header-dayInfo'>
              <p className='homePage-header-dayInfo-title'>{formattedDate}</p>
              <p className='homePage-header-dayInfo-description'>{currentHelloText.text}</p>
            </div>
            <div className='homePage-header-createEvent' onClick={() => navigate('/createEvent')}>
              <img src={buttonPlusIcon} alt="" className='homePage-header-createEvent-img' />
              <div className='homePage-header-createEvent-text'>
                <p className='homePage-header-createEvent-text-title'>New event</p>
                <p className='homePage-header-createEvent-text-description'>Create a new event to lorem ipsum</p>
              </div>
            </div>
          </div>
          <div className='eventDetails_content homePage-content'>
            <EventsGallery refetchEvents={refetchEvents} />
            <div className='homePage-content-todos'>
              <div className='homePage-content-todos-header'>
                <div className='homePage-content-todos-header-topic'>
                  <h3 className='homePage-content-todos-header-topic-title'>To do's</h3>
                  <p className='homePage-content-todos-header-topic-todosCount'>{todoItems?.total}</p>
                </div>
                <img src="" alt="" />
              </div>
              {
                todoItems?.total
                  ? <div className='homePage-content-todos-content'>
                  {
                    todoItems?.todoItems.map(todo => <TodoPreview
                      toDo={todo}
                      name={
                        todo.type === TodoItemType.RespondToInvite 
                          ? 'Event invite' 
                          : todo.type === TodoItemType.MealOrder 
                            ? 'Meal order' 
                            : todo.type === TodoItemType.MealSelection 
                              ? 'Meal selection' 
                              : todo.type === TodoItemType.SubmitEvent 
                                ? 'Submit event' 
                                : todo.type === TodoItemType.ReviewDocument
                                    ? 'Review document'
                                    : ''
                      }
                      description={
                        todo.type === TodoItemType.RespondToInvite 
                          ? 'Respond to the event invite' 
                          : todo.type === TodoItemType.MealOrder 
                            ? `Order meal for ${todo.event.name}`
                            : todo.type === TodoItemType.MealSelection 
                              ? `Select meal for ${todo.event.name}`
                              : todo.type === TodoItemType.SubmitEvent 
                                ? 'Your event hasn’t been submitted.' 
                                : todo.type === TodoItemType.ReviewDocument
                                    ? 'Please, review the document'
                                    : ''
                      }
                    />)
                  }
                  </div>
                  : <div className='fullHeight fullWidth items-center justifyCenter flex flex-column gap-16'>
                    <img src={listIcon} alt="" className='homePage-content-todos-listIcon' />
                    <div>
                      <h3 className='textCenter color-gray-900 text-md font-semibold'>No to do’s found</h3>
                      <p className='textCenter color-gray-700 text-sm font-regular'>You’ve done all your to do’s great job!</p>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
