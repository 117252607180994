import EventPage from "./pages/EventPage/EventPage";
import { SetStateAction, useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import avatar from './assets/images/Avatar-olivia.svg'
import TopSideNavigation from "./components/Navigation/TopSideNavigation";
import TeamMembersTable from "./components/Tables/TeamMembersTable";
import VendorMovementsTable from "./components/Tables/VendorMovementsTable";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import SetNewPassword from "./pages/ForgotPassword/SetNewPassword";
import EventsPageV2 from "./pages/EventsPageV2/EventsPageV2";
import Settings from "./pages/Settings/Settings";
import { Support } from "./pages/Support/Support";
import ReviewEventDetailsView from "./pages/ReviewEventsDetailsPage/ReviewEventDetailsView";
import Contacts from "./pages/Contacts/Contacts";
import {  fetchMyData, fetchSbConfig, registerUser, resetPassword } from "./httpQueries/http";
import { useDispatch } from "./store/hooks";
import { currentUserActions } from "./store/currentUser";
import { supabase } from "./utilities/supabaseClient";
import NotFound from "./pages/NotFound/NotFound";
import NewCategoryDropdown from "./components/Dropdown/NewCategoryDropdown";
import axios from "axios";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import moment from "moment-timezone";
import { getTimeInSomeTimezone } from "./utilities/getTimeInSomeTimezone";
import TeamsDropdown from "./components/Dropdown/teamsDropdown";
import ModalUserAdded from "./components/Modal/ModalUserAdded";
import ReviewEventDetailsModal from "./components/Modal/ReviewEventDetailsModal";
import ReviewFile from "./components/FileAttach/ReviewFile";
import { Dropdown } from "./components/Dropdown/Dropdown";
import NumberInput from "./components/Inputs/NumberInput";
import AttachDocumentModal from "./components/Modal/AttachDocumentModal";
import ReviewFileBase from "./components/FileAttach/ReviewFileBase";
import AttachLinkModal from "./components/Modal/AttachLinkModal";
import RestaurantSelect from "./components/MealSelection/RestaurantSelect/RestaurantSelect";
import RestaurantMenu from "./components/MealSelection/MealSelect/RestaurantMenu";
import AddNewRestaurantModal from "./components/Modal/AddNewRestaurantModal";
import SelectMealCustomizationModal from "./components/Modal/SelectMealCustomizationModal";
import RadioButtons from "./components/Selectors/RadioButtons";
import SelectOptions from "./components/Selectors/SelectOptions";
import Loader from "./components/Loader";
import Gallery from "./components/Gallery/Gallery";
import a from './assets/images/eventsIcon.svg';
import b from './assets/images/AUFlag.svg';
import TodoCard from "./components/Cards/TodoCard";
import { AgendaBlockType } from "./types/agendaBlockType";
import { Button } from "./components/Buttons/Button";
import RestaurantDetails from "./components/MealSelection/RestaurantDetails";
import WelcomePage from "./pages/ReviewEventsDetailsPage/TodosPage";
import EventReviewBlockSmall from "./components/EventBlock/EventReviewBlockSmall";
import { logoColor } from "./types/logoColor";
import { logoImage } from "./types/logoImage";
import EventsGallery from "./components/Gallery/EventsGallery";
import HomePage from "./pages/Home/HomePage";
import BillingsTable from "./components/Tables/BillingsTable";
import PaymentMethodCard from "./components/Cards/PaymentMethodCard";
import DropdownWithAction from "./components/Dropdown/DropdownWithAction";
import { User } from "./types/userForAddType";
import DatePickerSmall from "./components/DatePickers/DatePickerSmall";
import MonthCalendar from "./components/CalendarSchedule/MonthCalendar";
import { refreshToken } from "./utilities/refreshToken";


function App() {
  let token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [isGoogleLoginWorks, setIsGoogleLoginWorks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refetchEvents, setRefetchEvents] = useState(false);

  useEffect(() => {
    if (!isGoogleLoginWorks) {

      supabase().auth.getSession().then(response => {
          if (response.data.session?.access_token) {
            if (token !== response.data.session?.access_token || !token) {
              token = response.data.session?.access_token;
              localStorage.setItem('token', token);
              localStorage.setItem('lastTokenSetTime', new Date().toISOString());
            }
            if (token) {
              fetchMyData({ token }).then(
                (response) => {
                  if (response) {
                    dispatch(currentUserActions.setCurrentUser({fullName: response.fullName, email: response.email, profileImage: avatar, completeTutorial: response.isTutorialCompleted}));
                  }
                  setRefetchEvents(true);
                }
              )
            }
          }
      })
    }
  }, []);

  useEffect(() => {
    const session = supabase().auth.getSession();
    
    const {
      data: { subscription },
    } = supabase().auth.onAuthStateChange(async (event, session) => {
      if (isGoogleLoginWorks) {
        if (session?.user.identities) {
          await registerUser({ user: {
            fullName: session.user.identities![0].identity_data!.full_name || session.user.identities![1].identity_data!.full_name,
            email: session.user.identities![0].identity_data!.email,
            id: session.user.id,
          }});
  
          fetchMyData({ token: session.access_token }).then(
            (response) => {
              if (response) {
                dispatch(currentUserActions.setCurrentUser({fullName: response.fullName, email: response.email, profileImage: avatar, completeTutorial: response.isTutorialCompleted}));
              }
            }
          );
          
          setRefetchEvents(true);
          setIsGoogleLoginWorks(false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [isGoogleLoginWorks]);
  
  return (
    <>
      {
        isLoading
          ? <div className="flex fullWidth items-center fullHeight justifyCenter">
              <Loader size='xl' />
            </div>
          : <Routes>
              <Route path="/" element={<HomePage refetchEvents={refetchEvents} />} />
              <Route path="/createEvent" element={<EventPage type='Details' />} />
              <Route path="/createEvent/inviteAttendees" element={<EventPage type='InviteTeam' />} />
              <Route path="/createEvent/agenda" element={<EventPage type='Agenda' />} />
              <Route path="/createEvent/reviewAndSubmit" element={<EventPage type='Review' />} />
              <Route path="/edit/:slug/details" element={<EventPage type='Details' isEdit />} />
              <Route path="/edit/:slug/agenda" element={<EventPage type='Agenda' isEdit />} />
              <Route path="/edit/:slug/inviteAttendees" element={<EventPage type='InviteTeam' isEdit />} />
              <Route path="/edit/:slug/reviewAndSubmit" element={<EventPage type='Review' isEdit />} />
              <Route path="/newCategory" element={<NewCategoryDropdown placeholder="Category/team" />} />
              {/* <Route path="/review" element={<ReviewEventDetailsModal />} /> */}
              <Route path="/teams" element={<TeamsDropdown />} />
              <Route path="/login" element={<Login setIsGoogleLoginWorks={setIsGoogleLoginWorks} />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetPassword" element={<ForgotPassword />} />
              <Route path="/createNewPassword" element={<SetNewPassword />} />
              <Route path="/createNewPasswordByEmail" element={<SetNewPassword delayed />} />
              <Route path="/events" element={<EventsPageV2 />} />
              <Route path="/events/:slug" element={<ReviewEventDetailsView />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="support" element={<Support />} />
              <Route path="/test" element={<TeamMembersTable />} />
              <Route path="/test2" element={<VendorMovementsTable />} />
              <Route path="/test3" element={<TopSideNavigation hierarchy='dark' withUpgradeFeat />} />
              <Route path="/test4" element={<TopSideNavigation hierarchy='default' withUpgradeFeat />} />
              <Route path="/test5" element={<TopSideNavigation hierarchy='primary' withUpgradeFeat />} />
              <Route path="/gallery" element={<Gallery photos={[a, b]} />} />
              <Route path="/card" element={<div style={{ width: '500px' }}>
                <PaymentMethodCard />
              </div>} />
              {/* <Route path="/test6" element={<SelectOptions label={'some label'} options={options} setOptions={setOptions} />}/> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
      }
    </>
  );
}

export default App;
