import React, { useState } from 'react'
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import plusCircle from '../../assets/images/plus-circle.svg';
import { Button } from '../Buttons/Button';
import LogoRepeat from '../LogoRepeat/LogoRepeat';

import lifebuoySm from '../../assets/images/life-buoy-01-sm.svg';
import briefCaseSm from '../../assets/images/briefcase-01-sm.svg';
import currencyDollarSm from '../../assets/images/currency-dollar-circle-sm.svg';
import lightBulbSm from '../../assets/images/lightbulb-02-sm.svg';
import rocketSm from '../../assets/images/rocket-02-sm.svg';
import shieldTickSm from '../../assets/images/shield-tick-sm.svg';
import starIconSm from '../../assets/images/cloud-blank-01-sm.svg';
import targetIconWhiteSm from '../../assets/images/target-04-sm.svg';
import zapIconSm from '../../assets/images/zap-sm.svg';
import computerSm from '../../assets/images/monitor-04-sm.svg';
import { logoImage } from '../../types/logoImage';
import { logoColor } from '../../types/logoColor';
import { getLogoRepeatImage } from '../../utilities/getLogoRepeatImage';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  onSave: (color: logoColor, image: logoImage) => void,
}

export default function ModalCreateEventLogo({ setIsOpen, onSave }: Props) {
  const [selectedColor, setSelectedColor] = useState<logoColor>(logoColor.BLUE);
  const [selectedPattern, setSelectedPattern] = useState<logoImage>(logoImage.COMPUTER);

  const onClear = () => {
    setSelectedColor(logoColor.BLUE);
    setSelectedPattern(logoImage.COMPUTER);
  }

  return (
      <div className='modalNotification'>
        <div className='modalNotification-container modalNotification-container-inviteAttendees'>
          <div className='modalNotification-circles-container'>
          <div className='modalNotification-circles'>
            <div className="eventDetails_circles">
              <div className="eventDetails-circle eventDetails-circle-fifth">
                <div className="eventDetails-circle eventDetails-circle-fourth">
                  <div className="eventDetails-circle eventDetails-circle-third">
                    <div className="eventDetails-circle eventDetails-circle-second">
                      <div className="eventDetails-circle eventDetails-circle-first">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className='modalNotification-header'>
            <div className='modalNotification-header-actions'>
              <FeaturedIcon icon={plusCircle} size='lg' type='gray' />
              <img
                src={closeIcon}
                alt="close icon"
                className='modalNotification-header-actions-close'
                onClick={() => {
                  setIsOpen(false)
                }}
              /> 
            </div>
            <div className='modalNotification-header-info'>
              <h3>Add color and pattern</h3>
            </div>
          </div>
          <div className='modalNotification-main modalCreateEventLogo'>
            <LogoRepeat image={getLogoRepeatImage({ selectedColor, selectedPattern })} color={selectedColor} size='md' />
            <h3 className='modalCreateEventLogo-label'>Color*</h3>
            <div className='modalCreateEventLogo-content'>
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-error
                    ${selectedColor === logoColor.RED ? 'modalCreateEventLogo-color-error-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.RED)}
                />
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-warning
                    ${selectedColor === logoColor.ORANGE ? 'modalCreateEventLogo-color-warning-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.ORANGE)}
                />
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-yellow
                    ${selectedColor === logoColor.YELLOW ? 'modalCreateEventLogo-color-yellow-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.YELLOW)}
                />
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-success
                    ${selectedColor === logoColor.GREEN ? 'modalCreateEventLogo-color-success-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.GREEN)}
                />
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-primary 
                    ${selectedColor === logoColor.BLUE ? 'modalCreateEventLogo-color-primary-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.BLUE)}
                />
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-purple
                    ${selectedColor === logoColor.PURPLE ? 'modalCreateEventLogo-color-purple-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.PURPLE)}
                />
                <div 
                  className={`
                    modalCreateEventLogo-color modalCreateEventLogo-color-blue
                    ${selectedColor === logoColor.BLUELIGHT ? 'modalCreateEventLogo-color-blue-selected' : ''}
                  `}
                  onClick={() => setSelectedColor(logoColor.BLUELIGHT)}
                />
            </div>
            <h3 className='modalCreateEventLogo-label'>Pattern*</h3>
            <div className='modalCreateEventLogo-content'>
              <FeaturedIcon 
                icon={computerSm} 
                size='md' 
                type={`${selectedPattern === logoImage.COMPUTER ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.COMPUTER)} 
              />
              <FeaturedIcon 
                icon={briefCaseSm} 
                size='md' 
                type={`${selectedPattern === logoImage.BRIEFCASE ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.BRIEFCASE)} 
              />
              <FeaturedIcon 
                icon={currencyDollarSm} 
                size='md' 
                type={`${selectedPattern === logoImage.CURRENCYDOLLAR ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.CURRENCYDOLLAR)} 
              />
              <FeaturedIcon 
                icon={lifebuoySm} 
                size='md' 
                type={`${selectedPattern === logoImage.LIFEBUOY ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.LIFEBUOY)} 
              />
              <FeaturedIcon 
                icon={targetIconWhiteSm} 
                size='md' 
                type={`${selectedPattern === logoImage.TARGET ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.TARGET)} 
              />
              <FeaturedIcon 
                icon={zapIconSm} 
                size='md' 
                type={`${selectedPattern === logoImage.ZAP ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.ZAP)} 
              />
              <FeaturedIcon 
                icon={rocketSm} 
                size='md' 
                type={`${selectedPattern === logoImage.ROCKET ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.ROCKET)} 
              />
              <FeaturedIcon 
                icon={lightBulbSm} 
                size='md' 
                type={`${selectedPattern === logoImage.LIGHTBULB ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.LIGHTBULB)} 
              />
              <FeaturedIcon 
                icon={shieldTickSm} 
                size='md' 
                type={`${selectedPattern === logoImage.SHIELDTICK ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.SHIELDTICK)} 
              />
              <FeaturedIcon 
                icon={starIconSm} 
                size='md' 
                type={`${selectedPattern === logoImage.STARICON ? 'secondaryColor' : 'grayColor'}`}
                onClick={() => setSelectedPattern(logoImage.STARICON)} 
              />
            </div>
          </div>
          <div className='modalNotification-actions' style={{justifyContent: 'end'}}>
            <Button
              buttonType='regular'
              hierarchy='secondaryGray'
              size='lg'
              contentCenter
              onClick={onClear}
            >
              Clear
            </Button>
            <Button
              buttonType='regular'
              hierarchy='primary'
              size='lg'
              contentCenter
              onClick={() => {
                onSave(selectedColor, selectedPattern);
                setIsOpen(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
}
