import React, { useEffect, useState } from 'react';
import logoColor from '../../assets/images/logo-lg-color.svg';
import logoWhite from '../../assets/images/logo-xl-white.svg';
import avatar from '../../assets/images/Avatar-olivia.svg';
import searchIcon from '../../assets/images/search-sm.svg';
import searchPrimary from '../../assets/images/search-lg-primary.svg';
import searchGray from '../../assets/images/search-lg-gray-300.svg';
import settingsIcon from '../../assets/images/settings-sm.svg';
import settingsPrimaryIcon from '../../assets/images/settings-lg-primary.svg';
import settingsGray from '../../assets/images/settings-gray-300-sm.svg';
import bellIcon from '../../assets/images/bell-sm.svg';
import bellPrimaryIcon from '../../assets/images/bell-lg-primary.svg';
import bellGray from '../../assets/images/bell-gray-300.svg';
import menuIcon from '../../assets/images/menu-02.svg';
import menuPrimary from '../../assets/images/menu-lg-primary.svg';
import closeIcon from '../../assets/images/x-close-white-lg.svg';
import zap from '../../assets/images/zap.svg';
import zapWhite from '../../assets/images/zap-white.svg';
import { Button } from '../Buttons/Button';
import { useWindowSize } from '@uidotdev/usehooks';
import SideBarNavigation from './SideBarNavigation';
import { FormInput } from '../Inputs/FormInput';

interface Props {
  hierarchy: 'primary' | 'default' | 'dark',
  withUpgradeFeat?: boolean
}

export default function TopSideNavigation({ hierarchy, withUpgradeFeat }: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [selectedSecondTab, setSelectedSecondTab] = useState<string | null>('Overview');
  const [startPosition, setStartPosition] = useState<number>(0);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 

  return (
    <>
      <div 
        className={
          `topSideNavigation
            ${hierarchy === 'dark' ? 'topSideNavigation-dark' : ''}
            ${hierarchy === 'default' ? 'topSideNavigation-default' : ''}
            ${hierarchy === 'primary' ? 'topSideNavigation-primary' : ''}
          `
        }
        onTouchStart={isOpenMobileMenu ? (e) => setStartPosition(e.changedTouches[0]?.clientX) : () => {}}
        onTouchEnd={isOpenMobileMenu ? (e) => e.changedTouches[0].clientX < startPosition ? setIsOpenMobileMenu(false) : null : () => {}}
      >
        <div className='topSideNavigation-nav'>
          <img src={hierarchy === 'default' ? logoColor : logoWhite} alt="" />
          {!sizeIsDesktop
            ? <Button 
                buttonType='small'
                hierarchy='tertiaryGray'
                size='md'
                buttonImage={hierarchy === 'default' ? menuIcon : menuPrimary}
                onClick={() => setIsOpenMobileMenu(prevState => !prevState)}
              />
            : null
          }
          {sizeIsDesktop
            ? <nav className='topSideNavigation-nav-items-container'>
                <ul className='topSideNavigation-nav-items'>
                  <li 
                    className={`
                      topSideNavigation-nav-items-item
                      ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                      ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                      ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                      ${hierarchy === 'default' && selectedTab === 'Home' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                      ${hierarchy === 'dark' && selectedTab === 'Home' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                      ${hierarchy === 'primary' && selectedTab === 'Home' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                    `}
                    onClick={() => setSelectedTab('Home')}
                  >
                    Home
                  </li>
                  <li 
                    className={`
                      topSideNavigation-nav-items-item
                      ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                      ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                      ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                      ${hierarchy === 'default' && selectedTab === 'Dashboard' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                      ${hierarchy === 'dark' && selectedTab === 'Dashboard' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                      ${hierarchy === 'primary' && selectedTab === 'Dashboard' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                    `}
                    onClick={() => setSelectedTab('Dashboard')}
                  >
                    Dashboard
                  </li>
                  <li 
                    className={`
                      topSideNavigation-nav-items-item
                      ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                      ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                      ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                      ${hierarchy === 'default' && selectedTab === 'Projects' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                      ${hierarchy === 'dark' && selectedTab === 'Projects' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                      ${hierarchy === 'primary' && selectedTab === 'Projects' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                    `}
                    onClick={() => setSelectedTab('Projects')}
                  >
                    Projects
                  </li>
                  <li 
                    className={`
                      topSideNavigation-nav-items-item
                      ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                      ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                      ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                      ${hierarchy === 'default' && selectedTab === 'Tasks' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                      ${hierarchy === 'dark' && selectedTab === 'Tasks' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                      ${hierarchy === 'primary' && selectedTab === 'Tasks' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                    `}
                    onClick={() => setSelectedTab('Tasks')}
                  >
                    Tasks
                  </li>
                  <li 
                    className={`
                      topSideNavigation-nav-items-item
                      ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                      ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                      ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                      ${hierarchy === 'default' && selectedTab === 'Reporting' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                      ${hierarchy === 'dark' && selectedTab === 'Reporting' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                      ${hierarchy === 'primary' && selectedTab === 'Reporting' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                    `}
                    onClick={() => setSelectedTab('Reporting')}
                  >
                    Reporting
                  </li>
                  <li 
                    className={`
                      topSideNavigation-nav-items-item
                      ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                      ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                      ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                      ${hierarchy === 'default' && selectedTab === 'Users' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                      ${hierarchy === 'dark' && selectedTab === 'Users' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                      ${hierarchy === 'primary' && selectedTab === 'Users' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                    `}
                    onClick={() => setSelectedTab('Users')}
                  >
                    Users
                  </li>
                </ul>
              </nav>
            : null
          }
          
          {
            isOpenMobileMenu
              ? <div className='topSideNavigation-nav-items-container-mobile'>
                  <SideBarNavigation hierarchy={hierarchy} fullWidth menuIsOpen currentItem='Events' />
                  <div className='topSideNavigation-nav-items-container-mobile-close'>
                    <img 
                      src={closeIcon} 
                      alt="" 
                      className='topSideNavigation-nav-items-container-mobile-close-icon'
                      onClick={() => setIsOpenMobileMenu(false)}
                    />
                  </div>
                </div>
              : null
          }
        </div>
        {
          sizeIsDesktop
            ? <div className='topSideNavigation-actions-container'>
                <div className='topSideNavigation-actions'>
                  {
                    !withUpgradeFeat
                      ? <Button
                          size='md'
                          buttonType='small'
                          hierarchy={'tertiaryGray'}
                          buttonImage={hierarchy === 'default' ? searchIcon : (hierarchy === 'dark' ? searchGray : (hierarchy === 'primary' ? searchPrimary : ''))}
                        />
                      : null
                  }

                  {
                    withUpgradeFeat
                      ? <Button
                          buttonType='regular'
                          hierarchy={hierarchy === 'primary' ? 'primary' : 'secondaryGray'}
                          size='md'
                        >
                          <div className='flex gap-8'>
                            <img src={hierarchy === 'primary' ? zapWhite : zap} alt="" />
                            <p className='text-sm font-semibold'>Upgrade now</p>
                          </div>
                        </Button>
                      : null
                  }
                  <Button
                    size='md'
                    buttonType='small'
                    hierarchy='tertiaryGray'
                    buttonImage={hierarchy === 'default' ? settingsIcon : (hierarchy === 'dark' ? settingsGray : (hierarchy === 'primary' ? settingsPrimaryIcon : ''))}
                  />
                  <Button
                    size='md'
                    buttonType='small'
                    hierarchy='tertiaryGray'
                    buttonImage={hierarchy === 'default' ? bellIcon : (hierarchy === 'dark' ? bellGray : (hierarchy === 'primary' ? bellPrimaryIcon : ''))}
                  />
                </div>
                <img src={avatar} alt="user icon"  className='topSideNavigation-actions-container-userImg' />
              </div>
            : null
        }
      </div>
      {
        selectedTab !== null && withUpgradeFeat && sizeIsDesktop
          ? <div
              className={
                `topSideNavigation
                  ${hierarchy === 'dark' ? 'topSideNavigation-dark' : ''}
                  ${hierarchy === 'default' ? 'topSideNavigation-default' : ''}
                  ${hierarchy === 'primary' ? 'topSideNavigation-primary' : ''}
                `
              }
            >
              <ul className='topSideNavigation-nav-items'>
                <li
                  className={`
                    topSideNavigation-nav-items-item
                    ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                    ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                    ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                    ${hierarchy === 'default' && selectedSecondTab === 'Overview' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                    ${hierarchy === 'dark' && selectedSecondTab === 'Overview' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                    ${hierarchy === 'primary' && selectedSecondTab === 'Overview' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                  `}
                  onClick={() => setSelectedSecondTab('Overview')}
                >
                  Overview
                </li>
                <li
                  className={`
                    topSideNavigation-nav-items-item
                    ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                    ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                    ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                    ${hierarchy === 'default' && selectedSecondTab === 'Notifications' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                    ${hierarchy === 'dark' && selectedSecondTab === 'Notifications' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                    ${hierarchy === 'primary' && selectedSecondTab === 'Notifications' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                  `}
                  onClick={() => setSelectedSecondTab('Notifications')}
                >
                  Notifications
                </li>
                <li
                  className={`
                    topSideNavigation-nav-items-item
                    ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                    ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                    ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                    ${hierarchy === 'default' && selectedSecondTab === 'Analytics' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                    ${hierarchy === 'dark' && selectedSecondTab === 'Analytics' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                    ${hierarchy === 'primary' && selectedSecondTab === 'Analytics' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                  `}
                  onClick={() => setSelectedSecondTab('Analytics')}
                >
                  Analytics
                </li>
                <li
                  className={`
                    topSideNavigation-nav-items-item
                    ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                    ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                    ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                    ${hierarchy === 'default' && selectedSecondTab === 'Saved reports' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                    ${hierarchy === 'dark' && selectedSecondTab === 'Saved reports' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                    ${hierarchy === 'primary' && selectedSecondTab === 'Saved reports' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                  `}
                  onClick={() => setSelectedSecondTab('Saved reports')}
                >
                  Saved reports
                </li>
                <li
                  className={`
                    topSideNavigation-nav-items-item
                    ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                    ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                    ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                    ${hierarchy === 'default' && selectedTab === 'Scheduled reports' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                    ${hierarchy === 'dark' && selectedTab === 'Scheduled reports' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                    ${hierarchy === 'primary' && selectedTab === 'Scheduled reports' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                  `}
                  onClick={() => setSelectedSecondTab('Scheduled reports')}
                >
                  Scheduled reports
                </li>
                <li
                  className={`
                    topSideNavigation-nav-items-item
                    ${hierarchy === 'default' ? 'topSideNavigation-nav-items-item-default' : ''}
                    ${hierarchy === 'dark' ? 'topSideNavigation-nav-items-item-dark' : ''}
                    ${hierarchy === 'primary' ? 'topSideNavigation-nav-items-item-primary' : ''}
                    ${hierarchy === 'default' && selectedSecondTab === 'User reports' ? 'topSideNavigation-nav-items-item-default-isSelected' : ''}
                    ${hierarchy === 'dark' && selectedSecondTab === 'User reports' ? 'topSideNavigation-nav-items-item-dark-isSelected' : ''}
                    ${hierarchy === 'primary' && selectedSecondTab === 'User reports' ? 'topSideNavigation-nav-items-item-primary-isSelected' : ''}
                  `}
                  onClick={() => setSelectedSecondTab('User reports')}
                >
                  User reports
                </li>
              </ul>

              <div className='topSideNavigation-search'>
                <FormInput 
                  placeholder='Search'
                  type={hierarchy === 'primary' ? 'SearchPrimary' : (hierarchy === 'default' ? 'SearchGray' : 'SearchDark')}
                  hasIconBefore
                />
              </div>
            </div>
          : null
      }
    </>
  )
}
