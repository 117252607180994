import React from 'react'
import { TodoItem, TodoItemType } from '../../types/todoItem';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import presentationIcon from '../../assets/images/presentation-chart-01.svg';
import happyFaceIcon from '../../assets/images/face-happy.svg';

interface Props {
  toDo: TodoItem,
  todoAction: React.ReactNode,
  name: string,
  description: string,
}

export default function Todo({ toDo, todoAction, name, description }: Props) {
  return (
    <div className='eventDetailsWelcomePage-thingsTodo-todo'>
      <div className='agendaItem-info-title'>
        <div 
          className={`
            agendaItem-info-title-icon 
              ${toDo.type === TodoItemType.MealOrder || toDo.type === TodoItemType.MealSelection
                  ? 'agendaItem-info-title-icon-meal' 
                  : ''
              }
          `}
        >
          <FeaturedIcon
            icon={
              toDo.type === TodoItemType.RespondToInvite || toDo.type === TodoItemType.ReviewDocument
                ? presentationIcon
                : happyFaceIcon
            }
            type={toDo.type === TodoItemType.RespondToInvite || toDo.type === TodoItemType.ReviewDocument ? 'primary' : 'warning'}
            size='sm'
          />
        </div>
        <div>
            <h3 className='agendaItem-info-title-text'>
              {name}
            </h3>
            <p className='todoCard-description'>
              {description}
            </p>
        </div>
      </div>
      {
        todoAction
      }
    </div>
  )
}


{/* <div className='agendaItem-info-title'>
              <img src={
                toDo.type === TodoItemType.RespondToInvite
                  ? userRsvpSuccess
                  : toDo.type === AttendeeRSVP.No
                      ? userRsvpError
                      : userRsvpMaybe
              } alt="user rsvp" className='eventDetailsWelcomePage-userRSVPImage' />
              <div>
                <h3 className='agendaItem-info-title-text'>{toDo.name}</h3>
              </div>
            </div> */}