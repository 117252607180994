import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import { Button } from '../../../components/Buttons/Button';
import SelectMealsAttendeesModal from '../../../components/Modal/SelectMealsAttendeesModal';


interface Props {
  name: string,
  description: string,
  price: string,
  image: string,
  productId: string,
  product: {
    count: number;
    name: string;
    price: number;
    qty_available: null;
    unit_size: null;
    unit_of_measurement: string;
    description: string;
    is_available: boolean;
    image: string;
    customizations: [
        {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
                {
                    name: string;
                    price: number;
                    customizations: any[];
                    min_qty: number;
                    max_qty: number;
                    conditional_price: {};
                    formatted_price: string;
                    default_qty: number;
                    option_id: string;
                }
            ];
            customization_id: string;
        }
    ];
    min_price: number;
    original_price: number;
    formatted_price: string;
    attributes: [];
    product_id: string;
    thumbnail_image: string;
    should_fetch_customizations: boolean;
    supports_image_scaling: boolean;
  },
  onDescrease: () => void,
  onIncrease: () => void,
  asyncOnAddMeal?: () => Promise<void>,
  onInputChange: (count: number) => void,
  preOrder: {
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  } | undefined,
  setPreOrder: React.Dispatch<React.SetStateAction<{
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  } | undefined>>
}

export default function MenuItem({ 
  name, 
  description, 
  price, 
  image,
  product,
  onDescrease,
  onIncrease,
  asyncOnAddMeal,
  onInputChange,
  preOrder,
  setPreOrder
}: Props) {
  const [showSelectCustomizationModal, setShowSelectCustomizationModal] = useState(false);

  return (
    <div 
      className={`menuItem`} 
    >
      {
        showSelectCustomizationModal
          ? createPortal(<SelectMealsAttendeesModal 
            setShow={() => setShowSelectCustomizationModal(false)} 
            customizations={product.customizations} 
            name={product.name} 
            description={product.description} 
            productId={product.product_id} 
            markedPrice={product.price} 
            onDescrease={onDescrease} 
            onIncrease={onIncrease} 
            count={preOrder?.count || 0} 
            setOrders={setPreOrder} 
            asyncOnAddMeal={asyncOnAddMeal}
            preOrder={preOrder}    
            photos={[product.image]}     
            onAddMeal={() => {
              setShowSelectCustomizationModal(false)
            }}
            disableIncrease={preOrder?.count === 1} 
            onInputChange={onInputChange}
            maxInputValue={1}
          />, document.getElementById('modal') as HTMLElement) 
          : null
      }
      {
        image
          ?  <img src={image} alt="" className='menuItem-image' />
          : <p>No image</p>
      }
      <div className='menuItem-content'>
        <h3 className='menuItem-content-name'>{name}</h3>
        <p className='menuItem-content-description'>{description}</p>
        <div className='flex fullWidth spaceBetween'>
          <p className='menuItem-content-price'>{price}</p>
          <Button
            buttonType='regular'
            size='lg'
            hierarchy='tertiaryColor'
            onClick={() => setShowSelectCustomizationModal(true)}
          >
            Add meal
          </Button>
        </div>
      </div>
    </div>
  )
}