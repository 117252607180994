import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { fetchEvents } from '../../httpQueries/http'
import { EventsFilter } from '../../types/eventsFilter';
import EventReviewBlockSmall from '../EventBlock/EventReviewBlockSmall';
import { getTimeFromMinutes } from '../../utilities/getTimeFromMinutes';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import moment from 'moment';
import { Button } from '../Buttons/Button';
import arrowLeft from '../../assets/images/arrow-left.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import { logoColor } from '../../types/logoColor';
import { logoImage } from '../../types/logoImage';
import chevronRight from '../../assets/images/chevron-right.png';
import chevronRightDisabled from '../../assets/images/chevron-right-disabled.png';
import chevronLeft from '../../assets/images/chevron-left.png';
import chevronLeftDisabled from '../../assets/images/chevron-left-disabled.png';
import logo from '../../assets/images/path90.svg';

export default function EventsGallery({ refetchEvents }: { refetchEvents: boolean }) {
  const token = localStorage.getItem('token');
  const today = new Date();
  const { data: events, fetchStatus } = useQuery({
    queryKey: ['events', token, refetchEvents],
    queryFn: () => fetchEvents({
        start: new Date(+today.getFullYear(), +today.getMonth(), +today.getDate(), 0, 0, 0, 0).valueOf(), 
        end: new Date(+today.getFullYear(), +today.getMonth(), +today.getDate(), 23, 59, 59, 999).valueOf(), 
        token, 
        eventFilter: EventsFilter.All,
        isPublished: true
    }).then(res => {
        if (res?.length) {
          setMaxTranslate((res.length - 1) * (eventBlockWidth + 24));
        } else {
          setMaxTranslate(0);
        }

        return res
    })
  })
  const navigate = useNavigate();
  const [translate, setTranslate] = useState(0);
  const [maxTranslate, setMaxTranslate] = useState(0);
  const [eventBlockWidth, setEventBlockWidth] = useState(0);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [dotsXTranslate, setDotsXTranslate] = useState(0);
  const [firstDotIndex, setFirstDotIndex] = useState(0);
  const [lastDotIndex, setLastDotIndex] = useState(2);
  const countOfVisibleDots = 3;
  const dotWidth = 20;

  useEffect(() => {
   const eventPreviewBlockWidth = document.getElementById('small')?.clientWidth;

    if (eventPreviewBlockWidth && events) {
      setTranslate(0)
      setMaxTranslate((events.length - 1) * (eventPreviewBlockWidth + 24));
      setEventBlockWidth(eventPreviewBlockWidth + 24);
    }
  }, [document.getElementById('small')?.clientWidth, events, fetchStatus]);

  return (
    <div className={`eventsGallery ${events?.length ? 'eventsGallery-minHeight410px' : ''}`}>
      <div className='flex fullWidth items-center spaceBetween'>
        <div className='flex items-center gap-4'>
          <p className='eventsGallery-title'>Upcoming events</p>
          <p className='eventsGallery-count'>{events?.length}</p>
        </div>
        {
          events
            ? <div className='eventsGallery-controls'>
                <img src={translate === 0 || !events.length ? chevronLeftDisabled : chevronLeft} style={{ cursor: translate === 0 || !events.length ? 'not-allowed' : 'pointer' }} alt="" onClick={() => {
                  if (translate !== 0) {
                    setTranslate(prevState => prevState + eventBlockWidth);
                    setSelectedEventIndex(prevState => prevState - 1);
                    if (firstDotIndex !== 0 && events.length > 3) {
                      setDotsXTranslate(prevState => prevState + dotWidth)
                      setFirstDotIndex(prevState => prevState - 1)
                      setLastDotIndex(prevState => prevState - 1)
                    }
                  }
                }} />
                {
                  events.length
                    ? <div style={{ maxWidth: '48px', overflow: 'hidden' }}>
                        <div className='eventsGallery-controls-dots' style={{ translate: dotsXTranslate}}>
                          {
                              events.map((event, index) => <div 
                              className={`
                                  eventsGallery-controls-dots-dot 
                                  ${selectedEventIndex === index ? 'eventsGallery-controls-dots-dot-selected' : ''}
                              `}
                              onClick={() => {
                                if (lastDotIndex === index && selectedEventIndex < index) {
                                  if (events.length - (index + 1) >= 2) {
                                    setDotsXTranslate(prevState => prevState - (dotWidth * 2))
                                    setFirstDotIndex(lastDotIndex)
                                    setLastDotIndex(prevState => prevState + 2)
                                  } else if (events.length - (index + 1) === 1) {
                                    setDotsXTranslate(prevState => prevState - dotWidth)
                                    setFirstDotIndex(prevState => prevState + 1)
                                    setLastDotIndex(prevState => prevState + 1)
                                  }
                                }
      
                                if (firstDotIndex === index && selectedEventIndex > index) {
                                  if (index >= 2) {
                                    setDotsXTranslate(prevState => prevState + (dotWidth * 2))
                                    setFirstDotIndex(prevState => prevState - 2)
                                    setLastDotIndex(prevState => prevState - 2)
                                  } else if (index === 1) {
                                    setDotsXTranslate(prevState => prevState + dotWidth)
                                    setFirstDotIndex(prevState => prevState - 1)
                                    setLastDotIndex(prevState => prevState - 1)
                                  }
                                }
                                setSelectedEventIndex(index);
                                setTranslate((index * -1) * eventBlockWidth)
                              }}
                              ></div>)
                          }
                        </div>
                      </div>
                    : <div className='fullWidth items-center justifyCenter'>
                        <div 
                          className={`
                            eventsGallery-controls-dots-dot 
                            eventsGallery-controls-dots-dot-selected
                          `}
                        ></div>
                    </div>
                }
                <img 
                  src={(-maxTranslate >= translate) || !events.length ? chevronRightDisabled : chevronRight} 
                  alt="" 
                  style={{ cursor: (-maxTranslate >= translate) || !events.length ?  'not-allowed' : 'pointer' }} 
                  onClick={() => {
                    if (-maxTranslate >= translate && translate || !events.length) {

                      return
                    } else {

                      setTranslate(prevState => prevState - eventBlockWidth);
                      setSelectedEventIndex(prevState => prevState + 1);
                      if (lastDotIndex + 1 !== events.length && events.length > 3) {
                        setDotsXTranslate(prevState => prevState - dotWidth)
                        setFirstDotIndex(prevState => prevState + 1)
                        setLastDotIndex(prevState => prevState + 1)
                      }
                    }
                  }} 
                />
              </div>
            : null
        }
      </div>
      {
        fetchStatus === 'fetching'
          ? <div className='flex fullWidth fullHeight items-center justifyCenter'>
              <Loader size={'xl'} />
            </div>
          : <div className='eventsGallery-events'>
              <div className='eventsGallery-events' id='container' style={{ translate: translate }}>
                {
                  events?.length 
                    ? events.map(event => 
                        <EventReviewBlockSmall 
                          name={event.name} 
                          date={moment(event.startDate).format('ll')} 
                          startTime={getTimeFromMinutes(event.startTime)} 
                          endTime={event.endTime ? getTimeFromMinutes(event.endTime) : undefined} 
                          location={event.location} 
                          onClick={() => navigate(`/events/${event.slug}`)} 
                          color={+new Date(event.startTime).valueOf() < +new Date().valueOf() ? 'pastEvent' : event.color} 
                          pattern={event.pattern} 
                        />
                      )
                    : <div className='noEventsFoundEventsGallery'>
                        <div className='noEventsFoundEventsGallery-logo'>
                          <img src={logo} alt="" />
                        </div>
                        <div className='flex flex-column gap-4 items-center justifyCenter'>
                          <h3 className='noEventsFoundEventsGallery-title'>No upcoming events</h3>
                          <p className='noEventsFoundEventsGallery-description'>You have no upcoming events</p>
                        </div>
                    </div>
                }
              </div>
            </div>
      }
    </div>
  )
}
