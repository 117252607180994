import React, { useEffect, useState } from 'react';
import RestaurantDetails from '../../../components/MealSelection/RestaurantDetails';
import restaurantIconEllipse from '../../../assets/images/Ellipse 1.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import RestaurantMenu from './Menu';

interface Props {
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null,
  showSelectMealModal: boolean,
  menu: {
    count: number;
    name: string;
    price: number;
    qty_available: null;
    unit_size: null;
    unit_of_measurement: string;
    description: string;
    is_available: boolean;
    image: string;
    customizations: [
        {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
                {
                    name: string;
                    price: number;
                    customizations: any[];
                    min_qty: number;
                    max_qty: number;
                    conditional_price: {};
                    formatted_price: string;
                    default_qty: number;
                    option_id: string;
                }
            ];
            customization_id: string;
        }
    ];
    min_price: number;
    original_price: number;
    formatted_price: string;
    attributes: [];
    product_id: string;
    thumbnail_image: string;
    should_fetch_customizations: boolean;
    supports_image_scaling: boolean;
  }[],
  preOrder: {
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  } | undefined,
  setPreOrder: React.Dispatch<React.SetStateAction<{
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  } | undefined>>,
  mealBlockId: number | undefined,
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  asyncOnAddMeal: () => Promise<void>,
  isLoading: boolean,
}

export default function PreviewPreselectedMealsInviteesMeals({ 
  selectedRestaurant, 
  showSelectMealModal,
  setPreOrder,
  setSelectMealsModalIsOpen,
  preOrder,
  menu,
  mealBlockId,
  asyncOnAddMeal,
  isLoading,
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    if (showSelectMealModal) {
      window.scrollTo(0, 0);

      document.getElementById('modal')?.classList.add('modal-main-container');

      document.body.style.overflow = 'hidden';
    }
  
    return () => {
      document.body.style.overflow = 'auto'
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (preOrder) {
      setTotalSum((preOrder.count * (preOrder.markedPrice / 100)) + preOrder.customizations.reduce((prevValue, currentItem) => prevValue + (currentItem.markedPrice / 100), 0))
    }
  }, [preOrder])
  
  return (
    <div className={`selectMeals-container ${showSelectMealModal ? 'modal-main-container-withBackdrop selectMeals-container-isOpen' : ''}`}>
      <div className={`selectMeals ${showSelectMealModal ? 'selectMeals-isOpen' : ''}`}>
        <RestaurantMenu 
          menu={menu} 
          preOrder={preOrder} 
          setPreOrder={setPreOrder} 
          mealBlockId={mealBlockId} 
          setSelectMealsModalIsOpen={setSelectMealsModalIsOpen} 
          asyncOnAddMeal={asyncOnAddMeal}      
        />
        {
          sizeIsDesktop
            ? <RestaurantDetails 
                isLoading={isLoading}
                name={selectedRestaurant?.name || 'Restaurant name example'}
                status={selectedRestaurant?.status}
                address={selectedRestaurant?.address}
                distance={selectedRestaurant?.distance}
                cuisines={selectedRestaurant?.cuisines || []}
                fee={0}
                total={totalSum}
                logo={selectedRestaurant?.logo || restaurantIconEllipse} 
                creatorIsOrder={true} 
                mealsForAttendeesChoose={[]}   
                orderedMeals={[]}   
              />
          : null
        }
      </div>
    </div>
  )
}