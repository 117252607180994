import React, { useState } from 'react';
import logo from '../../assets/images/logo-lg-color.svg';
import keyIcon from '../../assets/images/key-01.svg';
import { Button } from '../../components/Buttons/Button';
import FeaturedIcon from '../../components/FeaturedIcons/FeaturedIcon';
import leftArrow from '../../assets/images/arrow-left.svg';
import { FormInput } from '../../components/Inputs/FormInput';
import bgIcon from '../../assets/images/contentAccountCreatedBg.svg';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../utilities/validateEmail';
// import { supabase } from '../../utilities/supabase()';
import CheckEmail from './CheckEmail';
import { resetPassword } from '../../httpQueries/http';


export default function ForgotPassword() {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const [isEmailError, setIsEmailError] = useState(false);
  const [codeIsSended, setCodeIsSended] = useState(false);

  const onContinue = async () => {
    if (!validateEmail(inputValue)) {
      setIsEmailError(true);
      return
    } else {
      const response = await resetPassword({ body: { email: inputValue } });
      
      setCodeIsSended(true);
    }
  }

  return (
    <>
      {
        codeIsSended
          ? <CheckEmail email={inputValue} />
          : <div className='accountCreated'>
              <header className='accountCreated-header'>
                <img src={logo} alt="" className='accountCreated-header-logo' />
              </header>
              <section className='accountCreated-content'>
                <img src={bgIcon} alt="" className='accountCreated-content-image' />
                <div className='accountCreated-content-content'>
                  <div className='flex flex-column gap-24 items-center'>
                    <FeaturedIcon
                      icon={keyIcon}
                      size='lg'
                      type='gray'
                    />
                    <div className='flex flex-column items-center justifyCenter'>
                      <h3 className='accountCreated-content-content-title'>Forgot password?</h3>
                      <p className='accountCreated-content-content-description whiteSpaceNoWrap'>No worries, we’ll send you reset instructions.</p>
                    </div>
                  </div>
                  <div className='flex flex-column gap-24 fullWidth'>
                    <FormInput 
                      placeholder='Enter your email'
                      type='Email'
                      label='Email*'
                      fullWidth
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      isError={isEmailError}
                      errorMessage='Please, enter valid email.'
                    />
                    <Button
                      buttonType='regular'
                      hierarchy='primary'
                      size='lg'
                      fullWidth
                      contentCenter
                      asyncOnClick={onContinue}
                    >
                      Reset password
                    </Button>
                  </div>
                  <Button
                    buttonType='regular'
                    hierarchy='linkGray'
                    size='md'
                    onClick={() => navigate('/login')}
                  >
                    <div className='flex gap-8 items-center'>
                      <img src={leftArrow} alt="" />
                      <p>Back to log in</p>
                    </div>
                  </Button>
                </div>
              </section>
            </div>
      }
    </>
  )
}
