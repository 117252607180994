import React, { useContext, useEffect, useState } from 'react';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import userPlusIcon from '../../assets/images/user-plus-01.svg';
import { Button } from '../Buttons/Button';
import { FormInput } from '../Inputs/FormInput';
import { DropdownMenu } from '../Dropdown/DropdownMenu';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import { editAttendee, fetchLocations, postAttendee, postAttendeeToAttendeesNetwork } from '../../httpQueries/http';
import { createPortal } from 'react-dom';
import { LocationSearch } from '../LocationSearch/LocationSearch';
import { validateEmail } from '../../utilities/validateEmail';
import buildingIcon from '../../assets/images/building-02.svg';
import Counter from '../Counter/Counter';
import RadioButtons from '../Selectors/RadioButtons';
import SelectOptions from '../Selectors/SelectOptions';

interface Props {
  setShow: () => void,
  setTotalSum: React.Dispatch<React.SetStateAction<number>>,
  customizations: [
    {
      name: string,
      min_choice_options: number,
      max_choice_options: number,
      options: [
        {
          name: string,
          price: number,
          customizations: any[],
          min_qty: number,
          max_qty: number,
          conditional_price: {},
          formatted_price: string,
          default_qty: number,
          option_id: string
        }
      ],
      customization_id: string
    },
  ],
  agendaBlockId: number,
  name: string,
  description: string,
  productId: string,
  markedPrice: number,
  onDescrease: () => void,
  onIncrease: () => void,
  count: number,
  setOrders: React.Dispatch<React.SetStateAction<{
    agendaBlockId: number,
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  } | undefined>>,
  onAddMeal: () => void,
  preOrder: {
    agendaBlockId: number,
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  } | undefined,
  onInputChange: (count: number) => void,
  onTextFieldChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
  textFieldValue: string
}

export default function SelectMealCustomizationModal({
  setShow, 
  customizations,
  name,
  description,
  productId,
  markedPrice,
  onDescrease,
  onIncrease,
  count,
  setOrders,
  onAddMeal,
  preOrder,
  setTotalSum,
  onInputChange,
  onTextFieldChange,
  textFieldValue,
  agendaBlockId
}: Props) {
  const [selectedOptions, setSelectedOptions] = useState<{
    name: string;
    price: number;
    customizations: any[];
    min_qty: number;
    max_qty: number;
    conditional_price: {};
    formatted_price: string;
    default_qty: number;
    option_id: string;
    isSelected: boolean;
  }[]>([])
  const productWithCustomizationsPrice = ((count * (markedPrice / 100)) + (count * (preOrder ? preOrder.customizations.reduce((currentValue, option) => currentValue + option.markedPrice, 0) / 100 : 0))).toFixed(2);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  
  useEffect(() => {
    customizations?.map(customization => {
      if (customization.min_choice_options === 1) {

        if (!preOrder?.customizations.some(custom => custom.customizationId === customization.customization_id)) {
          setButtonIsDisabled(true)
        }
        
        if (preOrder?.customizations.some(custom => custom.customizationId === customization.customization_id)) {
          setButtonIsDisabled(false)
        }
      }
    })
  }, [preOrder])

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById('modal')?.classList.add('modal-main-container');
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto';
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);

  return (
    <div className='modalNotification' style={{ zIndex: '1000' }}>
      <div className='modalNotification-container modalNotification-container-selectMealsCustomization'>
        <div className='modalNotification-header'>
          <div className='modalNotification-header-actions'>
            <FeaturedIcon icon={buildingIcon} size='lg' type='gray' />
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={setShow}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
        </div>
        <div className='modalNotification-main'>
          {
            customizations?.map(customization => {
              if (customization.max_choice_options <= 1) {
                return <RadioButtons 
                  options={customization.options.map(item => {
                      let selectedCustomizationOptionIds: string[] = [];
                      preOrder?.customizations.map(optionCustomization => {
                        selectedCustomizationOptionIds = [...selectedCustomizationOptionIds, optionCustomization.optionId]
                      })
                      
                      return  {
                        ...item, 
                        onClick: () => {
                          setOrders(prevState => {
                            if (selectedCustomizationOptionIds.includes(item.option_id) && prevState) {
                              return  {
                                ...prevState,
                                customizations: prevState.customizations.filter(option => option.optionId !== item.option_id)
                              }
                            } else {
                              if (prevState) {
                                if (prevState.customizations.map(option => option.customizationId).includes(customization.customization_id)) {
                                  return { ...prevState!, customizations: [
                                    ...prevState.customizations.filter(custom => custom.customizationId !== customization.customization_id),
                                    { 
                                      customizationId: customization.customization_id,
                                      optionId: item.option_id,
                                      markedPrice: item.price,
                                    }
                                  ]}
                                }
                                return { ...prevState, customizations: [
                                  ...prevState!.customizations,
                                  { 
                                    customizationId: customization.customization_id,
                                    optionId: item.option_id,
                                    markedPrice: item.price,
                                  }
                                ]}
                              } else {
                                return {
                                  customizations: [{
                                    customizationId: customization.customization_id,
                                    optionId: item.option_id,
                                    markedPrice: item.price,
                                  }],
                                  productId: productId,
                                  markedPrice: markedPrice,
                                  notes: '',
                                  count: 1,
                                  agendaBlockId,
                                }
                              }
                            }
                          })
                        },
                        isSelected: selectedCustomizationOptionIds.includes(item.option_id)
                      }
                    }
                   )} 
                  label={customization.name} 
                  isRequired={customization.min_choice_options >= 1}
                />
              }

              if (customization.max_choice_options > 1) {
                return <SelectOptions options={customization.options.map(item => {
                  let selectedCustomizationOptionIds: string[] = [];
                  preOrder?.customizations.map(optionCustomization => {
                    selectedCustomizationOptionIds = [...selectedCustomizationOptionIds, optionCustomization.optionId]
                  })
                  
                  return  {
                    ...item, 
                    onClick: () => {
                      setOrders(prevState => {
                        if (selectedCustomizationOptionIds.includes(item.option_id) && prevState) {
                          return  {
                            ...prevState,
                            customizations: prevState.customizations.filter(option => option.optionId !== item.option_id)
                          }
                        } else {
                          if (prevState) {
                            if (prevState.customizations.map(option => option.customizationId).includes(customization.customization_id)) {
                              return { ...prevState!, customizations: [
                                ...prevState.customizations.filter(custom => custom.customizationId !== customization.customization_id),
                                { 
                                  customizationId: customization.customization_id,
                                  optionId: item.option_id,
                                  markedPrice: item.price,
                                }
                              ]}
                            }
                            return { ...prevState, customizations: [
                              ...prevState!.customizations,
                              { 
                                customizationId: customization.customization_id,
                                optionId: item.option_id,
                                markedPrice: item.price,
                              }
                            ]}
                          } else {
                            return {
                              customizations: [{
                                customizationId: customization.customization_id,
                                optionId: item.option_id,
                                markedPrice: item.price,
                              }],
                              productId: productId,
                              markedPrice: markedPrice,
                              notes: '',
                              count: 1,
                              agendaBlockId,
                            }
                          }
                        }
                      })
                    },
                    isSelected: selectedCustomizationOptionIds.includes(item.option_id)
                  }
                }
               ) } setOptions={setSelectedOptions} label={customization.name} />
              }
            })
          }
          <FormInput 
            type='TextArea'
            placeholder='Enter any special instructions'
            label='Special instructions'
            hintMessage='This is a hint text to help user.'
            fullWidth
            value={textFieldValue}
            onChange={onTextFieldChange}
          />
          <Counter 
            onDescrease={onDescrease}
            onIncrease={onIncrease}
            count={count}
            onInputChange={onInputChange}
          />
        </div>
        <div className='modalNotification-actions modalNotification-actions-flex-end' style={{marginLeft: 'auto'}}>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            // fullWidth={!isDesktopSize}
            onClick={() => {
              onAddMeal();
              setTotalSum(prevState => prevState + +productWithCustomizationsPrice)
            }}
            disabled={
              buttonIsDisabled || count === 0
            }
          >
            Add {name.toLocaleLowerCase()} (${productWithCustomizationsPrice})
          </Button>
        </div>
      </div>
    </div>
  )
}
