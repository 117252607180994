import React, { useEffect, useState } from 'react'
import CheckBox from '../CheckBox/CheckBox';
import trashIcon from '../../assets/images/trash-01-black.svg';
import pencilIcon from '../../assets/images/edit-01.svg';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import { Button } from '../Buttons/Button';
import { useWindowSize } from '@uidotdev/usehooks';
import oliviaAvatar from '../../assets/images/Avatar-olivia.svg';
import phoenixAvatar from '../../assets/images/Avatar-phoenix.svg';
import arrowDown from '../../assets/images/arrow-down.svg';
import arrowUp from '../../assets/images/arrow-up-success.svg';
import helpIcon from '../../assets/images/help-circle.svg';
import Pagination from '../Paginations/Pagination';
import { UserImagePlaceholder } from '../userProfileImagePlaceholder/userImagePlaceholder';

const content = [
  {
    id: 1,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 2,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 3,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 20,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 4,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 5,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 6,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 7,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 8,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 9,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 10,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 11,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 12,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 13,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 14,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 15,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 16,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 17,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 18,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 19,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 21,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 22,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 23,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 24,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 25,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 26,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 27,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 28,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 29,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 30,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 31,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 32,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 33,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 34,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 35,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 36,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 37,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 38,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 39,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 40,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 41,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 42,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 43,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 44,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 45,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 46,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 47,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 48,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 49,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 50,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 51,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 52,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 53,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 54,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 55,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 56,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 57,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 58,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 59,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 60,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 61,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 62,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 63,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 64,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 65,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 66,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 67,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 68,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 69,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 70,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 71,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 72,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: true,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Marketing', 'Marketinrsyd', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 73,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 74,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 75,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 76,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 77,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 78,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 79,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 80,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 81,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: false,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 82,
    img: phoenixAvatar,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 83,
    img: null,
    name: 'Phoenix Baker',
    supportText: 'phoenix',
    isActive: true,
    role: 'Product Manager',
    email: 'Product Manager',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
  {
    id: 84,
    img: oliviaAvatar,
    name: 'Olivia Rhye',
    supportText: 'olivia',
    isActive: false,
    role: 'Product Designer',
    email: 'olivia@untitledui.com',
    teams: ['Design', 'Product', 'Marketing', 'Product', 'Product', 'Product', 'Product']
  },
]

export default function TeamMembersTable() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [selectedPaginationPage, setSelectedPaginationPage] = useState<number>(0);
  const [isAscSorted, setIsAscSorted] = useState<boolean | null>(null);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 

  return (
    <div className='teamMembersTable-container'>
      <div className='teamMembersTable-header'>
        <div className='teamMembersTable-header-info'>
          <h3>Team members</h3>
          <p className='reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-session reviewAgendaSection-blocks-block-session-title'>{content.length} users</p>
        </div>
        <Button 
          buttonType='small'
          hierarchy='tertiaryGray'
          size='md'
          buttonImage={dotsIcon}
        />
      </div>
      <div className='teamMembersTable-table-container'>
        <table className='userInviteTable teamMembersTable-table'>
          <thead className='userInviteTable-header teamMembersTable-table-header'>
            <tr className='userInviteTable-header-container  teamMembersTable'>
              <th className='userInviteTable-header-invitees'>
                <CheckBox 
                isSelected={false} 
                items={content}
                setIsSelected={() => {}}
              />
                Name
              </th>
              <th 
                className='userInviteTable-header-status gap-4 flex'
                onClick={() => setIsAscSorted(prevState => prevState === null ? true : !prevState)}
              >
                Status
                <div className='flex'>
                  <img src={isAscSorted ? arrowDown : arrowUp} alt="" />
                  {
                    isAscSorted === null
                      ? <img src={!isAscSorted ? arrowDown : ''} alt="" />
                      : null
                  }
                </div>
              </th>
              <th className='userInviteTable-header-status gap-4 flex'>
                Role
                <img src={helpIcon} alt="" />
              </th>
              <th className='userInviteTable-header-status'>
                Email address
              </th>
              <th className='userInviteTable-header-status'>
                Teams
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className='userInviteTable-body'>
            {(isAscSorted === null ? content : (isAscSorted ? content.sort((i1, i2) => +i2.isActive - +i1.isActive) : content.sort((i1, i2) => +i1.isActive - +i2.isActive))).slice(selectedPaginationPage * 10, (selectedPaginationPage + 1) * 10).map((item, index) => (
              <tr className={`userInviteTable-body-container ${(index + 1) % 2 !== 0 ? 'userInviteTable-body-container-isOdd' : ''}  teamMembersTable ${index + 1 === content.length ? 'border-none border-radius-10' : ''}`} key={item.id}>
                <td className='userInviteTable-body-item-info teamMembersTable-table-cell'>
                  <CheckBox 
                    isSelected={false} 
                    item={item}
                    setIsSelected={() => {}}
                  />
                  {
                    item.img
                      ? <img src={item.img} alt="" className='teamMembersTable-table-avatar' />
                      : <UserImagePlaceholder name={item.name} verified={false} />
                  }
                  <div>
                    <h3 className='userInviteTable-body-item-info-name'>{item.name}</h3>
                    <p className='userInviteTable-body-item-info-email teamMembersTable-table-nickname'>@{item.supportText}</p>
                  </div>
                </td>
                <td className='userInviteTable-body-item-status teamMembersTable-table-cell'>
                  <div className='teamMembersTable-table-isActive'>
                    <div
                      className={`buttonIsOnlineCircle buttonIsOnlineCircle_success ${!item.isActive ? 'buttonIsOnlineCircle_success-disabled' : ''}`}
                    />
                    {item.isActive ? 'Active' : 'Inactive'}
                  </div>
                </td>
                <td className='userInviteTable-body-item-status teamMembersTable-table-cell'>
                  {item.role}
                </td>
                <td className='userInviteTable-body-item-status teamMembersTable-table-cell'>
                  {item.email}
                </td>
                <td className='userInviteTable-body-item-status teamMembersTable-table-cell'>
                  <div className='teamMembersTable-table-teams'>
                    {item.teams.length > 3
                      ? item.teams.slice(0, 3).map((team, i) => 
                          <p 
                            key={`team${item.id}${i}`}
                            className={`
                              teamMembersTable-table-teams-team 
                              ${i === 0 ? 'teamMembersTable-table-teams-team-primary' : ''}
                              ${i === 1 ? 'teamMembersTable-table-teams-team-blue' : ''}
                              ${i === 2 ? 'teamMembersTable-table-teams-team-indigo' : ''}
                            `}
                          >
                            {team.length > 9
                              ? `${team.slice(0, 7)}...`
                              : team
                            }
                          </p>
                        )
                      : item.teams.map((team, i) => 
                          <p 
                            key={`team${item.id}`}
                            className={`
                              teamMembersTable-table-teams-team 
                              ${i === 0 ? 'teamMembersTable-table-teams-team-primary' : ''}
                              ${i === 1 ? 'teamMembersTable-table-teams-team-blue' : ''}
                              ${i === 2 ? 'teamMembersTable-table-teams-team-indigo' : ''}
                            `}
                          >
                            {team.length > 9
                              ? `${team.slice(0, 7)}...`
                              : team
                            }
                          </p>
                        )
                    }
                    {
                      item.teams.length > 3
                        ? <p className='userInviteTable-body-item-status-info'>
                            +{item.teams.length - 3}
                          </p>
                        : null
                    }
                  </div>
                </td>
                <td className='userInviteTable-body-item-actions teamMembersTable-table-cell'>
                  {sizeIsDesktop
                    ? <>
                      <Button
                        buttonType='small'
                        hierarchy='tertiaryGray'
                        size='md'
                        buttonImage={trashIcon}
                      //   onClick={() => setSelectedAttendees(prevState => prevState.filter(it => it.id !== item.id))}
                      />
                      <Button
                        buttonType='small'
                        hierarchy='tertiaryGray'
                        size='md'
                        buttonImage={pencilIcon}
                      />
                    </>
                  : <Button
                      buttonType='small'
                      hierarchy='tertiaryGray'
                      size='md'
                      buttonImage={dotsIcon}
                    />
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination numberOfPages={Math.ceil(content.length / 10)} selected={selectedPaginationPage} setSelected={setSelectedPaginationPage} />
    </div>
  )
}