export const handleVerifyCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>, 
    index: number, 
    setDigit: React.Dispatch<React.SetStateAction<string>>, 
    setCurrentInput: React.Dispatch<React.SetStateAction<number>>
) => {
  let entry = e.target.value;

  if (
    (
      entry !== '0' && 
      entry !== '1' && 
      entry !== '2' && 
      entry !== '3' && 
      entry !== '4' && 
      entry !== '5' && 
      entry !== '6' && 
      entry !== '7' && 
      entry !== '8' && 
      entry !== '9'
    ) && entry !== '') {
    return
  };
    
  if (entry.length <= 1 && !Number.isNaN(entry)) {
    setDigit(entry);
    
    if (entry.length === 1) {
      if (index < 6) {
        let nextInput = document.querySelectorAll('.verifyCode-cell')[index] as HTMLInputElement;
      
        if (nextInput.value === "") {
          setCurrentInput(index);
        };
      }
    }
  } else return;
};