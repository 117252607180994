import { logoImage } from "../types/logoImage";
import computerIcon from '../assets/images/monitor-04.svg';
import lifebuoy from '../assets/images/life-buoy-01-white.svg';
import briefCase from '../assets/images/briefcase-01.svg';
import currencyDollar from '../assets/images/currency-dollar-circle.svg';
import lightBulb from '../assets/images/lightbulb-02.svg';
import rocket from '../assets/images/rocket-02.svg';
import shieldTick from '../assets/images/shield-tick.svg';
import starIcon from '../assets/images/star-01.svg';
import targetIcon from '../assets/images/target-04-white.svg';
import zapIcon from '../assets/images/zap-event-logo.svg';
import pathIcon from '../assets/images/path.svg';

export function getEventLogo(eventLogo: logoImage | null) {
  switch (eventLogo) {
    case logoImage.LIFEBUOY:
      return lifebuoy;
    case logoImage.BRIEFCASE:
      return briefCase;
    case logoImage.CURRENCYDOLLAR:
      return currencyDollar;
    case logoImage.LIGHTBULB:
      return lightBulb;
    case logoImage.ROCKET:
      return rocket;
    case logoImage.SHIELDTICK:
      return shieldTick;
    case logoImage.STARICON:
      return starIcon;
    case logoImage.TARGET:
      return targetIcon;
    case logoImage.ZAP:
      return zapIcon;
    case logoImage.COMPUTER:
      return computerIcon;
    default: return pathIcon;
  }
}