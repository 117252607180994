import { useState } from 'react'
import calendarIcon from '../../assets/images/calendar.svg';
import { Button } from '../Buttons/Button'
import DatePickerLarge from './DatePickerLarge';
import moment from 'moment';

interface Props {
  type: 'double' | 'small' | 'smallWithTodayFeat',
  top?: string | null,
  bottom?: string | null,
  right?: string | null,
  left?: string | null,
  placeholder: string,
  isError?: boolean,
  firstDate: moment.Moment | null,
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  secondDate?: moment.Moment | null,
  setSecondDate?: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  setShowOtherPickers: () => void,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isOpen: boolean,
  selectedDayOnlyFromToday: boolean,
  selectSecondDateFrom?: moment.Moment | null,
  onApply?: () => void;
}

export default function Date(
  { 
    type, 
    bottom, 
    left, 
    right, 
    top, 
    placeholder, 
    isError, 
    firstDate, 
    secondDate, 
    setFirstDate, 
    setSecondDate, 
    setShowOtherPickers,
    isOpen,
    setIsOpen,
    selectedDayOnlyFromToday,
    onApply,
    selectSecondDateFrom
  }: Props) {
  return (
    <div className='calendar'>
      <Button
        buttonType='regular'
        size='md'
        hierarchy={isError ? 'errorSecondaryGray' : 'secondaryGray'}
        onClick={() => {
          setShowOtherPickers && setShowOtherPickers();
          setIsOpen((prevState) => !prevState);
        }}
        fullWidth
      >
        <div className={`openButton ${firstDate ? '' : 'openButton-color-gray openButton-width-content'}`}>
          <img src={calendarIcon} alt="calendar icon" className='calendarIcon' />
          <p className={`text-sm ${isError ? 'textError' : ''}`}>{!firstDate && !secondDate ? placeholder : `${firstDate?.format('ll')} ${secondDate ? '-' : ''} ${secondDate ? secondDate?.format('ll') : ''}`}</p>
        </div>
      </Button>
      <DatePickerLarge
        firstDate={firstDate}
        setFirstDate={setFirstDate}
        secondDate={secondDate}
        setSecondDate={setSecondDate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type={type}
        bottom={bottom}
        left={left}
        right={right}
        top={top}
        selectedDayOnlyFromToday={selectedDayOnlyFromToday}
        onApply={onApply}
        selectSecondDateFrom={selectSecondDateFrom}
      />
    </div>
  )
}
