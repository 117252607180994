export enum logoImage {
  LIFEBUOY = 'lifeBuoy',
  BRIEFCASE = 'briefCase',
  CURRENCYDOLLAR = 'currencyDollar',
  LIGHTBULB = 'lightBulb',
  ROCKET = 'rocket',
  SHIELDTICK = 'shieldTick',
  STARICON = 'starIcon',
  TARGET = 'target',
  ZAP = 'zap',
  COMPUTER = 'computer',
  PATH = 'path',
}