import { getTimeDiffence } from "./getTimeDifference";
import { supabase } from "./supabaseClient";

export async function refreshToken() {
  const lastTokenSetTime = localStorage.getItem('lastTokenSetTime');
  const session = localStorage.getItem(`sb-${process.env.REACT_APP_SB_ID}-auth-token`); //sb-{supabaseId}-auth-token

  if (!lastTokenSetTime || !session) {
    const { data, error } = await supabase().auth.refreshSession();
  
    const token = data.session?.access_token;

    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('lastTokenSetTime', new Date().toISOString());

      return token;
    }
  }

  if (lastTokenSetTime && session) {
    if (getTimeDiffence({ currentTime: new Date().toISOString(), prevTime: lastTokenSetTime }) >= 55) {
      const { data, error } = await supabase().auth.refreshSession();
  
      const token = data.session?.access_token;

      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('lastTokenSetTime', new Date().toISOString());

        return token;
      }

      if (error) {
        window.location.pathname = '/login';
        localStorage.clear()
      }
  
    }
  } else if (window.location.pathname !== '/login' && window.location.pathname !== '/resetPassword') {
    window.location.pathname = '/login'
  }

  return null
}