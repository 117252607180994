import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./slice.init";
import { TCurrentRoutePayload } from "./slice.types";

export const slice = createSlice({
  name: "recentlyCreatedEvent",
  initialState,
  reducers: {
    setRecentlyCreatedEvent: (state, action: PayloadAction<TCurrentRoutePayload>) => {
      state.slug = action.payload.slug;
      state.name = action.payload.name;
    }
  },
});
