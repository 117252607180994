import React from 'react'
import InfoBlock from '../../components/Blocks/InfoBlock';
import usersPlusIcon from '../../assets/images/user-plus-01-primary.svg';
import chatIcon from '../../assets/images/message-chat-circle-primary.svg';
import calendarIcon from '../../assets/images/calendar-plus-02-primary.svg';
import { useNavigate } from 'react-router-dom';

export default function EmptyPage() {
  const navigate = useNavigate();

  return (
    <div className='eventsEmptyPage flex-row'>
      <div className='eventsEmptyPage-info'>
        <h3 className='eventsEmptyPage-info-title'>Events page</h3>
        <p className='eventsEmptyPage-info-description'>This is where you will see all the events you get invited to, wile you wait for that you could do a few of this things:</p>
      </div>
      <div className='eventsEmptyPage-actions'>
        <InfoBlock 
          icon={calendarIcon}
          action={() => navigate('/')}
          actionText='Create event'
          title='Create your own event'
          description='Lore ipsum'
        />
        <InfoBlock 
          icon={usersPlusIcon}
          action={() => navigate('/contacts')}
          actionText='Add contact'
          title='Contacts'
          description='Lore ipsum'
        />
        <InfoBlock 
          icon={chatIcon}
          action={() => navigate('/support')}
          actionText='Contact us'
          title='Need help?'
          description='Lore ipsum'
        />
      </div>
    </div>
  )
}
