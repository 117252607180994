import React, { useState } from 'react'
import CheckBox from '../CheckBox/CheckBox';
import { UserImagePlaceholder } from '../userProfileImagePlaceholder/userImagePlaceholder';
import { Button } from '../Buttons/Button';
import DotsModal from '../Modal/DotsModal';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import editDotsIcon from '../../assets/images/edit-01.svg';
import deleteIcon from '../../assets/images/trash-01-black.svg';
import { deleteAttendee, postReminder } from '../../httpQueries/http';
import Pagination from '../Paginations/Pagination';
import { AttendeeRSVP } from '../../types/attendeeRSVP';
import Badge from '../Badge/Badge';

interface Props {
  content: {
    id: number,
    networkMemberId: number,
    status: 'added',
    rsvp: AttendeeRSVP | null,
    networkMember: {
      id: number,
      fullName: string,
      email: string,
      phone: string,
      businessName: string,
      address: string,
      title: string,
      userId: null | number,
      accountId: number,
      createdAt: string,
      updatedAt: string,
      user: {
        accountId: number,
        createdAt: string,
        email: string,
        fullName: string,
        id: number,
        phone?: string | null,
        status: string,
        supabaseId: string,
        updatedAt: string,
      } | null,
    },
    mealSelections: any[]
  }[],
  setUserUpdated: React.Dispatch<React.SetStateAction<number | null>>,
  setUserDeleted: React.Dispatch<React.SetStateAction<number | null>>,
  setUserFullName: React.Dispatch<React.SetStateAction<string>>,
  setUserEmail: React.Dispatch<React.SetStateAction<string>>,
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string>>,
  setUserBusinessName: React.Dispatch<React.SetStateAction<string>>,
  setUserTitle: React.Dispatch<React.SetStateAction<string>>,
  setIsNewUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  selectedPaginationPage: number,
  setSelectedPaginationPage: React.Dispatch<React.SetStateAction<number>>,
  pages: number,
  onScroll: () => void,
  eventId: number,
}

export default function ReviewEventTableInvitees({ 
  content, 
  setUserBusinessName, 
  setUserDeleted, 
  setUserEmail, 
  setUserFullName, 
  setUserPhoneNumber, 
  setUserTitle, 
  setUserUpdated,
  setIsNewUserModalOpen,
  selectedPaginationPage,
  setSelectedPaginationPage,
  pages,
  onScroll,
  eventId
}: Props) {
  const token = localStorage.getItem('token');
  const [isDotsModalOpen, setIsDotsModalOpen] = useState<number | null>(null);

  return (
    <>
      <div className='teamMembersTable-table-container overflowXVisible' onScroll={onScroll}>
        <table className='userInviteTable teamMembersTable-table fullWidth border-radius-12'>
          <thead className='userInviteTable-header teamMembersTable-table-header'>
            <tr className='userInviteTable-header-container userInviteTable-body-container-invitedAttendees'>
              <th className='userInviteTable-header-invitees'>
                <CheckBox 
                  isSelected={false} 
                  items={content.map(item => ({
                    id: item.id,
                    name: item.networkMember.user ? item.networkMember.user.fullName : item.networkMember.fullName,
                    supportText: item.networkMember.user ? item.networkMember.user.email : item.networkMember.email
                  }))}
                  setIsSelected={() => {}}
                />
                Name
              </th>
              <th className='teamMembersTable-table-cell teamMembersTable-table-cell-flex font-medium'>RSVP status</th>
              <th className='teamMembersTable-table-cell teamMembersTable-table-cell-flex font-medium'>Food order status</th>
              <th></th>
            </tr>
          </thead>
          <tbody className='userInviteTable-body'>
            {content.map((item, index) => (
              <tr 
                className={`userInviteTable-header-container userInviteTable-body-container-invitedAttendees fullWidth ${(index + 1) % 2 !== 0 ? 'userInviteTable-body-container-isOdd' : ''}`} 
                key={item.id} 
              >
                <td className='userInviteTable-body-item-info teamMembersTable-table-cell overflowXVisible'>
                  <CheckBox 
                    isSelected={false} 
                    item={{
                      id: item.id, 
                      name: item.networkMember.user ? item.networkMember.user.fullName : item.networkMember.fullName, 
                      supportText: item.networkMember.user ? item.networkMember.user.email : item.networkMember.email
                    }}
                    setIsSelected={() => {}}
                  />
                    <UserImagePlaceholder name={(item.networkMember.user ? item.networkMember.user.fullName : item.networkMember.fullName).toLocaleUpperCase()} verified={!!item.networkMember.user} />
                  <div>
                    <h3 className='userInviteTable-body-item-info-name'>{item.networkMember.user ? item.networkMember.user.fullName : item.networkMember.fullName}</h3>
                    <p className='userInviteTable-body-item-info-email teamMembersTable-table-nickname'>{item.networkMember.user ? item.networkMember.user.email : item.networkMember.email}</p>
                  </div>
                </td>
                <td className='teamMembersTable-table-cell teamMembersTable-table-cell-flex'>
                  <Badge 
                    text={item.rsvp === AttendeeRSVP.Maybe ? 'Maybe' : item.rsvp === AttendeeRSVP.No ? 'Declined' : item.rsvp === AttendeeRSVP.Yes ? 'Accepted' : 'Pending'}  
                    status={item.rsvp === AttendeeRSVP.Maybe ? 'warning' : item.rsvp === AttendeeRSVP.No ? 'error' : item.rsvp === AttendeeRSVP.Yes ? 'success' : 'default'} 
                  />
                </td>
                <td className='teamMembersTable-table-cell teamMembersTable-table-cell-flex'>
                  <Badge 
                    text={item.mealSelections.length ? 'Ordered' : 'Pending'}
                    status={item.mealSelections.length ? 'success' : 'default'}
                  />
                </td>
                <td className='userInviteTable-body-item-actions teamMembersTable-table-cell positionRelative'>
                  <Button
                    buttonType='small'
                    hierarchy='tertiaryGray'
                    size='md'
                    buttonImage={dotsIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDotsModalOpen(isDotsModalOpen === item.id ? null : item.id);
                    }}
                  />

                  {
                    isDotsModalOpen === item.id
                      ? <DotsModal 
                          items={[
                            {id: 1, img: editDotsIcon, text: 'Send invite again', onClick: (e) => {
                              postReminder({
                                type: 'event-reminder',
                                token, 
                                networkMemberId: item.networkMemberId,
                                userId: item.id,
                                eventId
                              })
                            }}, 
                            {id: 2, img: deleteIcon, text: 'Remove', onClick: (e) => {
                              e.stopPropagation();
                              deleteAttendee({ token, attendeeId: item.id });
                              setUserDeleted(item.id)
                            }}
                          ]}
                          setIsOpen={() => {
                            setIsDotsModalOpen(null)
                          }}
                          top='60px'
                          right='0'
                        />
                      : null
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination numberOfPages={pages} selected={selectedPaginationPage} setSelected={setSelectedPaginationPage} />
    </>
  )
}
