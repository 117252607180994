import React, { useEffect, useState } from 'react'
import { handleVerifyCodeChange } from '../../utilities/verifyCodeHandle';

interface Props {
  firstDigit: string,
  setFirstDigit: React.Dispatch<React.SetStateAction<string>>,
  secondDigit: string,
  setSecondDigit: React.Dispatch<React.SetStateAction<string>>,
  thirdDigit: string,
  setThirdDigit: React.Dispatch<React.SetStateAction<string>>,
  fourthDigit: string,
  setFourthDigit: React.Dispatch<React.SetStateAction<string>>,
  fifthDigit: string,
  setFifthDigit: React.Dispatch<React.SetStateAction<string>>,
  sixthDigit: string,
  setSixthDigit: React.Dispatch<React.SetStateAction<string>>,
  isError: boolean,
}

export default function VerifyCode({ 
  firstDigit, 
  secondDigit, 
  thirdDigit, 
  fourthDigit, 
  fifthDigit, 
  sixthDigit, 
  setFifthDigit, 
  setFourthDigit, 
  setFirstDigit, 
  setSecondDigit, 
  setSixthDigit, 
  setThirdDigit,
  isError
}: Props) {
  const [currentInput, setCurrentInput] = useState<number>(0);

  useEffect(() => {
    const firstInput = document.querySelectorAll('.verifyCode-cell')[0] as HTMLInputElement;
    firstInput.focus();
  }, []);

  useEffect(() => {
    const current = document.querySelectorAll('.verifyCode-cell')[currentInput] as HTMLInputElement;
    current.focus();
  }, [currentInput]);

  const onBackspaceClick = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.code === 'Backspace') {
      const prevInput = document.querySelectorAll<HTMLInputElement>('.verifyCode-cell')[index - 2];
      prevInput.focus();
    }
  }


  return (
    <form className='verifyCode-container'>
      <div className="verifyCode">
        <input 
          className={`verifyCode-cell ${isError ? 'verifyCode-cell-error' : ''}`} 
          type='text' 
          onChange={(e) => handleVerifyCodeChange(e, 1, setFirstDigit, setCurrentInput)} 
          value={firstDigit}
        />
        <input 
          className={`verifyCode-cell ${isError ? 'verifyCode-cell-error' : ''}`} 
          type='text' 
          onChange={(e) => handleVerifyCodeChange(e, 2, setSecondDigit, setCurrentInput)} 
          disabled={!firstDigit.length}
          value={secondDigit}
          onKeyUp={(e) => onBackspaceClick(e, 2)}
        />
        <input 
          className={`verifyCode-cell ${isError ? 'verifyCode-cell-error' : ''}`} 
          type='text' 
          onChange={(e) => handleVerifyCodeChange(e, 3, setThirdDigit, setCurrentInput)} 
          disabled={!secondDigit.length} 
          value={thirdDigit}
          onKeyUp={(e) => onBackspaceClick(e, 3)}
        />
        <input 
          className={`verifyCode-cell ${isError ? 'verifyCode-cell-error' : ''}`} 
          type='text'  
          onChange={(e) => handleVerifyCodeChange(e, 4, setFourthDigit, setCurrentInput)} 
          disabled={!thirdDigit.length} 
          value={fourthDigit}
          onKeyUp={(e) => onBackspaceClick(e, 4)}
        />
        <input 
          className={`verifyCode-cell ${isError ? 'verifyCode-cell-error' : ''}`} 
          type='text' 
          onChange={(e) => handleVerifyCodeChange(e, 5, setFifthDigit, setCurrentInput)} 
          disabled={!fourthDigit.length} 
          value={fifthDigit}
          onKeyUp={(e) => onBackspaceClick(e, 5)}
        />
        <input 
          className={`verifyCode-cell ${isError ? 'verifyCode-cell-error' : ''}`} 
          type='text'  
          onChange={(e) => handleVerifyCodeChange(e, 6, setSixthDigit, setCurrentInput)} 
          disabled={!fifthDigit.length} 
          value={sixthDigit}
          onKeyUp={(e) => onBackspaceClick(e, 6)}
        />
      </div>
    </form>
  )
}
