import React from 'react';
import { Button } from '../Buttons/Button';
import VisaIcon from '../../assets/images/VisaCard.svg';
import mailIcon from '../../assets/images/mail-01.svg';

export default function PaymentMethodCard() {
  return (
    <div className='paymentMethodCard'>
      <div className='paymentMethodCard-info'>
        <h3 className='paymentMethodCard-info-title'>Payment method</h3>
        <p className='paymentMethodCard-info-description'>Change how you pay for your plan.</p>
      </div>
      <div className='paymentMethodCard-card'>
        <div className='paymentMethodCard-card-info'>
          <img src={VisaIcon}alt="" />
          <div className='paymentMethodCard-card-info-container'>
            <div>
              <p className='paymentMethodCard-card-info-title'>Visa ending in 1234</p>
              <p className='paymentMethodCard-card-info-description'>Expiry 06/2024</p>
            </div>
            <div className='flex gap-8 items-center'>
              <img src={mailIcon} alt="" />
              <p className='paymentMethodCard-card-info-description'>billing@untitledui.com</p>
            </div>
          </div>
        </div>
        <Button
          size='md'
          hierarchy='secondaryGray'
          buttonType='regular'
        >
          Edit
        </Button>
      </div>
    </div>
  )
}
