import React, { useEffect, useState } from 'react'
import { Button } from '../../components/Buttons/Button';
import HorizontalTabs from '../../components/Tabs/HorizontalTabs';
import plusIcon from '../../assets/images/plus.svg';
import { useWindowSize } from '@uidotdev/usehooks'; 
import Navigation from "../../components/Navigation/SideBarNavigation";
import oliviaPhoto from '../../assets/images/Avatar-olivia.svg';
import listIcon from '../../assets/images/listIcon.svg';
import gridIcon from '../../assets/images/layout-grid-01.svg';
import CustomTabs from '../../components/Tabs/CustomTabs';
import DatePicker from '../../components/DatePickers/Date';
import EventReviewBlockV2 from '../../components/EventBlock/EventReviewBlockV2';
import { useQuery } from '@tanstack/react-query';
import { fetchEvents } from '../../httpQueries/http';
import moment from 'moment';
import { getTimeFromMinutes } from '../../utilities/getTimeFromMinutes';
import { formatDate } from '../../utilities/formatDate';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import EventsCalendarPage from './EventsCalendarPage';
import NoEventsFound from './NoEventsFound';
import EmptyPage from './EmptyPage';
import { useSelector } from '../../store/hooks';
import { recentlyCreatedEventSelector } from '../../store/recentlyCreatedEvent';
import ModalEventWasCreated from '../../components/Modal/ModalEventWasCreated';
import { supabase } from '../../utilities/supabaseClient';
import { EventsFilter } from '../../types/eventsFilter';
import { currentUserSelector } from '../../store/currentUser';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function EventsPageV2() {
  const [queryParams, setQueryParams] = useSearchParams();
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [currentTab, setCurrentTab] = useState<{id: number, name: string} | null>({id: 1, name: 'Overview'});
  const [currentSecondTab, setCurrentSecondTab] = useState<{id: number, name: string}| null>(null);
  const [currentThirdTab, setCurrentThirdTab] = useState<number>(1);
  const [eventDate, setEventDate] = useState<moment.Moment | null>(null);
  const [eventSecondDate, setEventSecondDate] = useState<moment.Moment | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const startDay = queryParams.get('startDay') || new Date().getDate();
  const startMonth = queryParams.get('startMonth') || new Date().getMonth();
  const startYear = queryParams.get('startYear') || new Date().getFullYear();
  const endDay = queryParams.get('endDay') || new Date().getDate();
  const endMonth = queryParams.get('endMonth') || new Date().getMonth();
  const endYear = queryParams.get('endYear') || new Date().getFullYear();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const recentlyCreatedEvent = useSelector(recentlyCreatedEventSelector);
  const [isEventCreatedModalOpen, setIsEventCreatedModalOpen] = useState(!!recentlyCreatedEvent.name.length);
  const currentUser = useSelector(currentUserSelector);
  const filter = queryParams.get('filter');
  const [contentIsScrolled, setContentIsScrolled] = useState(false);

  const { data: events, fetchStatus } = useQuery({
    queryKey: ['events', token, endYear, endMonth, endDay, startDay, startYear, startMonth, queryParams.get('filter'), currentTab],
    queryFn: () => fetchEvents({
        start: new Date(+startYear, +startMonth, +startDay, 0, 0, 0, 0).valueOf(), 
        end: new Date(+endYear, +endMonth, +endDay, 23, 59, 59, 999).valueOf(), 
        token, 
        eventFilter: 
         queryParams.get('filter') === EventsFilter.Attendee 
           ? EventsFilter.Attendee 
           : EventsFilter.Organizer 
         || EventsFilter.All,
         isPublished: currentTab?.id === 1 || currentTab?.id === 2
    })
  });

  useEffect(() => {
    localStorage.removeItem('currentEditEvent');
  }, []);

  useEffect(() => {
    const currentDateTab = queryParams.get('current');
    switch(currentDateTab) {
      case 'custom':
        setCurrentSecondTab(null);
        break;
      case '12 months':
        setCurrentSecondTab({id: 1, name: '12 months'});
        break;
      case '30 days':
        setCurrentSecondTab({id: 2, name: '30 days'});
        break;
      case '7 days':
        setCurrentSecondTab({id: 3, name: '7 days'});
        break;
      case '24 hours':
        setCurrentSecondTab({id: 4, name: '24 hours'});
        break;
      case null:
        setCurrentSecondTab({id: 2, name: '30 days'});
        setQueryParams({
          filter: filter!,
          startDay: `${currentDay}`, 
          startMonth: `${currentMonth}`, 
          startYear: `${currentYear}`, 
          endYear: `${currentYear}`, 
          endMonth: `${currentMonth + 1}`, 
          endDay: `${currentDay}`,
          current: '30 days'
        })
        break;
    }
  }, [])
  
  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear()
    localStorage.clear();

    navigate('/login');
    
    return null
  }

  return (
    <div className={`eventDetails_container ${!sizeIsDesktop ? 'flex-column' : ''}`}>
      <Navigation
        hierarchy='primary'
        currentItem={queryParams.get('filter') === 'attendee' ? 'EventsAttendee' : 'EventsOrganizer'}
      />
      {
        isEventCreatedModalOpen
          ? <ModalEventWasCreated setIsOpen={setIsEventCreatedModalOpen} />
          : null
      }
      <div className='eventDetails_main-container eventDetails_main-container-reviewPage'>
        <div className="eventDetails_main eventDetails_main-reviewPage">
          {
            currentUser.completeTutorial
              ? <>
                  <div className={`eventDetails_main-header px-32`} style={{minHeight: '0'}}>
                    <div className='eventsPage-header'>
                      <div className='eventsPage-header-info'>
                        <h3 className='eventsPage-header-title'>Events {queryParams.get('filter') === EventsFilter.Attendee ? 'I\'m Attending' : 'I\'m Organizing'}</h3>
                      </div>
                      <div style={{height: 'fit-content'}}>
                        <Button
                          buttonType='regular'
                          hierarchy='primary'
                          size='md'
                          onClick={() => navigate('/createEvent')}
                        >
                          <div className='eventsPage-header-addNewButton'>
                            <img src={plusIcon} alt="" />
                            <p className='eventsPage-header-addNewButton-text'>Create event</p>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='eventDetails_content flex flex-column gap-20 px-32' id='mobileCalendar' onScroll={() => setContentIsScrolled((document.getElementById('mobileCalendar') as HTMLElement).scrollTop >= 32)}>
                      <HorizontalTabs 
                        tabs={queryParams.get('filter') === EventsFilter.Attendee ? [{id: 1, name: 'Overview'}, {id: 2, name: 'Calendar'}] : [{id: 1, name: 'Overview'}, {id: 2, name: 'Calendar'}, {id: 3, name: 'Unpublished'}]} 
                        current={currentTab} 
                        setCurrent={setCurrentTab}
                        type='Underline'
                      />
                      {
                        currentTab?.id === 1 || currentTab?.id === 3
                          ? <>
                            <div className='flex spaceBetween items-center'>
                                <CustomTabs 
                                  current={currentSecondTab}
                                  setCurrent={setCurrentSecondTab}
                                  tabs={[
                                    {id: 1, name: '12 months', onSelect: () => {
                                      setEventDate(null);
                                      setEventSecondDate(null);
                                      
                                      setQueryParams({
                                        filter: filter!,
                                        startDay: `${currentDay}`, 
                                        startMonth: `${currentMonth}`, 
                                        startYear: `${currentYear}`, 
                                        endYear: `${currentYear + 1}`, 
                                        endMonth: `${currentMonth}`, 
                                        endDay: `${currentDay}`,
                                        current: '12 months'
                                      })
                                    }}, 
                                    {id: 2, name: '30 days', onSelect: () => {
                                      setEventDate(null);
                                      setEventSecondDate(null);
                                      
                                      setQueryParams({
                                        filter: filter!,
                                        startDay: `${currentDay}`, 
                                        startMonth: `${currentMonth}`, 
                                        startYear: `${currentYear}`, 
                                        endYear: `${currentYear}`, 
                                        endMonth: `${currentMonth + 1}`, 
                                        endDay: `${currentDay}`,
                                        current: '30 days'
                                      })
                                    }}, 
                                    {id: 3, name: '7 days', onSelect: () => {
                                      setEventDate(null);
                                      setEventSecondDate(null);

                                      setQueryParams({
                                        filter: filter!,
                                        startDay: `${currentDay}`, 
                                        startMonth: `${currentMonth}`, 
                                        startYear: `${currentYear}`, 
                                        endYear: `${currentYear}`, 
                                        endMonth: `${currentMonth}`, 
                                        endDay: `${currentDay + 7}`,
                                        current: '7 days'
                                      })
                                    }}, 
                                    {id: 4, name: '24 hours', onSelect: () => {
                                      setEventDate(null);
                                      setEventSecondDate(null);

                                      setQueryParams({
                                        filter: filter!,
                                        startDay: `${currentDay}`, 
                                        startMonth: `${currentMonth}`, 
                                        startYear: `${currentYear}`, 
                                        endYear: `${currentYear}`, 
                                        endMonth: `${currentMonth}`, 
                                        endDay: `${currentDay}`,
                                        current: '24 hours'
                                      })
                                    }}
                                  ]}
                                />
                                <div className='flex items-center gap-12'>
                                  <DatePicker
                                    firstDate={eventDate}
                                    secondDate={eventSecondDate}
                                    setSecondDate={setEventSecondDate}
                                    isOpen={showDatePicker}
                                    placeholder='Select dates'
                                    setFirstDate={setEventDate}
                                    setIsOpen={setShowDatePicker}
                                    type='double'
                                    selectedDayOnlyFromToday={false}
                                    selectSecondDateFrom={eventDate}
                                    setShowOtherPickers={() => {}}
                                    right={'-4px'}
                                    top={'42px'}
                                    onApply={() => {
                                      setCurrentSecondTab(null)
                                      const date = eventDate?.date();
                                      const month = eventDate?.month();
                                      const year = eventDate?.year();
                                      const dateSecond = eventSecondDate?.date();
                                      const monthSecond = eventSecondDate?.month();
                                      const yearSecond = eventSecondDate?.year();

                                      if (date && month !== undefined && year && dateSecond && monthSecond !== undefined && yearSecond) {
                                        setQueryParams({
                                          filter: filter!,
                                          startDay: `${date}`, 
                                          startMonth: `${month}`, 
                                          startYear: `${year}`, 
                                          endYear: `${yearSecond}`, 
                                          endMonth: `${monthSecond}`, 
                                          endDay: `${dateSecond}`,
                                          current: 'custom'
                                        })
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              {
                                fetchStatus === 'fetching'
                                  ? <div className='flex justifyCenter items-center fullWidth fullHeight'>
                                      <Loader size='xl' />
                                    </div>
                                  : events && events.length > 0 
                                      ? events.map(item => {
                                        return <EventReviewBlockV2 color={+new Date(item.startTime).valueOf() < +new Date().valueOf() ? 'pastEvent' : item.color} pattern={item.pattern} key={item.slug} onClick={() => navigate(`${item.slug}`)} endTime={item.endTime ? getTimeFromMinutes(item.endTime) : undefined} name={item.name} date={moment(item.startDate).format('ll')} startTime={getTimeFromMinutes(item.startTime)} location={item.location}  />
                                      })
                                      : <NoEventsFound />
                              }
                            </>
                          : <EventsCalendarPage filter={queryParams.get('filter') === EventsFilter.Attendee 
                                ? EventsFilter.Attendee 
                                : EventsFilter.Organizer 
                              || EventsFilter.All
                            } sizeIsDesktop={sizeIsDesktop} contentIsScrolled={contentIsScrolled} />
                      }
                  </div>
                </>
              : fetchStatus === 'fetching'
                  ? <div className='flex justifyCenter items-center fullWidth fullHeight'>
                      <Loader size='xl' />
                    </div>
                  : <EmptyPage />
          }
        </div>
      </div>
    </div>
  )
}
