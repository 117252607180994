import React from 'react'

export default function BillingsDetails() {
  return (
    <div className='eventDetails_content myDetails'>
      <div className='myDetails-content fullWidth'>
        <div className='settingsBilling'>
          <p className='settingsBilling-title'>You are in a beta trial</p>
          <p className='settingsBilling-description'>We will update our pricing and plans soon!</p>
        </div>
      </div>
    </div>
  )
}
