import React from 'react';
import alertIcon from '../../assets/images/alert-circle-lg.svg';

interface Props {
  title: string,
  description: string,
  link?: string,
}

export default function Alert({ title, description, link }: Props) {
  return (
    <div className='alert'>
      <div className='alert-firstCircle'>
        <div className='alert-secondCircle'>
          <img src={alertIcon} alt="alert" />
        </div>
      </div>
      <div className='flex flex-column gap-4'>
        <h3 className='errorMessage-title'>{title}</h3>
        <p className='errorMessage-description'>{description}</p>
      </div>
    </div>
  )
}
