import React, { useEffect, useState } from 'react';
import arrowRight from '../../assets/images/chevron-right-black.svg';
import arrowLeft from '../../assets/images/chevron-left-black.svg';

interface Props {
  photos: string[],
}

export default function Gallery({ photos }: Props) {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  return (
    <div className='gallery'>
      <img src={photos[currentPhotoIndex]} alt="" className='gallery-photo' />
      <div>
        {
          currentPhotoIndex + 1 !== photos.length
            ? <img 
                src={arrowRight} 
                alt="" 
                className={`gallery-button gallery-button-right`} 
                onClick={() => {
                setCurrentPhotoIndex(prevState => prevState + 1)
                }}
              />
            : null
        }

        {
          photos.length - currentPhotoIndex >= 1 && currentPhotoIndex !== 0
            ? <img 
                src={arrowLeft} 
                alt="" 
                className={`gallery-button gallery-button-left`} 
                onClick={() => {
                  setCurrentPhotoIndex(prevState => prevState - 1)
                }}
              />
            : null
        }
      </div>
      <div>
        <p className='gallery-text'>{photos.length} photos</p>
      </div>
    </div>
  )
}
