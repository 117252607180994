import React from 'react';
import loader from '../assets/images/loader.json';
import { useLottie } from "lottie-react";

interface Props {
  size: 'sm' | 'md' | 'lg' | 'xl'
}

export default function ({ size }: Props) {
  const options = {
    animationData: loader,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <div className={`
      loader
      ${size === 'sm' ? 'loader-sm' : ''} 
      ${size === 'md' ? 'loader-md' : ''}
      ${size === 'lg' ? 'loader-lg' : ''}
    `}>
      {View}
    </div>
  )
}
