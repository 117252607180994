import React, {useEffect} from 'react';
import trashIcon from '../../assets/images/trash-01.svg';
import closeIcon from '../../assets/images/x-close-black.svg';
import { Button } from '../Buttons/Button';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>>,
  name: string, 
  description: string,
  onFirstButton: () => void,
  onSecondButton: () => void,
  firstButtonName: string,
  secondButtonName: string
}

export default function ModalNotification({ setIsOpen, name, description, onFirstButton, onSecondButton, firstButtonName, secondButtonName }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById('modal')?.classList.add('modal-main-container');
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto';
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, [])

  return (
    <div className='modalNotification'>
      <div className='modalNotification-container'>
        <div className='modalNotification-circles-error'>
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modalNotification-header modalNotification-header-error'>
          <div className='modalNotification-header-actions'>
            <div className='modalNotification-header-actions-icon'>
              <img src={trashIcon} alt="trash icon" />
            </div>
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={() => setIsOpen(null)}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
        </div>
        <div className='modalNotification-actions'>
          <Button
            buttonType='regular'
            size='lg'
            hierarchy='secondaryGray'
            fullWidth
            contentCenter
            onClick={onFirstButton}
          >
            {firstButtonName}
          </Button>
          <Button
            buttonType='regular'
            size='lg'
            hierarchy='error'
            fullWidth
            contentCenter
            onClick={onSecondButton}
          >
            {secondButtonName}
          </Button>
        </div>
      </div>
    </div>
  )
}
