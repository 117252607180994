import React, { useEffect, useState } from 'react'
import { Button } from '../Buttons/Button';
import arrowLeft from '../../assets/images/arrow-left.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import { useWindowSize } from '@uidotdev/usehooks';

interface Props {
  numberOfPages: number,
  selected: number,
  setSelected: React.Dispatch<React.SetStateAction<number>>
}

export default function Pagination({ numberOfPages, selected, setSelected }: Props) {
  const [arrayOfPages, setArrayOfPages] = useState<{id: number, value: number}[]>([]);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 

  useEffect(() => {
    for (let i = 1; i <= numberOfPages; i++) {
      setArrayOfPages(prev => [...prev, {id: i - 1, value: i}])
    }

    return () => setArrayOfPages([]);
  }, []);

  return (
    <div className='pagination'>
      <Button
        buttonType='regular'
        hierarchy='secondaryGray'
        size='sm'
        disabled={selected === 0}
        onClick={() => setSelected(prev => prev - 1)}
      >
        <div className='flex gap-8 flex-nowrap'>
          <img src={arrowLeft} alt="" />
          {
            sizeIsDesktop
              ? <p>Previous</p>
              : null
          }
        </div>
      </Button>

      {
        sizeIsDesktop
          ? <div className='pagination-numbers'>
              {
                arrayOfPages.length > 6
                  ? (
                      selected > 1 && selected < 7
                        ? arrayOfPages.slice(0, 1).map((page) => 
                            <div 
                              key={page.id}
                              onClick={() => setSelected(page.id)}
                              className={`pagination-numbers-number ${selected === page.id ? 'pagination-numbers-number-selected' : ''}`}
                            >
                              {page.value}
                            </div>
                          )
                        : arrayOfPages.slice(0, 3).map((page) => 
                            <div 
                              key={page.id}
                              onClick={() => setSelected(page.id)}
                              className={`pagination-numbers-number ${selected === page.id ? 'pagination-numbers-number-selected' : ''}`}
                            >
                              {page.value}
                            </div>
                          ) 
                    )
                  : arrayOfPages.map((page) => 
                      <div 
                        key={page.id}
                        onClick={() => setSelected(page.id)}
                        className={`pagination-numbers-number ${selected === page.id ? 'pagination-numbers-number-selected' : ''}`}
                      >
                        {page.value}
                      </div>
                    )
              }
      
              {
                arrayOfPages.length > 6
                  ? <div className='pagination-numbers-number pagination-numbers-number-notNumber'>...</div>
                  : null
              }
    
              {
                selected > 1 && selected < 7
                  ? arrayOfPages.slice(selected, selected + 2).map((page) => 
                      <div 
                        key={page.id}
                        onClick={() => setSelected(page.id)}
                        className={`pagination-numbers-number ${selected === page.id ? 'pagination-numbers-number-selected' : ''}`}
                      >
                        {page.value}
                      </div>
                    )
                  : null
              }
    
              {
                selected > 1 && selected < 7
                  ? <div className='pagination-numbers-number pagination-numbers-number-notNumber'>...</div>
                  : null
              }
              
              {
                arrayOfPages.length > 6
                  ? (
                      selected > 1 && selected < 7
                        ? arrayOfPages.slice(-1).map((page) => 
                            <div 
                              key={page.id}
                              onClick={() => setSelected(page.id)}
                              className={`pagination-numbers-number ${selected === page.id ? 'pagination-numbers-number-selected' : ''}`}
                            >
                              {page.value}
                            </div>
                          )
                        : arrayOfPages.slice(-3).map((page) => 
                            <div 
                              key={page.id}
                              onClick={() => setSelected(page.id)}
                              className={`pagination-numbers-number ${selected === page.id ? 'pagination-numbers-number-selected' : ''}`}
                            >
                              {page.value}
                            </div>
                          ) 
                    )
                  : null
              }
            </div>
          : <p>Page {selected + 1} of {numberOfPages}</p>
      }

      <Button
        buttonType='regular'
        hierarchy='secondaryGray'
        size='sm'
        disabled={selected === numberOfPages - 1 || numberOfPages <= 0}
        onClick={() => setSelected(prev => prev + 1)}
      >
        <div className='flex gap-8 flex-nowrap'>
          {
            sizeIsDesktop
              ? <p>Next</p>
              : null
          }
          <img src={arrowRight} alt="" />
        </div>
      </Button>
    </div>
  )
}
