import React from 'react';
import checkIcon from '../../../assets/images/check-white.svg';
import restaurantPlaceholder from '../../../assets/images/restaurantPlaceholder.svg';

interface Props {
  id: number,
  name: string,
  location: string,
  distance?: number,
  setSelectedRestaurant: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null>>,
  selectedRestaurantId: string | number | undefined,
}

export default function UserRestaurantItem({ id, name, location, selectedRestaurantId, setSelectedRestaurant, distance }: Props) {
  return (
    <div 
      className={`
        restaurantItem
        ${selectedRestaurantId === id ? 'restaurantItem-selected' : ''}
      `} 
      onClick={() => {
        setSelectedRestaurant({ id, name, status: 'Open', logo: '', address: location });
      }}
    >
      <img className='restaurantItem-logo' src={restaurantPlaceholder} alt="restaurant logo" />
      <div className='restaurantItem-content'>
        <h3 className='restaurantItem-name'>{name}</h3>
        <p className='restaurantItem-info'>{location}</p>
        <p className='restaurantItem-info'>{distance ? `${distance.toFixed(2)}mi` : ''}</p>
      </div>
      {selectedRestaurantId === id ? <img src={checkIcon} alt="" className='fileUploadBase-downloaded restaurantItem-selected-icon' /> : null}
    </div>
  )
}