import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from 'react';
import moment from 'moment';
import { CustomDay } from './CustomDay';

moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

interface Props {
  pickedDate: moment.Moment | null,
  setPickedDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  selectedDayOnlyFromToday: boolean
}

export default function DatePickerSmall({ pickedDate, setPickedDate, selectedDayOnlyFromToday }: Props) {
  const [daysWithEvents, setDaysWithEvents] = useState<string[]>(['2024-04-04']);
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        value={pickedDate}
        onChange={(value: moment.Moment | null) => setPickedDate(value)}
        onMonthChange={(value) => setSelectedMonth(value.month())}
        dayOfWeekFormatter={(day) => day.slice(0, day.length - 1)}
        fixedWeekNumber={6}
        slots={{ day: CustomDay} as any}
        slotProps={{day: {
            day: moment(),
            daysWithEvents,
            pickedDate,
            setPickedDate,
            selectedMonth,
            selectedDayOnlyFromToday
          } as any, 
        }}
        sx={{
          maxHeight: 'max-content',
        
          '& .MuiDayCalendar-weekDayLabel': {
            width: '40px',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20px',
            color: '#344054',
            margin: '0',
          },
          '& .MuiPickersCalendarHeader-root': {
            position: 'relative',
            maxWidth: '280px',
            marginBottom: '60px',
          },

          '& .MuiPickersCalendarHeader-labelContainer': {
            position: 'absolute',
            right: '50%',
            left: '50%',
            width: 'max-content',
            transform: 'translate(-50%)',
            pointerEvents: 'none'
          },

          '& .MuiPickersSlideTransition-root': {
            minHeight: '260px'
          },

          '& .MuiPickersFadeTransitionGroup-root': {
            width: 'max-content',
            margin: '0 auto'
          },

          '& .MuiPickersCalendarHeader-switchViewButton': {
            display: 'none'
          },

          '& .MuiIconButton-root:hover': {
            bgcolor: 'white !important',
          },

          '& .MuiTouchRipple-root': {
            visibility: 'hidden'
          },

          '& .MuiIconButton-edgeEnd': {
            position: 'absolute',
            left: 0,
            top: '50%',
            bottom: '50%',
            transform: 'translate(50%, -50%)'
          },

          '& .MuiIconButton-edgeStart': {
            position: 'absolute',
            right: 0,
            top: '50%',
            bottom: '50%',
            transform: 'translate(-30%, -50%)',
          }
        }}
      />
    </LocalizationProvider>
  )
}