import React, { useEffect, useState } from 'react';
import Navigation from "../../components/Navigation/SideBarNavigation";
import { useWindowSize } from '@uidotdev/usehooks';
import { Button } from '../../components/Buttons/Button';
import HorizontalTabs from '../../components/Tabs/HorizontalTabs';
import ReviewDetailsSection from '../../components/Sections/ReviewDetailsSection';
import ReviewAgendaSection from '../../components/Sections/ReviewAgendaSection';
import dotsIcon from '../../assets/images/dots-horizontal.svg';
import LogoRepeat from '../../components/LogoRepeat/LogoRepeat';
import pathIcon from '../../assets/images/path90.svg';
import arrowDown from '../../assets/images/chevron-down.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import usersPlusIcon from '../../assets/images/users-plus.svg';
import { fetchAttendeesContacts, fetchAttendeesNetwork, fetchEvent, fetchEventAgenda, fetchInvitedAttendees, fetchMeals, fetchRestaurantMenu, fetchRestaurants, fetchSpecificRestaurant, fetchTodoItems, postAttendeeRSVP, postMealsSelections, queryClient } from '../../httpQueries/http';
import moment from 'moment';
import { getTimeFromMinutes } from '../../utilities/getTimeFromMinutes';
import presentationIcon from '../../assets/images/presentation-chart-01.svg';
import clockPlusIcon from '../../assets/images/clock-plus.svg';
import happyFaceIcon from '../../assets/images/face-happy.svg';
import { durations } from '../../utilities/agendaDurationsArray';
import { editTime } from '../../utilities/EditAgendaBlockTime';
import { logoColor } from '../../types/logoColor';
import { getEventLogo } from '../../utilities/getEventLogoImage';
import { logoImage } from '../../types/logoImage';
import { getLogoRepeatImage } from '../../utilities/getLogoRepeatImage';
import { AttendeeRSVP } from '../../types/attendeeRSVP';
import { supabase } from '../../utilities/supabaseClient';
import Loader from '../../components/Loader';
import { AgendaBlockType } from '../../types/agendaBlockType';
import ContactsTable from '../../components/Tables/ContactsTable';
import ReviewEventTableInvitees from '../../components/Tables/ReviewEventTableInvitees';
import { createPortal } from 'react-dom';
import ModalAddNewAttende from '../../components/Modal/ModalAddNewAttende';
import AttachDocumentModal from '../../components/Modal/AttachDocumentModal';
import checkFileIcon from '../../assets/images/file-check-02-black.svg';
import FeaturedIcon from '../../components/FeaturedIcons/FeaturedIcon';
import { DropdownMenu } from '../../components/Dropdown/DropdownMenu';
import ModalAddAttendees from '../../components/Modal/ModalAddAttendees';
import { AddAttendee } from '../../components/AddAttendee/AddAttendee';
import { MealBlockType } from '../../types/meals';
import SelectMealsAttendeesModal from '../../components/Modal/SelectMealsAttendeesModal';
import PreviewPreselectedMealsInviteesMeals from './SelectCustomizationAttendeesView/PreviewPreselectedMealsForAttendees';
import editDotsIcon from '../../assets/images/edit-01.svg';
import fileCheck from '../../assets/images/file-check-02.svg';
import DotsModal from '../../components/Modal/DotsModal';
import { TodoItem, TodoItemType } from '../../types/todoItem';
import Todo from '../../components/Todo/Todo';
import TodosPage from './TodosPage';
import RSVP from '../../components/RSVP/RSVP';
import NotificationsPage from './NotificationsPage';
import { markTodoAsCompleted } from '../../utilities/markTodoAsCompleted';
import PaymentCheckout from '../../components/PaymentModal/PaymentCheckout';

export default function ReviewEventDetailsView() {
  const slug = useParams().slug;
  const [selectedRSVP, setSelectedRSVP] = useState<AttendeeRSVP | undefined>();
  const [isAttachDocumentModalOpen, setIsAttachDocumentModalOpen] = useState(false);
  const [isDocumentsUploaded, setIsDocumentsUploaded] = useState(false);
  const { data: event, isFetching } = useQuery({
    queryKey: ['event', slug, isDocumentsUploaded],
    queryFn: () => fetchEvent({ slug, token }).catch(() => navigate('/*')),
  });
  const { data: todoItems } = useQuery({
    queryKey: ['home', event],
    queryFn: () => fetchTodoItems({ token, take: 10, skip: 0, eventId: event?.id })
  })
  const [preOrder, setPreOrder] = useState<{
    customizations: {
        customizationId: string,
        optionId: string,
        markedPrice: number
      }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  } | undefined>()
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [currentTab, setCurrentTab] = useState<{id: number, name: string} | null>({id: 1, name: 'Overview'});
  const [selectedPaginationPage, setSelectedPaginationPage] = useState<number>(0);
  const [selectedAddAttendees, setSelectedAddAttendees] = useState<{ id: number; name: string; supportText?: string | undefined; value?: number | undefined; } | null>(null);
  const [newUserFullName, setNewUserFullName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState('');
  const [newUserBusinessName, setNewUserBusinessName] = useState('');
  const [newUserTitle, setNewUserTitle] = useState('');
  const [newUserAdded, setNewUserAdded] = useState(false);
  const [userUpdated, setUserUpdated] = useState<number | null>(null);
  const [userDeleted, setUserDeleted] = useState<number | null>(null);
  const [createNewUserIsOpen, setCreateNewUserIsOpen] = useState(false);
  const [attendees, setAttendees] = useState<{
    attendees: {
      id: number,
      networkMemberId: number,
      status: 'added',
      rsvp: AttendeeRSVP | null,
      networkMember: {
        id: number,
        fullName: string,
        email: string,
        phone: string,
        businessName: string,
        address: string,
        title: string,
        userId: null | number,
        accountId: number,
        createdAt: string,
        updatedAt: string,
        user: {
          accountId: number,
          createdAt: string,
          email: string,
          fullName: string,
          id: number,
          phone?: string | null,
          status: string,
          supabaseId: string,
          updatedAt: string,
        } | null,
      },
      mealSelections: any[]
    }[],
    count: number,
  } | 'isFetching'>({ attendees: [], count: 0 });
  const [searchValue, setSearchValue] = useState('');
  const [isOpenAddAttendeesDropdown, setIsOpenAddAttendeesDropdown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [logoRepeaterHeight, setLogoRepeaterHeight] = useState('60%');
  const [attendeesNetwork, setAttendeesNetwork] = useState<{
    accountId: number;
    createdAt: string;
    email: string;
    fullName: string;
    id: number;
    phone?: string;
    updatedAt: string;
    address: string | null,
    businessName: string | null,
    title: string | null,
  }[]>();
  const [selectMealsModalIsOpen, setSelectMealsModalIsOpen] = useState(false);
  const token = localStorage.getItem('token');
  const [isUsersInvitedQueryWorks, setIsUsersInvitedQueryWorks] = useState(true);
  const [eventAgendaMealBlock, setEventAgendaMealBlock] = useState<{
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
          {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                  {
                      name: string;
                      price: number;
                      customizations: any[];
                      min_qty: number;
                      max_qty: number;
                      conditional_price: {};
                      formatted_price: string;
                      default_qty: number;
                      option_id: string;
                  }
              ];
              customization_id: string;
          }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
  }[]>([]);
  const [mealBlockId, setMealBlockId] = useState<number | undefined>();
  const [mealBlockIds, setMealBlockIds] = useState<{restaurantId: string, mealBlockId: number, isPickup: MealBlockType, preSelectedMeals: string[]}[]>([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close" | undefined;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
  } | null>(null)
  const [notifications, setNotifications] = useState<{
    type: AttendeeRSVP,
    name: string
  }[]>([])
  const navigate = useNavigate();
  const [dotsModalIsOpen, setDotsModalIsOpen] = useState(false);
  const [orderMealsModalIsOpen, setOrderMealsModalIsOpen] = useState(false);
  const [mealBlockIdForOrder, setMealBlockIdForOrder] = useState(0);
  const [cartId, setCartId] = useState<null | string>(null);
  const [isEventHaveAlreadyPassed, setIsEventHaveAlreadyPassed] = useState(false);

  useEffect(() => {
    if (isOpenAddAttendeesDropdown) {
      fetchAttendeesNetwork({ skip: 0, take: 10, fetchEvents: false, token }).then(response => setAttendeesNetwork(response));
    }
  }, [isOpenAddAttendeesDropdown]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const data = await fetchAttendeesNetwork({ skip: 0, take: 10, fetchEvents: false, token, search: searchValue.length ? searchValue : undefined });
      setAttendeesNetwork(data);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue])

  useEffect(() => {
    if (userDeleted) {
      setAttendees(prevState => {
        if (prevState !== 'isFetching') {
          return {
            attendees: prevState!.attendees.filter(contact => contact.id !== userDeleted),
            count: prevState!.attendees.filter(contact => contact.id !== userDeleted).length,
          }
        }

        return { attendees: [], count: 0 };
      })

      setUserDeleted(null);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (event && !event.isOrganizer) {
      fetchEventAgenda({ dayId: event.id, token }).then(response => {
        if (response) {
          const mealBlocks = response.filter(item => item.type === AgendaBlockType.Meal).filter(block => !!block.mealBlock);
          setMealBlockIds(mealBlocks.map(agendaBlock => ({restaurantId: agendaBlock.mealBlock.restaurantId, mealBlockId: agendaBlock.mealBlockId, isPickup: agendaBlock.mealBlock.type, preSelectedMeals: agendaBlock.mealBlock.preSelectedMealsIds})));
        }
      });
    }
  }, [event]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (currentTab?.id === 2 || currentTab?.id === 3) {
      setAttendees('isFetching');
      fetchInvitedAttendees({ token, eventId: event!.id, take: 10, skip: selectedPaginationPage * 10 }).then(response => {
        if (response) {
          setAttendees(response);
          setNotifications(response.attendees.map(attendee => ({
            type: attendee.rsvp!,
            name: attendee.networkMember.user ? attendee.networkMember.user.fullName : attendee.networkMember.fullName,
          })))
        }
      })
    }
  }, [currentTab, selectedPaginationPage]);

  useEffect(() => {
    if (!isUsersInvitedQueryWorks) {
      setAttendees('isFetching');
      fetchInvitedAttendees({ token, eventId: event!.id, take: 20, skip: 0 }).then(response => {
        if (response) {
          setAttendees(response);
        }
      })
    }
  }, [isUsersInvitedQueryWorks]);

  useEffect(() => {
    if (isDocumentsUploaded) {
      fetchEvent({ slug, token }).then(response => {
        queryClient.setQueryData(['event', slug], {
          data: {
            ...response
          },
        })
      })

      setIsDocumentsUploaded(false);
    }
  }, [isDocumentsUploaded])

  useEffect(() => {
    if (selectedRSVP !== event?.rsvp) {
      setSelectedRSVP(event?.rsvp)
    }

    if (event) {
      setIsEventHaveAlreadyPassed(+new Date(event.startTime).valueOf() < +new Date().valueOf());
    } else {
      setIsEventHaveAlreadyPassed(false);
    }
  }, [event]);

  function scrollFunction() {
    if (Math.ceil(document.getElementById('scrolledInfo')?.scrollTop || 0) >= 1) {
      setIsScrolled(true);
      setLogoRepeaterHeight('47%');
    } 
  }

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear()
    localStorage.clear();

    navigate('/login');
    
    return null
  }
  
  async function onRSVPSelect(item: AttendeeRSVP) {
    if (!event) return;

    await postAttendeeRSVP({ body: { rsvp: item, eventId: event.id }, token });

    queryClient.setQueryData(['event' ,event.slug], {
      ...event,
      rsvp: item
    });
  }

  return (
    <div className={`eventDetails_container ${!sizeIsDesktop ? 'flex-column' : 'flex-row'}`} style={{background: sizeIsDesktop ? undefined : 'none'}}>
      {
        createNewUserIsOpen
          ? createPortal(
              <ModalAddNewAttende
                setShow={() => setCreateNewUserIsOpen(false)}
                setUserFullName={setNewUserFullName}
                userFullName={newUserFullName}
                setUserEmail={setNewUserEmail}
                userEmail={newUserEmail}
                setUserPhoneNumber={setNewUserPhoneNumber}
                userPhoneNumber={newUserPhoneNumber}
                setUserBusinessName={setNewUserBusinessName}
                userBusinessName={newUserBusinessName}
                setUserTitle={setNewUserTitle}
                userTitle={newUserTitle}
                eventId={event!.id}
                setNewUserAdded={setNewUserAdded}
                isEdit={userUpdated}
                setUserUpdated={setUserUpdated}
                isDesktopSize={sizeIsDesktop}
              />, 
              document.getElementById('modal') as HTMLElement
            )
          : null
      }
      {
        orderMealsModalIsOpen
          ? <PaymentCheckout mealBlockId={mealBlockIdForOrder} alreadySettedCartId={cartId} setIsOpen={setOrderMealsModalIsOpen} />
          : null
      }
      {
        createPortal(<PreviewPreselectedMealsInviteesMeals 
          selectedRestaurant={selectedRestaurant} 
          showSelectMealModal={selectMealsModalIsOpen} 
          menu={eventAgendaMealBlock} 
          preOrder={preOrder} 
          setPreOrder={setPreOrder} 
          mealBlockId={mealBlockId} 
          setSelectMealsModalIsOpen={setSelectMealsModalIsOpen} 
          asyncOnAddMeal={async () => {
            if (mealBlockId && preOrder) {
              await postMealsSelections({
                id: mealBlockId,
                body: {
                  customizations: preOrder.customizations,
                  productId: preOrder.productId,
                  markedPrice: preOrder.markedPrice,
                  notes: preOrder.notes,
                },
                token
              })

              setSelectMealsModalIsOpen(false)
            }
          }} 
          isLoading={false}          
        />, document.getElementById('modal') as HTMLElement)
      }
      {
        selectedAddAttendees?.name === 'Add existing attendees' && attendees !== 'isFetching'
          ? sizeIsDesktop
              ? createPortal(
                  <ModalAddAttendees
                    attendees={
                      attendees 
                        ? attendeesNetwork!.filter(attendee => {
                            const invitedAttendeesEmails = attendees.attendees.map(item => item.networkMember.user ? item.networkMember.user.email : item.networkMember.email);
  
                            return !invitedAttendeesEmails.includes(attendee.email);
                          })
                        : attendeesNetwork!
                    }
                    setShow={setSelectedAddAttendees}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    eventId={event!.id}
                    setIsUsersInvitedQueryWorks={setIsUsersInvitedQueryWorks}
                  />, 
                  document.getElementById('modal') as HTMLElement
                )
              : null
          : selectedAddAttendees?.name === 'Add new'
              ? createPortal(
                <ModalAddNewAttende
                  setShow={() => setSelectedAddAttendees(null)}
                  setUserFullName={setNewUserFullName}
                  userFullName={newUserFullName}
                  setUserEmail={setNewUserEmail}
                  userEmail={newUserEmail}
                  setUserPhoneNumber={setNewUserPhoneNumber}
                  userPhoneNumber={newUserPhoneNumber}
                  setUserBusinessName={setNewUserBusinessName}
                  userBusinessName={newUserBusinessName}
                  setUserTitle={setNewUserTitle}
                  userTitle={newUserTitle}
                  eventId={event!.id}
                  setNewUserAdded={setNewUserAdded}
                  isEdit={userUpdated}
                  setUserUpdated={setUserUpdated}
                  isDesktopSize={sizeIsDesktop}
                />, 
                document.getElementById('modal') as HTMLElement
              )
              : null
        }
      {isAttachDocumentModalOpen
        ? createPortal(<AttachDocumentModal widthIsMobile={!sizeIsDesktop} setIsFileAdded={setIsDocumentsUploaded} setShow={setIsAttachDocumentModalOpen} eventId={event!.id} />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      <Navigation
        hierarchy='primary'
        currentItem='Events'
      />
      <div className='eventDetails_main-container eventDetails_main-container-reviewEventDetails' style={{marginTop: sizeIsDesktop ? undefined : '0'}}>
        <div className="eventDetails_main reviewEventDetailsPage">
          <header className='reviewEventDetailsPage-header'>
            <div className='reviewEventDetailsPage-header-path'>
              <p className='reviewEventDetailsPage-header-path-step' onClick={() => navigate(`/events?filter=organizer`)}>Events</p>
              <p className='reviewEventDetailsPage-header-path-arrow'>&gt;</p>
              <p className='reviewEventDetailsPage-header-path-step reviewEventDetailsPage-header-path-step-current'>Event details</p>
            </div>
            {
              isFetching
                ? null
                : <>
                  <LogoRepeat 
                    color={isEventHaveAlreadyPassed ? 'pastEvent' : event!.color ? event!.color : undefined} 
                    image={(!event!.pattern 
                      ? pathIcon 
                      : getLogoRepeatImage(
                          { 
                            selectedColor: isEventHaveAlreadyPassed 
                              ? 'pastEvent' 
                              : event!.color 
                                ? event!.color 
                                : logoColor.BLUE, 
                            selectedPattern: event!.pattern 
                              ? event!.pattern 
                              : logoImage.PATH 
                          }
                        ))} 
                    size={isScrolled ? 'sm' : 'lg'}
                    height={sizeIsDesktop ? logoRepeaterHeight : undefined} 
                    position='absolute' 
                    positionTop={isScrolled ? sizeIsDesktop ? '25%' : '15%' : '13%'} 
                    opacity={isScrolled ? 0.4 : undefined}
                  />
                  <div className={`reviewEventDetailsPage-header-content`} style={{ marginTop: isScrolled ? '3%' : '20%' }}>
                    <div className={`flex ${isScrolled ? 'flex-row items-center' : sizeIsDesktop ? 'flex-row' : 'flex-column'} gap-24`}>
                      <div className={`
                        ${isEventHaveAlreadyPassed ? 'reviewEventDetailsPage-header-mainImage-pastEvent' : ''}
                        reviewEventDetailsPage-header-mainImage
                        ${isScrolled ? 'reviewEventDetailsPage-header-mainImage-lg' : ''}
                        ${event!.color === logoColor.YELLOW ? 'reviewEventDetailsPage-header-mainImage-yellow' : ''}
                        ${event!.color === logoColor.BLUELIGHT ? 'reviewEventDetailsPage-header-mainImage-blueLight' : ''}
                        ${event!.color === logoColor.RED ? 'reviewEventDetailsPage-header-mainImage-error' : ''}
                        ${event!.color === logoColor.PURPLE ? 'reviewEventDetailsPage-header-mainImage-purple' : ''}
                        ${event!.color === logoColor.GREEN ? 'reviewEventDetailsPage-header-mainImage-success' : ''}
                        ${event!.color === logoColor.ORANGE ? 'reviewEventDetailsPage-header-mainImage-warning' : ''}
                      `}>
                        <img src={getEventLogo(event!.pattern)} alt=""/>
                      </div>
                      <div className='reviewEventDetailsPage-header-info'>
                        <div className='reviewEventDetailsPage-header-info-text'>
                          <h3 className='reviewEventDetailsPage-header-info-text-title'>{event!.name}</h3>
                          <p className='reviewEventDetailsPage-header-info-text-decription'>{event!.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className='reviewEventDetailsPage-header-info-actions'>
                        {
                          event!.isOrganizer
                            ? <>
                                <div className='positionRelative'>
                                  <Button 
                                    size='md'
                                    hierarchy='secondaryGray'
                                    buttonType='small'
                                    buttonImage={dotsIcon}    
                                    onClick={() => setDotsModalIsOpen(prevState => !prevState)}         
                                  />
                                  {
                                    dotsModalIsOpen
                                      ? <DotsModal 
                                          items={[
                                            {id: 1, img: editDotsIcon, text: 'Edit event details', onClick: () => {
                                              localStorage.setItem('currentEditEvent', slug!);
                                              navigate(`/edit/${slug}/details`);
                                            }}, 
                                            {id: 2, img: usersPlusIcon, text: 'Add more invitees', onClick: () => setSelectedAddAttendees({id: 1, name: 'Add existing attendees'})},
                                            {id: 3, img: fileCheck, text: 'Manage files', onClick: () => setIsAttachDocumentModalOpen(true)}
                                          ]}
                                          setIsOpen={setDotsModalIsOpen}
                                          top='46px'
                                          right='-241px'
                                        />
                                      : null
                                  }
                                </div>
                                {
                                  sizeIsDesktop
                                    ?  <Button
                                        buttonType='regular'
                                        hierarchy='secondaryColor'
                                        size='lg'
                                        onClick={() => {
                                          setIsAttachDocumentModalOpen(true);
                                        }}
                                      >
                                        <div className='eventDetails_main-footer-buttonSaveAsDraft'>
                                          <img src={checkFileIcon} alt="save Icon" />
                                          <p>Attach documents</p>
                                        </div>
                                      </Button>
                                  : null
                                }
                                <Button
                                  size='md'
                                  hierarchy='primary'
                                  buttonType='regular'
                                  onClick={() => {
                                    localStorage.setItem('currentEditEvent', slug!);
                                    navigate(`/edit/${slug}/details`);
                                  }}
                                >
                                  Manage event
                                </Button>
                              </>
                            : <RSVP 
                                selectedRSVP={selectedRSVP} 
                                setSelectedRSVP={setSelectedRSVP} 
                                onRSVPSelect={onRSVPSelect}                                          
                              />
                        }
                    </div>
                  </div>
                  </>
            }
          </header>
          {
            isFetching
              ? <div className='flex fullHeight fullWidth items-center justifyCenter'>
                <Loader size='xl' />
              </div>
              : <main 
                  className='reviewEventDetailsPage-main overflowYScroll' 
                  onScroll={scrollFunction}
                  id={currentTab?.id !== 1 ? 'scrolledInfo' : ''}
                >
                  <div className={`fullWidth ${isScrolled ? 'reviewEventDetailsPage-main-scrolled' : ''}`}>
                    {
                      event?.isOrganizer
                        ? <HorizontalTabs 
                            tabs={[{id: 1, name: 'Overview'}, {id: 2, name: 'Invitees'}, {id: 4, name: 'To do\'s', withNotification: todoItems?.total}, {id: 3, name: 'Notifications', withNotification: notifications?.length}]} 
                            current={currentTab} 
                            setCurrent={setCurrentTab}
                            type='Underline'
                          />
                        : <HorizontalTabs 
                            tabs={[{id: 1, name: 'Overview'}, {id: 4, name: 'To do\'s', withNotification: todoItems?.total}]} 
                            current={currentTab} 
                            setCurrent={setCurrentTab}
                            type='Underline'
                          />
                    }
                  </div>
                  {
                    currentTab && currentTab.id === 1 && currentTab.name === 'Overview'
                      ? <div className={`eventDetails_main-content gap-32 overflowYScroll ${sizeIsDesktop ? 'flex-row' : ''}`} id='scrolledInfo' onScroll={scrollFunction}>
                          <ReviewDetailsSection
                            eventDate={moment(event!.startDate)}
                            eventDescription={event!.description}
                            eventLocation={event!.location}
                            eventName={event!.name}
                            eventStartTime={getTimeFromMinutes(event!.startTime)}
                            eventLocationNotes={event?.locationNote}
                            lat={event ? event.locationLatitude : null}
                            lng={event ? event.locationLongitude : null}
                            documents={event?.documents}
                            eventId={event!.id}
                          />
                          {
                            event?.agenda
                              ? <ReviewAgendaSection 
                                  agenda={editTime(event.agenda.map((item, index) => ({
                                    id: `${item.id}`,
                                    icon: item.type === AgendaBlockType.Break ? clockPlusIcon : item.type === AgendaBlockType.Meal ? happyFaceIcon : presentationIcon,
                                    name: item.name,
                                    description: item.description,
                                    duration: durations.find(duration => duration.value === item.duration) || {id: 1, name: `${item.duration} min`, value: item.duration},
                                    startTime: getTimeFromMinutes(event!.startTime),
                                    endTime: event!.endTime ? getTimeFromMinutes(event!.endTime) : '',
                                    type: item.type,
                                    index
                                  })), getTimeFromMinutes(event!.startTime))!}
                                />
                              : null
                          }
                        </div>
                      : currentTab && currentTab.id === 2 && currentTab.name === 'Invitees'
                          ? attendees === 'isFetching'
                              ? <div className='flex fullWidth items-center justifyCenter fullHeight' onScroll={scrollFunction}>
                                  <Loader size='xl' />
                                </div>
                              : attendees.attendees.length
                                  ? <ReviewEventTableInvitees 
                                      onScroll={scrollFunction}
                                      content={attendees.attendees} 
                                      setUserUpdated={setUserUpdated} 
                                      setUserDeleted={setUserDeleted} 
                                      setUserFullName={setNewUserFullName} 
                                      setUserEmail={setNewUserEmail} 
                                      setUserPhoneNumber={setNewUserPhoneNumber} 
                                      setUserBusinessName={setNewUserBusinessName} 
                                      setUserTitle={setNewUserTitle} 
                                      setIsNewUserModalOpen={setCreateNewUserIsOpen} 
                                      selectedPaginationPage={selectedPaginationPage} 
                                      setSelectedPaginationPage={setSelectedPaginationPage}  
                                      pages={Math.ceil(attendees.count / 10)} 
                                      eventId={event!.id}                       
                                    />
                                  : <>
                                      <div className='eventAgenda-createNotification'>
                                        <FeaturedIcon icon={usersPlusIcon} size='md' type='gray' />
                                        <Button
                                          buttonType='regular'
                                          size='md'
                                          hierarchy='linkColor'
                                          paddingNone
                                          onClick={() => setIsOpenAddAttendeesDropdown(prevState => !prevState)}
                                        >
                                          <div className='eventAddAttendees-addFirstAttend'>
                                            <p>Add attendees</p>
                                            <img src={arrowDown} alt="" />
                                          </div>
                                        </Button>
                                      </div>
                                      {
                                        isOpenAddAttendeesDropdown
                                          ? <DropdownMenu 
                                              content={[{id: 1, name: 'Add existing attendees'}, {id: 2, name: 'Add new'}]}
                                              selectedItem={selectedAddAttendees}
                                              setSelectedItem={setSelectedAddAttendees}
                                              setShow={() => setIsOpenAddAttendeesDropdown(prevState => !prevState)}
                                              size='max-content'
                                              positionLeft={!sizeIsDesktop ? '10%' : '45%'}
                                              positionTop={'72%'}
                                            />
                                          : null
                                      }
                                    </>
                          : null
                  }
                  {
                    currentTab && (currentTab.id === 4 && currentTab.name === 'To do\'s') && event
                      ? <TodosPage
                          eventName={event.name} 
                          needTodo={
                            todoItems?.todoItems.map(todo => <Todo
                              toDo={todo}
                              todoAction={
                                todo.type === TodoItemType.MealSelection
                                  ? <Button
                                      buttonType='regular'
                                      hierarchy='linkColor'
                                      size='md'
                                      asyncOnClick={async () => {
                                        const mealBlock = await fetchMeals({ id: todo.mealBlockId!, token });
 
                                        if (mealBlock?.restaurantId) {
                                          fetchRestaurantMenu({
                                            id: mealBlock.restaurantId,
                                            token,
                                            isPickup: mealBlock.type === MealBlockType.Pickup
                                          }).then(menu => {
                                            if (menu) {
                                              const preSelectedMeals: {
                                                count: number;
                                                name: string;
                                                price: number;
                                                qty_available: null;
                                                unit_size: null;
                                                unit_of_measurement: string;
                                                description: string;
                                                is_available: boolean;
                                                image: string;
                                                customizations: [
                                                    {
                                                        name: string;
                                                        min_choice_options: number;
                                                        max_choice_options: number;
                                                        options: [
                                                            {
                                                                name: string;
                                                                price: number;
                                                                customizations: any[];
                                                                min_qty: number;
                                                                max_qty: number;
                                                                conditional_price: {};
                                                                formatted_price: string;
                                                                default_qty: number;
                                                                option_id: string;
                                                            }
                                                        ];
                                                        customization_id: string;
                                                    }
                                                ];
                                                min_price: number;
                                                original_price: number;
                                                formatted_price: string;
                                                attributes: [];
                                                product_id: string;
                                                thumbnail_image: string;
                                                should_fetch_customizations: boolean;
                                                supports_image_scaling: boolean;
                                              }[] = [];
                                
                                              const mealsIds = mealBlock.preSelectedMealsIds;

                                              if (mealsIds) {
                                                menu.map(category => category.menu_item_list.map(product => {
                                                  if (mealsIds.includes(product.product_id)) {
                                                    preSelectedMeals.push({
                                                      ...product,
                                                      count: 0,
                                                    })
                                                  }
                                                }));
                                                
                                                setEventAgendaMealBlock(preSelectedMeals);
                                              }
                                            }
                                          })
            
                                          const restaurant = await fetchSpecificRestaurant({
                                            id: mealBlock.restaurantId,
                                            token
                                          });
                              
                                          setSelectedRestaurant(restaurant ? {
                                            id: restaurant._id,
                                            name: restaurant.name,
                                            logo: restaurant.logo_photos[0],
                                            status: restaurant.is_open ? 'Open' : 'Close',
                                            address: restaurant.address.street_addr,
                                            distance: restaurant.miles,
                                            cuisines: restaurant.cuisines
                                          } : null);

                                          setMealBlockId(todo.mealBlockId);
                                          setSelectMealsModalIsOpen(true);
                                        }

                                        await markTodoAsCompleted(todo, token)
                                      }}
                                    >
                                      Select meals
                                    </Button>
                                : todo.type === TodoItemType.MealOrder
                                    ? <Button
                                        buttonType='regular'
                                        hierarchy='linkColor'
                                        size='md'
                                        asyncOnClick={async () => {
                                          setMealBlockIdForOrder(todo.mealBlockId!);
                                          setCartId(todo.mealBlock?.cartId ? todo.mealBlock?.cartId : null)
                                          setOrderMealsModalIsOpen(true);
                                          await markTodoAsCompleted(todo, token)
                                        }}
                                      >
                                        Order meals
                                      </Button>
                                    : todo.type === TodoItemType.RespondToInvite
                                        ? <RSVP 
                                            selectedRSVP={selectedRSVP} 
                                            setSelectedRSVP={setSelectedRSVP} 
                                            onRSVPSelect={async (item) => {
                                              await onRSVPSelect(item);
                                              await markTodoAsCompleted(todo, token)
                                            }}                                          
                                          />
                                        : null
                              }
                              name={
                                todo.type === TodoItemType.RespondToInvite 
                                  ? 'Event invite' 
                                  : todo.type === TodoItemType.MealOrder 
                                    ? `${todo.mealBlock?.agendaItem.name} needs order meal`
                                    : todo.type === TodoItemType.MealSelection 
                                      ? 'Meal selection' 
                                      : todo.type === TodoItemType.SubmitEvent 
                                        ? 'Submit event' 
                                        : todo.type === TodoItemType.ReviewDocument
                                            ? 'Review document'
                                            : ''
                              }
                              description={
                                todo.type === TodoItemType.RespondToInvite 
                                  ? 'Respond to the event invite' 
                                  : todo.type === TodoItemType.MealOrder 
                                    ? `You need to select and order your meals for ${todo.mealBlock?.restaurantName} by ${new Date(todo.dueDate).toLocaleDateString('en-GB', {
                                      day : 'numeric',
                                      month : 'long',
                                      year : 'numeric'
                                    })}`
                                    : todo.type === TodoItemType.MealSelection 
                                      ? `You need to select your meals for ${todo.mealBlockId}`
                                      : todo.type === TodoItemType.SubmitEvent 
                                        ? 'Your event hasn’t been submitted.' 
                                        : todo.type === TodoItemType.ReviewDocument
                                            ? 'Please, review the document'
                                            : ''
                              }
                            />)
                          } 
                          onScroll={scrollFunction}                       
                        />
                      : null
                  }
                  {
                    currentTab && (currentTab.id === 3 && currentTab.name === 'Notifications') && event
                      ? <NotificationsPage
                          eventName={event.name} 
                          notifications={notifications} 
                          onScroll={scrollFunction}                       
                        />
                      : null
                  }
                </main>
          }
        </div>
      </div>
    </div>
  )
}
