import React, { useState } from 'react';
import Alert from '../Alert/Alert';
import Loader from '../Loader';
import SimpleAddMeal from './MealSelect/SimpleAddMeal';
import dummyImg from '../../assets/images/AUFlag.svg'

interface Props {
  name: string,
  status?: 'Open' | 'Close',
  address?: string,
  distance?: number,
  cuisines: string[],
  fee: number,
  total: number,
  creatorIsOrder: boolean,
  logo: string,
  mealsForAttendeesChoose: { name: string, count: number }[][],
  isLoading: boolean,
  withProductCards?: boolean,
  orderedMeals: {img: string, title: string, description: string, price: string, count: number, onIncrease: () => void, onDecrease: () => void}[]
} 

export default function RestaurantDetails({ name, status, address, distance, cuisines, fee, total, logo, creatorIsOrder, mealsForAttendeesChoose, isLoading, withProductCards, orderedMeals }: Props) {
  let totalOrderedCountOfMeals = 0;

  mealsForAttendeesChoose.map(category => category.map(item => {
    totalOrderedCountOfMeals += item.count;
  }))

  return (
    <div className='restaurantDetails'>
      {
        isLoading
          ? <div className='flex items-center justifyCenter fullWidth fullHeight'>
            <Loader size='xl' />
          </div>
          : <div className='restaurantDetails-info'>
              <h3 className='restaurantDetails-label'>Summary</h3>
              <div className={`restaurantDetails-info-restaurant ${status && address && distance && cuisines.length ? 'restaurantDetails-info-restaurant-selected' : ''}`}>
                  <img src={logo} alt="restaurant logo" className='restaurantDetails-image' />
                  <div className='restaurantDetails-info-restaurant-info'>
                  <h3 className='restaurantDetails-info-restaurant-info-name'>
                      {name}
                  </h3>
                  {
                      status && address && distance && cuisines.length
                      ? <>
                          <p className='restaurantDetails-info-restaurant-info-text'>{status} • {address}</p>
                          <p className='restaurantDetails-info-restaurant-info-text'>{distance.toFixed(2)}mi • {cuisines.join(', ')}</p>
                          </>
                      : <p className='restaurantDetails-info-restaurant-info-text'>Description example</p>
                  }
                  </div>
              </div>
              {
                  totalOrderedCountOfMeals >= 10
                  ? <Alert title={'Orders over 10 items'} description={'For orders with over 10 items, we recommend to place the order 72 hours in advance to make sure the restaurant can accommodate. Don\'t worry - we\'ll remind you!'} />
                  : null
              }
              <h4 className='restaurantDetails-label'>Meals</h4>
              <div className='restaurantDetails-paymentInfo'>
                  {
                    creatorIsOrder
                      ? <>
                          {
                            mealsForAttendeesChoose.map(category => category.map(meal => <p className='restaurantDetails-paymentInfo-content-label' key={meal.name}>{meal.name}</p>))
                          }
                        </>
                      : <>
                          {
                            orderedMeals.map(item => <SimpleAddMeal 
                              image={item.img} 
                              title={item.title} 
                              description={item.description} 
                              price={item.price} 
                              onIcnrease={item.onIncrease} 
                              onDescrease={item.onDecrease} 
                              count={item.count}

                            />)
                          }
                        </>
                  }
                  <div className='restaurantDetails-paymentInfo-content'>
                  <p className='restaurantDetails-paymentInfo-content-label'>Fees</p>
                  <p className='restaurantDetails-paymentInfo-content-sum'>${fee.toFixed(2)}</p>
                  </div>
                  <div className='restaurantDetails-paymentInfo-content'>
                  <p className='restaurantDetails-paymentInfo-content-label'>Estimated total</p>
                  <p className='restaurantDetails-paymentInfo-content-sum'>${total.toFixed(2)}</p>
                  </div>
              </div>
            </div>
      }
    </div>
  )
}
