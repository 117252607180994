import { logoColor } from "../types/logoColor";
import { logoImage } from "../types/logoImage";

import computerIconPrimary from '../assets/images/monitor-04-primary.svg';
import lifebuoyPrimary from '../assets/images/life-buoy-01-primary.svg';
import briefCasePrimary from '../assets/images/briefcase-01-primary.svg';
import currencyDollarPrimary from '../assets/images/currency-dollar-circle-primary.svg';
import lightBulbPrimary from '../assets/images/lightbulb-02-primary.svg';
import rocketPrimary from '../assets/images/rocket-02-primary.svg';
import shieldTickPrimary from '../assets/images/shield-tick-primary.svg';
import starIconPrimary from '../assets/images/star-01-primary.svg';
import targetIconPrimary from '../assets/images/target-04-primary.svg';
import zapIconPrimary from '../assets/images/zap-primary.svg';

import computerIconGREEN from '../assets/images/monitor-04-success.svg';
import lifebuoyGREEN from '../assets/images/life-buoy-01-success.svg';
import briefCaseGREEN from '../assets/images/briefcase-01-success.svg';
import currencyDollarGREEN from '../assets/images/currency-dollar-circle-success.svg';
import lightBulbGREEN from '../assets/images/lightbulb-02-success.svg';
import rocketGREEN from '../assets/images/rocket-02-success.svg';
import shieldTickGREEN from '../assets/images/shield-tick-success.svg';
import starIconGREEN from '../assets/images/star-01-success.svg';
import targetIconGREEN from '../assets/images/target-04-success.svg';
import zapIconGREEN from '../assets/images/zap-success.svg';

import computerIconYellow from '../assets/images/monitor-04-yellow.svg';
import lifebuoyYellow from '../assets/images/life-buoy-01-yellow.svg';
import briefCaseYellow from '../assets/images/briefcase-01-yellow.svg';
import currencyDollarYellow from '../assets/images/currency-dollar-circle-yellow.svg';
import lightBulbYellow from '../assets/images/lightbulb-02-yellow.svg';
import rocketYellow from '../assets/images/rocket-02-yellow.svg';
import shieldTickYellow from '../assets/images/shield-tick-yellow.svg';
import starIconYellow from '../assets/images/star-01-yellow.svg';
import targetIconYellow from '../assets/images/target-04-yellow.svg';
import zapIconYellow from '../assets/images/zap-yellow.svg';

import computerIconORANGE from '../assets/images/monitor-04-warning.svg';
import lifebuoyORANGE from '../assets/images/life-buoy-01-warning.svg';
import briefCaseORANGE from '../assets/images/briefcase-01-warning.svg';
import currencyDollarORANGE from '../assets/images/currency-dollar-circle-warning.svg';
import lightBulbORANGE from '../assets/images/lightbulb-02-warning.svg';
import rocketORANGE from '../assets/images/rocket-02-warning.svg';
import shieldTickORANGE from '../assets/images/shield-tick-warning.svg';
import starIconORANGE from '../assets/images/star-01-warning.svg';
import targetIconORANGE from '../assets/images/target-04-warning.svg';
import zapIconORANGE from '../assets/images/zap-warning.svg';

import computerIconRED from '../assets/images/monitor-04-error.svg';
import lifebuoyRED from '../assets/images/life-buoy-01-error.svg';
import briefCaseRED from '../assets/images/briefcase-01-error.svg';
import currencyDollarRED from '../assets/images/currency-dollar-circle-error.svg';
import lightBulbRED from '../assets/images/lightbulb-02-error.svg';
import rocketRED from '../assets/images/rocket-02-error.svg';
import shieldTickRED from '../assets/images/shield-tick-error.svg';
import starIconRED from '../assets/images/star-01-error.svg';
import targetIconRED from '../assets/images/target-04-error.svg';
import zapIconRED from '../assets/images/zap-error.svg';

import computerIconPurple from '../assets/images/monitor-04-purple.svg';
import lifebuoyPurple from '../assets/images/life-buoy-01-purple.svg';
import briefCasePurple from '../assets/images/briefcase-01-purple.svg';
import currencyDollarPurple from '../assets/images/currency-dollar-circle-purple.svg';
import lightBulbPurple from '../assets/images/lightbulb-02-purple.svg';
import rocketPurple from '../assets/images/rocket-02-purple.svg';
import shieldTickPurple from '../assets/images/shield-tick-purple.svg';
import starIconPurple from '../assets/images/star-01-purple.svg';
import targetIconPurple from '../assets/images/target-04-purple.svg';
import zapIconPurple from '../assets/images/zap-purple.svg';

import computerIconLightBlue from '../assets/images/monitor-04-lightBlue.svg';
import lifebuoyLightBlue from '../assets/images/life-buoy-01-lightBlue.svg';
import briefCaseLightBlue from '../assets/images/briefcase-01-lightBlue.svg';
import currencyDollarLightBlue from '../assets/images/currency-dollar-circle-lightBlue.svg';
import lightBulbLightBlue from '../assets/images/lightbulb-02-lightBlue.svg';
import rocketLightBlue from '../assets/images/rocket-02-lightBlue.svg';
import shieldTickLightBlue from '../assets/images/shield-tick-lightBlue.svg';
import starIconLightBlue from '../assets/images/star-01-lightBlue.svg';
import targetIconLightBlue from '../assets/images/target-04-lightBlue.svg';
import zapIconLightBlue from '../assets/images/zap-lightBlue.svg';

import computerDisabledIcon from '../assets/images/monitor-04-disabled.svg';
import lifebuoyDisabled from '../assets/images/life-buoy-01-disabled.svg';
import briefCaseDisabled from '../assets/images/briefcase-01-disabled.svg';
import currencyDollarDisabled from '../assets/images/currency-dollar-circle-disabled.svg';
import lightBulbDisabled from '../assets/images/lightbulb-02-disabled.svg';
import rocketDisabled from '../assets/images/rocket-02-disabled.svg';
import shieldTickDisabled from '../assets/images/shield-tick-disabled.svg';
import starIconDisabled from '../assets/images/star-01-disabled.svg';
import targetIconDisabled from '../assets/images/target-04-disabled.svg';
import zapIconDisabled from '../assets/images/zap-disabled.svg';


export function getLogoRepeatImage({ selectedPattern, selectedColor }: { selectedPattern: logoImage, selectedColor: logoColor | 'pastEvent' }) {
  switch (selectedPattern) {
      case logoImage.LIFEBUOY:
        if (selectedColor === 'pastEvent') {
          return lifebuoyDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return lifebuoyGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return lifebuoyLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return lifebuoyRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return lifebuoyPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return lifebuoyORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return lifebuoyYellow;
        }

        return lifebuoyPrimary;
      case logoImage.BRIEFCASE:
        if (selectedColor === 'pastEvent') {
          return briefCaseDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return briefCaseGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return briefCaseLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return briefCaseRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return briefCasePurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return briefCaseORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return briefCaseYellow;
        }
      
        return briefCasePrimary;
      case logoImage.CURRENCYDOLLAR:
        if (selectedColor === 'pastEvent') {
          return currencyDollarDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return currencyDollarGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return currencyDollarLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return currencyDollarRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return currencyDollarPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return currencyDollarORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return currencyDollarYellow;
        }

        return currencyDollarPrimary;
      case logoImage.LIGHTBULB:
        if (selectedColor === 'pastEvent') {
          return lightBulbDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return lightBulbGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return lightBulbLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return lightBulbRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return lightBulbPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return lightBulbORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return lightBulbYellow;
        }

        return lightBulbPrimary;
      case logoImage.ROCKET:
        if (selectedColor === 'pastEvent') {
          return rocketDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return rocketGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return rocketLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return rocketRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return rocketPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return rocketORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return rocketYellow;
        }

        return rocketPrimary;
      case logoImage.SHIELDTICK:
        if (selectedColor === 'pastEvent') {
          return shieldTickDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return shieldTickGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return shieldTickLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return shieldTickRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return shieldTickPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return shieldTickORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return shieldTickYellow;
        }

        return shieldTickPrimary;
      case logoImage.STARICON:
        if (selectedColor === 'pastEvent') {
          return starIconDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return starIconGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return starIconLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return starIconRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return starIconPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return starIconORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return starIconYellow;
        }

        return starIconPrimary;
      case logoImage.TARGET:
        if (selectedColor === 'pastEvent') {
          return targetIconDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return targetIconGREEN;
        }

        if (selectedColor === logoColor.BLUELIGHT) {
          return targetIconLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return targetIconRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return targetIconPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return targetIconORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return targetIconYellow;
        }

        return targetIconPrimary;
      case logoImage.ZAP:
        if (selectedColor === 'pastEvent') {
          return zapIconDisabled
        }  

        if (selectedColor === logoColor.GREEN) {
          return zapIconGREEN;
        }  

        if (selectedColor === logoColor.BLUELIGHT) {
          return zapIconLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return zapIconRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return zapIconPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return zapIconORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return zapIconYellow;
        }

        return zapIconPrimary;
      case logoImage.COMPUTER:
        if (selectedColor === logoColor.GREEN) {
          return computerIconGREEN;
        }

        if (selectedColor === 'pastEvent') {
          return computerDisabledIcon
        }  

        if (selectedColor === logoColor.BLUELIGHT) {
          return computerIconLightBlue;
        }

        if (selectedColor === logoColor.RED) {
          return computerIconRED;
        }

        if (selectedColor === logoColor.PURPLE) {
          return computerIconPurple;
        }

        if (selectedColor === logoColor.ORANGE) {
          return computerIconORANGE;
        }

        if (selectedColor === logoColor.YELLOW) {
          return computerIconYellow;
        }

        return computerIconPrimary;

      default: return computerIconPrimary;
  }
}