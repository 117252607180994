import React from 'react';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import faceHappy from '../../assets/images/face-happy-sm.svg';
import presentationChart from '../../assets/images/presentation-chart-01-sm.svg';
import clockIcon from '../../assets/images/clock-sm.svg';
import { Button } from '../Buttons/Button';
import { AgendaBlockType } from '../../types/agendaBlockType';

interface Props {
  agenda: {
    id: number;
    name: string;
    description: string;
    type: AgendaBlockType;
  }[],

}

export default function ReviewAgendaSmallSection({ agenda }: Props) {
  return (
    <div className='reviewDetailsSection'>
      <div className='reviewDetailsSection-content reviewAgendaSection' style={{ padding: '0' }}>
        <div className='reviewDetailsSection-content-about'>
          <div className='reviewDetailsSection-content-about-info'>
            <div className='reviewAgendaSection-blocks'>
              {
                agenda.some(block => block.type === AgendaBlockType.Meal)
                  ? <div className='reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-meal'>
                      <img src={faceHappy} alt="" />
                      <p className='reviewAgendaSection-blocks-block-meal-title' style={{whiteSpace: 'nowrap'}}>{agenda.filter(block => block.type === AgendaBlockType.Meal).length} meal</p>
                    </div>
                  : null
              }
              {
                agenda.some(block => block.type === AgendaBlockType.Session)
                  ? <div className='reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-session'>
                      <img src={presentationChart} alt="" />
                      <p className='reviewAgendaSection-blocks-block-session-title' style={{whiteSpace: 'nowrap'}}>{agenda.filter(block => block.type === AgendaBlockType.Session).length} session</p>
                    </div>
                  : null
              }
              {
                agenda.some(block => block.type === AgendaBlockType.Break)
                  ? <div className='reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-break'>
                      <img src={clockIcon} alt="" />
                      <p className='reviewAgendaSection-blocks-block-break-title' style={{whiteSpace: 'nowrap'}}>{agenda.filter(block => block.type === AgendaBlockType.Break).length} break</p>
                    </div>
                  : null
              }
            </div>
          </div>
          <Button
            buttonType='regular'
            hierarchy='linkColor'
            size='md'
            paddingNone
          >
            View detail
          </Button>
        </div>
      </div>
    </div>
  )
}