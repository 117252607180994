import React from 'react';
import calendarIcon from '../../assets/images/calendar.svg';
import clockIcon from '../../assets/images/clock.svg';
import pathIconLogoRepeater from '../../assets/images/path90.svg';
import markerPinIcon from '../../assets/images/marker-pin-01.svg';
import { Button } from '../Buttons/Button';
import { logoColor } from '../../types/logoColor';
import { logoImage } from '../../types/logoImage';
import LogoRepeat from '../LogoRepeat/LogoRepeat';
import { getLogoRepeatImage } from '../../utilities/getLogoRepeatImage';
import { getEventLogo } from '../../utilities/getEventLogoImage';

interface Props {
  name: string,
  date: string,
  startTime: string,
  endTime?: string,
  location: string,
  onClick: () => void,
  color: logoColor | 'pastEvent' | null,
  pattern: logoImage | null,
}

export default function EventReviewBlockSmall({ 
  name,
  date,
  startTime,
  endTime,
  location,
  onClick,
  color,
  pattern
}: Props) {
  return (
    <div className='eventReviewBlockSmall' id='small'>
      <div className='eventReviewBlockSmall-icon' onClick={onClick}> 
        <LogoRepeat 
          color={color === 'pastEvent' ? 'pastEvent' : color ? color : undefined } 
          image={(!pattern 
            ? pathIconLogoRepeater 
            : getLogoRepeatImage({ 
                selectedColor: color === 'pastEvent'
                  ? 'pastEvent' 
                  : color 
                      ? color
                      : logoColor.BLUE, 
                selectedPattern: pattern 
                  ? pattern 
                  : logoImage.PATH 
             }))} 
          size='lg' 
          height={'100%'} 
          position='absolute' 
        />
      </div>
      <div className='eventReviewBlockSmall-content'>
        <div className='eventReviewBlockSmall-content-header'>
          <div className='flex items-center gap-12'>
            <div className={`
              ${color === 'pastEvent' ? 'reviewEventDetailsPage-header-mainImage-pastEvent' : ''}
              reviewEventDetailsPage-header-mainImage
              reviewEventDetailsPage-header-mainImage-sm
              ${color === logoColor.YELLOW ? 'reviewEventDetailsPage-header-mainImage-yellow' : ''}
              ${color === logoColor.BLUELIGHT ? 'reviewEventDetailsPage-header-mainImage-blueLight' : ''}
              ${color === logoColor.RED ? 'reviewEventDetailsPage-header-mainImage-error' : ''}
              ${color === logoColor.PURPLE ? 'reviewEventDetailsPage-header-mainImage-purple' : ''}
              ${color === logoColor.GREEN ? 'reviewEventDetailsPage-header-mainImage-success' : ''}
              ${color === logoColor.ORANGE ? 'reviewEventDetailsPage-header-mainImage-warning' : ''}
            `}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            >
                <img src={getEventLogo(pattern)} alt=""/>
            </div>
            <h3 className='eventReviewBlockSmall-content-header-title' onClick={onClick}>{name}</h3>
          </div>
        </div>
        <div className='eventReviewBlockSmall-content-content'>
          <div className='eventReviewBlockSmall-content-content-info'>
            <div className='eventReviewBlockSmall-content-header-time'>
              <img src={calendarIcon} alt="" />
              <p className='eventReviewBlockSmall-content-header-description'>{date}</p>
            </div>
            <div className='eventReviewBlockSmall-content-content-info-block'>
              <img src={clockIcon} alt="" />
              <div className='eventReviewBlockSmall-content-content-info-block-info'>
                <p className='eventReviewBlockSmall-content-header-description'>{startTime}</p>
                {
                  endTime
                    ? <>
                        <p className='eventReviewBlockSmall-content-header-description'>-</p>
                        <p className='eventReviewBlockSmall-content-header-description'>{endTime}</p>
                      </>
                    : null
                }
              </div>
            </div>
            <div className='eventReviewBlockSmall-content-content-info-block'>
              <img src={markerPinIcon} alt="" />
              <div className='eventReviewBlockSmall-content-content-info-block-info'>
                <p className='eventReviewBlockSmall-content-header-description'>{location.split(',')[0]}</p>
                <p className='eventReviewBlockSmall-content-header-description eventReviewBlockSmall-content-header-description-lite'>{location.split(',')[1]}</p>
              </div>
            </div>
          </div>
          <div className='eventReviewBlockSmall-footer'>
            <Button
              buttonType='regular'
              hierarchy='primary'
              size='lg'
              contentCenter
              onClick={onClick}
            >
              Full details
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
