import React, { useEffect, useState } from 'react';
import RestaurantSelect from './RestaurantSelect/RestaurantSelect';
import RestaurantMenu from './MealSelect/RestaurantMenu';
import RestaurantDetails from './RestaurantDetails';
import restaurantIconEllipse from '../../assets/images/Ellipse 1.svg';
import { createPortal } from 'react-dom';
import AddNewRestaurantModal from '../Modal/AddNewRestaurantModal';
import { fetchRestaurantMenu } from '../../httpQueries/http';
import { useWindowSize } from '@uidotdev/usehooks';

interface Props {
  longitude: number,
  latitude: number,
  location: string,
  setSelectedRestaurant: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null>>
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>,
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null,
  onAddBlock: () => void,
  setCreatorIsOrder: React.Dispatch<React.SetStateAction<boolean>>,
  creatorIsOrder: boolean,
  dayId: number,
  agendaBlockStartTime: number,
  showSelectRestaurantModal: boolean,
  setOrderedMeals: React.Dispatch<React.SetStateAction<{
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[]>>,
  orderedMeals: {
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[],
  setIsPickup: React.Dispatch<React.SetStateAction<boolean>>,
  isPickup: boolean,
  setOrders: React.Dispatch<React.SetStateAction<{
    agendaBlockId: number,
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  }[]>>
  orders: {
    agendaBlockId: number,
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  }[],
  setSelectedAvailableMealsForAttendees: React.Dispatch<React.SetStateAction<{id: number, productsIds: string[]}[]>>,
  selectedAvailableMealsForAttendees: {id: number, productsIds: string[]}[],
  agendaBlockId: number,
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[],
  setRestaurantsForOrders: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[]>>
}

export default function SelectMeals({ 
  location, 
  longitude, 
  latitude, 
  setSelectedRestaurant, 
  setShowSelectRestaurantModal, 
  selectedRestaurant, 
  onAddBlock, 
  setCreatorIsOrder, 
  creatorIsOrder, 
  dayId, 
  agendaBlockStartTime, 
  showSelectRestaurantModal,
  orderedMeals,
  setOrderedMeals,
  setIsPickup,
  isPickup,
  setOrders,
  orders,
  selectedAvailableMealsForAttendees,
  setSelectedAvailableMealsForAttendees,
  agendaBlockId,
  restaurantsForOrders,
  setRestaurantsForOrders
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const token = localStorage.getItem('token');
  const [currentStep, setCurrentStep] = useState<'Restaurant' | 'Meals'>('Restaurant');
  const [fee, setFee] = useState(3.00);
  const [totalSum, setTotalSum] = useState(fee);
  const [showAddNewRestaurantModal, setShowAddNewRestaurantModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchRestaurantValue, setSearchRestaurantValue] = useState('');
  const [menu, setMenu] = useState<{
    name: string,
    subcategory_id: string,
    menu_item_list: {
      count: number
      name: string,
      price: number,
      qty_available: null,
      unit_size: null,
      unit_of_measurement: string
      description: string,
      is_available: boolean,
      image: string,
      customizations: [
          {
              name: string,
              min_choice_options: number,
              max_choice_options: number,
              options: [
                  {
                    name: string,
                    price: number,
                    customizations: any[],
                    min_qty: number,
                    max_qty: number,
                    conditional_price: {},
                    formatted_price: string,
                    default_qty: number,
                    option_id: string
                  }
              ],
              customization_id: string
          },
      ],
      min_price: number,
      original_price: number,
      formatted_price: string,
      attributes: [],
      product_id: string,
      thumbnail_image: string,
      should_fetch_customizations: boolean,
      supports_image_scaling: boolean
    }[]
  }[]>([]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);


  useEffect(() => {
    if (selectedRestaurant && typeof selectedRestaurant.id === 'string') {
      setIsLoading(true);
      fetchRestaurantMenu({ id: selectedRestaurant.id, token, isPickup }).then(res => {
        const menuItems = res?.map(category => ({
          ...category,
          menu_item_list: category.menu_item_list.map(meal => ({
            count: 0,
            ...meal
          }))
        }));
  
        setMenu(menuItems || []);
        setIsLoading(false);
      });
    
      if (creatorIsOrder) {
        setSelectedAvailableMealsForAttendees(prevState => prevState.filter(item => item.id !== agendaBlockId));
      }
    }
  }, [creatorIsOrder]);

  useEffect(() => {
    setTotalSum(fee);
    setOrders(prevState => [...prevState.filter(item => item.agendaBlockId !== agendaBlockId)]);
  }, [currentStep, creatorIsOrder]);

  useEffect(() => {
    if (currentStep === 'Restaurant') {
      setMenu([]);
    }
  }, [currentStep])

  useEffect(() => {
    if (showSelectRestaurantModal) {
      
      setSearchRestaurantValue('');
    }
  }, [showSelectRestaurantModal]) 
  
  return (
    <div className={`selectMeals ${showSelectRestaurantModal ? 'selectMeals-isOpen' : ''}`}>
      {
        showAddNewRestaurantModal
          ? createPortal(<AddNewRestaurantModal setShow={() => setShowAddNewRestaurantModal(prevState => !prevState)} />, document.getElementById('modal') as HTMLElement) 
          : null
      }
      {
        currentStep === 'Restaurant'
          ? <RestaurantSelect
              latitude={latitude}
              location={location}
              longitude={longitude}
              setSelectedRestaurant={setSelectedRestaurant}
              setShowSelectRestaurantModal={setShowSelectRestaurantModal}
              setCurrentStep={setCurrentStep}
              setIsPickup={setIsPickup}
              isPickup={isPickup}
              selectedRestaurant={selectedRestaurant}
              dayId={dayId}
              agendaBlockStartTime={agendaBlockStartTime}
              setShowAddNewRestaurantModal={setShowAddNewRestaurantModal}
              isDesktopSize={sizeIsDesktop}
              showSelectRestaurantModal={showSelectRestaurantModal}
              searchValue={searchRestaurantValue}
              setSearchValue={setSearchRestaurantValue}
              restaurantsForOrders={restaurantsForOrders}
              setRestaurantsForOrders={setRestaurantsForOrders}     
              agendaBlockId={agendaBlockId}
            />
          : <RestaurantMenu 
              id={typeof selectedRestaurant!.id === 'string' ? selectedRestaurant!.id : ''}
              name={selectedRestaurant!.name}
              setCurrentStep={setCurrentStep}
              isPickup={isPickup}
              setTotalSum={setTotalSum}
              fee={fee}
              setMenu={setMenu}
              menu={menu}
              setShowSelectRestaurantModal={setShowSelectRestaurantModal}
              onAddBlock={onAddBlock}
              setCreatorIsOrder={setCreatorIsOrder}
              creatorIsOrder={creatorIsOrder}
              currentStep={currentStep}
              orderedMeals={orderedMeals}
              setOrderedMeals={setOrderedMeals}
              selectedAvailableMealsForAttendees={selectedAvailableMealsForAttendees}
              setSelectedAvailableMealsForAttendees={setSelectedAvailableMealsForAttendees}
              setOrders={setOrders}
              orders={orders}
              selectedRestaurant={selectedRestaurant}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              agendaBlockId={agendaBlockId}
            />
      }
      {
        sizeIsDesktop
          ? <RestaurantDetails 
              isLoading={isLoading}
              name={selectedRestaurant?.name || 'Restaurant name example'}
              status={selectedRestaurant?.status}
              address={selectedRestaurant?.address}
              distance={selectedRestaurant?.distance}
              cuisines={selectedRestaurant?.cuisines || []}
              fee={fee}
              total={totalSum}
              logo={selectedRestaurant?.logo || restaurantIconEllipse} 
              creatorIsOrder={creatorIsOrder} 
              mealsForAttendeesChoose={menu.map(category => {
                let selectedProductsName: {name: string, count: number}[] = [];
                const filteredList = category.menu_item_list.filter(menuItem => menuItem.count >= 1);
                filteredList.map(selectedProduct => {
                  selectedProductsName = [...selectedProductsName, { name: selectedProduct.name, count: selectedProduct.count }]
                });
      
                return selectedProductsName
              })}   
              orderedMeals={orders.map(order => {
                let product: {
                  count: number
                  name: string,
                  price: number,
                  qty_available: null,
                  unit_size: null,
                  unit_of_measurement: string
                  description: string,
                  is_available: boolean,
                  image: string,
                  customizations: [
                      {
                          name: string,
                          min_choice_options: number,
                          max_choice_options: number,
                          options: [
                              {
                                name: string,
                                price: number,
                                customizations: any[],
                                min_qty: number,
                                max_qty: number,
                                conditional_price: {},
                                formatted_price: string,
                                default_qty: number,
                                option_id: string
                              }
                          ],
                          customization_id: string
                      },
                  ],
                  min_price: number,
                  original_price: number,
                  formatted_price: string,
                  attributes: [],
                  product_id: string,
                  thumbnail_image: string,
                  should_fetch_customizations: boolean,
                  supports_image_scaling: boolean
                } | undefined

                menu.map(category => {
                  category.menu_item_list.map(item => {
                    if (item.product_id === order.productId) {
                      product = item
                    }
                  });
                })

                return {
                  img: product?.image || '',
                  title: product?.name || '',
                  description: product?.description || '',
                  price: product?.formatted_price || '',
                  count: order.count,
                  onIncrease: () => {
                    setOrders(prevState => {
                      const productToOrder = prevState.find(item => item.productId === order?.productId);
                      const productToOrderIndex = prevState.findIndex(item => item.productId === order?.productId);

                      if (!!productToOrder) { 
                        setTotalSum(prevState => prevState + ((order.markedPrice / 100) + order.customizations.reduce((accum, currentValue) => (currentValue.markedPrice / 100) + accum, 0)))
                        return [...prevState.slice(0, productToOrderIndex), {...productToOrder, count: productToOrder.count + 1}, ...prevState.slice(productToOrderIndex + 1)]
                      }

                      return prevState
                    })
                  },
                  onDecrease: () => {
                    setOrders(prevState => {
                      const productToOrder = prevState.find(item => item.productId === order.productId);
                      const productToOrderIndex = prevState.findIndex(item => item.productId === order?.productId);

                      if (!!productToOrder) { 
                        setTotalSum(prevState => prevState - ((order.markedPrice / 100) + order.customizations.reduce((accum, currentValue) => (currentValue.markedPrice / 100) + accum, 0)))

                        if (productToOrder.count === 1) {
                          return [...prevState.filter(item => item.productId !== order?.productId)]
                        }
                        return [...prevState.slice(0, productToOrderIndex), {...productToOrder, count: productToOrder.count - 1}, ...prevState.slice(productToOrderIndex + 1)]
                      }

                      return prevState
                    })
                  },
                }
              })}   
            />
        : null
      }
    </div>
  )
}
