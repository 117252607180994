import { Button } from "../Buttons/Button";
import { DropdownMenu } from "../Dropdown/DropdownMenu";
import { timeZones } from "../../utilities/defaultTimeZonesArray";

interface Props {
  setShow: () => void,
  zone: {id: number, name: string, timezone?: string} | null,
  setZone: React.Dispatch<React.SetStateAction<{id: number, name: string, timezone?: string} | null>>,
  show: boolean,
  isDesktopSize?: boolean,
  isError: boolean
}

export default function ZonePicker({ setShow, zone, setZone, show, isDesktopSize, isError }: Props) {
  return (
    <div className={`zonePicker ${isError ? 'zonePicker-error' : ''}`}>
      {
        zone
          ? <p className="zonePicker-zone" onClick={setShow}>{zone.name}</p>
          : <Button
              buttonType='regular'
              hierarchy='tertiaryGray'
              size='lg'
              onClick={setShow}
            >
              Time zone
            </Button>
      }
      
      <form>
        {
          show
            ? <div className="zonePicker-container">
                <div className="zonePicker-picker">
                  <DropdownMenu
                    content={timeZones}
                    selectedItem={zone}
                    setSelectedItem={setZone}
                    positionRight={isDesktopSize ? '0' : null}
                    positionLeft={!isDesktopSize ? '0' : null}
                    positionTop={!isDesktopSize ? '100px' : null}
                    setShow={setShow}
                    size='base'
                    reactToFocus
                  />
                </div>
              </div>
            : null
        }
      </form>
    </div>
  )
}
