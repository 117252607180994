import { countries, getEmojiFlag, getCountryCode } from 'countries-list';

export const countriesList = Object.entries(countries).map((country, index) => {
  const countryCode = getCountryCode(country[1].name);
    
  return {
    id: index + 1,
    name: country[1].name,
    emoji: countryCode ? getEmojiFlag(countryCode) : ''
  }      
});
