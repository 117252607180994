import React, { useContext, useEffect, useState } from 'react';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import userPlusIcon from '../../assets/images/user-plus-01.svg';
import { Button } from '../Buttons/Button';
import { FormInput } from '../Inputs/FormInput';
import { DropdownMenu } from '../Dropdown/DropdownMenu';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import { editAttendee, fetchLocations, postAttendee, postAttendeeToAttendeesNetwork } from '../../httpQueries/http';
import { createPortal } from 'react-dom';
import { LocationSearch } from '../LocationSearch/LocationSearch';
import { validateEmail } from '../../utilities/validateEmail';
import buildingIcon from '../../assets/images/building-02.svg';
import Counter from '../Counter/Counter';
import RadioButtons from '../Selectors/RadioButtons';
import SelectOptions from '../Selectors/SelectOptions';
import Gallery from '../Gallery/Gallery';

interface Props {
  setShow: () => void,
  customizations: [
    {
      name: string,
      min_choice_options: number,
      max_choice_options: number,
      options: [
        {
          name: string,
          price: number,
          customizations: any[],
          min_qty: number,
          max_qty: number,
          conditional_price: {},
          formatted_price: string,
          default_qty: number,
          option_id: string
        }
      ],
      customization_id: string
    },
  ],
  name: string,
  description: string,
  productId: string,
  markedPrice: number,
  onDescrease: () => void,
  onIncrease: () => void,
  count: number,
  setOrders: React.Dispatch<React.SetStateAction<{
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  } | undefined>>,
  onAddMeal?: () => void,
  preOrder: {
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  } | undefined,
  photos: string[],
  disableIncrease?: boolean,
  onInputChange: (count: number) => void,
  maxInputValue: number,
  asyncOnAddMeal?: () => Promise<void>
}

export default function SelectMealsAttendeesModal({
  setShow, 
  customizations,
  name,
  description,
  productId,
  markedPrice,
  onDescrease,
  onIncrease,
  count,
  setOrders,
  onAddMeal,
  preOrder,
  photos,
  disableIncrease,
  onInputChange,
  maxInputValue,
  asyncOnAddMeal
}: Props) {
  const [selectedOptions, setSelectedOptions] = useState<{
    name: string;
    price: number;
    customizations: any[];
    min_qty: number;
    max_qty: number;
    conditional_price: {};
    formatted_price: string;
    default_qty: number;
    option_id: string;
    isSelected: boolean;
  }[]>([])
  const token = localStorage.getItem('token');
  let timer = setTimeout(() => {}, 300);
  const productWithCustomizationsPrice = ((count * (markedPrice / 100)) + (count * (preOrder ? preOrder.customizations.reduce((currentValue, option) => currentValue + option.markedPrice, 0) / 100 : 0))).toFixed(2);
  const [mealsBlock, setMealsBlock] = useState<HTMLElement | null>(null);
  const [scrollTop, setScrollTop] = useState<number | undefined>(0);
  

  useEffect(() => {
    window.scrollTo(0, 0);

    setMealsBlock(document.getElementById('mealBlock'))
    document.getElementById('modal')?.classList.add('modal-main-container');
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto';
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);

  return (
    <div className='modalNotification' style={{ zIndex: '1000' }}>
      <div className='modalNotification-container modalNotification-container-inviteAttendees selectMeals-customizations' id='mealBlock' onScroll={() => {
        setScrollTop(mealsBlock?.scrollTop)
      }}>
        <div className='modalNotification-header selectMeals-customizations-header'>
          <div className='selectMeals-customizations-close'>
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={setShow}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
          <Gallery photos={photos} />
        </div>
        <div className='modalNotification-main selectMeals-customizations-main'>
          {
            customizations.map(customization => {
              if (customization.max_choice_options <= 1) {
                return <RadioButtons 
                  options={customization.options.map(item => {
                      let selectedCustomizationOptionIds: string[] = [];
                      preOrder?.customizations.map(optionCustomization => {
                        selectedCustomizationOptionIds = [...selectedCustomizationOptionIds, optionCustomization.optionId]
                      })
                      


                      return  {
                        ...item, 
                        onClick: () => {
                          setOrders(prevState => {
                            if (selectedCustomizationOptionIds.includes(item.option_id) && prevState) {
                              return  {
                                ...prevState,
                                customizations: prevState.customizations.filter(option => option.optionId !== item.option_id)
                              }
                            } else {
                              if (prevState) {
                                if (prevState.customizations.map(option => option.customizationId).includes(customization.customization_id)) {
                                  return { ...prevState!, customizations: [
                                    ...prevState.customizations.filter(custom => custom.customizationId !== customization.customization_id),
                                    { 
                                      customizationId: customization.customization_id,
                                      optionId: item.option_id,
                                      markedPrice: item.price,
                                    }
                                  ]}
                                }
                                return { ...prevState, customizations: [
                                  ...prevState!.customizations,
                                  { 
                                    customizationId: customization.customization_id,
                                    optionId: item.option_id,
                                    markedPrice: item.price,
                                  }
                                ]}
                              } else {
                                return {
                                  customizations: [{
                                    customizationId: customization.customization_id,
                                    optionId: item.option_id,
                                    markedPrice: item.price,
                                  }],
                                  productId: productId,
                                  markedPrice: markedPrice,
                                  notes: '',
                                  count: 1
                                }
                              }
                            }
                          })
                        },
                        isSelected: selectedCustomizationOptionIds.includes(item.option_id)
                      }
                    }
                   )} 
                  label={customization.name} 
                  isRequired={customization.min_choice_options >= 1}
                />
              }

              if (customization.max_choice_options > 1) {
                return <SelectOptions options={selectedOptions} setOptions={setSelectedOptions} label={customization.name} />
              }
            })
          }
        </div>
        <div className={`modalNotification-actions modalNotification-actions-flex-end selectMeals-customizations-actions ${(mealsBlock?.clientHeight && scrollTop ? scrollTop + mealsBlock.clientHeight : scrollTop) === mealsBlock?.scrollHeight ? '' : 'selectMeals-customizations-actions-notScrolledToBottom'}`} style={{marginLeft: 'auto'}}>
          <Counter 
            onDescrease={onDescrease}
            onIncrease={onIncrease}
            count={count}
            disableIncrease={disableIncrease}
            onInputChange={onInputChange}
            maxInputValue={maxInputValue}
          />
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            // fullWidth={!isDesktopSize}
            onClick={onAddMeal}
            asyncOnClick={asyncOnAddMeal}
            // disabled={}
          >
            Add food name (${productWithCustomizationsPrice})
          </Button>
        </div>
      </div>
    </div>
  )
}