import React, { useEffect, useState } from 'react'
import Pagination from '../Paginations/Pagination'
import { Button } from '../Buttons/Button'
import CheckBox from '../CheckBox/CheckBox'
import { useWindowSize } from '@uidotdev/usehooks';
import plusIcon from '../../assets/images/plus.svg';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import { UserImagePlaceholder } from '../userProfileImagePlaceholder/userImagePlaceholder';
import { logoColor } from '../../types/logoColor';
import { AttendeeRSVP } from '../../types/attendeeRSVP';
import { logoImage } from '../../types/logoImage';
import editDotsIcon from '../../assets/images/edit-01.svg';
import deleteIcon from '../../assets/images/trash-01-black.svg';
import DotsModal from '../Modal/DotsModal';
import { deleteAttendeeFromContacts, editAttendee, queryClient } from '../../httpQueries/http';
import { createPortal } from 'react-dom';
import ModalNotification from '../Modal/ModalNotification';

interface Props {
  content: {
    "network": [
      {
        "id": number,
        "fullName": string,
        "email": string,
        "phone"?: string,
        "businessName": string,
        "address": string,
        "title": string,
        "updatedAt": string,
        "user": {
          "id": number,
          "email": string,
          "fullName": string,
          "phone"?: null | string,
        },
        "events": [
          {
            "id": number,
            "name": string,
            "slug": string,
            "description": string,
            "timezone": string,
            "color": null | logoColor,
            "pattern": null | logoImage,
            "startTime": string,
            "endTime": string,
            "attendee": {
              "email": string,
              "userId": number,
              "status": "added",
              "rsvp": null | AttendeeRSVP
            }
          },
        ]
      }
    ],
    "count": number
  },
  setReviewContactDetailsIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsNewUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  selectedPaginationPage: number,
  setSelectedPaginationPage: React.Dispatch<React.SetStateAction<number>>,
  setShowUserInfo: React.Dispatch<React.SetStateAction<
      {
        "id": number,
        "fullName": string,
        "email": string,
        "phone"?: string,
        "businessName": string,
        "address": string,
        "title": string,
        "updatedAt": string,
        "user": {
          "id": number,
          "email": string,
          "fullName": string,
          "phone"?: null | string,
        },
        "events": [
          {
            "id": number,
            "name": string,
            "slug": string,
            "description": string,
            "timezone": string,
            "color": null | logoColor,
            "pattern": null | logoImage,
            "startTime": string,
            "endTime": string,
            "attendee": {
              "email": string,
              "userId": number,
              "status": "added",
              "rsvp": null | AttendeeRSVP
            }
          },
        ]
  } | null>>,
  setUserUpdated: React.Dispatch<React.SetStateAction<number | null>>,
  setUserDeleted: React.Dispatch<React.SetStateAction<number | null>>,
  setUserFullName: React.Dispatch<React.SetStateAction<string>>,
  setUserEmail: React.Dispatch<React.SetStateAction<string>>,
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string>>,
  setUserBusinessName: React.Dispatch<React.SetStateAction<string>>,
  setUserTitle: React.Dispatch<React.SetStateAction<string>>,
  selected?: number,
}

export default function ContactsTable({ 
  content, 
  setReviewContactDetailsIsOpen, 
  setIsNewUserModalOpen, 
  selectedPaginationPage, 
  setSelectedPaginationPage, 
  setShowUserInfo, 
  setUserUpdated,
  setUserBusinessName,
  setUserEmail,
  setUserFullName,
  setUserPhoneNumber,
  setUserTitle,
  setUserDeleted,
  selected
}: Props) {
  const token = localStorage.getItem('token');
  const [isDotsModalOpen, setIsDotsModalOpen] = useState<number | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<number | null>(null);

  return (
    <div className='teamMembersTable-container contactsTable spaceBetween' style={{ minHeight: '100%' }}>
      {
        isDeleteModalOpen
          ? createPortal(<ModalNotification 
              setIsOpen={() => setIsDeleteModalOpen(null)} 
              name={'Delete user'} 
              description={'[Event name] has been created. Invitees will be notified.'} 
              onFirstButton={() => setIsDeleteModalOpen(null)} 
              onSecondButton={() => {
                if (isDeleteModalOpen) {
                  deleteAttendeeFromContacts({ token, attendeeId: isDeleteModalOpen });
                  setUserDeleted(isDeleteModalOpen)
                }
              }} 
              firstButtonName={'Close'} 
              secondButtonName={'Delete user'} 
            />, document.getElementById('modal') as HTMLElement) 
          : null
      }
      <div>
        <div className='teamMembersTable-header contactsTable-header'>
          <div>
            <div className='teamMembersTable-header-info'>
              <p className='reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-session reviewAgendaSection-blocks-block-session-title'>{content.count} users</p>
            </div>
            <p className='contactsTable-description'>Manage your contacts here.</p>
          </div>
          <Button
            buttonType='regular'
            size='md'
            hierarchy='primary'
            onClick={() => setIsNewUserModalOpen(true)}
          >
            <div className='flex gap-8'>
              <img src={plusIcon} alt="" />
              <p>Add contact</p>
            </div>
          </Button>
        </div>
        <div className='teamMembersTable-table-container overflowXVisible'>
          <table className='userInviteTable teamMembersTable-table fullWidth'>
            <thead className='userInviteTable-header teamMembersTable-table-header'>
              <tr className='userInviteTable-header-container  teamMembersTable contactsTable-flex'>
                <th className='userInviteTable-header-invitees'>
                  <CheckBox 
                  isSelected={false} 
                  items={content.network.map(item => ({
                    id: item.id,
                    name: item.fullName,
                    supportText: item.email
                  }))}
                  setIsSelected={() => {}}
                />
                  Name
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className='userInviteTable-body'>
              {content.network.map((item, index) => (
                <tr 
                  className={` fullWidth contactsTable-flex contactsTable-flex-tr ${selected === item.id ? 'contactsTable-selected' : ''} ${(index + 1) % 2 !== 0 ? 'userInviteTable-body-container-isOdd' : ''}  teamMembersTable`} 
                  key={item.id} 
                  onClick={() => {
                    setReviewContactDetailsIsOpen(true);
                    setShowUserInfo(item);
                  }}
                >
                  <td className='userInviteTable-body-item-info teamMembersTable-table-cell overflowXVisible'>
                    <CheckBox 
                      isSelected={false} 
                      item={{id: item.id, name: item.fullName, supportText: item.email}}
                      setIsSelected={() => {}}
                    />
                      <UserImagePlaceholder name={(item.user ? item.user.fullName : item.fullName).toLocaleUpperCase()} verified={!!item.user} />
                    <div>
                      <h3 className='userInviteTable-body-item-info-name'>{item.user ? item.user.fullName : item.fullName}</h3>
                      <p className='userInviteTable-body-item-info-email teamMembersTable-table-nickname'>{item.user ? item.user.email : item.email}</p>
                    </div>
                  </td>
                  <td className='userInviteTable-body-item-actions teamMembersTable-table-cell positionRelative'>
                    <Button
                      buttonType='small'
                      hierarchy='tertiaryGray'
                      size='md'
                      buttonImage={dotsIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDotsModalOpen(isDotsModalOpen === item.id ? null : item.id);
                      }}
                    />

                    {
                      isDotsModalOpen === item.id
                        ? <DotsModal 
                            items={[
                              {id: 1, img: editDotsIcon, text: 'Edit', onClick: (e) => {
                                e.stopPropagation();
                                setUserUpdated(item.id);
                                setUserBusinessName(item.businessName);
                                setUserEmail(item.email);
                                setUserFullName(item.fullName);
                                setUserPhoneNumber(item.phone || '');
                                setUserTitle(item.title);
                                setIsNewUserModalOpen(true);
                              }}, 
                              {id: 2, img: deleteIcon, text: 'Delete', onClick: (e) => {
                                e.stopPropagation();
                                setIsDeleteModalOpen(item.id)
                              }}
                            ]}
                            setIsOpen={() => {
                              setIsDotsModalOpen(null)
                            }}
                            top='60px'
                            right='0'
                          />
                        : null
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination numberOfPages={Math.ceil(content.count / 10)} selected={selectedPaginationPage} setSelected={setSelectedPaginationPage} />
    </div>
  )
}
