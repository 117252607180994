import React, { useEffect, useState } from 'react';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import { Button } from '../Buttons/Button';
import { FormInput } from '../Inputs/FormInput';
import { DropdownMenu } from '../Dropdown/DropdownMenu';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import { fetchLocations, postNewRestaurant } from '../../httpQueries/http';
import { createPortal } from 'react-dom';
import { LocationSearch } from '../LocationSearch/LocationSearch';
import buildingIcon from '../../assets/images/building-02.svg';

interface Props {
  setShow: () => void,
//   isDesktopSize: boolean
}

export default function AddNewRestaurantModal({
  setShow, 
//   isDesktopSize
}: Props) {
  const [newRestaurantName, setNewRestaurantName] = useState('');
  const [restaurantLink, setRestaurantLink] = useState('');
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const token = localStorage.getItem('token');
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<{id: number, name: string, supportText?: string | undefined, lng?: string, lat?: string} | null>(null)
  const [locations, setLocations] = useState<{name: string, lng: number, lat: number}[] | undefined>([]);
  let timer = setTimeout(() => {}, 300);
  

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById('modal')?.classList.add('modal-main-container');
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto';
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(async () => {
      const response = await fetchLocations({ search: inputLocationValue, token });
      setLocations(response);
    }, 400);

    return () => clearTimeout(timer);
  }, [inputLocationValue]);

  const onAddNewRestaurant = () => {
    if (selectedLocation?.name && selectedLocation.lat && selectedLocation.lng && restaurantLink.length && newRestaurantName.length) {
      postNewRestaurant({ token, body: {
        location: selectedLocation.name,
        locationLatitude: `${selectedLocation.lat}`,
        locationLongitude: `${selectedLocation.lng}`,
        url: restaurantLink,
        name: newRestaurantName,
      } }).then(() => setShow())
    }
  }

  return (
    <div className='modalNotification' style={{ zIndex: 1000 }}>
      <div className='modalNotification-container modalNotification-container-inviteAttendees'>
      <div className='modalNotification-circles-container'>
        <div className='modalNotification-circles'>
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className='modalNotification-header'>
          <div className='modalNotification-header-actions'>
            <FeaturedIcon icon={buildingIcon} size='lg' type='gray' />
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={setShow}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>Add new restaurant</h3>
          </div>
        </div>
        <div className='modalNotification-main'>
          <FormInput
            label={newRestaurantName.length ? 'Restaurant name*' : undefined}
            placeholder='Restaurant name'
            type='Text'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setNewRestaurantName(event.target.value)}
            value={newRestaurantName}
          />
          <div >
            {
              !showSelectLocation && selectedLocation
                ? <div className='eventDetails_content-location-container' onClick={() => setShowSelectLocation(true)}>
                    <h3 className='eventDetails_content-location-title'>Location*</h3>
                    <div className='eventDetails_content-location'>
                      <p className='eventDetails_content-location-main'>{selectedLocation?.name}</p>
                      <p className='eventDetails_content-location-support'>{selectedLocation?.supportText}</p>
                    </div>
                  </div>
                : <FormInput
                    placeholder={selectedLocation?.name ? `${selectedLocation?.name}, ${selectedLocation?.supportText}` : 'Address'}
                    type='SearchGray'
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setInputLocationValue(event.target.value)}
                    value={inputLocationValue}
                    setShow={() => setShowSelectLocation(false)}
                    onFocus={() => {
                      setShowSelectLocation(true)
                    }}
                  />
            }

            {showSelectLocation && inputLocationValue.length && locations
              ? <div>
                <DropdownMenu
                  content={locations.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}`, lat: location.lat, lng: location.lng }))}
                  selectedItem={selectedLocation}
                  setSelectedItem={setSelectedLocation}
                  setShow={() => {
                    setShowSelectLocation(false);
                  }}
                  withSupportText
                  size='lg'
                  heightSize='xs'
                />
              </div>
              : null
            }
          </div>
          {/* {showSelectLocation && !isDesktopSize
            ? createPortal(<LocationSearch
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                setShowSelectLocation={setShowSelectLocation}
                locations={locations?.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}` }))}
                inputValue={inputLocationValue}
                setInputValue={setInputLocationValue}
              />, document.getElementById('modal') as HTMLElement)
            : null
          } */}
          <FormInput
            label={'Link*'}
            placeholder='www.example.com'
            type='Website'
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setRestaurantLink(event.target.value)}
            value={restaurantLink}
            fullWidth
          />
        </div>
        <div className='modalNotification-actions modalNotification-actions-flex-end' style={{marginLeft: 'auto'}}>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            // fullWidth={!isDesktopSize}
            onClick={onAddNewRestaurant}
            disabled={!(newRestaurantName.length && selectedLocation && restaurantLink.length)}
          >
            Add restaurant
          </Button>
        </div>
      </div>
    </div>
  )
}
