import React from 'react';
import { Button } from '../Buttons/Button';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import uploadIcon from '../../assets/images/upload-cloud-02.svg';

interface Props {
  setSelectedFileUrl?: React.Dispatch<React.SetStateAction<string>>,
  setSelectedFile?: React.Dispatch<React.SetStateAction<any | null>>,
  fileTypesInText: string,
  fileTypes: string,
  isDisabled?: boolean,
  widthIsMobile?: boolean
}

export default function Upload({ setSelectedFile, fileTypes, fileTypesInText, setSelectedFileUrl, isDisabled, widthIsMobile }: Props) {
  function upload() {
    document.getElementById("selectImage")?.click();
  }

  return (
    <div 
      className={`upload ${isDisabled ? 'upload-disabled' : ''}`}
      onDrop={(e) => {
        const formData = new FormData()
        e.nativeEvent.preventDefault();
        //e.dataTransfer.files[0]
        setSelectedFileUrl && setSelectedFileUrl(window.URL.createObjectURL(e.dataTransfer.files[0]));
        
        setSelectedFile && setSelectedFile(e.dataTransfer.files[0]);
      }} 
      onDragOver={(e) => e.preventDefault()}
    >
      <FeaturedIcon
        icon={uploadIcon}
        size='md'
        type='gray'
      />
      <div className='upload-content'>
        <div className='upload-content-action'>
          <Button
            buttonType='regular'
            hierarchy='linkColor'
            size='md'
            contentCenter
            paddingNone
            onClick={() => upload()}
            disabled={isDisabled}
          >
            Click to upload {widthIsMobile && 'and attach files'}
            <input type='file' hidden id='selectImage' accept={fileTypes} onChange={(e) => {
              setSelectedFileUrl && setSelectedFileUrl(window.URL.createObjectURL(e.target.files![0]));
              setSelectedFile && setSelectedFile(e.target.files![0]);
            }} />
          </Button>
          {
            !widthIsMobile
              ? <p>or drag and drop</p>
              : null
          }
        </div>
        <p className='upload-content-supportText'>{fileTypesInText}</p>
      </div>
    </div>
  )
}
