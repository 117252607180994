import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import DatePickerComponent from './DatePickerLarge';
import calendarIcon from '../../assets/images/calendar.svg';
import { Button } from '../Buttons/Button';

interface Props {
  type: 'double' | 'small' | 'smallWithTodayFeat',
  show: boolean,
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  firstDate: moment.Moment | null,
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  secondDate?: moment.Moment | null,
  setSecondDate?: React.Dispatch<React.SetStateAction<moment.Moment | null>>
  selectedDayOnlyFromToday: boolean;
}

export default function DatePickerModal({ type, show, setShow, firstDate, setFirstDate, secondDate, setSecondDate, selectedDayOnlyFromToday }: Props) {

  useEffect(() => {
    if (document) {
      document.body.style.overflow = show ? 'hidden' : 'auto'
    }
  }, [show]);

  return (
    <div style={{width: '100%'}}>
      <Button
        buttonType='regular'
        size='md'
        hierarchy='secondaryGray'
        onClick={() => {
          setShow((prevState) => !prevState);
        }}
        fullWidth
      >
        <div className={`openButton ${firstDate ? '' : 'openButton-color-gray openButton-width-content'} text-sm`}>
          <img src={calendarIcon} alt="calendar icon" className='calendarIcon' />
          <p>{firstDate === null ? 'Select date' : `${firstDate?.format('ll')}`}</p>
        </div>
      </Button>
      {createPortal(<div className='modal' style={{display: (show ? 'block' : 'none')}}>
          {
            type === 'double'
              ? <DatePickerComponent
                  firstDate={firstDate}
                  setFirstDate={setFirstDate}
                  isModal
                  type='double'
                  secondDate={secondDate}
                  setSecondDate={setSecondDate}
                  setIsOpen={setShow}
                  selectedDayOnlyFromToday={selectedDayOnlyFromToday}
                />
              : null
          }

          {type === 'small'
            ? <DatePickerComponent
                firstDate={firstDate}
                setFirstDate={setFirstDate}
                isModal
                type='small'
                secondDate={secondDate}
                setSecondDate={setSecondDate}
                setIsOpen={setShow}
                selectedDayOnlyFromToday={selectedDayOnlyFromToday}
              />
            : null
          }

          {type === 'smallWithTodayFeat'
            ? <DatePickerComponent
                firstDate={firstDate}
                setFirstDate={setFirstDate}
                isModal
                type='smallWithTodayFeat'
                secondDate={secondDate}
                setSecondDate={setSecondDate}
                setIsOpen={setShow}
                selectedDayOnlyFromToday={selectedDayOnlyFromToday}
              />
            : null
          }
        </div>, document.getElementById('modal') as HTMLElement)}
    </div>
  )
}
