import React, { useEffect, useState } from 'react';
import auFlag from '../../assets/images/AUFlag.svg';
import arrowDown from '../../assets/images/chevron-down.svg';
import { countriesList } from '../../utilities/countriesList';
import { DropdownMenu } from './DropdownMenu';

interface Props {
  selectedCountry: {
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
    emoji?: string
  } | null,
  setSelectedCountry: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
    emoji?: string
  } | null>>
}


export default function CountryDropdown({ selectedCountry, setSelectedCountry }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedCountry) {
      setInputValue(selectedCountry.name)
    }
  }, [selectedCountry])

  return (
    <div className='countryDropdown'>
      <h3 className='countryDropdown-label'>Country</h3>
      <div className='countryDropdown-input' onClick={() => setIsOpen(prevState => !prevState)}>
        <div className='countryDropdown-input-container'>
         <p>{selectedCountry?.emoji}</p>
          <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
        <img src={arrowDown} alt="" />
      </div>

      {
        isOpen
          ? <DropdownMenu 
              content={inputValue ? countriesList.filter(item => item.name.includes(inputValue)) : countriesList}
              selectedItem={selectedCountry}
              setSelectedItem={setSelectedCountry}
              setShow={() => setIsOpen(prevState => !prevState)}
              size='base'
              positionTop={'70px'}
              positionRight={'0'}
              reactToFocus
            />
          : null
      }
    </div>
  )
}
