import React, { useEffect, useState } from 'react';
import clockIcon from '../../assets/images/clock.svg';
import { timeZones } from '../../utilities/defaultTimeZonesArray';
import { DropdownMenu } from './DropdownMenu';
import arrowDown from '../../assets/images/chevron-down.svg';

interface Props {
  selectedTimeZone: {
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null,
  setSelectedTimeZone: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>>
}

export default function TimeZoneDropdown({ selectedTimeZone, setSelectedTimeZone }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
  
    useEffect(() => {
      if (selectedTimeZone) {
        setInputValue(selectedTimeZone.name.split(')')[0].slice(1))
      }
    }, [selectedTimeZone])
  
    return (
      <div className='countryDropdown'>
        <h3 className='countryDropdown-label'>Timezone</h3>
        <div className='countryDropdown-input' onClick={() => setIsOpen(prevState => !prevState)}>
          <div className='countryDropdown-input-container'>
            <img src={clockIcon} alt="" />
            {
              selectedTimeZone
                ? <p className='countryDropdown-abbr'>{selectedTimeZone.abbr}</p>
                : null
            }
            <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} className='countryDropdown-input-container-input' />
          </div>
          <img src={arrowDown} alt="" />
        </div>
  
        {
          isOpen
            ? <DropdownMenu 
                content={timeZones}
                selectedItem={selectedTimeZone}
                setSelectedItem={setSelectedTimeZone}
                setShow={() => setIsOpen(prevState => !prevState)}
                size='base'
                positionTop={'70px'}
                positionRight={'0'}
                reactToFocus
              />
            : null
        }
      </div>
    )
}
