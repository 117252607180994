import React, { useEffect } from 'react'
import { Button } from '../Buttons/Button';
import closeIcon from '../../assets/images/x-close-black.svg';
import successCircle from '../../assets/images/check-circle-green.svg';
import { FormInput } from '../Inputs/FormInput';
import { useDispatch, useSelector } from '../../store/hooks';
import { getRecentlyCreatedEvent } from '../../store/recentlyCreatedEvent/selectors';
import { useNavigate } from 'react-router-dom';
import { recentlyCreatedEventActions } from '../../store/recentlyCreatedEvent';
import { hostname } from 'os';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ModalEventWasCreated({ setIsOpen }: Props) {
  const recentlyCreatedEvent = useSelector(getRecentlyCreatedEvent);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    document.getElementById('modal')?.classList.add('modal-main-container');
    
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'auto'
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);
    
  return (
    <div className='modalNotification'>
      <div className='modalNotification-container modalEventCreated'>
        <div className='modalNotification-circles-error'>
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modalNotification-header modalNotification-header-error'>
          <div className='modalNotification-header-actions'>
            <div className='modalNotification-header-actions-icon modalNotification-header-actions-icon-successSecond'>
              <img src={successCircle} alt="trash icon" />
            </div>
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={() => {
                setIsOpen(false);
                dispatch(recentlyCreatedEventActions.setRecentlyCreatedEvent({ name: '', slug: '' }))
              }}
            /> 
          </div>
          <div className='flex flex-column gap-20 fullWidth'>
            <div className='modalNotification-header-info'>
              <h3>Event created</h3>
              <p>{recentlyCreatedEvent.name} has been created. Invitees will be notified.</p>
            </div>
          </div>
        </div>
        <div className='modalNotification-actions'>
          <Button
            buttonType='regular'
            size='lg'
            hierarchy='secondaryGray'
            fullWidth
            onClick={() => {
              setIsOpen(false);
              navigator.clipboard.writeText(`${window.location.protocol}//${window.location.hostname}/events/${recentlyCreatedEvent.slug}`)
            }}
            contentCenter
          >
            Share
          </Button>
          <Button
            buttonType='regular'
            size='lg'
            hierarchy='primary'
            fullWidth
            onClick={() => {
              setIsOpen(false);
              dispatch(recentlyCreatedEventActions.setRecentlyCreatedEvent({ name: '', slug: '' }))
              navigate(`/events/${recentlyCreatedEvent.slug}`)
            }}
            contentCenter
          >
            View event
          </Button>
        </div>
      </div>
    </div>
  )
}
