import clockIcon from '../../assets/images/clock.svg';
import { Button } from '../Buttons/Button';
import { DropdownMenu } from '../Dropdown/DropdownMenu';
import { Times } from '../../utilities/timesArray';

interface Props {
  setShow: () => void,
  time: {id: number, name: string} | null,
  setTime: React.Dispatch<React.SetStateAction<{id: number, name: string} | null>>,
  show: boolean,
  placeholder: string, 
  startOfPhrase: string,
  isDesktopSize: boolean,
  isError?: boolean,
  minValue?: {id: number, name: string} | null,
  disabled?: boolean
}

export default function TimePicker({ setShow, time, setTime, show, placeholder, startOfPhrase, isDesktopSize, isError, minValue, disabled }: Props) {
  return (
    <div className='time-container'>
      <Button
        buttonType='regular'
        size='md'
        hierarchy={isError ? 'errorSecondaryGray' : 'secondaryGray'}
        onClick={() => setShow()}
        fullWidth
        disabled={disabled}
      >
        <div className={`openButton ${time ? '' : 'openButton-color-gray openButton-width-content'} text-sm`}>
          <img src={clockIcon} alt="clock icon" className='calendarIcon' />
          <p className={isError ? 'textError' : ''}>{time === null ? placeholder : `${startOfPhrase} ${time.name}`}</p>
        </div>
      </Button>

      <div>
        {show
          ? <DropdownMenu
              selectedItem={time}
              setSelectedItem={setTime}
              content={Times}
              positionBottom={isDesktopSize ? '0' : '50px'}
              positionTop={isDesktopSize ? '290px' : '0px'}
              setShow={setShow}
              size='base'
              minValue={minValue}
              reactToFocus
          />
          : null
        }
      </div>
    </div>
  )
}
