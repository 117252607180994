import React from 'react'

export default function Badge({ text, status }: { text: string, status: 'default' | 'success' | 'error' | 'warning' }) {
  return (
    <p 
      className={`
        userInviteTable-body-item-status-info
        ${status === 'error' ? 'userInviteTable-body-item-status-info-error' : ''}
        ${status === 'success' ? 'userInviteTable-body-item-status-info-success' : ''}
        ${status === 'warning' ? 'userInviteTable-body-item-status-info-warning' : ''}
      `}
    >
      {text}
    </p>
  )
}
