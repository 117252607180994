import React, { useState, useEffect, useContext } from 'react'
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import announcementIcon from '../../assets/images/announcement-01.svg';
import { Button } from '../Buttons/Button';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import presentationIcon from '../../assets/images/presentation-chart-01.svg';
import clockPlusIcon from '../../assets/images/clock-plus.svg';
import happyFaceIcon from '../../assets/images/face-happy.svg';
import Tabs from '../Tabs/Tabs';
import { FormInput } from '../Inputs/FormInput';
import { DropdownMenu } from '../Dropdown/DropdownMenu';
import clockIcon from '../../assets/images/clock.svg';
import { editTime } from '../../utilities/EditAgendaBlockTime';
import { EventInfoContext } from '../../utilities/eventInfoContext';
import { durations } from '../../utilities/agendaDurationsArray';
import { AgendaBlockType } from '../../types/agendaBlockType';
import { Dropdown } from '../Dropdown/Dropdown';
import chevronDown from '../../assets/images/chevron-down-primary.svg';
import pencilIcon from '../../assets/images/pencil-02.svg'
import { useWindowSize } from '@uidotdev/usehooks';
import { createPortal } from 'react-dom';
import RestaurantSelect from '../MealSelection/RestaurantSelect/RestaurantSelect';
import RestaurantMenu from '../MealSelection/MealSelect/RestaurantMenu';
import SelectMeal from '../MealSelection/SelectMeals';
import { getTimeInMinutes } from '../../utilities/getTimeInMinutes';
import plusCircle from '../../assets/images/plus-circle-2.svg';

const tabs: { id: number; name: string; type: AgendaBlockType; }[] = [
  {
    id: 1,
    name: 'Session',
    type: AgendaBlockType.Session
  }, 
  {
    id: 2,
    name: 'Break',
    type: AgendaBlockType.Break
  }, 
  {
    id: 3,
    name: 'Meal',
    type: AgendaBlockType.Meal
  }
];

interface Props {
  longitude: number,
  latitude: number,
  location: string,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAgenda: React.Dispatch<React.SetStateAction<{
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType,
    index: number
  }[]>>,
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number,
  }[],
  currentItem?: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
        id: number;
        name: string;
        value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number
  },
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  isEdit: boolean,
  setMealSelectedOrders: React.Dispatch<React.SetStateAction<{
    restaurantId: string | number;
    mealAgendaItemId: string;
    orderedMeals: {
      customizations?: [
        {
          customizationId: string;
          optionId: string;
          markedPrice: number;
        }
      ];
      productId: string;
      markedPrice: number;
      notes: string;
    }[];
  }[]>>,
  dayId: number,
  setIsPickup: React.Dispatch<React.SetStateAction<boolean>>,
  isPickup: boolean,
  setCreatorIsOrder: React.Dispatch<React.SetStateAction<boolean>>,
  creatorIsOrder: boolean,
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null,
  setSelectedRestaurant: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null>>,
  setOrders: React.Dispatch<React.SetStateAction<{
    agendaBlockId: number,
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  }[]>>,
  orders: {
    agendaBlockId: number
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  }[],
  setSelectedAvailableMealsForAttendees: React.Dispatch<React.SetStateAction<{id: number, productsIds: string[]}[]>>,
  selectedAvailableMealsForAttendees: {id: number, productsIds: string[]}[],
  setMealBlocksWithoutMealsSelection: React.Dispatch<React.SetStateAction<string[]>>,
  agendaBlockId: number,
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[],
  setRestaurantsForOrders: React.Dispatch<React.SetStateAction<{
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[]>>
}

export default function ModalAgenda({ 
  setIsOpen, 
  setAgenda, 
  agenda, 
  currentItem = {
    id: '',
    icon: '',
    name: '',
    description: '',
    duration:  {
      id: 0,
      name: '',
      value: 0
    },
    startTime:  '',
    endTime:  '',
    type: AgendaBlockType.Session,
    index: 0,
  },
  setIsEdit,
  isEdit,
  location,
  latitude,
  longitude,
  setMealSelectedOrders,
  dayId,
  setIsPickup,
  isPickup,
  creatorIsOrder,
  setCreatorIsOrder, 
  selectedRestaurant, 
  setSelectedRestaurant,
  setOrders,
  orders,
  selectedAvailableMealsForAttendees,
  setSelectedAvailableMealsForAttendees,
  setMealBlocksWithoutMealsSelection,
  agendaBlockId,
  restaurantsForOrders,
  setRestaurantsForOrders
}: Props) {
  const [currentTab, setCurrentTab] = useState<{id: number, name: string, type?: AgendaBlockType}| null>(null);
  const [selectedDuration, setSelectedDuration] = useState<{ id: number, name: string, value?: number} | null>(null);
  const [showSelectDuration, setShowSelectDuration] = useState(false);
  const [sessionName, setSessionName] = useState(currentItem.name.length ? currentItem.name : (currentTab?.name !== 'Session' ? currentTab?.name : ''));
  const [sessionDescription, setSessionDescription] = useState('');
  const [agendaNameError, setAgendaNameError] = useState(false);
  const [agendaDescriptionError, setAgendaDescriptionError] = useState(false);
  const [agendaDurationError, setAgendaDurationError] = useState(false);
  const [showFindRestaurantDropdown, setShowFindRestaurantDropdown] = useState(false);
  const [selectedRestaurantType, setSelectedRestaurantType] = useState<{id: number, name: string} | null>(null);
  const [isAdd, setIsAdd] = useState(false);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [showSelectRestaurantModal, setShowSelectRestaurantModal] = useState(false);
  const [showRestaurantMenu, setShowRestaurantMenu] = useState(false);
  const { startTime: selectedStartTime } = useContext(EventInfoContext);
  
  const [menu, setMenu] = useState<{
    name: string,
    subcategory_id: string,
    menu_item_list: {
      count: number
      name: string,
      price: number,
      qty_available: null,
      unit_size: null,
      unit_of_measurement: string
      description: string,
      is_available: boolean,
      image: string,
      customizations: [
          {
              name: string,
              min_choice_options: number,
              max_choice_options: number,
              options: [
                  {
                    name: string,
                    price: number,
                    customizations: any[],
                    min_qty: number,
                    max_qty: number,
                    conditional_price: {},
                    formatted_price: string,
                    default_qty: number,
                    option_id: string
                  }
              ],
              customization_id: string
          },
      ],
      min_price: number,
      original_price: number,
      formatted_price: string,
      attributes: [],
      product_id: string,
      thumbnail_image: string,
      should_fetch_customizations: boolean,
      supports_image_scaling: boolean
    }[]
  }[]>([]);
  const [orderedMeals, setOrderedMeals] = useState<{
    customizations: {
      customizationId: string,
      optionId: string,
      markedPrice: number
    }[],
    productId: string,
    markedPrice: number,
    notes: string,
  }[]>([]);

  if (!selectedStartTime) {
    setIsOpen(false);
  }

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 

  useEffect(() => {
    if (!sessionName?.length) {
      setSessionName(currentItem.name || '')
    }
  }, [sessionName])


  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById('modal')?.classList.add('modal-main-container');

    document.body.style.overflow = 'hidden';

    if (currentItem.name.length) {
      setIsEdit(true);
      setCurrentTab(tabs.find(tab => tab.type === currentItem.type) || null);
      setSelectedDuration(currentItem.duration);
      setSessionDescription(currentItem.description);
    }

    return () => {
      document.body.style.overflow = 'auto'
      document.getElementById('modal')?.classList.remove('modal-main-container');
    }
  }, []);

  useEffect(() => {
    setSessionName(currentTab?.name !== 'Session' ? currentTab?.name : '')
  }, [currentTab]);

  useEffect(() => {
    if (selectedStartTime) {
      if (isAdd) {
        if (!agendaDurationError && !agendaNameError && !agendaDescriptionError) {
          let newItem: {
            id: string;
            icon: string;
            name: string;
            description: string;
            duration: {
              id: number;
              name: string;
              value?: number | undefined;
            };
            startTime: string;
            endTime: string;
            type: AgendaBlockType,
            index: number,
          } = currentItem;

          newItem = {...newItem, index: agendaBlockId}

          if (currentTab?.type === AgendaBlockType.Break) {
            newItem = {...newItem, icon: clockPlusIcon}
          };
      
          if (currentTab?.type === AgendaBlockType.Meal) {
            newItem = {...newItem, icon: happyFaceIcon}
          };
    
          if (currentTab?.type === AgendaBlockType.Session) {
            newItem = {...newItem, icon: presentationIcon}
          };
    
          newItem = {...newItem, name: sessionName?.length ? sessionName : currentItem.name};
    
          newItem = {...newItem, description: sessionDescription};
      
          if (!isEdit) {
            newItem = {...newItem, startTime: agenda.length === 0 ? selectedStartTime.name : agenda[agenda.length - 1].endTime};
          }
    
          if (isEdit) {
            newItem = {...newItem, startTime: agenda.find(item => item.id === newItem.id)!.startTime};
          }
    
          newItem = {...newItem, type: currentTab?.type || AgendaBlockType.Session}
      
          let endHour;
    
          let endMinutes = 0;
      
          if (selectedDuration) {
            newItem = {...newItem, duration: selectedDuration};
      
            if (selectedDuration.value! >= 60) {
              endHour = +newItem.startTime.split(':')[0] + (Math.floor(selectedDuration.value! / 60));
    
              endMinutes = selectedDuration.value! + +newItem.startTime.split(':')[1].slice(0, 2) - ((endHour - +newItem.startTime.split(':')[0]) * 60);
    
              if (endMinutes >= 60) {
                endHour = endHour + (Math.floor(endMinutes / 60));
                endMinutes = endMinutes - ((Math.floor(endMinutes / 60)) * 60)
              }
            } else {
              endHour = +newItem.startTime.split(':')[0];
              endMinutes = selectedDuration.value! + +newItem.startTime.split(':')[1].slice(0, 2);
    
              if (endMinutes >= 60) {
                endHour = endHour + (Math.floor(endMinutes / 60));
                endMinutes = endMinutes - ((Math.floor(endMinutes / 60)) * 60)
              }
            }
          }
      
          newItem = {...newItem, endTime: `${endHour}:${endMinutes! > 0 ? endMinutes : '00'} AM`};
      
          if (isEdit) {
            const indexOfEditItem = agenda.findIndex(item => item.id === newItem.id);
            
            setAgenda(prevState => [
              ...prevState.filter(agendaBlock => agendaBlock.id !== newItem.id).slice(0, indexOfEditItem), 
              newItem, 
              ...prevState.filter(agendaBlock => agendaBlock.id !== newItem.id).slice(indexOfEditItem)
            ]);
    
            setIsEdit(false);
          }
    
          if (!isEdit) {
            const newAgendaItemId = crypto.randomUUID();

            setAgenda(prevState => [...prevState, {...newItem, id: newAgendaItemId}]);
            let orderedMeals: {
              customizations?: [
                {
                  customizationId: string,
                  optionId: string,
                  markedPrice: number
                }
              ],
              productId: string,
              markedPrice: number,
              notes: string,
            }[] = [];

            menu.map(category => {
              const ordersFromCategory = category.menu_item_list.map(menuItem => ({
                customizations: undefined,
                productId: menuItem.product_id,
                markedPrice: menuItem.price,
                notes: ''
              }));

              orderedMeals = [...orderedMeals, ...ordersFromCategory]
            });

            if (currentTab?.type === AgendaBlockType.Meal && orderedMeals.length === 0) {
              setMealBlocksWithoutMealsSelection(prevState => [...prevState, newAgendaItemId])
            }
            
            if (selectedRestaurant) {
              const order = {
                restaurantId: selectedRestaurant?.id,
                mealAgendaItemId: newAgendaItemId,
                orderedMeals: orderedMeals
              };

              setMealSelectedOrders(prevState => [...prevState, order])
            }
          }
        }
          setIsOpen(false);
  
        setIsAdd(false);
      }
    }
  }, [agendaNameError, agendaDescriptionError, agendaDurationError, isAdd]);

  const onAddItem = () => {
    setIsAdd(true);

    if (!sessionName?.trim().length) {
      setAgendaNameError(true);
    };

    if (sessionName?.trim().length) {
      setAgendaNameError(false);
    }

    if (currentTab?.name === 'Session') {
      if (!sessionDescription.trim().length) {
        setAgendaDescriptionError(true);
      }

      if (sessionDescription.trim().length) {
        setAgendaDescriptionError(false);
      }
    };

    if (!selectedDuration) {
      setAgendaDurationError(true);
    }

    if (selectedDuration) {
      setAgendaDurationError(false);
    }
  }

  return (
    <div className='modalNotification'>
      {
        createPortal(<SelectMeal 
          setOrderedMeals={setOrderedMeals} 
          orderedMeals={orderedMeals} 
          showSelectRestaurantModal={showSelectRestaurantModal} 
          agendaBlockStartTime={getTimeInMinutes(agenda.length === 0 ? selectedStartTime!.name : agenda[agenda.length - 1].endTime)} 
          dayId={dayId} 
          setCreatorIsOrder={setCreatorIsOrder} 
          creatorIsOrder={creatorIsOrder} 
          onAddBlock={onAddItem} 
          location={location} 
          latitude={latitude} 
          longitude={longitude} 
          setSelectedRestaurant={setSelectedRestaurant} 
          selectedRestaurant={selectedRestaurant} 
          setShowSelectRestaurantModal={setShowSelectRestaurantModal} 
          setIsPickup={setIsPickup}
          isPickup={isPickup}
          setOrders={setOrders}
          orders={orders.filter(item => item.agendaBlockId === agendaBlockId) || []}
          selectedAvailableMealsForAttendees={selectedAvailableMealsForAttendees}
          setSelectedAvailableMealsForAttendees={setSelectedAvailableMealsForAttendees}
          agendaBlockId={agendaBlockId}
          restaurantsForOrders={restaurantsForOrders}
          setRestaurantsForOrders={setRestaurantsForOrders} 
        />, document.getElementById('modal') as HTMLElement) 
      }
      <div className='modalNotification-container modalNotification-container-agenda'>
        <div className='modalNotification-circles-container'>
        <div className='modalNotification-circles'>
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className='modalNotification-header'>
          <div className='modalNotification-header-actions'>
            <FeaturedIcon icon={announcementIcon} size='lg' type='gray' />
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={() => {
                setIsOpen(false)
                setIsEdit(false);
              }}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>Add agenda block</h3>
          </div>
        </div>
        <div className='modalNotification-main modalNotification-main-agenda'>
          {/* <Tabs 
            tabs={tabs}
            current={currentTab}
            setCurrent={setCurrentTab}
          /> */}
          <Dropdown reactToFocus height='auto' content={tabs} currentItem={currentTab} setCurrentItem={setCurrentTab} withBorder label='Agenda type*' id='agendaCreate' />
          {
            currentTab
              ? <>
                  <FormInput 
                    label='Name*'
                    placeholder='Session name'
                    type='Text'
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSessionName(event.target.value)}
                    value={sessionName}
                    isError={agendaNameError}
                  />
                  {
                    currentTab?.name === 'Session'
                      ? <FormInput 
                          label='Description*'
                          placeholder='e.g. Lorem ipsum'
                          type='TextArea'
                          value={sessionDescription}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSessionDescription(event.target.value)}
                          isError={agendaDescriptionError}
                        />
                      : null
                  }
                  <Button
                    buttonType='regular'
                    size='md'
                    hierarchy={agendaDurationError ? 'errorSecondaryGray' : 'secondaryGray'}
                    onClick={() => setShowSelectDuration((prevState) => !prevState)}
                    fullWidth
                  >
                    <div className={`openButton ${selectedDuration ? '' : 'openButton-color-gray openButton-width-content'} text-sm`}>
                      <img src={clockIcon} alt="clock icon" className='calendarIcon' />
                      <p>{selectedDuration === null ? 'Duration*' : `${selectedDuration.name}`}</p>
                    </div>
                  </Button>
                  {
                    showSelectDuration 
                      ? 
                          <DropdownMenu 
                            content={durations}
                            selectedItem={selectedDuration}
                            setSelectedItem={setSelectedDuration}
                            heightSize='sm'
                            size='lg'
                            positionBottom={currentTab?.type === AgendaBlockType.Session ? '-44%' : '-62%'}
                            setShow={() => setShowSelectDuration(prevState => !prevState)}
                            reactToFocus
                          />
                      : null
                  }
                  {
                    currentTab.type === AgendaBlockType.Meal && selectedDuration && sessionName
                      ? !selectedRestaurant
                          ? <Button
                              buttonType='regular'
                              size='md'
                              hierarchy='linkColor'
                              onClick={() => setShowSelectRestaurantModal(true)}
                              fullWidth
                              paddingNone
                            >
                              <div className={`openButton ${selectedDuration ? '' : 'openButton-width-content'} text-sm`}>
                              <img src={plusCircle} alt="chevron down icon" className='calendarIcon' />
                                <p>Add restaurant</p>
                              </div>
                            </Button>
                          : <div className='flex flex-column gap-4'>
                              <p className="text-sm font-medium">Restaurant</p>
                              <div className={`dropdown dropdown-withBorder`} onClick={() => setShowSelectRestaurantModal(true)}>
                                <h1 className='text-md font-regular'>
                                  {
                                    selectedRestaurant
                                      ? selectedRestaurant.name
                                      : null
                                  }
                                </h1>
                                <img src={pencilIcon} alt="arrow down" />
                              </div>
                            </div>
                      : null
                  }
                </>
              : null
          }
        </div>
        <div className='modalNotification-actions modalNotification-actions-agenda' style={{justifyContent: 'end'}}>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            onClick={() => {
              onAddItem()
            }}
          >
            {isEdit ? 'Save' : 'Add block'}
          </Button>
        </div>
      </div>
    </div>
  )
}
