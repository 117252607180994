import { Button } from "../Buttons/Button";
import DatePicker from "./DateCalendar";
import { useState } from 'react';
import moment from "moment";
import DatePickerSmall from "./DatePickerSmall";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { endOfYear, startOfYear } from "date-fns";

interface Props {
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  firstDate: moment.Moment | null,
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  secondDate?: moment.Moment | null,
  setSecondDate?: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  type: 'double' | 'small' | 'smallWithTodayFeat',
  isModal?: boolean,
  top?: string | null,
  bottom?: string | null,
  right?: string | null,
  left?: string | null,
  selectedDayOnlyFromToday: boolean,
  onApply?: () => void;
  selectSecondDateFrom?: moment.Moment | null,
}

export default function DatePickerComponent(
  { 
    isOpen, 
    setIsOpen, 
    setFirstDate, 
    setSecondDate, 
    firstDate, 
    secondDate,
    type,
    isModal,
    bottom,
    left,
    right,
    top, 
    selectedDayOnlyFromToday,
    onApply,
    selectSecondDateFrom
  }: Props) {
  const [selectedTimeButton, setSelectedTimeButton] = useState<string | null>(null);

  const buttonClickHandler = (name: string) => {
    setSelectedTimeButton(name);
  }  

  return (
    <div
      className={`largeDatePicker ${isOpen ? 'largeDatePicker-open' : ''} ${isModal ? 'largeDatePicker-modal' : ''}`}
      style={{bottom: `${bottom}`, top: `${top}`, left: `${left}`, right: `${right}`}}
    >
      {type === 'double' && setSecondDate
        ? <div className="dayPicker">
            <button
              className={`dayPicker-button ${selectedTimeButton === 'Today' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                const today = new Date();
                buttonClickHandler('Today');
                setFirstDate(moment(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)));
                setSecondDate(moment(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)))
              }}
            >
              Today
            </button>
            <button
              className={`dayPicker-button ${selectedTimeButton === 'Yesterday' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                buttonClickHandler('Yesterday');
                const today = new Date();
                setFirstDate(moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0, 0)));
                setSecondDate(moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0, 0)))
              }}
            >
              Yesterday
            </button>
            <button 
              className={`dayPicker-button ${selectedTimeButton === 'This week' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                buttonClickHandler('This week');
                const today = new Date();
                const startDate = startOfWeek(today, { weekStartsOn: 1 });
                const endDate = endOfWeek(today, { weekStartsOn: 1 });

                setFirstDate(moment(startDate));
                setSecondDate(moment(endDate))
              }}
            >
              This week
            </button>
            <button
              className={`dayPicker-button ${selectedTimeButton === 'Last week' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                buttonClickHandler('Last week');
                const today = new Date();
                const previousWeekDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0, 0);
                const startDate = startOfWeek(previousWeekDay, { weekStartsOn: 1 });
                const endDate = endOfWeek(previousWeekDay, { weekStartsOn: 1 });
                setFirstDate(moment(startDate));
                setSecondDate(moment(endDate))
              }}
            >
              Last week
            </button>
            <button 
              className={`dayPicker-button ${selectedTimeButton === 'This month' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                buttonClickHandler('This month');
                const today = new Date();
                const monthStart = startOfMonth(today);
                const monthEnd = endOfMonth(monthStart);
                setFirstDate(moment(monthStart));
                setSecondDate(moment(monthEnd))
              }}
            >
              This month
            </button>
            <button 
              className={`dayPicker-button ${selectedTimeButton === 'Last month' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                buttonClickHandler('Last month');

                const today = new Date();
                const previousWeekDay = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0, 0);
                const monthStart = startOfMonth(previousWeekDay);
                const monthEnd = endOfMonth(monthStart);;
                
                setFirstDate(moment(monthStart));
                setSecondDate(moment(monthEnd))
              }}
            >
              Last month
            </button>
            <button 
              className={`dayPicker-button ${selectedTimeButton === 'This year' ? 'dayPicker-button-selected' : ''}`}
              onClick={() => {
                buttonClickHandler('This year');
                const today = new Date();
                const monthStart = startOfYear(today);
                const monthEnd = endOfYear(monthStart);
                setFirstDate(moment(monthStart));
                setSecondDate(moment(monthEnd));
              }}
            >
              This year
            </button>
            <button 
              className={`dayPicker-button ${selectedTimeButton === 'Last year' ? 'dayPicker-button-selected' : ''}`} 
              onClick={() => {
                buttonClickHandler('Last year');

                const today = new Date();
                const previousWeekDay = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate(), 0, 0, 0, 0);
                const monthStart = startOfYear(previousWeekDay);
                const monthEnd = endOfYear(monthStart);;
                
                setFirstDate(moment(monthStart));
                setSecondDate(moment(monthEnd))
              }}
            >
              Last year
            </button>
            <button 
              className={`dayPicker-button ${selectedTimeButton === 'All time' ? 'dayPicker-button-selected' : ''}`} 
              onClick={() => buttonClickHandler('All time')}
            >
              All time
            </button>
          </div>
        : null
      }
      <div>
        <div className="datePickersContainer">
        {type === 'double' && secondDate !== undefined && setSecondDate
          ? <>
              <div className="datePicker datePicker-first">
                <DatePicker
                  pickedDate={firstDate}
                  setPickedDate={setFirstDate}
                  selectedDayOnlyFromToday={selectedDayOnlyFromToday}
                />
              </div>
              <div className="datePicker">
                <DatePicker
                  pickedDate={secondDate}
                  setPickedDate={setSecondDate}
                  selectedDayOnlyFromToday={selectedDayOnlyFromToday}
                  selectSecondDateFrom={selectSecondDateFrom}
                />
              </div>
            </>
          : <DatePickerSmall
              pickedDate={firstDate}
              setPickedDate={setFirstDate}
              selectedDayOnlyFromToday={selectedDayOnlyFromToday}
            />
        }
        </div>
        <div className="datePicker-footer">
          <div className={`flex items-center ${type === 'small' || type === 'smallWithTodayFeat' ? 'gap-8 calendarDates fullWidth' : 'gap-12'}`}>
            { type === 'smallWithTodayFeat'
              ? <>
                  <p className="input calendarDateInfo calendarDateInfo-lg">{firstDate !== null ? firstDate?.format('ll') : 'Select date'}</p>
                  <Button
                    buttonType='regular'
                    hierarchy='secondaryGray'
                    size='md'
                    onClick={() => setFirstDate(moment().set('hour', 0).set('minute', 0).set('second', 0))}
                  >
                    Today
                  </Button>
                </>
              : <>
                  <p className="input calendarDateInfo">{firstDate !== null ? firstDate?.format('ll') : 'Select'}</p>
                  <p className="datePickerMinus">-</p>
                  <p className="input calendarDateInfo">{secondDate !== null ? secondDate?.format('ll') : 'Select'}</p>
                </>
            }
          </div>
          <div className={`flex items-center gap-12 ${type === 'small' || type === 'smallWithTodayFeat' ? 'fullWidth' : ''}`}>
            <Button
              buttonType='regular'
              hierarchy='secondaryGray'
              size='md'
              onClick={
                setIsOpen 
                  ? () => {
                      setFirstDate(null);
                      setSecondDate && setSecondDate(null);
                      setIsOpen(false);
                    } 
                  : () => {}
              }
              fullWidth
              contentCenter
            >
              Cancel
            </Button>
            <Button
              buttonType='regular'
              hierarchy='primary'
              size='md'
              fullWidth
              onClick={() => {
                setIsOpen && setIsOpen(false);
                onApply && onApply();
              }}
              contentCenter
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
