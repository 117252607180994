import React, { useEffect, useState } from 'react';
import arrowLeft from '../../assets/images/chevron-left-black.svg';
import arrowRight from '../../assets/images/chevron-right-black.svg';
import CustomTabs from '../../components/Tabs/CustomTabs';
import CalendarSchedule from '../../components/CalendarSchedule/CalendarSchedule';
import addMonth from 'date-fns/addMonths';
import subMonth from 'date-fns/subMonths';
import { EventsFilter } from '../../types/eventsFilter';
import { addDays, addWeeks, subDays, subWeeks } from 'date-fns';
import moment from 'moment';
import MonthCalendar from '../../components/CalendarSchedule/MonthCalendar';
import MobileCalendar from './MobileCalendar';

export default function EventsCalendarPage({ filter, sizeIsDesktop, contentIsScrolled }: { filter: EventsFilter, sizeIsDesktop: boolean, contentIsScrolled: boolean }) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentThirdTab, setCurrentThirdTab] = useState<number>(1);
  const [currentSecondTab, setCurrentSecondTab] = useState<{id: number, name: string} | null>({id: 1, name: 'month'});
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
      
  return (
      <>
        {
          sizeIsDesktop
            ? <div className='flex spaceBetween items-center'>
                <CustomTabs 
                  current={currentSecondTab}
              setCurrent={setCurrentSecondTab}
              tabs={[
                {id: 1, name: 'month'}, 
                {id: 2, name: 'week', onSelect: () => setCurrentMonth(new Date())}, 
                  {id: 3, name: 'day', onSelect: () => setCurrentMonth(new Date(moment().utcOffset(0, true).year(), moment().utcOffset(0, true).month(), moment().utcOffset(0, true).date(), 0, 0, 0, 0))}]}
                />
                <div className='flex items-center gap-12'>
              <div className='customTabs'>
                <div 
                  className={`customTabs-tab-first customTabs-tab-withBorderRight customTabs-tab ${currentThirdTab === 1 ? 'customTabs-tab-selected' : ''}`}
                  onClick={() => {
                    const newDate = currentSecondTab?.name === 'week' ? subWeeks(currentMonth, 1) : currentSecondTab?.name === 'month' ? subMonth(currentMonth, 1) : subDays(currentMonth, 1)
                    setCurrentThirdTab(1);
                    setCurrentMonth(newDate);
                  }}
                >
                  <img src={arrowLeft} alt="" />
                </div>
                <div 
                  className={`customTabs-tab-last customTabs-tab ${currentThirdTab === 2 ? 'customTabs-tab-selected' : ''}`}
                  onClick={() => {
                    const newDate = currentSecondTab?.name === 'week' ? addWeeks(currentMonth, 1) : currentSecondTab?.name === 'month' ? addMonth(currentMonth, 1) : addDays(currentMonth, 1)
                    setCurrentThirdTab(2);
                    setCurrentMonth(newDate)
                  }}
                >
                  <img src={arrowRight} alt="" />
                </div>
              </div>
                </div>
              </div>
            : null
        }
        {
          sizeIsDesktop
            ? <CalendarSchedule currentMonth={currentMonth} filter={filter} currentTab={currentSecondTab!.name === 'month' ? 'month' : currentSecondTab!.name === 'week' ? 'week' : 'day'} />
            : <MobileCalendar year={currentYear} setCurrentYear={setCurrentYear} contentIsScrolled={contentIsScrolled} />
        }
      </>
  )
}
