import React, { useEffect, useState } from 'react';
import menuIcon from '../../assets/images/menu-05.svg';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import trashIcon from '../../assets/images/trash-01-black.svg';
import editIcon from '../../assets/images/edit-01.svg';
import { Button } from '../Buttons/Button'
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import { useWindowSize } from '@uidotdev/usehooks';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import DotsModal from '../Modal/DotsModal';
import editDotsIcon from '../../assets/images/edit-01.svg';
import deleteIcon from '../../assets/images/trash-01-black.svg';
import { AgendaBlockType } from '../../types/agendaBlockType';
import alertIcon from '../../assets/images/alert-triangle.svg';

interface Props {
  id: string,
  icon: string,
  startTime: string,
  endTime: string,
  name: string,
  duration: {
    id: number;
    name: string;
    value?: number | undefined;
  },
  index: number,
  description: string,
  type: AgendaBlockType,
  setAgenda?: React.Dispatch<React.SetStateAction<{
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number
  }[]>>,
  hasEditActions?: boolean,
  hasDragElement?: boolean,
  setShowAgendaModal?: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentItem?: React.Dispatch<React.SetStateAction<{
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
        id: number;
        name: string;
        value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number,
  } | undefined>>,
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined,
  isReview?: boolean,
  height: 'full' | 'max',
  isAgendaMealBlockWithoutMeals?: boolean,
}

export default function AgendaItem({ 
  id, 
  icon, 
  startTime, 
  endTime, 
  name, 
  index,
  duration, 
  description, 
  type, 
  setAgenda, 
  setShowAgendaModal, 
  setCurrentItem,
  dragHandleProps,
  hasDragElement,
  hasEditActions,
  isReview,
  height,
  isAgendaMealBlockWithoutMeals
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [showDotsModal, setShowDotsModal] = useState<boolean>(false);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 
  return (
    <div className={`agendaItem ${isReview ? 'reviewAgendaModalBlock' : ''} ${height === 'max' ? 'agendaItem-maxHeight' : ''}`}>
      <div className={`agendaItem-timeInfo ${isReview ? 'reviewAgendaModalBlock-timeInfo' : ''}`}>
        <div className='agendaItem-timeInfo-container'>
          <h3 className='agendaItem-timeInfo-startTime'>{startTime}</h3>
          <p className='agendaItem-timeInfo-endTime'>{endTime}</p>
        </div>
        <div 
          className={`
            agendaItem-timeInfo-rectangle 
            ${type === AgendaBlockType.Meal ? 'agendaItem-timeInfo-rectangle-meal' : ''}
            ${type === AgendaBlockType.Break ? 'agendaItem-timeInfo-rectangle-break' : ''}
          `}
        />
      </div>
      {
        isAgendaMealBlockWithoutMeals
          ? <div className='fullWidth'>
              <div className='agendaItem-warning'>
                <img src={alertIcon} alt="" className='agendaItem-warning-image' />
                <h3 className='agendaItem-warning-text'>No meals selected</h3>
              </div>
              <div className={`agendaItem-info agendaItem-info-withWarning ${isReview ? 'reviewAgendaModalBlock-info' : ''}`}>
                {
                  hasDragElement
                    ? <div {...dragHandleProps} className='agendaItem-info-dragBtn'>
                        <Button
                          size='md'
                          buttonType='small'
                          hierarchy='tertiaryGray'
                          buttonImage={menuIcon}
                          pointerEventsNone
                          disabled
                        />
                      </div>
                    : null
                }
                {showDotsModal && !sizeIsDesktop
                  ? <DotsModal 
                    items={[
                      {id: 1, img: editDotsIcon, text: 'Edit', onClick: () => {
                        setShowAgendaModal && setShowAgendaModal(true);
                        setCurrentItem && setCurrentItem({
                          id,
                          description,
                          duration,
                          endTime,
                          icon,
                          name,
                          startTime,
                          type,
                          index
                        })
                      }}, 
                      {id: 2, img: deleteIcon, text: 'Delete', onClick: () => setAgenda && setAgenda(prevState => prevState.filter(item => item.id !== id))}
                    ]}
                    setIsOpen={setShowDotsModal}
                    top='60px'
                    right='0'
                  />
                  : null
                }
                <div className='agendaItem-info-title-wrapper'>
                  <div className='agendaItem-info-title'>
                    <div 
                      className={`
                        agendaItem-info-title-icon 
                        ${type === AgendaBlockType.Break
                          ? 'agendaItem-info-title-icon-break' 
                          : (type === AgendaBlockType.Meal
                              ? 'agendaItem-info-title-icon-meal' 
                              : '')
                        }
                      `}
                    >
                      <FeaturedIcon
                        icon={icon}
                        type={type === AgendaBlockType.Session ? 'primary' : (type === AgendaBlockType.Break ? 'gray' : 'warning')}
                        size='sm'
                      />
                    </div>
                    <h3 className='agendaItem-info-title-text'>{name}</h3>
                    <p className='agendaItem-info-title-duration'>{duration.name}</p>
                  </div>
                  <p className='agendaItem-info-description'>{description}</p>
                </div>
                {
                  hasEditActions
                    ? <>
                        {!sizeIsDesktop
                          ? <Button
                              size='md'
                              buttonType='small'
                              hierarchy='tertiaryGray'
                              buttonImage={dotsIcon}
                              onClick={() => setShowDotsModal(prev => !prev)}
                            />
                          : null
                        }
        
                        {sizeIsDesktop
                          ? <div className='agendaItem-info-actions'>
                              <Button
                                size='md'
                                buttonType='small'
                                hierarchy='tertiaryGray'
                                buttonImage={trashIcon}
                                onClick={() => setAgenda && setAgenda(prevState => prevState.filter(item => item.id !== id))}
                              /> 
                              <Button
                                size='md'
                                buttonType='small'
                                hierarchy='tertiaryGray'
                                buttonImage={editIcon}
                                onClick={() => {
                                  setShowAgendaModal && setShowAgendaModal(true);
                                  setCurrentItem && setCurrentItem({
                                    id,
                                    description,
                                    duration,
                                    endTime,
                                    icon,
                                    name,
                                    startTime,
                                    type,
                                    index
                                  })
                                }}
                              /> 
                            </div>
                          : null
                        }
                      </>
                    : null
                }
              </div>
            </div>
          : <div className={`agendaItem-info ${isReview ? 'reviewAgendaModalBlock-info' : ''}`}>
              {
                hasDragElement
                  ? <div {...dragHandleProps} className='agendaItem-info-dragBtn'>
                      <Button
                        size='md'
                        buttonType='small'
                        hierarchy='tertiaryGray'
                        buttonImage={menuIcon}
                        pointerEventsNone
                        disabled
                      />
                    </div>
                  : null
              }
              {showDotsModal && !sizeIsDesktop
                ? <DotsModal 
                  items={[
                    {id: 1, img: editDotsIcon, text: 'Edit', onClick: () => {
                      setShowAgendaModal && setShowAgendaModal(true);
                      setCurrentItem && setCurrentItem({
                        id,
                        description,
                        duration,
                        endTime,
                        icon,
                        name,
                        startTime,
                        type,
                        index
                      })
                    }}, 
                    {id: 2, img: deleteIcon, text: 'Delete', onClick: () => setAgenda && setAgenda(prevState => prevState.filter(item => item.id !== id))}
                  ]}
                  setIsOpen={setShowDotsModal}
                  top='60px'
                  right='0'
                />
                : null
              }
              <div className='agendaItem-info-title-wrapper'>
                <div className='agendaItem-info-title'>
                  <div 
                    className={`
                      agendaItem-info-title-icon 
                      ${type === AgendaBlockType.Break
                        ? 'agendaItem-info-title-icon-break' 
                        : (type === AgendaBlockType.Meal
                            ? 'agendaItem-info-title-icon-meal' 
                            : '')
                      }
                    `}
                  >
                    <FeaturedIcon
                      icon={icon}
                      type={type === AgendaBlockType.Session ? 'primary' : (type === AgendaBlockType.Break ? 'gray' : 'warning')}
                      size='sm'
                    />
                  </div>
                  <h3 className='agendaItem-info-title-text'>{name}</h3>
                  <p className='agendaItem-info-title-duration'>{duration.name}</p>
                </div>
                <p className='agendaItem-info-description'>{description}</p>
              </div>
              {
                hasEditActions
                  ? <>
                      {!sizeIsDesktop
                        ? <Button
                            size='md'
                            buttonType='small'
                            hierarchy='tertiaryGray'
                            buttonImage={dotsIcon}
                            onClick={() => setShowDotsModal(prev => !prev)}
                          />
                        : null
                      }
      
                      {sizeIsDesktop
                        ? <div className='agendaItem-info-actions'>
                            <Button
                              size='md'
                              buttonType='small'
                              hierarchy='tertiaryGray'
                              buttonImage={trashIcon}
                              onClick={() => setAgenda && setAgenda(prevState => prevState.filter(item => item.id !== id))}
                            /> 
                            <Button
                              size='md'
                              buttonType='small'
                              hierarchy='tertiaryGray'
                              buttonImage={editIcon}
                              onClick={() => {
                                setShowAgendaModal && setShowAgendaModal(true);
                                setCurrentItem && setCurrentItem({
                                  id,
                                  description,
                                  duration,
                                  endTime,
                                  icon,
                                  name,
                                  startTime,
                                  type,
                                  index
                                })
                              }}
                            /> 
                          </div>
                        : null
                      }
                    </>
                  : null
              }
            </div>
      }
    </div>
  )
}
