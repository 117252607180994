import { AgendaBlockType } from "../types/agendaBlockType";

export function getItemBeforeEndTime(
    endTime: {
      id: number;
      name: string;
    }, 
    agenda: {
      id: string;
      icon: string;
      name: string;
      description: string;
      duration: {
          id: number;
          name: string;
          value?: number | undefined;
      };
      startTime: string;
      endTime: string;
      type: AgendaBlockType;
    }[],
    setAgendaExtendsBeyondEndTime: React.Dispatch<React.SetStateAction<boolean>>,
    setItemBeforeEndTime: React.Dispatch<React.SetStateAction<{
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
            id: number;
            name: string;
            value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
    } | null>>
  ) {
    if (!!endTime) {
      const endTimeHour = endTime.name.split(':')[1].slice(-2) === 'PM' &&  +endTime.name.split(':')[0] !== 12 ? +endTime.name.split(':')[0] + 12 : +endTime.name.split(':')[0];

      if (agenda.length) {
        const itemEndTimeHour = agenda[agenda.length - 1].endTime.split(':')[1].slice(-2) === 'PM' &&  +agenda[agenda.length - 1].endTime.split(':')[0] !== 12 ? +agenda[agenda.length - 1].endTime.split(':')[0] + 12 : +agenda[agenda.length - 1].endTime.split(':')[0];

        if (itemEndTimeHour > endTimeHour || 
            (
              +agenda[agenda.length - 1].endTime.split(':')[1].slice(0, 2) > +endTime.name.split(':')[1].slice(0, 2) &&
              itemEndTimeHour === endTimeHour
            )
          ) {
            setAgendaExtendsBeyondEndTime(true);
        }
      }

      for (let i = 0; i < agenda.length; i++) {
        const item = agenda[i];
        const itemEndTimeHour = item.endTime.split(':')[1].slice(-2) === 'PM' &&  +item.endTime.split(':')[0] !== 12 ? +item.endTime.split(':')[0] + 12 : +item.endTime.split(':')[0];
        const itemStartTimeHour = item.startTime.split(':')[1].slice(-2) === 'PM' &&  +item.startTime.split(':')[0] !== 12 ? +item.startTime.split(':')[0] + 12 : +item.startTime.split(':')[0];

        if (
          itemStartTimeHour > endTimeHour ||
          (
            itemStartTimeHour === endTimeHour &&
            +item.startTime.split(':')[1].slice(0, 2) >= +endTime.name.split(':')[1].slice(0, 2)
          )
        ) {
          setAgendaExtendsBeyondEndTime(true);
          break;
        }

        if (
            (i + 1 === agenda.length 
              && itemEndTimeHour <= endTimeHour
              && +item.endTime.split(':')[1].slice(0, 2) <= +endTime.name.split(':')[1].slice(0, 2)
            )  || 
            itemEndTimeHour <= endTimeHour
        ) {
          setItemBeforeEndTime(item);
        }
      }
    }

    return;
  }