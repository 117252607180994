import searchIcon from '../../assets/images/search-lg.svg';
import searchIconGray from '../../assets/images/search-lg-gray.svg';
import helpIcon from '../../assets/images/help-circle.svg';
import errorIcon from '../../assets/images/alert-circle.svg';
import searchIconGrayLight from '../../assets/images/search-lg-gray-300.svg';
import { useEffect, useRef } from 'react';

interface Props {
  placeholder: string,
  type: 'primary' | 'gray' | 'dark',
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isError?: boolean,
  hasHelpIcon?: boolean,
  value?: string,
  border?: 'full' | 'left' | 'right' | 'bottom' | 'top' | 'none',
  hasIconBefore?: boolean,
  fullWidth?: boolean,
  setShow?: () => void,
  onFocus?: () => void
}

export function SearchInput({ placeholder, type, onChange, isError, onFocus, hasHelpIcon, value, border, hasIconBefore, fullWidth, setShow }: Props) {
  return (
    <div 
      autoFocus
      onBlur={setShow}
      onFocus={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onFocus && onFocus()
      }}
      className={`
        input 
        ${isError ? 'input-error' : ''} 
        ${type === 'primary' ? 'input-search-primary' : ''}
        ${type === 'gray' ? 'input-search-gray' : ''}
        ${type === 'dark' ? 'input-search-dark' : ''}
        ${border !== 'full' ? 'border-input' : ''}
        ${border !== 'full' && border === 'right' ? 'border-input-right' : ''}
        ${border !== 'full' && border === 'left' ? 'border-input-left' : ''}
        ${border !== 'full' && border === 'top' ? 'border-input-top' : ''}
        ${border !== 'full' && border === 'bottom' ? 'border-input-bottom' : ''}
        ${fullWidth ? 'fullWidth' : ''}
      `}
      tabIndex={0}
    >
      <div className='inputWrapper'>
        {hasIconBefore ? <img src={type === 'primary' ? searchIcon : (type === 'gray' ? searchIconGray : searchIconGrayLight)} alt="search icon" /> : null}
        <input
          type='text'
          placeholder={placeholder}
          className="text-md"
          onChange={onChange}
          value={value}
          tabIndex={1}
        />
      </div>
      {hasHelpIcon ? <img src={isError ? errorIcon : helpIcon} alt="help" /> : null}
    </div>
  )
}
