import React from 'react'
import { postMealsSelections } from '../../../httpQueries/http'
import arrowLeft from '../../../assets/images/arrow-narrow-left.svg';
import { Button } from '../../../components/Buttons/Button';
import Loader from '../../../components/Loader';
import MenuItem from './MenuItem';

interface Props {
  menu: {
    count: number;
    name: string;
    price: number;
    qty_available: null;
    unit_size: null;
    unit_of_measurement: string;
    description: string;
    is_available: boolean;
    image: string;
    customizations: [
        {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
                {
                    name: string;
                    price: number;
                    customizations: any[];
                    min_qty: number;
                    max_qty: number;
                    conditional_price: {};
                    formatted_price: string;
                    default_qty: number;
                    option_id: string;
                }
            ];
            customization_id: string;
        }
    ];
    min_price: number;
    original_price: number;
    formatted_price: string;
    attributes: [];
    product_id: string;
    thumbnail_image: string;
    should_fetch_customizations: boolean;
    supports_image_scaling: boolean;
  }[],
  preOrder: {
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  } | undefined,
  setPreOrder: React.Dispatch<React.SetStateAction<{
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  } | undefined>>,
  mealBlockId: number | undefined,
  setSelectMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  asyncOnAddMeal: () => Promise<void>
}

export default function RestaurantMenu({ 
  menu, 
  preOrder,
  setPreOrder,
  mealBlockId,
  setSelectMealsModalIsOpen,
  asyncOnAddMeal
}: Props) {
  const token = localStorage.getItem('token');

  return (
    <div className='restaurantMenu'>
      <div className='selectRestaurant-main'>
        <header className='selectRestaurant-main-header'>
          <div className='selectRestaurant-main-header-circles'>
            <div className="eventDetails_circles">
              <div className="eventDetails-circle eventDetails-circle-fifth">
                <div className="eventDetails-circle eventDetails-circle-fourth">
                  <div className="eventDetails-circle eventDetails-circle-third">
                    <div className="eventDetails-circle eventDetails-circle-second">
                      <div className="eventDetails-circle eventDetails-circle-first">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: 'relative', zIndex: '30' }}>
            <Button 
              size='lg' 
              hierarchy={'secondaryGray'}
              buttonType='small'
              onClick={(e) => {
                e.stopPropagation();
                setSelectMealsModalIsOpen(false)
              }}
              buttonImage={arrowLeft}
            />
          </div>
          <div className='selectRestaurant-main-header-info'>
            <h3 className='selectRestaurant-main-header-info-text'>Select meals</h3>
          </div>
        </header>
        <main className='restaurantMenu-main'>
          {
            menu.length > 0
              ? menu.map(menuItem => {
                  return <MenuItem 
                    name={menuItem.name} 
                    description={menuItem.description} 
                    price={menuItem.formatted_price} 
                    image={menuItem.image} 
                    productId={menuItem.product_id} 
                    product={menuItem} 
                    onDescrease={() => {
                        setPreOrder(prevState => {
                          if (prevState) {
                            return {
                              ...prevState,
                              count: prevState.count - 1
                            }
                          }
                        })
                    }} 
                    onIncrease={() => {
                        setPreOrder(prevState => {
                          if (prevState) {
                            return {
                              ...prevState,
                              count: prevState.count + 1
                            }
                          } else {
                            return {
                              customizations: [],
                              productId: menuItem.product_id,
                              markedPrice: menuItem.price,
                              notes: '',
                              count: 1,
                            }
                          }
                        })
                    }} 
                    onInputChange={async () => {
                        if (mealBlockId && preOrder) {
                          await postMealsSelections({
                            id: mealBlockId,
                            body: {
                              customizations: preOrder.customizations,
                              productId: preOrder.productId,
                              markedPrice: preOrder.markedPrice,
                              notes: preOrder.notes,
                            },
                            token
                          })
        
                          setSelectMealsModalIsOpen(false)
                        }
                    }}
                    preOrder={preOrder} 
                    setPreOrder={setPreOrder}                            
                  />
                })
              : <div className='flex items-center fullWidth justifyCenter'>
                  <Loader size='xl' />
                </div>
          }
        </main>
      </div>
      <footer className='selectRestaurant-footer restaurantMenu-footer'>
        <Button 
          size='lg' 
          hierarchy={'primary'}
          buttonType='regular'        
          asyncOnClick={async () => {
            await asyncOnAddMeal();
            setSelectMealsModalIsOpen(false)
          }}
        >
          Add selection
        </Button>
      </footer>
    </div>
  )
}
