import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const recentlyCreatedEvent = (state: TRootState) => (state.recentlyCreatedEvent);

const getRecentlyCreatedEvent = createSelector(
  [recentlyCreatedEvent],
  (recentlyCreatedEvent) => recentlyCreatedEvent
);

export { getRecentlyCreatedEvent }