import React from 'react';

interface Props {
  toggleOn: boolean,
  setToggleOn?: React.Dispatch<React.SetStateAction<boolean>>,
  onClick?: () => void
}

export default function Toggle({ toggleOn, setToggleOn, onClick }: Props) {
  return (
    <div className={`toggle ${toggleOn ? 'toggle-on' : ''}`} onClick={() => {
      setToggleOn && setToggleOn(prevState => !prevState);
      onClick && onClick()
    }}>
      <div className='toggle-circle'></div>
    </div>
  )
}
