import React, { useEffect } from 'react';
import fileTypePDF from '../../assets/images/fileTypeIconPdf.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchDocument } from '../../httpQueries/http';
import { redirect, useNavigate } from 'react-router-dom';
import { markTodoAsCompleted } from '../../utilities/markTodoAsCompleted';

export default function ReviewFileBase({ name, documentId, eventId }: { name: string, documentId: number, eventId: number }) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { data: document } = useQuery({
    queryKey: ['document', documentId, eventId, name],
    queryFn: () => fetchDocument({ documentId, id: eventId, token })
  });
  

  return (
    <div className='fileUploadBase items-center'>
      <img src={fileTypePDF} alt="" className='fileUploadBase-fileTypeIcon-sm' />
      <div className='fullWidth'>
        <a className='fileUploadBase-name-withAction' href={document?.url ? document.url : document?.document.url} target='_blank'>{name}</a>
      </div>
    </div>
  )
}
