import React, { Suspense } from "react";
import { SearchInput } from "./SearchInput";
import PasswordInput from "./PasswordInput";
import { User } from "../../types/userForAddType";
import InputWithActions from "./InputWithActions";

interface Props {
  isError?: boolean,
  label?: string,
  hintMessage?: string,
  errorMessage?: string,
  type: 
    'Card' | 
    'Email' | 
    'EmailWithIconBefore' | 
    'Phone' | 
    'Sale' | 
    'TextArea' | 
    'TextAreaWithAddFeat' | 
    'Website' | 
    'WebsiteWithCopyFeat' |
    'Users' |
    'Text' |
    'SearchPrimary' |
    'SearchGray' |
    'SearchDark' |
    'Password' |
    'InputWithActions'
  placeholder: string,
  disabled?: boolean,
  hasHelpIcon?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  value?: string,
  border?: 'full' | 'left' | 'right' | 'bottom' | 'top' | 'none',
  hasDropdown?: boolean,
  hasIconBefore?: boolean,
  fullWidth?: boolean,
  setShow?: () => void,
  onFocus?: () => void,
  websiteStartWith?: string,
  setUsersFromInput?: React.Dispatch<React.SetStateAction<User[]>>,
  usersFromInput?: User[],
  onAddNewItem?: (newItem: User) => void,
  onBlur?: () => void
}

const CardInput = React.lazy(() => import('./CardInput'));
const EmailInput = React.lazy(() => import("./EmailInput"));
const NumberInput = React.lazy(() => import("./NumberInput"));
const TextArea = React.lazy(() => import("./TextArea"));
const UsersInput = React.lazy(() => import("./UsersInput"));
const WebsiteInput = React.lazy(() => import("./WebsiteInput"));

export function FormInput({ 
  isError, 
  label, 
  hintMessage, 
  errorMessage, 
  type, 
  placeholder, 
  disabled, 
  hasHelpIcon, 
  onChange, 
  value, 
  border='full', 
  hasDropdown, 
  hasIconBefore, 
  fullWidth,
  setShow,
  onFocus,
  websiteStartWith,
  setUsersFromInput,
  usersFromInput,
  onAddNewItem,
  onBlur
}: Props) {
  function getInputType() {
    switch(type) {
      case "Card":
        return <CardInput 
          placeholder={placeholder} 
          isError={isError} 
          disabled={disabled}
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "Email":
        return <EmailInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          type='email'
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "EmailWithIconBefore":
        return <EmailInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          hasBeforeIcon
          type='email'
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "Phone":
        return <NumberInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          type="phoneNumber"
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          hasDropdown={hasDropdown}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "Sale":
        return <NumberInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          type='saleAmount'
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          hasDropdown={hasDropdown}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "TextArea":
        return <TextArea
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "TextAreaWithAddFeat":
        return <TextArea
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          withAddFeat
          onChange={onChange}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      case "Website":
        return <WebsiteInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
          websiteStartWith={websiteStartWith}
        />
      case "WebsiteWithCopyFeat":
        return <WebsiteInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          withCopyFeat
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
          websiteStartWith={websiteStartWith}
        />
      case "Users":
        return <UsersInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
          setUsersFromInput={setUsersFromInput!}
          usersFromInput={usersFromInput!}
        />

      case "Text":
        return <EmailInput
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          type='text'
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />

      case 'SearchPrimary':
        return <SearchInput
          placeholder={placeholder}
          type='primary'
          onChange={onChange}
          isError={isError}
          hasHelpIcon={hasHelpIcon}
          value={value}
          border={border}
          hasIconBefore={hasIconBefore}
          fullWidth={fullWidth}
          setShow={setShow}
          onFocus={onFocus}
        />

      case "SearchGray":
        return <SearchInput
          placeholder={placeholder}
          type='gray'
          onChange={onChange}
          isError={isError}
          hasHelpIcon={hasHelpIcon}
          value={value}
          border={border}
          hasIconBefore={hasIconBefore}
          fullWidth={fullWidth}
          setShow={setShow}
          onFocus={onFocus}
        />
      
      case "SearchDark":
        return <SearchInput
          placeholder={placeholder}
          type='dark'
          onChange={onChange}
          isError={isError}
          hasHelpIcon={hasHelpIcon}
          value={value}
          border={border}
          hasIconBefore={hasIconBefore}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />
      
      case "Password":
        return <PasswordInput
          placeholder={placeholder}
          onChange={onChange}
          isError={isError}
          hasHelpIcon={hasHelpIcon}
          value={value}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
        />

      case "InputWithActions":
        return <InputWithActions
          placeholder={placeholder} 
          isError={isError}
          disabled={disabled}
          hasHelpIcon={hasHelpIcon}
          onChange={onChange}
          fullWidth={fullWidth}
          onFocus={onFocus}
          setShow={setShow}
          setUsersFromInput={setUsersFromInput!}
          usersFromInput={usersFromInput!}
          onAddNewItem={onAddNewItem}
          onBlur={onBlur}
        />
    }
  }

  return (
    <form 
      className={`form ${isError ? 'form_error' : ''}`}
      onSubmit={(e) => e.preventDefault()}
    >
      {label ? <label className="text-sm font-medium">{label}</label> : null}
      <Suspense fallback={<div>Loading...</div>}>
        {getInputType()}
      </Suspense>
      {hintMessage || errorMessage ? <p className="text-sm font-regular">{isError ? errorMessage : hintMessage}</p> : null}
    </form>
  )
}
