import React from 'react';
import checkIcon from '../../assets/images/check-sm.svg';
import disabledCheckIcon from '../../assets/images/check-gray-sm.svg';
import { User } from '../../types/userForAddType';

interface Props {
  setIsSelected?: React.Dispatch<React.SetStateAction<{id: number, name: string, supportText?: string}[]>>,
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void,
  isSelected: boolean,
  disabled?: boolean,
  item?: {id: number, name: string, supportText?: string},
  items?: {id: number, name: string, supportText?: string}[],
}

export default function CheckBox({ setIsSelected, isSelected, disabled, item, items, onClick }: Props) {
  return (
    <div 
      className={`checkBox ${isSelected ? 'checkBox-selected' : ''}`} 
      onClick={(e) => {
        if (setIsSelected) {
          if (items) {
            if (isSelected) {
              setIsSelected([])
            } else {
              setIsSelected(items)
            }
          }
  
          if (item) {
            if (isSelected) {
              setIsSelected(prevState => prevState.filter(it => it.id !== item.id))
            } else {
              setIsSelected(prevState => [...prevState, item])
            }
          }
        }

        if (onClick) {
          onClick(e);
        }
      }}
    >
      {isSelected ? <img src={disabled ? disabledCheckIcon : checkIcon} alt='' /> : null}
    </div>
  )
}
