import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { TypedUseSelectorHook } from "react-redux";
import { TRootState, TAppDispatch } from "./store";

const useDispatch = () => useReduxDispatch<TAppDispatch>();
const useSelector: TypedUseSelectorHook<TRootState> = useReduxSelector;

export { useDispatch, useSelector };