import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../store";

const currentUser = (state: TRootState) => (state.currentUser);

const getCurrentUser = createSelector(
  [currentUser],
  (currentUser) => currentUser
);

export { getCurrentUser }