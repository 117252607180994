import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { fetchRestaurantMenu } from '../../../httpQueries/http'
import MenuItem from './MenuItem';
import { Button } from '../../Buttons/Button';
import arrowLeft from '../../../assets/images/arrow-narrow-left.svg';
import Loader from '../../Loader';
import Toggle from '../../Toggle/Toggle';

interface Props {
  id: string, 
  name: string,
  setCurrentStep: React.Dispatch<React.SetStateAction<"Restaurant" | "Meals">>,
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>,
  isPickup: boolean,
  setCreatorIsOrder: React.Dispatch<React.SetStateAction<boolean>>,
  creatorIsOrder: boolean,
  setTotalSum: React.Dispatch<React.SetStateAction<number>>,
  fee: number,
  setMenu: React.Dispatch<React.SetStateAction<{
    name: string;
    subcategory_id: string;
    menu_item_list: {
        count: number;
        name: string;
        price: number;
        qty_available: null;
        unit_size: null;
        unit_of_measurement: string;
        description: string;
        is_available: boolean;
        image: string;
        customizations: [
            {
                name: string;
                min_choice_options: number;
                max_choice_options: number;
                options: [
                    {
                        name: string;
                        price: number;
                        customizations: any[];
                        min_qty: number;
                        max_qty: number;
                        conditional_price: {};
                        formatted_price: string;
                        default_qty: number;
                        option_id: string;
                    }
                ];
                customization_id: string;
            }
        ];
        min_price: number;
        original_price: number;
        formatted_price: string;
        attributes: [];
        product_id: string;
        thumbnail_image: string;
        should_fetch_customizations: boolean;
        supports_image_scaling: boolean;
    }[];
  }[]>>,
  menu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
        count: number;
        name: string;
        price: number;
        qty_available: null;
        unit_size: null;
        unit_of_measurement: string;
        description: string;
        is_available: boolean;
        image: string;
        customizations: [
            {
                name: string;
                min_choice_options: number;
                max_choice_options: number;
                options: [
                    {
                        name: string;
                        price: number;
                        customizations: any[];
                        min_qty: number;
                        max_qty: number;
                        conditional_price: {};
                        formatted_price: string;
                        default_qty: number;
                        option_id: string;
                    }
                ];
                customization_id: string;
            }
        ];
        min_price: number;
        original_price: number;
        formatted_price: string;
        attributes: [];
        product_id: string;
        thumbnail_image: string;
        should_fetch_customizations: boolean;
        supports_image_scaling: boolean;
    }[];
  }[],
  onAddBlock: () => void,
  setOrderedMeals: React.Dispatch<React.SetStateAction<{
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[]>>,
  orderedMeals: {
    customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
  }[],
  currentStep: "Restaurant" | "Meals",
  setSelectedAvailableMealsForAttendees: React.Dispatch<React.SetStateAction<{id: number, productsIds: string[]}[]>>,
  selectedAvailableMealsForAttendees: {id: number, productsIds: string[]}[],
  setOrders: React.Dispatch<React.SetStateAction<{
    agendaBlockId: number,
    customizations: {
        customizationId: string,
        optionId: string,
        markedPrice: number
      }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  }[]>>
  orders: {
    agendaBlockId: number,
    customizations: {
        customizationId: string,
        optionId: string,
        markedPrice: number
      }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number
  }[],
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: 'Open' | 'Close';
    address: string;
    distance?: number;
    cuisines?: string[];
  } | null,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  agendaBlockId: number,
}

export default function RestaurantMenu({ 
  id, 
  name, 
  setCurrentStep, 
  setShowSelectRestaurantModal, 
  isPickup, 
  setTotalSum, 
  fee, 
  setMenu, 
  menu, 
  onAddBlock, 
  creatorIsOrder, 
  setCreatorIsOrder, 
  currentStep,
  orderedMeals,
  setOrderedMeals,
  setSelectedAvailableMealsForAttendees,
  selectedAvailableMealsForAttendees,
  setOrders,
  orders,
  selectedRestaurant,
  isLoading,
  setIsLoading,
  agendaBlockId
}: Props) {
  const token = localStorage.getItem('token');
  const [isUpdatingTotalSum, setIsUpdatingTotalSum] = useState(false);
  const [addAllMealsFromCategory, setAddAllMealsFromCategory] = useState<{ categoryId: string, isToggled: boolean }[]>([]);
  const [isToggledFromCategory, setIsToggledFromCategory] = useState(false);

  useEffect(() => {
    if (selectedRestaurant && typeof selectedRestaurant.id === 'string') {
      setIsLoading(true);
      fetchRestaurantMenu({ id: selectedRestaurant.id, token, isPickup }).then(res => {
        const menuItems = res?.map(category => ({
          ...category,
          menu_item_list: category.menu_item_list.map(meal => ({
            count: 0,
            ...meal
          }))
        }));
  
        setMenu(menuItems || []);
        setIsLoading(false);
      });

      setSelectedAvailableMealsForAttendees(prevState => prevState.filter(item => item.id !== agendaBlockId));
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (isUpdatingTotalSum) {
      let currentValue = 0;

      menu.map(category => category.menu_item_list.map(menuItem => {
        currentValue = ((menuItem.count * menuItem.price) + currentValue);
      }));
      setIsUpdatingTotalSum(false);
      
      setTotalSum((currentValue / 100) + fee);
    }
  }, [isUpdatingTotalSum]);

  useEffect(() => {
    if (!creatorIsOrder) {
      setAddAllMealsFromCategory(prevState => {
        return prevState.map(category => {
           return { categoryId: category.categoryId, isToggled: false }
        })
      })
    }
  }, [creatorIsOrder])

  useEffect(() => {
    if (isToggledFromCategory) {
      addAllMealsFromCategory.map(selectedCategory => {
        if (selectedCategory.isToggled) {
            setMenu(prevState => prevState.map(category => {
                if (category.subcategory_id === selectedCategory.categoryId) {
                  setIsUpdatingTotalSum(true);
                  return ({
                    ...category,
                    menu_item_list: category.menu_item_list.map(menuItem => {
                      return ({
                        ...menuItem,
                        count: menuItem.count >= 1 ? menuItem.count : menuItem.count + 1
                      })
                    })
                  })
                }
    
                return category;
            }));
            const currentOrder = selectedAvailableMealsForAttendees.find(item => item.id === agendaBlockId);

          setSelectedAvailableMealsForAttendees(prevState => [...prevState.filter(item => item.id !== agendaBlockId), { id: agendaBlockId, productsIds: menu.find(categoryFromMenu => categoryFromMenu.subcategory_id === selectedCategory.categoryId)!.menu_item_list.filter(item => !currentOrder?.productsIds.includes(item.product_id)).map(item => item.product_id) }])
        } else {
            setMenu(prevState => prevState.map(category => {
              if (category.subcategory_id === selectedCategory.categoryId) {
                setIsUpdatingTotalSum(true);  
                return ({
                  ...category,
                  menu_item_list: category.menu_item_list.map(menuItem => {
                    return ({
                      ...menuItem,
                      count: menuItem.count - 1
                    })
                  })
                })
              }  
    
              return category;
              }))
              setSelectedAvailableMealsForAttendees(prevState => [...prevState.filter(item => item.id !== agendaBlockId), { id: agendaBlockId, productsIds: menu.find(categoryFromMenu => categoryFromMenu.subcategory_id === selectedCategory.categoryId)!.menu_item_list.map(item => item.product_id) }])
        }
      });

      setIsToggledFromCategory(false);
    }
  }, [isToggledFromCategory]);

  return (
    <div className='restaurantMenu'>
      <div className='selectRestaurant-main'>
        <header className='selectRestaurant-main-header'>
          <div className='selectRestaurant-main-header-circles'>
            <div className="eventDetails_circles">
              <div className="eventDetails-circle eventDetails-circle-fifth">
                <div className="eventDetails-circle eventDetails-circle-fourth">
                  <div className="eventDetails-circle eventDetails-circle-third">
                    <div className="eventDetails-circle eventDetails-circle-second">
                      <div className="eventDetails-circle eventDetails-circle-first">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: 'relative', zIndex: '30' }}>
            <Button 
              size='lg' 
              hierarchy={'secondaryGray'}
              buttonType='small'
              onClick={(e) => {
                e.stopPropagation();
                setCurrentStep('Restaurant')
              }}
              buttonImage={arrowLeft}
            />
          </div>
          <div className='selectRestaurant-main-header-info'>
            <h3 className='selectRestaurant-main-header-info-text'>Select meals</h3>
            <div className='flex items-center gap-8'>
              <Toggle toggleOn={creatorIsOrder} setToggleOn={setCreatorIsOrder} />
              <p>Attendees choose</p>
            </div>
          </div>
        </header>
        <main className='restaurantMenu-main'>
          {
            menu.length > 0 || isLoading
              ? menu.map(category => (
                  <>
                    <div style={{ position: 'relative' }}>
                      <h3>{category.name}</h3>
                      {
                        creatorIsOrder
                          ? <div className='flex items-center gap-12'>
                              <Toggle 
                                toggleOn={!!addAllMealsFromCategory.find(selectedCategory => selectedCategory.categoryId === category.subcategory_id)?.isToggled} 
                                onClick={() => {
                                  setIsToggledFromCategory(true);
                              
                                  setAddAllMealsFromCategory(prevState => [...prevState.filter(item => item.categoryId !== category.subcategory_id), { categoryId: category.subcategory_id, isToggled: !prevState.find(item => item.categoryId === category.subcategory_id)?.isToggled }]);
                                }} 
                              />
                              <p>Select all</p>
                            </div>
                          : null
                      }
                    </div>
                    {
                        category.menu_item_list.map(menuItem => {
                            return <MenuItem 
                            customizations={menuItem.customizations}
                            key={menuItem.product_id} 
                            setIsUpdatingTotalSum={setIsUpdatingTotalSum} 
                            setMenu={setMenu} name={menuItem.name} 
                            description={menuItem.description} 
                            price={menuItem.formatted_price} 
                            image={menuItem.image} 
                            count={orders.find(order => order.productId === menuItem.product_id)?.count || 0} 
                            categoryId={category.subcategory_id} 
                            productId={menuItem.product_id} 
                            orderForAttendees={creatorIsOrder} 
                            selectedAvailableMealsForAttendees={selectedAvailableMealsForAttendees} 
                            setSelectedAvailableMealsForAttendees={setSelectedAvailableMealsForAttendees}
                            setTotalSum={setTotalSum}
                            setAddAllMealsFromCategory={setAddAllMealsFromCategory}
                            orderedMeals={orderedMeals}
                            setOrderedMeals={setOrderedMeals}
                            markedPrice={menuItem.price}
                            setOrders={setOrders}
                            orders={orders}
                            agendaBlockId={agendaBlockId}
                          />
                        })
                    }
                  </>
                ))
              : <div className='flex items-center fullWidth justifyCenter'>
                  <Loader size='xl' />
                </div>
          }
        </main>
      </div>
      <footer className='selectRestaurant-footer restaurantMenu-footer'>
        <Button 
          size='lg' 
          hierarchy='secondaryGray'
          buttonType='regular'        
          onClick={() => setCurrentStep('Restaurant')}
        >
          Back
        </Button>
        <Button 
          size='lg' 
          hierarchy='secondaryColor'
          buttonType='regular'        
          onClick={() => setCurrentStep('Meals')}
        >
          Save and do this later
        </Button>
        <Button 
          size='lg' 
          hierarchy={'primary'}
          buttonType='regular'        
          onClick={() => {
            onAddBlock();
            setShowSelectRestaurantModal(false);
          }}
        >
          Add block
        </Button>
      </footer>
    </div>
  )
}
