import helpIcon from '../../assets/images/help-circle.svg';
import errorIcon from '../../assets/images/alert-circle.svg';
import xClose from '../../assets/images/x-close-gray-small.svg';
import { useState } from 'react';
import { User } from '../../types/userForAddType';
import checkSuccessIcon from '../../assets/images/check-success.svg';
import closeIcon from '../../assets/images/x-close-alert.svg';

interface Props {
  placeholder: string,
  isError?: boolean,
  disabled?: boolean,
  hasHelpIcon?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  fullWidth?: boolean,
  setShow?: () => void,
  onFocus?: () => void,
  setUsersFromInput: React.Dispatch<React.SetStateAction<User[]>>,
  usersFromInput: User[],
  onAddNewItem?: (newItem: User) => void,
  onBlur?: () => void
}

export default function InputWithActions({ placeholder, setShow, onFocus, isError, disabled, hasHelpIcon, onChange, fullWidth, setUsersFromInput, usersFromInput, onAddNewItem, onBlur }: Props) {
  const [inputValue, setInputValue] = useState('');  

  const onChangeAddUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  
    onChange && onChange(event);
  }
  
  const onSpaceBarOrEnterClick = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.code === 'Space' || event.code === 'Enter') && inputValue.trim().length) {
      const newUser: User = {
        id: usersFromInput ? usersFromInput.length + 1 : 1,
        img: '',
        name: inputValue 
      }
      setUsersFromInput && setUsersFromInput(prevState => [...prevState, newUser]);
      onAddNewItem && onAddNewItem(newUser)
      setInputValue('');
      setShow && setShow();
    }
  }

  return (
    <div 
      className={`input input-lg ${isError ? 'input-error' : (disabled ? 'input-disabled' : '')} ${fullWidth ? 'fullWidth' : ''}`} tabIndex={0}
      onKeyUp={onSpaceBarOrEnterClick}
      autoFocus
      onBlur={onBlur}
      onFocus={(e) => {
        e.preventDefault()
        onFocus && onFocus()
      }}
    >
      <input
        type='text'
        placeholder={placeholder}
        className="text-md"
        onChange={onChangeAddUsers}
        value={inputValue}
        tabIndex={1}
      />
      {inputValue.length
        ? <div className='cardsInInputWrapper overflowXVisible'>
            <img src={closeIcon} alt="" onClick={() => {
              'lelele'
              setInputValue('');
              setShow && setShow();
            }} style={{ cursor: 'pointer'}} />
            <img src={checkSuccessIcon} alt="" onClick={() => {
              const newUser: User = {
                id: usersFromInput ? usersFromInput.length + 1 : 1,
                img: '',
                name: inputValue 
              }
              setUsersFromInput && setUsersFromInput(prevState => [...prevState, newUser]);
              onAddNewItem && onAddNewItem(newUser)
              setInputValue('');
              setShow && setShow();
            }} style={{ cursor: 'pointer'}} />
          </div> 
        : null
      }
      {hasHelpIcon ? <img src={isError ? errorIcon : helpIcon} alt="help" /> : null}
    </div>
  )
}
