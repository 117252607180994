import React from 'react';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import faceHappy from '../../assets/images/face-happy-sm.svg';
import presentationChart from '../../assets/images/presentation-chart-01-sm.svg';
import closeIcon from '../../assets/images/x-close-primary-sm.svg';
import clockIcon from '../../assets/images/clock-sm.svg';
import { Button } from '../Buttons/Button';

interface Props {
  invitedAttendees: {
    id: number;
    name: string;
    supportText?: string | undefined;
  }[],
  setInvitedAttendees: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    supportText?: string | undefined;
  }[]>>
}

export default function ReviewInvitedAttendeesSection({ invitedAttendees, setInvitedAttendees }: Props) {
  const onDelete = (id: number) => {
    setInvitedAttendees(prevState => prevState.filter(attendee => attendee.id !== id));
  }

  return (
    <div className='reviewDetailsSection'>
      <div className='reviewDetailsSection-content reviewAgendaSection' style={{ padding: '0' }}>
        <div className='reviewDetailsSection-content-about'>
          <div className='reviewDetailsSection-content-about-info'>
            <div className='reviewAgendaSection-blocks flex-row'>
              {
                invitedAttendees.map((attendee) => 
                  <div className='reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-session' key={attendee.id}>
                    <p className='reviewAgendaSection-blocks-block-session-title' style={{whiteSpace: 'nowrap'}}>{attendee.name}</p>
                    <img src={closeIcon} alt="" className='reviewAgendaSection-blocks-close' onClick={() => onDelete(attendee.id)} />
                  </div>
                )
              }
            </div>
          </div>
          <Button
            buttonType='regular'
            hierarchy='linkColor'
            size='md'
            paddingNone
          >
            View detail
          </Button>
        </div>
      </div>
    </div>
  )
}
