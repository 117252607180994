import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import Navigation from "../../components/Navigation/SideBarNavigation";
import oliviaPhoto from '../../assets/images/Avatar-olivia.svg';
import HorizontalTabs from '../../components/Tabs/HorizontalTabs';
import MyDetails from './components/MyDetails';
import MyPassword from './components/MyPassword';
import BillingsDetails from './components/BillingsDetails';
import Notification from '../../components/Notifications/Notification';

export default function Settings() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [currentTab, setCurrentTab] = useState<{id: number, name: string} | null>({id: 1, name: 'My details'});
  const [notification, setNotification] = useState<{isError: boolean, title: string} | null>(null)

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width])

  return (
    <div className={`eventDetails_container`}>
      {sizeIsDesktop
        ? <Navigation
            hierarchy='primary'
            currentItem='Settings'
          />
        : null
      }
      {
        notification
          ? <Notification isError={notification.isError} setIsOpen={() => {setNotification(null)}} title={notification.title} />
          : null
      }
      <div className='eventDetails_main-container'>
        <div className="eventDetails_main settingsBackColor">
          <header className='eventDetails_main-header flex-column items-start'>
            <h3 className='eventsPage-header-title'>Settings</h3>
            <HorizontalTabs
              current={currentTab}
              setCurrent={setCurrentTab}
              tabs={[{id: 1, name: 'My details'}, {id: 2, name: 'Password'}, {id: 3, name: 'Billing'}]}
              type='Underline'
            />        
          </header>
          {
            currentTab?.name === 'My details' && currentTab.id === 1
              ? <MyDetails setNotification={setNotification} />
              : null
          }
          {
            currentTab?.name === 'Password' && currentTab.id === 2
              ? <MyPassword setNotification={setNotification} />
              : null
          }
          {
            currentTab?.name === 'Billing' && currentTab.id === 3
              ? <BillingsDetails />
              : null
          }
        </div>
      </div>
    </div>
  )
}
