import { useEffect, useState } from 'react';
import arrowDown from '../../assets/images/chevron-down.svg';
import checkIcon from '../../assets/images/check.svg';
import { AgendaBlockType } from '../../types/agendaBlockType';
import { restaurantSortTypes } from '../../types/restaurantSortTypes';

export function Dropdown(
  { content, currentItem, setCurrentItem, withBorder, label, top, height, reactToFocus, id }: 
    { 
      top?: string
      content: {id: number, name: string, type?: AgendaBlockType, sortBy?: restaurantSortTypes, onSelect?: () => void}[], 
      currentItem: { id: number, name: string, type?: AgendaBlockType, sortBy?: restaurantSortTypes } | null, 
      setCurrentItem: React.Dispatch<React.SetStateAction<{ id: number, name: string, type?: AgendaBlockType, sortBy?: restaurantSortTypes } | null>>,
      label?: string,
      withBorder?: boolean,
      height?: string,
      reactToFocus: boolean,
      id: string
    }
  ) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (isSelected) {
      setIsDropdownOpen(false);
      setIsSelected(false);
    };
  }, [isSelected]);

  useEffect(() => {
    const formElement = document.getElementById(id);

    if (reactToFocus) {
      formElement?.focus(); 
    }
  }, []);
  
  return (
    <form
      onMouseDown={(e) => e.preventDefault()}
      autoFocus
      tabIndex={0}
      id={id}
      onBlur={() => {
        if (reactToFocus) {
          setIsDropdownOpen(false)
        }
      }}
    >
      <div className='flex flex-column gap-4'>
        {
          label
            ? <p className="text-sm font-medium">{label}</p>
            : null
        }
        <div className={`dropdown ${withBorder ? 'dropdown-withBorder' : ''}`} onClick={(e) => {
          e.preventDefault();

          e.stopPropagation();
          setIsDropdownOpen(prevState => !prevState)
        }}>
          <h1 className='text-md font-regular'>
            {
              currentItem
                ? currentItem.name
                : 'Select one'
            }
          </h1>
          <img src={arrowDown} alt="arrow down" />
        </div>
      </div>

      {
        isDropdownOpen
          ? <div className='dropdown-content' style={{ top, height }}>
              <ul className='timePicker-timeContainer'>
                {content.map(item => {
                  return (
                    <div 
                      className={`timePicker-timeContainer-time ${currentItem?.id === item?.id ? 'timePicker-time-selected' : ''}`} 
                      key={item?.id}
                    >
                      <div
                        className='timePicker-timeContainer-info overflowXScroll'
                        onClick={() => {
                          item.onSelect && item.onSelect()
                          setCurrentItem(item);
                          setIsSelected(true);
                        }}
                      >
                        <li
                          className={`timePicker-time`}
                        >
                          {item?.name}
                        </li>
                      </div>
                      {currentItem?.id === item?.id ? <img src={checkIcon} alt="selected icon" /> : null}
                    </div>
                  )
                })}
              </ul>
            </div>
          : null
      }
    </form>
  )
}
