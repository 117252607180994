import React, { useEffect, useState } from 'react';

import startOfWeek from "date-fns/startOfWeek";
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import isSameMonth from 'date-fns/isSameMonth';
import addDays from 'date-fns/addDays';
import { useQuery } from '@tanstack/react-query';
import { fetchEvents } from '../../httpQueries/http';
import { EventsFilter } from '../../types/eventsFilter';
import moment from 'moment';
import { CustomDay } from '../DatePickers/CustomDay';
import calendarIcon from '../../assets/images/calendar-tabIcon.svg';
import calendarSelectedIcon from '../../assets/images/calendar-tabIcon-selected.svg';
import listIcon from '../../assets/images/list.svg';
import listSelectedIcon from '../../assets/images/list-selected.svg';
import { isSameDay } from 'date-fns';
import { logoColor } from '../../types/logoColor';

function RenderCells(currentMonth: Date, filter: EventsFilter, currentTab: 'month' | 'week' | 'day', setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>, selectedDate: moment.Moment | null) {
    const token = localStorage.getItem('token');
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = currentTab === 'day' ? currentMonth : startOfWeek(currentTab === 'month' ? monthStart : currentMonth, { weekStartsOn: 1 });
    const endDate = endOfWeek(currentTab === 'month' ? monthEnd : currentMonth, { weekStartsOn: 1 });
    const {data: events, fetchStatus} = useQuery({
      queryKey: ['event', startDate, endDate, filter],
      queryFn: () => fetchEvents({
        start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0).valueOf(), 
        end: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999).valueOf(), 
        token,
        eventFilter: filter,
        isPublished: true
      })
    })
  
    const dayFormat = "dd";
    const rows: JSX.Element[] = []
    let day = startDate;
    let formattedDate = "";
    let days: { 
      reactNode: JSX.Element, 
      date: Date, 
      events?: {
        slug: string;
        name: string;
        startTime: string;
        endTime?: string;
        color: logoColor | null;
      }[]
  }[] = [];
  
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dayFormat);
        days.push(
          {
            reactNode: (
              <td className='customScheduleDay-container flex items-center justifyCenter'>
                <CustomDay
                  day={moment(day).utcOffset(0, true)}
                  disabled={false}
                  key={`${formattedDate}`}
                  events={events?.filter(event => {
                    return event.startDate === moment(day).utcOffset(0, true).toISOString();
                  }).map(item => ({
                    slug: item.slug, name: item.name, startTime: item.startTime, color: item.color
                  }))} 
                  onDaySelect={(day) => setSelectedDate(day)} 
                  outsideCurrentMonth={false} 
                  isFirstVisibleCell={false} 
                  isLastVisibleCell={false} 
                  pickedDate={selectedDate} 
                  setPickedDate={setSelectedDate} 
                  selectedMonth={currentMonth.getMonth()} 
                  selectedDayOnlyFromToday={false}            
                />
              </td>
            ),
            date: day,
            events: events?.filter(event => {
              return event.startDate === moment(day).utcOffset(0, true).toISOString();
            }).map(item => ({
              slug: item.slug, name: item.name, startTime: item.startTime, color: item.color, endTime: item.endTime
            }))
          }
        );
        if (currentTab === 'day') {
          break
        }
        day = addDays(day, 1);
      }
  
      rows.push(
        <>
          <tr 
            className={`${currentTab === 'day' ? 'flex items-center justifyCenter fullWidth' : 'calendarSchedule-table-grid'} ${currentTab === 'month' ? '' : 'daySchedule'}`} 
            key={`${formattedDate}`}
          >
            {days.map(day => day.reactNode)}
          </tr>
          {
            selectedDate && days.map(day => day.date).some(date => isSameDay(date, new Date(selectedDate.toISOString())))
              ? <div className='flex flex-column gap-20'>
                  {
                    days[days.findIndex(day => isSameDay(day.date, new Date(selectedDate.toISOString())))]
                      .events?.map(event => 
                        <div className='daySchedule-day-event' style={{ height: '60px' }} key={event.name}>
                          <div className='flex flex-column gap-2'>
                            <div className='daySchedule-day-event-circle'></div>
                            <h3 className='daySchedule-day-event-name'>{event.name}</h3>
                          </div>
                          <p className='daySchedule-day-event-description'>{event.startTime} {event.endTime ? '-' : ''} {event.endTime}</p>
                        </div>
                      )
                  }
                </div>
              : null
          }
        </>
        
      );
      if (currentTab === 'day') {
        break
      }
      days = []
    }
  
    return (
      <tbody className='monthCalendar-calendar-body'>
        {rows.map(i => i)}
      </tbody>
    )
}

function RenderHeader ( currentMonth: Date, filter: EventsFilter, currentTab: 'month' | 'week' | 'day' ) {
    const startDate = currentTab === 'day' ? currentMonth : startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = endOfWeek(currentMonth, { weekStartsOn: 1 });
    const dayFormat = "dd";
    const dayToStringFormat = 'eee';
    let day = startDate;
    let days: JSX.Element[] = [];
    let formattedDate;
    if (currentTab === 'week') {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dayFormat);
        days.push(
          <th key={`${day}`}>
            <div>
              <p className='calendarSchedule-table-body-day'>{format(day, dayToStringFormat)}</p>
              <p className='calendarSchedule-table-body-day'>{formattedDate}</p>
            </div>
          </th>
        );
        day = addDays(day, 1);
      }
    }
    
    return (
      <thead>
        <tr className='monthCalendar-calendar-header'>
          {
            currentTab === 'month'
              ? <>
                  <th className='calendarSchedule-table-body-day'>Mo</th>
                  <th className='calendarSchedule-table-body-day'>Tu</th>
                  <th className='calendarSchedule-table-body-day'>We</th>
                  <th className='calendarSchedule-table-body-day'>Th</th>
                  <th className='calendarSchedule-table-body-day'>Fr</th>
                  <th className='calendarSchedule-table-body-day'>Sat</th>
                  <th className='calendarSchedule-table-body-day'>Su</th>
                </>
              : null
          }
        </tr>
      </thead>
    )
  }

export default function MonthCalendar({ month, selectedDate, setSelectedDate }: { month: Date, setSelectedDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>, selectedDate: moment.Moment | null }) {
  const [selectedTab, setSelectedTab] = useState<'calendar' | 'list'>('calendar')
  const dateFormat = "MMMM yyyy";

  useEffect(() => {
    const today = new Date();

    if (month.getFullYear() === today.getFullYear()) {
      document.getElementById(`${today.getMonth()}`)?.scrollIntoView({behavior: "smooth"});
    }
  }, [])

  return (
    <div className='monthCalendar' id={`${month.getMonth()}`}>
      <header className='monthCalendar-header'>
        {format(month, dateFormat)}
        <div className='monthCalendar-calendar-tabs'>
          <img 
            src={selectedTab === 'calendar' ? calendarSelectedIcon : calendarIcon} 
            alt="" 
            onClick={() => setSelectedTab('calendar')} 
            className={`monthCalendar-calendar-tabs-tab ${selectedTab === 'calendar' ? 'monthCalendar-calendar-tabs-tab-selected' : ''} `}
          />
          <img 
            src={selectedTab === 'list' ? listSelectedIcon : listIcon} 
            alt="" 
            onClick={() => setSelectedTab('list')} 
            className={`monthCalendar-calendar-tabs-tab ${selectedTab === 'list' ? 'monthCalendar-calendar-tabs-tab-selected' : ''} `}
          />
        </div>
      </header>
      <div className='monthCalendar-content'>
        {RenderHeader(month, EventsFilter.Organizer, 'month')}
        {RenderCells(month, EventsFilter.Organizer, 'month', setSelectedDate, selectedDate)}
      </div>
    </div>
  )
}
