import React from 'react';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import arrowRightUp from '../../assets/images/arrow-up-right.svg';
import { Button } from '../Buttons/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LocationButton from '../LocationButton';
import ReviewFileBase from '../FileAttach/ReviewFileBase';

interface Props {
  eventName: string,
  eventDescription: string,
  eventLocation: string,
  eventDate: moment.Moment,
  eventStartTime: string,
  eventLocationNotes?: string,
  lat: string | null,
  lng: string | null,
  documents?: [
    {
      id: number,
      isUploaded: boolean,
      key: string,
      originalFileName: string,
      url: null
    }
  ],
  eventId: number
}

export default function ReviewDetailsSection({ eventDate, eventLocation, eventLocationNotes, eventStartTime, lat, lng, documents, eventId }: Props) {
  const navigate = useNavigate();

  return (
    <div className='reviewDetailsSection' id='scrolledInfo'>
      <div className='reviewDetailsSection-content'>
        <div className='reviewDetailsSection-content-info'>
          <div className='reviewDetailsSection-content-info-container'>
            <p className='reviewDetailsSection-content-info-container-title'>Location</p>
            <LocationButton lng={lng} lat={lat} name={eventLocation} />
          </div>
          <div className='reviewDetailsSection-content-info-container'>
            <p className='reviewDetailsSection-content-info-container-title'>Date and time</p>
            <Button
              buttonType='regular'
              hierarchy='linkGray'
              size='lg'
              paddingNone
              fullWidth
            >
              <p className='reviewDetailsSection-button-content'>{`${eventDate.format('ll')} • ${eventStartTime} EST`}</p>
            </Button>
          </div>
          <div className='reviewDetailsSection-content-info-container'>
            <p className='reviewDetailsSection-content-info-container-title'>Internal location</p>
            <Button
              buttonType='regular'
              hierarchy='linkGray'
              size='lg'
              paddingNone
              fullWidth
            >
              <p className='reviewDetailsSection-button-content'>{eventLocationNotes ? eventLocationNotes : 'No info about internal location'}</p>
            </Button>
          </div>
          {
            documents && documents.map(document => {
              if (document.isUploaded) {
                return <ReviewFileBase name={ document.originalFileName } documentId={document.id} key={document.key} eventId={eventId} />
              }
            })
          }
        </div>
      </div>
    </div>
  )
}
