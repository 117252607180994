import welcomeImage from '../../assets/images/welcomeImage.png';

interface Props {
  eventName: string,
  needTodo: JSX.Element[] | undefined,
  onScroll: () => void
}

export default function TodosPage({ eventName, needTodo, onScroll }: Props) {
  return (
    <div className='eventDetailsWelcomePage' onScroll={onScroll}>
      <div className='eventDetailsWelcomePage-card'>
        <img src={welcomeImage} alt="" />
        <div>
          <h3 className='eventDetailsWelcomePage-card-title'>You’ve been invited to the {eventName} event</h3>
          <p className='eventDetailsWelcomePage-card-description'>Please respond to the invite to help the organizer</p>
        </div>
      </div>
      {
        needTodo?.length
          ? <div className='flex gap-12 items-center'>
              <p className='eventDetailsWelcomePage-thingsTodo-text'>Things you need to do</p>
              <div className='eventDetailsWelcomePage-thingsTodo-count'>
                <p className='eventDetailsWelcomePage-thingsTodo-count-text'>{needTodo?.filter(item => !!item).length}</p>
              </div>
            </div>
          : null
      }
      {
        needTodo?.map(toDo => {
          if (toDo) {
            return toDo
          } 

          return null
        })
      }
    </div>
  )
}
