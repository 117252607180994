import React, { useEffect, useState } from 'react';
import { Button } from '../Buttons/Button';

interface Props {
  numberOfPages: number,
  selected: number,
  setSelected: React.Dispatch<React.SetStateAction<number>>
}

export default function MinimalPagination({ numberOfPages, selected, setSelected }: Props) {

  return (
    <div className='flex spaceBetween fullWidth px-24 py-16 border-box items-center'>
      <p className='text-sm font-medium gray-700'>
        Page {selected + 1} of {numberOfPages}
      </p>
      <div className='flex gap-12'>
        <Button
          buttonType='regular'
          hierarchy='secondaryGray'
          size='sm'
          onClick={() => setSelected(prev => prev - 1)}          
        >
          Previous
        </Button>
        <Button
          buttonType='regular'
          hierarchy='secondaryGray'
          size='sm'
          onClick={() => setSelected(prev => prev + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
