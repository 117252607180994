import React, { useEffect, useState } from 'react'
import { FormInput } from '../Inputs/FormInput';
import { User } from '../../types/userForAddType';
import CheckBox from '../CheckBox/CheckBox';
import { el } from 'date-fns/locale';
import RadioButtons from '../Selectors/RadioButtons';

interface Props {
  content: User[],
  setContent: React.Dispatch<React.SetStateAction<User[]>>,
  isDropdownOpen: boolean,
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>,
  label: string,
  placeholder: string,
  onFocus: () => void,
  selectedItem: User | null,
  setSelectedItem: React.Dispatch<React.SetStateAction<User | null>>,
}

export default function DropdownWithRadioButtons({ content, setContent, isDropdownOpen, setIsDropdownOpen, label, placeholder, onFocus, selectedItem, setSelectedItem }: Props) {
  const newItemId = content.length + 1;
  const [otherInputIsOpen, setOtherInputIsOpen] = useState(false);

  useEffect(() => {
    if (!isDropdownOpen) {
      setOtherInputIsOpen(false);
    }
  }, [isDropdownOpen])
  
  return (
    <div 
      className='flex flex-column gap-12 fullWidth border-box px-5 py-5' 
      tabIndex={0} 
      onFocus={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(true);
        onFocus();
      }}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setIsDropdownOpen(false);
        }
      }}
    >
      <FormInput 
        type='Text' 
        placeholder={placeholder}    
        label={label} 
        value={selectedItem?.name}
      />
      {
        isDropdownOpen
          ? <div className='teamsDropdown'>
              {
                content.map(item => (
                  <div 
                    className={`teamsDropdown-item ${selectedItem?.id === item.id ? 'teamsDropdown-item-selected' : ''}`} 
                    key={item.id}
                  >
                    <div 
                      className={`radioButtons-button-circle-container ${item.id === selectedItem?.id ? 'radioButtons-button-circle-container-selected' : ''}`}
                      onClick={() => {
                        setSelectedItem(item)
                      }}
                    >
                      <div className={`${item.id === selectedItem?.id  ? 'radioButtons-button-circle' : ''} `}></div>
                    </div>
                    <li
                      className={`teamsDropdown-item-name`}
                    >
                      {item.name}
                    </li>
                  </div>
                ))
              }
              <div 
                className={`teamsDropdown-item item.id === selectedItem.id  ? 'teamsDropdown-item-selected' : ''}`} 
                key={newItemId}
              >
                <div 
                  className={`radioButtons-button-circle-container`}
                  onClick={() => {
                    setOtherInputIsOpen(true);
                  }}
                >
                  <div></div>
                </div>
                <li
                  className={`teamsDropdown-item-name`}
                >
                  Other
                </li>
              </div>
              {
                otherInputIsOpen
                  ? <div className='fullWidth border-box px-5 py-5'>
                      <FormInput 
                        type={'InputWithActions'} 
                        placeholder={''}     
                        setShow={() => {
                          setOtherInputIsOpen(prevState => !prevState);
                        }}
                        fullWidth={true}
                        onAddNewItem={(newItem) => {
                          newItem.id = newItemId;
                          setContent(prevState => [...prevState, newItem]);
                          setOtherInputIsOpen(false)
                        }}
                      />
                    </div>
                  : null
              }
            </div>
          : null
      }
    </div>
  )
}