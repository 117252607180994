import { useEffect, useState } from 'react'
import { Button } from '../../../components/Buttons/Button'
import FeaturedIcon from '../../../components/FeaturedIcons/FeaturedIcon';
import usersPlusIcon from '../../../assets/images/users-plus.svg';
import closeIcon from '../../../assets/images/x-close-black.svg';
import chevronDown from '../../../assets/images/chevron-down-primary.svg';
import ProgressSteps from '../../../components/ProgressSteps/ProgressSteps';
import { CustomIcon } from '../../../components/CustomIcons/CustomIcon';
import arrowDown from '../../../assets/images/chevron-down-primary.svg';
import flagIcon from '../../../assets/images/flag-05.svg';
import { DropdownMenu } from '../../../components/Dropdown/DropdownMenu';
import ModalAddAttendees from '../../../components/Modal/ModalAddAttendees';
import { createPortal } from 'react-dom';
import ModalAddNewAttende from '../../../components/Modal/ModalAddNewAttende';
import UserInvitesTable from '../../../components/Tables/UserInvitesTable';
import { AddAttendee } from '../../../components/AddAttendee/AddAttendee';
import ModalNotification from '../../../components/Modal/ModalNotification';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from '@tanstack/react-query';
import { deleteAttendee, fetchAttendeesNetwork, fetchEvent, fetchInvitedAttendees } from '../../../httpQueries/http';
import Loader from '../../../components/Loader';
import { supabase } from '../../../utilities/supabaseClient';
import { AttendeeRSVP } from '../../../types/attendeeRSVP';
import moment from 'moment';
import { getUTCTimeFromMinutes } from '../../../utilities/getUTCTimeFromMinutes';

interface Props {
  isDesktopSize: boolean,
  invitedAttendees: {
    attendees: {
      id: number,
      networkMemberId: number,
      status: 'added',
      rsvp: AttendeeRSVP | null,
      networkMember: {
        id: number,
        fullName: string,
        email: string,
        phone: string,
        businessName: string,
        address: string,
        title: string,
        userId: null | number,
        accountId: number,
        createdAt: string,
        updatedAt: string,
        user: {
          accountId: number,
          createdAt: string,
          email: string,
          fullName: string,
          id: number,
          phone?: string | null,
          status: string,
          supabaseId: string,
          updatedAt: string,
        } | null,
      }
    }[],
    count: number,
  },
  setInvitedAttendees: React.Dispatch<React.SetStateAction<{
    attendees: {
      id: number,
      networkMemberId: number,
      status: 'added',
      rsvp: AttendeeRSVP | null,
      networkMember: {
        id: number,
        fullName: string,
        email: string,
        phone: string,
        businessName: string,
        address: string,
        title: string,
        userId: null | number,
        accountId: number,
        createdAt: string,
        updatedAt: string,
        user: {
          accountId: number,
          createdAt: string,
          email: string,
          fullName: string,
          id: number,
          phone?: string | null,
          status: string,
          supabaseId: string,
          updatedAt: string,
        } | null,
      }
    }[],
    count: number,
  }>>,
  eventId: number,
  isEdit?: boolean,
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>,
  currentLastStep: number,
  eventSlug: string,
  setSelectedStartTime: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  } | null>>,
  setSelectedEndTime: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  } | null>>,
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  setDescriptionInputValue: React.Dispatch<React.SetStateAction<string>>,
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>,
  setEventIds: React.Dispatch<React.SetStateAction<{
    eventId: number;
    eventDayId: number;
  } | null>>,
  setSelectedLocation: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    supportText?: string | undefined;
    lat?: string,
    lng?: string
  } | null>>,
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>,
  setSelectedZone: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    timezone?: string
  } | null>>,
  setSlug: React.Dispatch<React.SetStateAction<string>>
}

export default function EventInviteTeam({ 
  isDesktopSize,
  invitedAttendees, 
  setInvitedAttendees, 
  eventId, 
  isEdit, 
  setCurrentLastStep, 
  currentLastStep, 
  eventSlug,
  setDescriptionInputValue,
  setEventIds,
  setFirstDate,
  setLocationNotes,
  setNameInputValue,
  setSelectedEndTime,
  setSelectedLocation,
  setSelectedStartTime,
  setSelectedZone,
  setSlug
}: Props) {
  const currentStep = 3;
  const [selectedAttendees, setSelectedAttendees] = useState<{id: number, name: string, supportText?: string}[]>([]);
  const [isOpenAddAttendeesDropdown, setIsOpenAddAttendeesDropdown] = useState(false);
  const [selectedAddAttendees, setSelectedAddAttendees] = useState<{ id: number; name: string; supportText?: string | undefined; value?: number | undefined; } | null>(null);
  const [newUserFullName, setNewUserFullName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState('');
  const [newUserBusinessName, setNewUserBusinessName] = useState('');
  const [newUserTitle, setNewUserTitle] = useState('');
  const [newUserAdded, setNewUserAdded] = useState(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{name: string, description: string, firstButtonName: string, secondButtonName: string, onFirstButtonClick: () => void, onSecondButtonClick: () => void} | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isUsersInvitedQueryWorks, setIsUsersInvitedQueryWorks] = useState(true);
  const [skipAttendees, setSkipAttendees] = useState(0);
  const [attendeesNetwork, setAttendeesNetwork] = useState<{
    accountId: number;
    createdAt: string;
    email: string;
    fullName: string;
    id: number;
    phone?: string;
    updatedAt: string;
    address: string | null,
    businessName: string | null,
    title: string | null,
  }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const slug = useParams().slug;

  const {data: attendees, fetchStatus} = useQuery({
    queryKey: ['attendees'],
    queryFn: () => fetchAttendeesNetwork({ skip: 0, take: 10, fetchEvents: false, token })
  });

  const fetchMoreAttendees = async () => {
    fetchInvitedAttendees({ token, eventId, skip: invitedAttendees.attendees.length, take: 10 }).then(res => {
      if(res) {
        setInvitedAttendees(prevState => ({attendees: [...prevState.attendees, ...res.attendees], count: res.count}))
      }
    })
  };


  async function loadEditableEventData() {
    const data = await fetchEvent({ slug, token});
    return data;
  }

  useEffect(() => {
    if (isEdit) {
      loadEditableEventData().then(data => {
      setFirstDate(moment(data!.startDate));
      setDescriptionInputValue(data!.description);
      setNameInputValue(data!.name);
      setSelectedEndTime(data?.endTime ? { id: 1, name: getUTCTimeFromMinutes(data!.endTime) } : null)
      setSelectedLocation({ id: 1, name: data!.location, supportText: '', lat: data?.locationLatitude ? data.locationLatitude : '0', lng: data?.locationLongitude ? data.locationLongitude : '0'})
      setLocationNotes(data?.locationNote || '')
      setSelectedStartTime({ id: 1, name: getUTCTimeFromMinutes(data!.startTime) })
      setSelectedZone(data?.timezone ? {id: 1, name: data.timezone, timezone: data.timezoneName } : null)
      setEventIds({eventId: data!.id, eventDayId: data!.days[0].id});
      setSlug(data!.slug)
    })
  }
  }, [isEdit])

  useEffect(() => {
    if (currentLastStep < currentStep) {
      setCurrentLastStep(currentStep);
    }
  }, [])

  useEffect(() => {
    setAttendeesNetwork(attendees);
  }, [attendees]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(async () => {
      const data = await fetchAttendeesNetwork({ skip: 0, take: 10, fetchEvents: false, token, search: searchValue.length ? searchValue : undefined });
      setAttendeesNetwork(data);
    }, 500);

    setIsLoading(false);

    return () => clearTimeout(timer);
  }, [searchValue])

  useEffect(() => {
    if (newUserAdded) {
      setIsLoading(true);
      fetchAttendeesNetwork({ skip: 0, take: 10, fetchEvents: false, token, search: searchValue.length ? searchValue : undefined }).then(response => setAttendeesNetwork(response));
      fetchInvitedAttendees({ token, eventId, skip: skipAttendees, take: 20 }).then((response) => {
        if (response) {
          setInvitedAttendees(response);
        }
      });

      setIsLoading(false);
      setNewUserAdded(false);
    }
  }, [newUserAdded])

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      fetchInvitedAttendees({ token, eventId, skip: 0, take: 10 }).then((response) => {
        if (response) {
          setInvitedAttendees(response);
        }
      });
      setIsLoading(false);
    }
  }, [isEdit])

  useEffect(() => {
      if (!isUsersInvitedQueryWorks) {
        setIsLoading(true);
        fetchInvitedAttendees({ token, eventId, skip: 0, take: 10 }).then((response) => {
          if (response) {
            setInvitedAttendees(response);
          }
        });
        setIsLoading(false);
        setIsUsersInvitedQueryWorks(true);
      }
  }, [isUsersInvitedQueryWorks])

  const onDeleteSelected = async () => {
    await Promise.all(selectedAttendees.map(async (item) => {
      setSelectedAttendees(prevState => prevState.filter(it => it !== item));
      await deleteAttendee({attendeeId: item.id, token});
    }))

    fetchInvitedAttendees({ token, eventId, skip: 0, take: 20 }).then((response) => {
      if (response) {
        setInvitedAttendees(response);
      }
    });
  }

  const onDeleteAttendee = async (item: {id: number, name: string, supportText?: string}) => {
    setSelectedAttendees(prevState => prevState.filter(it => it !== item));
    await deleteAttendee({attendeeId: item.id, token});

    fetchInvitedAttendees({ token, eventId, skip: 0, take: 20 }).then((response) => {
      if (response) {
        setInvitedAttendees(response);
      }
    });
  }
  
  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear()
    localStorage.clear();

    navigate('/login');
    
    return null
  }

  return (
    <div className="eventDetails_main">
      {modalNotificationIsOpen 
        ? createPortal(<ModalNotification 
            setIsOpen={setModalNotificationIsOpen} 
            name={modalNotificationIsOpen.name} 
            description={modalNotificationIsOpen.description} 
            onFirstButton={modalNotificationIsOpen.onFirstButtonClick} 
            onSecondButton={modalNotificationIsOpen.onSecondButtonClick} 
            firstButtonName={modalNotificationIsOpen.firstButtonName} 
            secondButtonName={modalNotificationIsOpen.secondButtonName} 
          />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      {
        fetchStatus === 'fetching' || isLoading
          ? <div className='flex fullWidth fullHeight items-center justifyCenter'>
              <Loader size='xl' />
            </div>
          : <>
            <div className={`eventDetails_main-header`}>
              <div className="eventDetails_circles">
                <div className="eventDetails-circle eventDetails-circle-fifth">
                  <div className="eventDetails-circle eventDetails-circle-fourth">
                    <div className="eventDetails-circle eventDetails-circle-third">
                      <div className="eventDetails-circle eventDetails-circle-second">
                        <div className="eventDetails-circle eventDetails-circle-first">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isDesktopSize 
                ? <ProgressSteps slug={eventSlug} current={currentStep} /> 
                : <CustomIcon
                    icon={flagIcon}
                    title='Invite attendees'
                    subtitle='Start collaborating'
                  />
              }
              {!isDesktopSize 
                ? <img
                    src={closeIcon} 
                    alt="close icon" 
                    className={`eventDetails_main-close`}
                    onClick={() => setModalNotificationIsOpen({ name: 'Delete event', description: 'You have not saved this event yet. If you leave now, your event will be deleted. Are you sure you want to continue?', firstButtonName: 'Cancel', secondButtonName: 'Delete event', onFirstButtonClick: () => setModalNotificationIsOpen(null), onSecondButtonClick: () => navigate('/')})}
                  /> 
                : null
              }
            </div>
            <div id="scrollableDiv" className={`eventDetails_content eventDetails_content-agenda ${!isDesktopSize ? 'eventAddAttendees-content' : ''}`}>
              <div className='eventDetails_main-content eventDetails_main-content-inviteAttendees'>
                {
                  !invitedAttendees.attendees.length
                    ? <div className='eventAgenda-createNotification'>
                        <FeaturedIcon icon={usersPlusIcon} size='md' type='gray' />
                        <Button
                          buttonType='regular'
                          size='md'
                          hierarchy='linkColor'
                          paddingNone
                          onClick={() => setIsOpenAddAttendeesDropdown(prevState => !prevState)}
                        >
                          <div className='eventAddAttendees-addFirstAttend'>
                            <p>Add attendees</p>
                            <img src={arrowDown} alt="" />
                          </div>
                        </Button>
                      </div>
                    : <InfiniteScroll
                        dataLength={invitedAttendees.attendees.length}
                        next={fetchMoreAttendees}
                        hasMore={invitedAttendees.attendees.length < invitedAttendees.count}
                        loader={<div className='flex fullWidth justifyCenter'>
                          <Loader size={'xl'}  />
                        </div>}
                        scrollableTarget="scrollableDiv"
                      >
                       <UserInvitesTable
                        content={invitedAttendees.attendees.map(attendee => ({
                          supportText: attendee.networkMember.user ? attendee.networkMember.user.email : attendee.networkMember.email,
                          name: attendee.networkMember.user ? attendee.networkMember.user.fullName : attendee.networkMember.fullName,
                          id: attendee.id,
                          address: attendee.networkMember.address,
                          businessName: attendee.networkMember.businessName,
                          title: attendee.networkMember.title,
                          status: attendee.status
                        }))}
                        token={token}
                        selectedAttendees={selectedAttendees}
                        setSelectedAttendees={setSelectedAttendees}
                        onDeleteSelected={onDeleteSelected}
                        onDeleteAttendee={onDeleteAttendee}
                      />
                    </InfiniteScroll>
                }

                {
                  invitedAttendees.attendees.length
                    ? <div className='eventAddAttendees-addAction'>
                        <Button
                          hierarchy='secondaryColor'
                          size='lg'
                          buttonType='regular'
                          contentCenter
                          onClick={() => setIsOpenAddAttendeesDropdown(prevState => !prevState)}
                        >
                          Add more attendees
                          <img src={chevronDown} alt="" />
                        </Button>
                      </div>
                    : null
                }

                {
                  isOpenAddAttendeesDropdown
                    ? <DropdownMenu 
                        content={[{id: 1, name: 'Add existing attendees'}, {id: 2, name: 'Add new'}]}
                        selectedItem={selectedAddAttendees}
                        setSelectedItem={setSelectedAddAttendees}
                        setShow={() => setIsOpenAddAttendeesDropdown(prevState => !prevState)}
                        size='max-content'
                        positionLeft={selectedAttendees.length || !isDesktopSize ? '10%' : '45%'}
                        positionTop={selectedAttendees.length ? '105%' : '90%'}
                      />
                    : null
                }
                {
                  selectedAddAttendees?.name === 'Add existing attendees'
                    ? isDesktopSize
                        ? createPortal(
                            <ModalAddAttendees
                              attendees={
                                invitedAttendees 
                                  ? attendeesNetwork!.filter(attendee => {
                                      const invitedAttendeesEmails = invitedAttendees.attendees.map(item => item.networkMember.user ? item.networkMember.user.email : item.networkMember.email);
            
                                      return !invitedAttendeesEmails.includes(attendee.email);
                                    })
                                  : attendeesNetwork!
                              }
                              setShow={setSelectedAddAttendees}
                              searchValue={searchValue}
                              setSearchValue={setSearchValue}
                              eventId={eventId}
                              setIsUsersInvitedQueryWorks={setIsUsersInvitedQueryWorks}
                            />, 
                            document.getElementById('modal') as HTMLElement
                          )
                        : createPortal(
                            <AddAttendee
                              attendees={
                                invitedAttendees 
                                  ? attendeesNetwork!.filter(attendee => {
                                      const invitedAttendeesEmails = invitedAttendees.attendees.map(item => item.networkMember.user ? item.networkMember.user.email : item.networkMember.email);
            
                                      return !invitedAttendeesEmails.includes(attendee.email);
                                    })
                                  : attendeesNetwork!
                              }
                              inputValue=''
                              setInputValue={() => {}}
                              searchValue={searchValue}
                              setSearchValue={setSearchValue}
                              setShow={setSelectedAddAttendees}
                              eventId={eventId}
                              setIsUsersInvitedQueryWorks={setIsUsersInvitedQueryWorks}
                            />, 
                            document.getElementById('modal') as HTMLElement
                          )
                    : null
                }
                {
                  selectedAddAttendees?.name === 'Add new'
                    ? createPortal(
                        <ModalAddNewAttende
                          setShow={() => setSelectedAddAttendees(null)}
                          setUserFullName={setNewUserFullName}
                          userFullName={newUserFullName}
                          setUserEmail={setNewUserEmail}
                          userEmail={newUserEmail}
                          setUserPhoneNumber={setNewUserPhoneNumber}
                          userPhoneNumber={newUserPhoneNumber}
                          setUserBusinessName={setNewUserBusinessName}
                          userBusinessName={newUserBusinessName}
                          setUserTitle={setNewUserTitle}
                          userTitle={newUserTitle}
                          eventId={eventId}
                          setNewUserAdded={setNewUserAdded}
                          isDesktopSize={isDesktopSize}
                        />, 
                        document.getElementById('modal') as HTMLElement
                      )
                    : null
                }
              </div>
              <div className='eventDetails_main-footer eventAddAttendees-footer'>
                <Button
                  buttonType='regular'
                  hierarchy='secondaryGray'
                  size='lg'
                  fullWidth
                  contentCenter
                  disabled={!!selectedAttendees.length}
                  onClick={() => {
                    if (isEdit) {
                      navigate(`/edit/${slug}/reviewAndSubmit`)
                    }else {
                      navigate('/createEvent/reviewAndSubmit');
                    }
                  }}
                >
                  <div className='eventDetails_main-footer-buttonSaveAsDraft'>
                    <p>Do this later</p>
                  </div>
                </Button>
                <Button
                  size='lg'
                  buttonType='regular'
                  hierarchy='primary'
                  fullWidth
                  contentCenter
                  disabled={!invitedAttendees.attendees.length}
                  onClick={() => {
                    if (isEdit) {
                      if (currentStep + 1 >= currentLastStep && !isEdit) {
                        navigate(`/createEvent/reviewAndSubmit`);
                      } else {
                        navigate(`/edit/${slug}/reviewAndSubmit`)
                      }
                    }else {
                      navigate('/createEvent/reviewAndSubmit');
                    }
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>  
          </>
      } 
    </div>
  )
}
