import React from 'react';
import verifiedTick from '../../assets/images/verified-tick.svg';

export function UserImagePlaceholder({ name, verified }: { name: string, verified: boolean }  ) {
  return (
    <div className='userImagePlaceholder-wrapper'>
      <p className={`
        userImagePlaceholder userImagePlaceholder-text ${verified ? 'userImagePlaceholder-verified userImagePlaceholder-text-verified' : ''}
      `}>
        {`${name.split(' ')[0].slice(0, 1)}${name.split(' ')[1] ? name.split(' ')[1].slice(0, 1) : ''}`}
      </p>
      {
        verified
          ? <img src={verifiedTick} alt="verified tick" className='userImagePlaceholder-verifiedTick' />
          : null
      }
    </div>
  )
}
