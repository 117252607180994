import React from 'react'
import { TodoItem, TodoItemType } from '../../types/todoItem';
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import presentationIcon from '../../assets/images/presentation-chart-01.svg';
import happyFaceIcon from '../../assets/images/face-happy.svg';
import arrowRight from '../../assets/images/chevron-right-black.svg'
import { useNavigate } from 'react-router-dom';

interface Props {
  toDo: TodoItem,
  name: string,
  description: string,
}

export default function Todo({ toDo, name, description }: Props) {
  const navigate = useNavigate();

  return (
    <div className='eventDetailsWelcomePage-thingsTodo-todo' onClick={() => navigate(`events/${toDo.event.slug}`)}>
      <div className='agendaItem-info-title'>
        <div 
          className={`
            agendaItem-info-title-icon 
              ${toDo.type === TodoItemType.MealOrder || toDo.type === TodoItemType.MealSelection
                  ? 'agendaItem-info-title-icon-meal' 
                  : ''
              }
          `}
        >
          <FeaturedIcon
            icon={
              toDo.type === TodoItemType.RespondToInvite || toDo.type === TodoItemType.ReviewDocument
                ? presentationIcon
                : happyFaceIcon
            }
            type={toDo.type === TodoItemType.RespondToInvite || toDo.type === TodoItemType.ReviewDocument ? 'primary' : 'warning'}
            size='sm'
          />
        </div>
        <div>
            <h3 className='agendaItem-info-title-text'>
              {name}
            </h3>
            <p className='todoCard-description'>
              {description}
            </p>
        </div>
      </div>
      <img src={arrowRight} alt="" style={{ cursor: 'pointer' }} />
    </div>
  )
}
