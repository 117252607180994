export function checkIsPasswordHasSpecialCharacter(password: string) {
  const passwordCharactersArray = password.split('');

  const specialCharacters = '`~!@#$%^&*()_+-={}|[]\:;"\'<>,.?/';

  let hasSpecialCharacter = false;

  for (let i = 0; i < passwordCharactersArray.length; i++) {
    if (specialCharacters.includes(passwordCharactersArray[i])) {
      hasSpecialCharacter = true
    }
  }

  return hasSpecialCharacter;
}