import React from 'react';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import checkCircle from '../../assets/images/check-circle-success.svg';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ModalSaveAsDraft({ setIsOpen }: Props) {
  return (
    <div className='modalNotification-container modalNotification-container-success'>
      <div className='modalNotification-header modalNotification-header-success'>
        <div className='modalNotification-header-actions modalNotification-header-actions-success'>
          <div className='modalNotification-header-actions-icon-container-success'>
            <div className='modalNotification-header-actions-icon modalNotification-header-actions-icon-success'>
              <img src={checkCircle} alt="trash icon" />
            </div>
          </div>
        </div>
        <div className='modalNotification-header-info modalNotification-header-info-success'>
          <h3>Saved as draft</h3>
          <p>Your event changes has been saved and put into draft status.</p>
        </div>
      </div>
      <img
        src={closeIcon}
        alt="close icon"
        className='modalNotification-header-actions-close modalNotification-header-actions-close-success'
        onClick={() => setIsOpen(false)} 
      /> 
    </div>
  )
}
