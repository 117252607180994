import React, { useEffect } from 'react';
import phoenixAvatar from '../../assets/images/Avatar-phoenix.svg';
import  oliviaAvatar from '../../assets/images/Avatar-olivia.svg';
import xClose from '../../assets/images/x-close-gray-small.svg';
import { useState } from 'react';
import { User } from '../../types/userForAddType';
import { Button } from '../Buttons/Button';
import checkIcon from '../../assets/images/check.svg';
import closeIcon from '../../assets/images/x-close-alert.svg';
import { logoColor } from '../../types/logoColor';

interface Props {
  placeholder: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  fullWidth?: boolean,
}

export default function NewCategoryDropdown({ placeholder, onChange, fullWidth }: Props) {
  const [usersFromInput, setUsersFromInput] = useState<{id: string, name: string, color: logoColor | null}[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [showCreateNewTeamInput, setShowCreateNewTeamInput] = useState(false);
  const [selectedColor, setSelectedColor] = useState<logoColor | null>(null);
  const [showSelectColor, setShowSelectColor] = useState(false);
  
  const onChangeAddUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  
    onChange && onChange(event);
  }
  
  const onAddClick = () => {
    const newUser = {
      id: `${inputValue}-${usersFromInput.length + 1}-${new Date().valueOf}`,
      name: inputValue,
      color: selectedColor
    }
    setUsersFromInput(prevState => [...prevState, newUser]);
    setInputValue('');
    setSelectedColor(null);
    setShowCreateNewTeamInput(false);
  };

  useEffect(() => {
    document.getElementById('teamInput')?.focus();
  }, [showCreateNewTeamInput])
  
  return (
    <div className='flex flex-column gap-8 justifyCenter items-center'>
      <div 
        className={`input input-lg ${fullWidth ? 'fullWidth' : ''} ${showCreateNewTeamInput ? 'input-disabled' : ''}`}
      >
        {usersFromInput.length > 0 
          ? <div className='cardsInInputWrapper'>
              {usersFromInput.map(user => 
                <div 
                  className={`
                    cardInInput whiteSpaceNoWrap
                    newCategoryDropdown-teamTicket
                    ${user.color === logoColor.BLUE ? 'newCategoryDropdown-teamTicket-blue' : ''}
                    ${user.color === logoColor.BLUELIGHT ? 'newCategoryDropdown-teamTicket-blueLight' : ''}
                    ${user.color === logoColor.GREEN ? 'newCategoryDropdown-teamTicket-success' : ''}
                    ${user.color === logoColor.ORANGE ? 'newCategoryDropdown-teamTicket-orange' : ''}
                    ${user.color === logoColor.PURPLE ? 'newCategoryDropdown-teamTicket-purple' : ''}
                    ${user.color === logoColor.RED ? 'newCategoryDropdown-teamTicket-red' : ''}
                    ${user.color === logoColor.YELLOW ? 'newCategoryDropdown-teamTicket-yellow' : ''}
                  `} 
                  key={user.id}
                >
                  <h4 className='text-sm font-medium'>{user.name}</h4>
                  <img src={xClose} alt="close icon" onClick={() => setUsersFromInput(prevState => prevState.filter(u => u.id !== user.id))} />
                </div>
              )}
            </div> 
          : null
        }
        <input
          type='text'
          placeholder={placeholder}
          className="text-md"
          value={searchValue}
          size={usersFromInput.length > 0 ? (usersFromInput.length === 1 ? undefined : 13) : undefined}
          tabIndex={1}
          onClick={() => setShowCreateNewTeamInput(prev => !prev)}
        />
      </div>
      {
        showCreateNewTeamInput
          ?<div 
              className={`input input-lg ${fullWidth ? 'fullWidth' : ''} positionRelative`}
            >
              <div className='flex items-center gap-4'>
                {
                  selectedColor
                    ? <div 
                        className={`
                        newCategoryDropdown-colorCircle
                        ${selectedColor === logoColor.BLUE ? 'newCategoryDropdown-colorCircle-blue' : ''}
                        ${selectedColor === logoColor.BLUELIGHT ? 'newCategoryDropdown-colorCircle-blueLight' : ''}
                        ${selectedColor === logoColor.GREEN ? 'newCategoryDropdown-colorCircle-success' : ''}
                        ${selectedColor === logoColor.ORANGE ? 'newCategoryDropdown-colorCircle-orange' : ''}
                        ${selectedColor === logoColor.PURPLE ? 'newCategoryDropdown-colorCircle-purple' : ''}
                        ${selectedColor === logoColor.RED ? 'newCategoryDropdown-colorCircle-red' : ''}
                        ${selectedColor === logoColor.YELLOW ? 'newCategoryDropdown-colorCircle-yellow' : ''}
                        `}
                      />
                    : null
                }
                <input
                  type='text'
                  placeholder={placeholder}
                  className="text-md"
                  value={inputValue}
                  tabIndex={1}
                  onChange={onChangeAddUsers}
                  id='teamInput'
                />
                {
                  inputValue.trim().length
                    ? <Button
                        buttonType='regular'
                        size='sm'
                        hierarchy='linkColor'
                        paddingNone
                        onClick={() => setShowSelectColor(prev => !prev)}
                      >
                        <p className='whiteSpaceNoWrap'>{showSelectColor ? 'Close' : 'Add color'}</p>
                      </Button>
                    : null
                }
              </div>
              <div className='flex gap-8 items-center'>
                <Button
                  buttonType='small'
                  size='sm'
                  hierarchy='tertiaryGray'
                  paddingNone
                  buttonImage={closeIcon}
                  onClick={() => {
                    setInputValue('');
                    setShowCreateNewTeamInput(false);
                    setSelectedColor(null)
                  }}
                />
                <Button
                  buttonType='small'
                  size='sm'
                  hierarchy='tertiaryGray'
                  paddingNone
                  buttonImage={checkIcon}
                  onClick={onAddClick}
                />
              </div>
              {
                showSelectColor
                  ? <div className='newCategoryDropdown-colorPicker'>
                      <h3 className='modalCreateEventLogo-label'>Color*</h3>
                      <div className='modalCreateEventLogo-content'>
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-error
                            ${selectedColor === logoColor.RED ? 'modalCreateEventLogo-color-error-selected' : ''}
                          `}
                          onClick={() => setSelectedColor(logoColor.RED)}
                        />
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-warning
                            ${selectedColor === logoColor.ORANGE ? 'modalCreateEventLogo-color-warning-selected' : ''}
                          `}
                          onClick={() => setSelectedColor(logoColor.ORANGE)}
                        />
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-yellow
                            ${selectedColor === logoColor.YELLOW ? 'modalCreateEventLogo-color-yellow-selected' : ''}
                          `}
                          onClick={() => setSelectedColor(logoColor.YELLOW)}
                        />
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-success
                            ${selectedColor === logoColor.GREEN ? 'modalCreateEventLogo-color-success-selected' : ''}
                          `}
                          onClick={() => setSelectedColor(logoColor.GREEN)}
                        />
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-primary 
                            ${selectedColor === logoColor.BLUE ? 'modalCreateEventLogo-color-primary-selected' : ''}
                          `}
                          onClick={() => setSelectedColor(logoColor.BLUE)}
                        />
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-purple
                            ${selectedColor === logoColor.PURPLE ? 'modalCreateEventLogo-color-purple-selected' : ''}
                          `}
                          onClick={() => setSelectedColor(logoColor.PURPLE)}
                        />
                        <div 
                          className={`
                            modalCreateEventLogo-color modalCreateEventLogo-color-blue
                            ${selectedColor === logoColor.BLUELIGHT ? 'modalCreateEventLogo-color-blue-selected' : ''}
                         `}
                           onClick={() => setSelectedColor(logoColor.BLUELIGHT)}
                        />
                      </div>      
                    </div>
                : null
              }
            </div>
          : null
      }
    </div>
  )
}
