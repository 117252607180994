import React from 'react';

interface Props {
  hierarchy: 'primary' | 'default' | 'dark',
  progress: number,
  showPercent?: boolean,
}

export default function ProgressLine({ hierarchy, progress, showPercent }: Props) {
  return (
    <div className='flex flex-row items-center gap-12'>
      <div 
        className={`
          progressBar-progress
          ${hierarchy === 'primary' ? 'progressBar-progress-primary' : ''}
          ${hierarchy === 'default' ? 'progressBar-progress-default' : ''}
          ${hierarchy === 'dark' ? 'progressBar-progress-dark' : ''}
        `}
      >
        <div 
          className={`
            progressBar-progress-withColor
            ${hierarchy === 'primary' ? 'progressBar-progress-withColor-primary' : ''}
            ${hierarchy === 'default' ? 'progressBar-progress-withColor-default' : ''}
            ${hierarchy === 'dark' ? 'progressBar-progress-withColor-dark' : ''}
          `} 
          style={{width: `${progress}%`}} 
        />
      </div>
      {
        showPercent
          ? <p className='progressBar-progress-percents'>{progress}%</p>
          : null
      }
    </div>
  )
}
