import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo-lg-color.svg';
import mailIcon from '../../assets/images/mail-01-black.svg';
import { Button } from '../../components/Buttons/Button';
import FeaturedIcon from '../../components/FeaturedIcons/FeaturedIcon';
import leftArrow from '../../assets/images/arrow-left.svg';
import bgIcon from '../../assets/images/contentAccountCreatedBg.svg';
import VarifyEmail from './VerifyEmail'
import VerifyEmail from './VerifyEmail';
import { supabase } from '../../utilities/supabaseClient';
import { useNavigate } from 'react-router-dom';

export default function CheckEmail({ email } : { email: string }) {
  const [enterCodeManually, setEnterCodeManualy] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  return (
    <>
      {
        enterCodeManually
          ? <VerifyEmail email={email} />
          : <div className='accountCreated'>
            <header className='accountCreated-header'>
              <img src={logo} alt="" className='accountCreated-header-logo' />
            </header>
            <section className='accountCreated-content'>
              <img src={bgIcon} alt="" className='accountCreated-content-image' />
              <div className='accountCreated-content-content'>
                <div className='flex flex-column gap-24 items-center'>
                  <FeaturedIcon
                    icon={mailIcon}
                    size='lg'
                    type='gray'
                  />
                  <div className='flex flex-column items-center'>
                    <h3 className='accountCreated-content-content-title'>Check your email</h3>
                    <div className='flex flex-column gap-4 items-center'>
                      <p className='accountCreated-content-content-description'>We sent a password reset link to</p>
                      <p className='accountCreated-content-content-medium'>{email}</p>
                    </div>
                  </div>
                </div>
                <Button
                  buttonType='regular'
                  hierarchy='primary'
                  size='lg'
                  fullWidth
                  contentCenter
                  onClick={() => setEnterCodeManualy(true)}
                >
                  Enter code manually
                </Button>
                <div className='flex gap-4 items-center'>
                  <p className='login-signIn-copyright'>Didn’t receive the email?</p>
                  <Button
                    buttonType='regular'
                    hierarchy='linkColor'
                    size='md'
                    paddingNone
                    asyncOnClick={async () => {
                      await supabase().auth.resetPasswordForEmail(email, {
                        redirectTo: 'http://localhost:3000/createNewPassword'
                      });
                    }}
                  >
                    Click to resend
                  </Button>
                </div>
                <Button
                  buttonType='regular'
                  hierarchy='linkGray'
                  size='md'
                  onClick={() => navigate('/login')}
                >
                  <div className='flex gap-8 items-center'>
                    <img src={leftArrow} alt="" />
                    <p>Back to Log in</p>
                  </div>
                </Button>
              </div>
            </section>
          </div>
      }
    </>
  )
}
