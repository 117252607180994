import React, { useState } from 'react'
import { Button } from '../Buttons/Button'
import RSVPDropdown from '../Dropdown/RSVPDropdown'
import { AttendeeRSVP } from '../../types/attendeeRSVP';
import arrowDown from '../../assets/images/chevron-down.svg';
import arrowDownPrimary from '../../assets/images/chevron-down-primary.svg';
import arrowDownError from '../../assets/images/chevron-down-error.svg';

interface Props { 
  selectedRSVP: AttendeeRSVP | undefined, 
  setSelectedRSVP: React.Dispatch<React.SetStateAction<AttendeeRSVP | undefined>>, 
  onRSVPSelect: (item: AttendeeRSVP) => Promise<void>
}

export default function RSVP(
  { selectedRSVP, setSelectedRSVP, onRSVPSelect }: Props) {
  const [isRSVPDropdownOpen, setIsRSVPDropdownOpen] = useState(false);

  return (
    <div style={{position: 'relative'}}>
      <Button 
        size='md'
        hierarchy={!selectedRSVP ? 'secondaryGray' : selectedRSVP === AttendeeRSVP.Yes ? 'secondaryColor' : selectedRSVP === AttendeeRSVP.No ? 'errorSecondaryColor' : 'secondaryGray'}
        buttonType='regular' 
        onClick={() => setIsRSVPDropdownOpen(prev => !prev)}         
      >
        <div className='flex gap-8 items-center'>
          <p 
            className={`
              text-sm 
              font-semibold 
              ${selectedRSVP === AttendeeRSVP.Yes ? 'color-primary-600' : selectedRSVP === AttendeeRSVP.No ? 'color-error-700' : 'color-gray-700'}`}
            >{`RSVP${selectedRSVP ? ':' : ''} ${selectedRSVP ? selectedRSVP : ''}`}</p>
          <img src={selectedRSVP === AttendeeRSVP.Yes ? arrowDownPrimary : selectedRSVP === AttendeeRSVP.No ? arrowDownError : arrowDown} alt="" />
        </div>
      </Button>
      <div style={{position: 'absolute', width: '126px', top: '63%'}}>
        {
          isRSVPDropdownOpen
            ? <RSVPDropdown 
                selectedItem={selectedRSVP} 
                setSelectedItem={setSelectedRSVP} 
                content={[AttendeeRSVP.Maybe, AttendeeRSVP.Yes, AttendeeRSVP.No]} 
                onSelect={onRSVPSelect}          
              />
            : null
        }
      </div>
    </div>
  )
}
