import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react'
import Navigation from "../../components/Navigation/SideBarNavigation";
import oliviaPhoto from '../../assets/images/Avatar-olivia.svg';
import CheckBox from '../../components/CheckBox/CheckBox';
import trashIcon from '../../assets/images/trash-01-black.svg';
import pencilIcon from '../../assets/images/edit-01.svg';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import { Button } from '../../components/Buttons/Button';
import oliviaAvatar from '../../assets/images/Avatar-olivia.svg';
import phoenixAvatar from '../../assets/images/Avatar-phoenix.svg';
import arrowDown from '../../assets/images/arrow-down.svg';
import arrowUp from '../../assets/images/arrow-up-success.svg';
import helpIcon from '../../assets/images/help-circle.svg';
import Pagination from '../../components/Paginations/Pagination';
import plusIcon from '../../assets/images/plus.svg';
import ContactsTable from '../../components/Tables/ContactsTable';
import ReviewContactDetails from '../../components/reviewContactDetails/reviewContactDetails';
import { createPortal } from 'react-dom';
import ModalAddNewAttende from '../../components/Modal/ModalAddNewAttende';
import { useQuery } from '@tanstack/react-query';
import { fetchAttendeesContacts, queryClient } from '../../httpQueries/http';
import Loader from '../../components/Loader';
import { logoColor } from '../../types/logoColor';
import { logoImage } from '../../types/logoImage';
import { AttendeeRSVP } from '../../types/attendeeRSVP';
import EmptyPage from './EmptyPage';
import ModalUserAdded from '../../components/Modal/ModalUserAdded';

export default function Contacts() {
  const size = useWindowSize();
  const token = localStorage.getItem('token');
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [reviewContactDetailsIsOpen, setReviewContactDetailsIsOpen] = useState(false);
  const [createNewUserIsOpen, setCreateNewUserIsOpen] = useState(false);
  const [newUserFullName, setNewUserFullName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState('');
  const [newUserBusinessName, setNewUserBusinessName] = useState('');
  const [newUserTitle, setNewUserTitle] = useState('');
  const [newUserAdded, setNewUserAdded] = useState(false);
  const [addedUserName, setAddedUserName] = useState('');
  const [showUserInfo, setShowUserInfo] = useState<{
        "id": number,
        "fullName": string,
        "email": string,
        "phone"?: string,
        "businessName": string,
        "address": string,
        "title": string,
        "updatedAt": string,
        "user": {
          "id": number,
          "email": string,
          "fullName": string,
          "phone"?: null | string,
        },
        "events": [
          {
            "id": number,
            "name": string,
            "slug": string,
            "description": string,
            "timezone": string,
            "color": null | logoColor,
            "pattern": null | logoImage,
            "startTime": string,
            "endTime": string,
            "attendee": {
              "email": string,
              "userId": number,
              "status": "added",
              "rsvp": null | AttendeeRSVP
            }
          },
        ],
  } | null>(null)
  const [selectedPaginationPage, setSelectedPaginationPage] = useState<number>(0);
  const [userUpdated, setUserUpdated] = useState<number | null>(null);
  const { data: network, fetchStatus } = useQuery({
    queryKey: ['network', newUserAdded, selectedPaginationPage, userUpdated, token],
    queryFn: () => fetchAttendeesContacts({skip: selectedPaginationPage * 10, take: (selectedPaginationPage + 1) * 10, token, fetchEvents: true }),
  });
  const [userDeleted, setUserDeleted] = useState<number | null>(null);

  useEffect(() => {
    if (userDeleted) {
      queryClient.setQueryData(['network', newUserAdded, selectedPaginationPage, userUpdated, token], {
        network: network!.network.filter(contact => contact.id !== userDeleted),
        count: network!.count - 1,
      });

      setUserDeleted(null);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (newUserFullName.length) {
      setAddedUserName(newUserFullName);
    }
  }, [newUserFullName])

  useEffect(() => {
    if (userDeleted === showUserInfo?.id) {
      setShowUserInfo(null);
    }

    if (userUpdated === showUserInfo?.id) {
      setShowUserInfo(network?.network.find(contact => contact.id === showUserInfo.id) || null);
    }
  }, [userDeleted, userUpdated])
  
  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]) 
  return (
    <div className={`eventDetails_container ${!sizeIsDesktop ? 'flex-column' : 'flex-row'}`} style={{background: sizeIsDesktop ? undefined : 'none'}}>
      {
        createNewUserIsOpen
          ? createPortal(
              <ModalAddNewAttende
                setShow={() => setCreateNewUserIsOpen(false)}
                setUserFullName={setNewUserFullName}
                userFullName={newUserFullName}
                setUserEmail={setNewUserEmail}
                userEmail={newUserEmail}
                setUserPhoneNumber={setNewUserPhoneNumber}
                userPhoneNumber={newUserPhoneNumber}
                setUserBusinessName={setNewUserBusinessName}
                userBusinessName={newUserBusinessName}
                setUserTitle={setNewUserTitle}
                userTitle={newUserTitle}
                eventId={0}
                setNewUserAdded={setNewUserAdded}
                isContactsPage={!userUpdated}
                isEdit={userUpdated}
                setUserUpdated={setUserUpdated}
                isDesktopSize={sizeIsDesktop}
              />, 
              document.getElementById('modal') as HTMLElement
            )
          : null
      }
      {
        newUserAdded
          ? createPortal(
              <ModalUserAdded setIsOpen={setNewUserAdded} name={addedUserName} />, 
              document.getElementById('modal') as HTMLElement
            )
          : null
      }
      <Navigation
        hierarchy='primary'
        currentItem='Contacts/Network'
      />
      <div className='eventDetails_main-container eventDetails_main-container-reviewEventDetails flex-row flex' style={{marginTop: sizeIsDesktop ? undefined : '0'}}>
        <div className="eventDetails_main reviewEventDetailsPage">
          <header className='reviewEventDetailsPage-header'>
            <h3 className='reviewEventDetailsPage-header-title-lg'>Contacts</h3>
          </header>
          <main className='reviewEventDetailsPage-main overflowYScroll'>
            {
              fetchStatus !== 'fetching'
                ? network?.count 
                    ? <ContactsTable 
                        selectedPaginationPage={selectedPaginationPage} 
                        setSelectedPaginationPage={setSelectedPaginationPage} 
                        setIsNewUserModalOpen={setCreateNewUserIsOpen} 
                        content={network} 
                        setReviewContactDetailsIsOpen={setReviewContactDetailsIsOpen} 
                        setShowUserInfo={setShowUserInfo}
                        setUserUpdated={setUserUpdated}
                        setUserFullName={setNewUserFullName}
                        setUserEmail={setNewUserEmail}
                        setUserPhoneNumber={setNewUserPhoneNumber}
                        setUserBusinessName={setNewUserBusinessName}
                        setUserTitle={setNewUserTitle}
                        setUserDeleted={setUserDeleted}
                        selected={showUserInfo?.id}
                      />
                    : <EmptyPage setCreateNewUserIsOpen={setCreateNewUserIsOpen} />
                : <div className='fullHeight fullWidth flex items-center justifyCenter'>
                    <Loader size='xl' />
                  </div>
            }
          </main>
        </div>
        {
          reviewContactDetailsIsOpen
            ? <ReviewContactDetails 
                setShowUserInfo={setShowUserInfo}
                setIsOpen={setReviewContactDetailsIsOpen} 
                showUserInfo={showUserInfo} 
                setUserUpdated={setUserUpdated}
                setUserFullName={setNewUserFullName}
                setUserEmail={setNewUserEmail}
                setUserPhoneNumber={setNewUserPhoneNumber}
                setUserBusinessName={setNewUserBusinessName}
                setUserTitle={setNewUserTitle}
                setUserDeleted={setUserDeleted}
                setIsNewUserModalOpen={setCreateNewUserIsOpen} 
              />
            : null
        }
      </div>
    </div>
  )
}
