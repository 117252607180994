import React, { useContext, useEffect, useState } from 'react';
import starsIcon from '../../../assets/images/stars-02.svg';
import ProgressSteps from '../../../components/ProgressSteps/ProgressSteps';
import { CustomIcon } from '../../../components/CustomIcons/CustomIcon';
import { EventInfoContext } from '../../../utilities/eventInfoContext';
import ReviewDetailsSection from '../../../components/Sections/ReviewDetailsSection';
import { useNavigate, useParams } from 'react-router-dom';
import ReviewAgendaSection from '../../../components/Sections/ReviewAgendaSection';
import { Button } from '../../../components/Buttons/Button';
import saveIcon from '../../../assets/images/save-01.svg';
import ModalSaveAsDraft from '../../../components/Modal/ModalSaveAsDraft';
import closeIcon from '../../../assets/images/x-close.svg';
import ModalNotification from '../../../components/Modal/ModalNotification';
import { createPortal } from 'react-dom';
import ModalEventWasCreated from '../../../components/Modal/ModalEventWasCreated';
import HorizontalTabs from '../../../components/Tabs/HorizontalTabs';
import LogoRepeat from '../../../components/LogoRepeat/LogoRepeat';
import ModalCreateEventLogo from '../../../components/Modal/ModalCreateEventLogo';
import presentationIcon from '../../../assets/images/presentation-chart-01.svg';
import clockPlusIcon from '../../../assets/images/clock-plus.svg';
import happyFaceIcon from '../../../assets/images/face-happy.svg';
import { fetchEvent, fetchEventAgenda, postEventLogo, publishEvent } from '../../../httpQueries/http';
import pathIcon from '../../../assets/images/path90.svg';
import { logoColor } from '../../../types/logoColor';
import { logoImage } from '../../../types/logoImage';
import { getLogoRepeatImage } from '../../../utilities/getLogoRepeatImage';
import { getEventLogo } from '../../../utilities/getEventLogoImage';
import { supabase } from '../../../utilities/supabaseClient';
import checkFileIcon from '../../../assets/images/file-check-02.svg';
import AttachDocumentModal from '../../../components/Modal/AttachDocumentModal';
import { DropdownMenu } from '../../../components/Dropdown/DropdownMenu';
import linkIcon from '../../../assets/images/link-03.svg';
import uploadCloudIcon from '../../../assets/images/upload-cloud-02-black.svg';
import AttachLinkModal from '../../../components/Modal/AttachLinkModal';
import { AttendeeRSVP } from '../../../types/attendeeRSVP';
import { AgendaBlockType } from '../../../types/agendaBlockType';
import moment from 'moment';
import { getUTCTimeFromMinutes } from '../../../utilities/getUTCTimeFromMinutes';
import { editTime } from '../../../utilities/EditAgendaBlockTime';
import { durations } from '../../../utilities/agendaDurationsArray';

interface Props {
  isDesktopSize: boolean,
  setInvitedAttendees: React.Dispatch<React.SetStateAction<{
    attendees: {
      id: number,
      networkMemberId: number,
      status: 'added',
      rsvp: AttendeeRSVP | null,
      networkMember: {
        id: number,
        fullName: string,
        email: string,
        phone: string,
        businessName: string,
        address: string,
        title: string,
        userId: null | number,
        accountId: number,
        createdAt: string,
        updatedAt: string,
        user: {
          accountId: number,
          createdAt: string,
          email: string,
          fullName: string,
          id: number,
          phone?: string | null,
          status: string,
          supabaseId: string,
          updatedAt: string,
        } | null,
      }
    }[],
    count: number,
  }>>,
  isEdit?: boolean,
  setAttachedDocumentsCount: React.Dispatch<React.SetStateAction<number>>,
  setSelectedStartTime: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  } | null>>,
  setSelectedEndTime: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
  } | null>>,
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
  setDescriptionInputValue: React.Dispatch<React.SetStateAction<string>>,
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>,
  setEventIds: React.Dispatch<React.SetStateAction<{
    eventId: number;
    eventDayId: number;
  } | null>>,
  setSelectedLocation: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    supportText?: string | undefined;
    lat?: string,
    lng?: string
  } | null>>,
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>,
  setSelectedZone: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    timezone?: string
  } | null>>,
  setAgenda: React.Dispatch<React.SetStateAction<{
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
        id: number;
        name: string;
        value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number
  }[]>>,
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>,
  currentLastStep: number,
  eventSlug: string,
  setSlug: React.Dispatch<React.SetStateAction<string>>
}

export default function EventReviewAndSubmit({ 
  isDesktopSize,
  setInvitedAttendees, 
  isEdit, 
  setAttachedDocumentsCount,
  setAgenda,
  setDescriptionInputValue,
  setEventIds,
  setFirstDate,
  setLocationNotes,
  setNameInputValue,
  setSelectedEndTime,
  setSelectedLocation,
  setSelectedStartTime,
  setSelectedZone,
  setCurrentLastStep,
  currentLastStep,
  eventSlug,
  setSlug
}: Props) {
  const currentStep = 4;
  const {
    agenda,
    eventDate,
    eventDescription,
    eventName,
    location,
    startTime,
    endTime,
    invitedAttendees,
    locationNotes,
    timeZone,
    eventIds,
    attachedDocumentsCount
  } = useContext(EventInfoContext);
  const [isSavedAsDraft, setIsSavedAsDraft] = useState(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{name: string, description: string, firstButtonName: string, secondButtonName: string, onFirstButtonClick: () => void, onSecondButtonClick: () => void} | null>(null);
  const [modalEventWasCreatedIsOpen, setModalEventWasCreatedIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState<{id: number, name: string} | null>({id: 1, name: 'Overview'});
  const [isEditLogoModalOpen, setIsEditLogoModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [eventLogo, setEventLogo] = useState<logoImage>(logoImage.PATH);
  const [eventLogoColor, setEventLogoColor] = useState<logoColor>(logoColor.BLUE);
  const [isAttachDocumentModalOpen, setIsAttachDocumentModalOpen] = useState(false);
  const token = localStorage.getItem('token');
  const [logoRepeaterHeight, setLogoRepeaterHeight] = useState('64%');
  const slug = useParams().slug;
  const [selectedFileModal, setSelectedFileModal] = useState<{id: number, name: string, img?: string} | null>(null);
  const [isSelectFileModalOpen, setIsSelectFileModalOpen] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState(false);
  const navigate = useNavigate();

  async function loadEditableEventData() {
    const data = await fetchEvent({ slug, token});
    return data;
  }

  useEffect(() => {
    if (isEdit) {
      loadEditableEventData().then(data => {
      setFirstDate(moment(data!.startDate));
      setDescriptionInputValue(data!.description);
      setNameInputValue(data!.name);
      setSelectedEndTime(data?.endTime ? { id: 1, name: getUTCTimeFromMinutes(data!.endTime) } : null)
      setSelectedLocation({ id: 1, name: data!.location, supportText: '', lat: data?.locationLatitude ? data.locationLatitude : '0', lng: data?.locationLongitude ? data.locationLongitude : '0'})
      setLocationNotes(data?.locationNote || '')
      setSelectedStartTime({ id: 1, name: getUTCTimeFromMinutes(data!.startTime) })
      setSelectedZone(data?.timezone ? {id: 1, name: data.timezone, timezone: data.timezoneName } : null)
      setEventIds({eventId: data!.id, eventDayId: data!.days[0].id});
      setSlug(data!.slug)
    })
  }
  }, [isEdit])

  useEffect(() => {
    if (slug) {
      fetchEvent({ slug, token }).then(response => {
        if (response) {
          fetchEventAgenda({dayId: response!.id, token}).then((res) => {
            const agenda = editTime(res!.sort((it1, it2) => it1.index - it2.index).map((item, index) => ({
              id: `${item.id}`,
              icon: item.type === AgendaBlockType.Break ? clockPlusIcon : item.type === AgendaBlockType.Meal ? happyFaceIcon : presentationIcon,
              name: item.name,
              description: item.description,
              duration: durations.find(duration => duration.value === item.duration) || {id: 1, name: `${item.duration} min`, value: item.duration},
              startTime: getUTCTimeFromMinutes(response!.startTime),
              endTime: response?.endTime ?getUTCTimeFromMinutes(response!.endTime) : '',
              type: item.type,
              index
            })), getUTCTimeFromMinutes(response!.startTime));
            setAgenda(agenda!);
          })
          setIsPublished(response.isPublished);
          setEventLogo(response.pattern || logoImage.PATH);
          setEventLogoColor(response.color || logoColor.BLUE);
          setFirstDate(moment(response!.startDate));
          setDescriptionInputValue(response!.description);
          setNameInputValue(response!.name);
          setSelectedEndTime(response?.endTime ? { id: 1, name: getUTCTimeFromMinutes(response!.endTime) } : null)
          setSelectedLocation({ id: 1, name: response!.location, supportText: '' })
          setLocationNotes(response?.locationNote || '')
          setSelectedStartTime({ id: 1, name: getUTCTimeFromMinutes(response!.startTime) })
          setSelectedZone(response?.timezone ? {id: 1, name: response.timezone } : null)
          setEventIds({eventId: response!.id, eventDayId: response!.days[0].id});
          setAttachedDocumentsCount(response.documents.length)
        }
      })
    } else {
      setEventLogo(logoImage.PATH);
      setEventLogoColor(logoColor.BLUE)
      setAttachedDocumentsCount(0)
    }
  }, [slug])

  useEffect(() => {
    if ((!eventDate || !agenda.length || !eventDescription || !eventName || !location || !startTime) && !isEdit) {
      navigate('/');
    }

    if (currentLastStep < currentStep) {
      setCurrentLastStep(currentStep);
    }
  }, []);

  useEffect(() => {
    if (isSavedAsDraft === true) {
      setTimeout(() => setIsSavedAsDraft(false), 5000)
    }
  }, [isSavedAsDraft]);

  function scrollFunction() {
    if (Math.ceil(document.getElementById('scrolledInfo')!.scrollTop) >= 1) {
      setIsScrolled(true);
      setLogoRepeaterHeight('33%');
    } else {
      setIsScrolled(false);
      setLogoRepeaterHeight('64%');
    }
  }

  if (!eventDate || !agenda.length || !eventDescription || !eventName || !location || !startTime || !eventIds) {
    return null;
  }

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear()
    localStorage.clear();

    navigate('/login');
    
    return null
  }

  return (
    <div className="eventDetails_main">
      {modalNotificationIsOpen 
        ? createPortal(<ModalNotification 
            setIsOpen={setModalNotificationIsOpen} 
            name={modalNotificationIsOpen.name} 
            description={modalNotificationIsOpen.description} 
            onFirstButton={modalNotificationIsOpen.onFirstButtonClick} 
            onSecondButton={modalNotificationIsOpen.onSecondButtonClick} 
            firstButtonName={modalNotificationIsOpen.firstButtonName} 
            secondButtonName={modalNotificationIsOpen.secondButtonName} 
          />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      {modalEventWasCreatedIsOpen 
        ? createPortal(<ModalEventWasCreated setIsOpen={setModalEventWasCreatedIsOpen} />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      {isAttachDocumentModalOpen && selectedFileModal
        ? selectedFileModal.name === 'Upload files'
            ? createPortal(<AttachDocumentModal widthIsMobile={!isDesktopSize} setSelectedFileModal={setSelectedFileModal} attachedFilesCount={attachedDocumentsCount} setShow={setIsAttachDocumentModalOpen} eventId={eventIds.eventId} />, document.getElementById('modal') as HTMLElement) 
            : createPortal(<AttachLinkModal setSelectedFileModal={setSelectedFileModal} setShow={setIsAttachDocumentModalOpen} eventId={eventIds.eventId} />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      {isEditLogoModalOpen 
        ? createPortal(<ModalCreateEventLogo onSave={(color: logoColor, image: logoImage) => {
          setEventLogo(image);
          setEventLogoColor(color);
          postEventLogo({ body: { color, pattern: image }, token, id: eventIds.eventId });
        }} setIsOpen={setIsEditLogoModalOpen} />, document.getElementById('modal') as HTMLElement) 
        : null
      }
      {isSavedAsDraft
        ? <ModalSaveAsDraft
            setIsOpen={setIsSavedAsDraft}
          />
        : null
      }
      <div className={`eventDetails_main-header  eventDetails_main-header-review`}>
        <div className="eventDetails_circles">
          <div className="eventDetails-circle eventDetails-circle-fifth">
            <div className="eventDetails-circle eventDetails-circle-fourth">
              <div className="eventDetails-circle eventDetails-circle-third">
                <div className="eventDetails-circle eventDetails-circle-second">
                  <div className="eventDetails-circle eventDetails-circle-first">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isDesktopSize 
          ? <ProgressSteps slug={eventSlug} current={currentStep} /> 
          : <CustomIcon
              icon={starsIcon}
              title='Event details'
              subtitle='Set up the basics'
            />
        }
        {!isDesktopSize 
          ? <img
              src={closeIcon} 
              alt="close icon" 
              className={`eventDetails_main-close`}
              onClick={() => setModalNotificationIsOpen({ name: 'Delete event', description: 'You have not saved this event yet. If you leave now, your event will be deleted. Are you sure you want to continue?', firstButtonName: 'Cancel', secondButtonName: 'Delete event', onFirstButtonClick: () => setModalNotificationIsOpen(null), onSecondButtonClick: () => navigate('/')})}
            /> 
          : null
        }
      </div>
      <div className='eventDetails_content eventDetails_main-container-reviewEventDetails' style={{marginTop: isDesktopSize ? undefined : '0'}}>
        <div className="eventDetails_main reviewEventDetailsPage">
          <header className='reviewEventDetailsPage-header'>
            <LogoRepeat 
              color={eventLogoColor} 
              image={(eventLogo === logoImage.PATH ? pathIcon : getLogoRepeatImage({ selectedColor: eventLogoColor, selectedPattern: eventLogo }))} 
              size={'lg'} 
              height={logoRepeaterHeight} 
              position='absolute' 
            />
            <div className='reviewEventDetailsPage-header-content' id='review-content' style={{paddingTop: '0', marginTop: isScrolled ? '3%' : '20%'}}>
              <div className={`
                reviewEventDetailsPage-header-mainImage
                ${eventLogoColor === logoColor.YELLOW ? 'reviewEventDetailsPage-header-mainImage-yellow' : ''}
                ${eventLogoColor === logoColor.BLUELIGHT ? 'reviewEventDetailsPage-header-mainImage-blueLight' : ''}
                ${eventLogoColor === logoColor.RED ? 'reviewEventDetailsPage-header-mainImage-error' : ''}
                ${eventLogoColor === logoColor.PURPLE ? 'reviewEventDetailsPage-header-mainImage-purple' : ''}
                ${eventLogoColor === logoColor.GREEN ? 'reviewEventDetailsPage-header-mainImage-success' : ''}
                ${eventLogoColor === logoColor.ORANGE ? 'reviewEventDetailsPage-header-mainImage-warning' : ''}
              `}>
                <img src={getEventLogo(eventLogo)} alt="" onClick={() => setIsEditLogoModalOpen(true)} />
              </div>
              <div className='reviewEventDetailsPage-header-info'>
                <div className='reviewEventDetailsPage-header-info-text'>
                  <h3 className='reviewEventDetailsPage-header-info-text-title'>{eventName}</h3>
                  <p className='reviewEventDetailsPage-header-info-text-decription'>{eventDescription}</p>
                </div>
              </div>
            </div>
          </header>
          <main className='reviewEventDetailsPage-main overflowYVisible'>
            <HorizontalTabs 
              tabs={[{id: 1, name: 'Overview'}, {id: 2, name: 'Invitees'}, {id: 3, name: 'Notifications'}]} 
              current={currentTab} 
              setCurrent={setCurrentTab}
              type='Underline'
            />
            {
              currentTab && currentTab.id === 1 && currentTab.name === 'Overview'
                ? <div 
                    className={`eventDetails_main-content gap-32 overflowYScroll ${isDesktopSize ? 'flex-row' : ''}`} 
                    // onScroll={() => {
                    //   scrollFunction();
                    // }}
                    style={{marginBottom: '70px'}}
                    id='scrolledInfo'
                  >
                    <ReviewDetailsSection
                      eventDate={eventDate!}
                      eventDescription={eventDescription!}
                      eventLocation={location!.name}
                      eventName={eventName!}
                      eventStartTime={startTime?.name!}
                      eventLocationNotes={locationNotes!}
                      lat={location.lat || null}
                      lng={location.lng || null}
                      documents={undefined}
                      eventId={eventIds.eventId}
                    />
                    <ReviewAgendaSection 
                      agenda={agenda}
                    />
                  </div>
                : null
            }
            {
              currentTab && currentTab.id === 2 && currentTab.name === 'Invitees'
                ? <div 
                    className={`eventDetails_main-content gap-32 overflowYScroll ${isDesktopSize ? 'flex-row' : ''}`} 
                    // onScroll={() => {
                    //   scrollFunction();
                    // }}
                    style={{marginBottom: '70px'}}
                    id='scrolledInfo'
                  >
                    <ReviewDetailsSection
                      eventDate={eventDate!}
                      eventDescription={eventDescription!}
                      eventLocation={location!.name}
                      eventName={eventName!}
                      eventStartTime={startTime?.name!}
                      eventLocationNotes={locationNotes!}
                      lat={location.lat || null}
                      lng={location.lng || null}
                      documents={undefined}
                      eventId={eventIds.eventId}
                    />
                    <ReviewAgendaSection 
                      agenda={agenda}
                    />
                  </div>
                : null
            }
          </main>
        </div>
      </div>
      <footer className='reviewEventDetailsPage-footer'>
        <Button
          buttonType='regular'
          hierarchy='secondaryGray'
          size='lg'
          disabled={!agenda.length}
          onClick={() => {
            setIsSavedAsDraft(true);
          }}
        >
          <div className='eventDetails_main-footer-buttonSaveAsDraft'>
            <img src={saveIcon} alt="save Icon" />
            <p>Save as draft</p>
          </div>
        </Button>
        {
          isSelectFileModalOpen
            ? <DropdownMenu 
                content={[{id: 1, name: 'Add links', img: linkIcon}, {id: 2, name: 'Upload files', img: uploadCloudIcon}]}
                selectedItem={selectedFileModal}
                setSelectedItem={setSelectedFileModal}
                setShow={() => {
                  setIsSelectFileModalOpen(false);
                }}
                size='max-content'
                positionRight={'15%'}
                positionTop={'-70%'}
              />
            : null
        }
        <Button
          buttonType='regular'
          hierarchy='secondaryColor'
          size='lg'
          onClick={() => {
            setIsSelectFileModalOpen(true);
            setIsAttachDocumentModalOpen(true);
          }}
        >
          <div className='eventDetails_main-footer-buttonSaveAsDraft'>
            <img src={checkFileIcon} alt="save Icon" />
            <p>Attach documents</p>
          </div>
        </Button>
        <Button
          size='lg'
          buttonType='regular'
          hierarchy='primary'
          contentCenter
          disabled={!agenda.length}
          asyncOnClick={async () => {
            if (!isPublished) {
              await publishEvent({eventId: eventIds.eventId, token});
            }
            navigate('/events')
          }}
        >
          {isEdit ? 'Save' : 'Create event'}
        </Button>
      </footer>
    </div>
  )
}
