export const timeZones = [
    {
        "id": 1,
        "name": "(GMT-11:00) Midway Island",
        "timezone": "Pacific/Midway"
    },
    {
        "id": 2,
        "name": "(GMT-11:00) Samoa",
        "timezone": "US/Samoa"
    },
    {
        "id": 3,
        "name": "(GMT-10:00) Hawaii",
        "timezone": "US/Hawaii"
    },
    {
        "id": 4,
        "name": "(GMT-09:00) Alaska",
        "timezone": "US/Alaska"
    },
    {
        "id": 5,
        "name": "(GMT-08:00) Pacific Time (US & Canada)",
        "timezone": "US/Pacific"
    },
    {
        "id": 6,
        "name": "(GMT-08:00) Tijuana",
        "timezone": "America/Tijuana"
    },
    {
        "id": 7,
        "name": "(GMT-07:00) Arizona",
        "timezone": "US/Arizona"
    },
    {
        "id": 8,
        "name": "(GMT-07:00) Mountain Time (US & Canada)",
        "timezone": "US/Mountain"
    },
    {
        "id": 9,
        "name": "(GMT-07:00) Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "id": 10,
        "name": "(GMT-07:00) Mazatlan",
        "timezone": "America/Mazatlan"
    },
    {
        "id": 11,
        "name": "(GMT-06:00) Mexico City",
        "timezone": "America/Mexico_City"
    },
    {
        "id": 12,
        "name": "(GMT-06:00) Monterrey",
        "timezone": "America/Monterrey"
    },
    {
        "id": 13,
        "name": "(GMT-06:00) Saskatchewan",
        "timezone": "Canada/Saskatchewan"
    },
    {
        "id": 14,
        "name": "(GMT-06:00) Central Time (US & Canada)",
        "timezone": "US/Central"
    },
    {
        "id": 15,
        "name": "(GMT-05:00) Eastern Time (US & Canada)",
        "timezone": "US/Eastern"
    },
    {
        "id": 16,
        "name": "(GMT-05:00) Indiana (East)",
        "timezone": "US/East-Indiana"
    },
    {
        "id": 17,
        "name": "(GMT-05:00) Bogota",
        "timezone": "America/Bogota"
    },
    {
        "id": 18,
        "name": "(GMT-05:00) Lima",
        "timezone": "America/Lima"
    },
    {
        "id": 19,
        "name": "(GMT-04:30) Caracas",
        "timezone": "America/Caracas"
    },
    {
        "id": 20,
        "name": "(GMT-04:00) Atlantic Time (Canada)",
        "timezone": "Canada/Atlantic"
    },
    {
        "id": 21,
        "name": "(GMT-04:00) La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "id": 22,
        "name": "(GMT-04:00) Santiago",
        "timezone": "America/Santiago"
    },
    {
        "id": 23,
        "name": "(GMT-03:30) Newfoundland",
        "timezone": "Canada/Newfoundland"
    },
    {
        "id": 24,
        "name": "(GMT-03:00) Buenos Aires",
        "timezone": "America/Buenos_Aires"
    },
    {
        "id": 25,
        "name": "(GMT-03:00) Greenland",
        "timezone": "Greenland"
    },
    {
        "id": 26,
        "name": "(GMT-02:00) Stanley",
        "timezone": "Atlantic/Stanley"
    },
    {
        "id": 27,
        "name": "(GMT-01:00) Azores",
        "timezone": "Atlantic/Azores"
    },
    {
        "id": 28,
        "name": "(GMT-01:00) Cape Verde Is.",
        "timezone": "Atlantic/Cape_Verde"
    },
    {
        "id": 29,
        "name": "(GMT) Casablanca",
        "timezone": "Africa/Casablanca"
    },
    {
        "id": 30,
        "name": "(GMT) Dublin",
        "timezone": "Europe/Dublin"
    },
    {
        "id": 31,
        "name": "(GMT) Lisbon",
        "timezone": "Europe/Lisbon"
    },
    {
        "id": 32,
        "name": "(GMT) London",
        "timezone": "Europe/London"
    },
    {
        "id": 33,
        "name": "(GMT) Monrovia",
        "timezone": "Africa/Monrovia"
    },
    {
        "id": 34,
        "name": "(GMT+01:00) Amsterdam",
        "timezone": "Europe/Amsterdam"
    },
    {
        "id": 35,
        "name": "(GMT+01:00) Belgrade",
        "timezone": "Europe/Belgrade"
    },
    {
        "id": 36,
        "name": "(GMT+01:00) Berlin",
        "timezone": "Europe/Berlin"
    },
    {
        "id": 37,
        "name": "(GMT+01:00) Bratislava",
        "timezone": "Europe/Bratislava"
    },
    {
        "id": 38,
        "name": "(GMT+01:00) Brussels",
        "timezone": "Europe/Brussels"
    },
    {
        "id": 39,
        "name": "(GMT+01:00) Budapest",
        "timezone": "Europe/Budapest"
    },
    {
        "id": 40,
        "name": "(GMT+01:00) Copenhagen",
        "timezone": "Europe/Copenhagen"
    },
    {
        "id": 41,
        "name": "(GMT+01:00) Ljubljana",
        "timezone": "Europe/Ljubljana"
    },
    {
        "id": 42,
        "name": "(GMT+01:00) Madrid",
        "timezone": "Europe/Madrid"
    },
    {
        "id": 43,
        "name": "(GMT+01:00) Paris",
        "timezone": "Europe/Paris"
    },
    {
        "id": 44,
        "name": "(GMT+01:00) Prague",
        "timezone": "Europe/Prague"
    },
    {
        "id": 45,
        "name": "(GMT+01:00) Rome",
        "timezone": "Europe/Rome"
    },
    {
        "id": 46,
        "name": "(GMT+01:00) Sarajevo",
        "timezone": "Europe/Sarajevo"
    },
    {
        "id": 47,
        "name": "(GMT+01:00) Skopje",
        "timezone": "Europe/Skopje"
    },
    {
        "id": 48,
        "name": "(GMT+01:00) Stockholm",
        "timezone": "Europe/Stockholm"
    },
    {
        "id": 49,
        "name": "(GMT+01:00) Vienna",
        "timezone": "Europe/Vienna"
    },
    {
        "id": 50,
        "name": "(GMT+01:00) Warsaw",
        "timezone": "Europe/Warsaw"
    },
    {
        "id": 51,
        "name": "(GMT+01:00) Zagreb",
        "timezone": "Europe/Zagreb"
    },
    {
        "id": 52,
        "name": "(GMT+02:00) Athens",
        "timezone": "Europe/Athens"
    },
    {
        "id": 53,
        "name": "(GMT+02:00) Bucharest",
        "timezone": "Europe/Bucharest"
    },
    {
        "id": 54,
        "name": "(GMT+02:00) Cairo",
        "timezone": "Africa/Cairo"
    },
    {
        "id": 55,
        "name": "(GMT+02:00) Harare",
        "timezone": "Africa/Harare"
    },
    {
        "id": 56,
        "name": "(GMT+02:00) Helsinki",
        "timezone": "Europe/Helsinki"
    },
    {
        "id": 57,
        "name": "(GMT+02:00) Istanbul",
        "timezone": "Europe/Istanbul"
    },
    {
        "id": 58,
        "name": "(GMT+02:00) Jerusalem",
        "timezone": "Asia/Jerusalem"
    },
    {
        "id": 59,
        "name": "(GMT+02:00) Kyiv",
        "timezone": "Europe/Kiev"
    },
    {
        "id": 60,
        "name": "(GMT+02:00) Minsk",
        "timezone": "Europe/Minsk"
    },
    {
        "id": 61,
        "name": "(GMT+02:00) Riga",
        "timezone": "Europe/Riga"
    },
    {
        "id": 62,
        "name": "(GMT+02:00) Sofia",
        "timezone": "Europe/Sofia"
    },
    {
        "id": 63,
        "name": "(GMT+02:00) Tallinn",
        "timezone": "Europe/Tallinn"
    },
    {
        "id": 64,
        "name": "(GMT+02:00) Vilnius",
        "timezone": "Europe/Vilnius"
    },
    {
        "id": 65,
        "name": "(GMT+03:00) Baghdad",
        "timezone": "Asia/Baghdad"
    },
    {
        "id": 66,
        "name": "(GMT+03:00) Kuwait",
        "timezone": "Asia/Kuwait"
    },
    {
        "id": 67,
        "name": "(GMT+03:00) Nairobi",
        "timezone": "Africa/Nairobi"
    },
    {
        "id": 68,
        "name": "(GMT+03:00) Riyadh",
        "timezone": "Asia/Riyadh"
    },
    {
        "id": 69,
        "name": "(GMT+03:00) Moscow",
        "timezone": "Europe/Moscow"
    },
    {
        "id": 70,
        "name": "(GMT+03:30) Tehran",
        "timezone": "Asia/Tehran"
    },
    {
        "id": 71,
        "name": "(GMT+04:00) Baku",
        "timezone": "Asia/Baku"
    },
    {
        "id": 72,
        "name": "(GMT+04:00) Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "id": 73,
        "name": "(GMT+04:00) Muscat",
        "timezone": "Asia/Muscat"
    },
    {
        "id": 74,
        "name": "(GMT+04:00) Tbilisi",
        "timezone": "Asia/Tbilisi"
    },
    {
        "id": 75,
        "name": "(GMT+04:00) Yerevan",
        "timezone": "Asia/Yerevan"
    },
    {
        "id": 76,
        "name": "(GMT+04:30) Kabul",
        "timezone": "Asia/Kabul"
    },
    {
        "id": 77,
        "name": "(GMT+05:00) Karachi",
        "timezone": "Asia/Karachi"
    },
    {
        "id": 78,
        "name": "(GMT+05:00) Tashkent",
        "timezone": "Asia/Tashkent"
    },
    {
        "id": 79,
        "name": "(GMT+05:30) Kolkata",
        "timezone": "Asia/Kolkata"
    },
    {
        "id": 80,
        "name": "(GMT+05:45) Kathmandu",
        "timezone": "Asia/Kathmandu"
    },
    {
        "id": 81,
        "name": "(GMT+06:00) Ekaterinburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "id": 82,
        "name": "(GMT+06:00) Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "id": 83,
        "name": "(GMT+06:00) Dhaka",
        "timezone": "Asia/Dhaka"
    },
    {
        "id": 84,
        "name": "(GMT+07:00) Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "id": 85,
        "name": "(GMT+07:00) Bangkok",
        "timezone": "Asia/Bangkok"
    },
    {
        "id": 86,
        "name": "(GMT+07:00) Jakarta",
        "timezone": "Asia/Jakarta"
    },
    {
        "id": 87,
        "name": "(GMT+08:00) Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        id: 88,
        "name" : "(GMT+08:00) Chongqing",
        "timezone" : "Asia/Chongqing"
    },
    {
        id: 89,
        "name" : "(GMT+08:00) Hong Kong",
        "timezone" : "Asia/Hong_Kong"
    },
    {
        id: 90,
        "name" : "(GMT+08:00) Kuala Lumpur",
        "timezone" : "Asia/Kuala_Lumpur"
    },
    {
        id: 91,
        "name" : "(GMT+08:00) Perth",
        "timezone" : "Australia/Perth"
    },
    {
        id: 92,
        "name" : "(GMT+08:00) Singapore",
        "timezone" : "Asia/Singapore"
    },
    {
        id: 93,
        "name" : "(GMT+08:00) Taipei",
        "timezone" : "Asia/Taipei"
    },
        {
        id: 94,
        "name" : "(GMT+08:00) Ulaan Bataar",
        "timezone" : "Asia/Ulaanbaatar"
    },
    {
            id: 95,
        "name" : "(GMT+08:00) Urumqi",
        "timezone" : "Asia/Urumqi"
    },
    {
        id: 96,
        "name" : "(GMT+09:00) Irkutsk",
        "timezone" : "Asia/Irkutsk"
    },
    {
        id: 97,
        "name" : "(GMT+09:00) Seoul",
        "timezone" : "Asia/Seoul"
    },
    {
        id: 98,
        "name" : "(GMT+09:00) Tokyo",
        "timezone" : "Asia/Tokyo"
    },
    {
        id: 99,
        "name" : "(GMT+09:30) Adelaide",
        "timezone" : "Australia/Adelaide"
    },
    {
        id: 100,
        "name" : "(GMT+09:30) Darwin",
        "timezone" : "Australia/Darwin"
    },
    {
        id: 101,
        "name" : "(GMT+10:00) Yakutsk",
        "timezone" : "Asia/Yakutsk"
    },
    {
        id: 102,
        "name" : "(GMT+10:00) Brisbane",
        "timezone" : "Australia/Brisbane"
    },
    {
        id: 103,
        "name" : "(GMT+10:00) Canberra",
        "timezone" : "Australia/Canberra"
    },
    {
        id: 104,
        "name" : "(GMT+10:00) Guam",
        "timezone" : "Pacific/Guam"
    },
    {
        id: 105,
        "name" : "(GMT+10:00) Hobart",
        "timezone" : "Australia/Hobart"
    },
    {
        id: 106,
        "name" : "(GMT+10:00) Melbourne",
        "timezone" : "Australia/Melbourne"
    },
    {
        id: 107,
        "name" : "(GMT+10:00) Port Moresby",
        "timezone" : "Pacific/Port_Moresby"
    },
    {
        id: 108,
        "name" : "(GMT+10:00) Sydney",
        "timezone" : "Australia/Sydney"
    },
    {
        id: 109,
        "name" : "(GMT+11:00) Vladivostok",
        "timezone" : "Asia/Vladivostok"
    },
    {
        id: 110,
        "name" : "(GMT+12:00) Magadan",
        "timezone" : "Asia/Magadan"
    },
    {
        id: 111,
        "name" : "(GMT+12:00) Auckland",
        "timezone" : "Pacific/Auckland"
    },
    {
        id: 112,
        "name" : "(GMT+12:00) Fiji",
        "timezone" : "Pacific/Fiji"
    }
]