import React, { useState } from 'react'
import FeaturedIcon from '../FeaturedIcons/FeaturedIcon';
import flagIcon from '../../assets/images/flag-05.svg';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import { Button } from '../Buttons/Button';
import dotsIcon from '../../assets/images/dots-vertical.svg';
import arrowRightUp from '../../assets/images/arrow-up-right.svg';
import presentationChart from '../../assets/images/presentation-chart-01-sm.svg';
import DotsModal from './DotsModal';
import AgendaItem from '../Agenda/AgendaItem';
import ReviewAgendaSmallSection from '../Sections/ReviewAgendaSmallSection';
import ReviewInvitedAttendeesSection from '../Sections/ReviewInvitedAttendeesSection';
import { useNavigate } from 'react-router-dom';
import LocationButton from '../LocationButton';
import { AgendaBlockType } from '../../types/agendaBlockType';
import { useQuery } from '@tanstack/react-query';
import { fetchEvent } from '../../httpQueries/http';
import { getUTCTimeFromMinutes } from '../../utilities/getUTCTimeFromMinutes';

interface Props {
  slug: string,
  closeModal: () => void
}

export default function ReviewEventDetailsModal({
  closeModal,
  slug
}: Props) {
  const navigate = useNavigate();
  const [dotsModalIsOpen, setDotsModalIsOpen] = useState(false);
  const token = localStorage.getItem('token');
  const { data: event } = useQuery({
    queryKey: ['event', slug],
    queryFn: () => fetchEvent({ slug, token })
  })
  return (
    <div className='positionAbsolute z100'>
      <div className='modalNotification-container modalNotification-container-inviteAttendees' style={{height: '600px', width: '460px', left: '250px', top: '170px'}}>
        <div className='modalNotification-circles-container'>
        <div className='modalNotification-circles'>
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className='modalNotification-header'>
          <div className='modalNotification-header-actions'>
            <div className='flex gap-16 items-center'>
              <FeaturedIcon icon={flagIcon} size='lg' type='gray' />
              <p className='reviewEventDetailsModal-name text-lg font-semibold'>Event details</p>
            </div>
            <div className='modalNotification-header-actions-close positionAbsolute' style={{ top: '-7px', right: '45px' }}>
              <Button
                size='md'
                hierarchy='closeGray'
                buttonType='small'
                buttonImage={dotsIcon}
                onClick={() => setDotsModalIsOpen(prev => !prev)}
              />
            </div>
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close'
              onClick={closeModal}
            /> 
          </div>
        </div>
        {
          event
            ? <div className='modalNotification-main modalNotification-main-agenda height-70'>
                <div className='reviewEventDetailsModal overflowYScroll'>
                  <div className='flex flex-column gap-20'>
                    <div>
                      <h3 className='reviewEventDetailsModal-name'>{event.name}</h3>
                      <p className='reviewEventDetailsModal-description'>{event.description}</p>
                    </div>
                    <Button
                      buttonType='regular'
                      hierarchy='linkColor'
                      size='md'
                      paddingNone
                      // onClick={onAddItem}
                    >
                      Read more
                    </Button>
                  </div>
                  <div className='flex flex-column gap-20'>
                    <div className='flex flex-column gap-8'>
                      <h3 className='reviewEventDetailsModal-topic'>Location</h3>
                      <LocationButton lng={event.locationLongitude} lat={event.locationLatitude} name={event.location} />
                    </div>
                  </div>
                  <div className='flex flex-column gap-20'>
                    <div className='flex flex-column gap-8'>
                      <h3 className='reviewEventDetailsModal-topic'>Date and time</h3>
                      <p className='reviewEventDetailsModal-name'>{getUTCTimeFromMinutes(event.startDate)} • {getUTCTimeFromMinutes(event.startTime)}</p>
                    </div>
                  </div>
                  <div className='flex flex-column gap-20'>
                    <div className='flex flex-column gap-8'>
                      <h3 className='reviewEventDetailsModal-topic'>Internal location</h3>
                      <p className='reviewEventDetailsModal-description'>{ event.locationNote ? event.locationNote : '-'}</p>
                    </div>
                  </div>
                  <div className='flex flex-column gap-20'>
                    <div className='flex flex-column gap-8'>
                      <h3 className='reviewEventDetailsModal-topic'>Agenda</h3>
                      <ReviewAgendaSmallSection 
                        agenda={event.agenda.map(item => ({
                          id: item.id,
                          name: item.name,
                          description: item.description,
                          type: item.type,
                        }))}
                      />
                    </div>
                  </div>
                  <div className='flex flex-column gap-20'>
                    <div className='flex flex-column gap-8'>
                      <h3 className='reviewEventDetailsModal-topic'>Attendees ({event.attendees.length})</h3>
                      <ReviewInvitedAttendeesSection 
                        invitedAttendees={event.attendees.map(item => ({ id: item.id, name: item.fullName, supportText: item.email }))} 
                        setInvitedAttendees={function (value: React.SetStateAction<{ id: number; name: string; supportText?: string | undefined; }[]>): void {
                                        throw new Error('Function not implemented.');
                                    } }                
                      />
                    </div>
                  </div>
                </div>
              </div>
            : null
        }
        <div className='modalNotification-actions' style={{justifyContent: 'end'}}>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            onClick={() => {
              localStorage.setItem('currentEditEvent', slug!);
              navigate('/');
            }}
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  )
}
