import React from 'react'
import ProgressLine from '../ProgressBar/ProgressLine';
import checkIcon from '../../assets/images/check-white.svg';
import fileTypePDF from '../../assets/images/fileTypeIconPdf.svg';
import trashIcon from '../../assets/images/trash-01-black.svg';
import { Button } from '../Buttons/Button';

export default function FileUploadBase({ progress, size, name, onDelete }: { progress: number, size: number, name: string, onDelete: () => void }) {
  function fileSizeToDisplay(size: number) {
    if (size < 1024) {
      return `${size} B`
    }

    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`
    }

    return `${((size / 1024) / 1024).toFixed(2)} MB`
  }

  return (
    <div className='fileUploadBase'>
      <img src={fileTypePDF} alt="" className='fileUploadBase-fileTypeIcon' />
      <div className='fullWidth'>
        <div>
          <h3 className='fileUploadBase-name'>{name}</h3>
          <p className='fileUploadBase-fileSize'>{fileSizeToDisplay(size)}</p>
        </div>
        <ProgressLine showPercent hierarchy='default' progress={progress} />
        {
          progress === 100
            ?  <div className='fileUploadBase-actionIcon'>
                  <Button
                    buttonImage={trashIcon}
                    buttonType='small'
                    size='sm'
                    hierarchy='tertiaryGray'
                    onClick={onDelete}
                  />
                  <img src={checkIcon} alt="" className='fileUploadBase-downloaded' />
                </div> 
            : <div className='fileUploadBase-actionIcon'>
                <Button
                  buttonImage={trashIcon}
                  buttonType='small'
                  size='sm'
                  hierarchy='tertiaryGray'
                  onClick={onDelete}
                />
              </div>
        }
      </div>
    </div>
  )
}
