import React, { useEffect, useState } from 'react';
import checkIcon from '../../assets/images/check.svg';
import { AttendeeRSVP } from '../../types/attendeeRSVP';

interface Props {
  selectedItem: AttendeeRSVP | undefined,
  setSelectedItem: React.Dispatch<React.SetStateAction<AttendeeRSVP | undefined>>,
  content: AttendeeRSVP[],
  positionBottom?: string | null,
  positionTop?: string | null,
  positionLeft?: string | null,
  positionRight?: string | null,
  onSelect: (item: AttendeeRSVP) => Promise<void>,
}

export default function RSVPDropdown(
   {  
    selectedItem, 
    setSelectedItem, 
    content, 
    positionBottom = null, 
    positionTop = null, 
    positionLeft = null, 
    positionRight = null, 
    onSelect
  }: Props
) {
  return (
    <form 
      className={`
        timePicker 
        heightMaxContent maxWidth-80
      `}
      style={{bottom: `${positionBottom}`, top: `${positionTop}`, left: `${positionLeft}`, right: `${positionRight}`}}
      autoFocus
    >
      <ul className='timePicker-timeContainer'>
        {content.map(item => {
          return (
            <div 
              className={`timePicker-timeContainer-time ${selectedItem === item ? 'timePicker-time-selected' : ''}`} 
              key={item}
            >
              <div
                className='timePicker-timeContainer-info'
                onClick={async () => {
                  setSelectedItem(item);
                  await onSelect(item);
                }}
              >
                <li
                  className={`timePicker-time `}
                >
                  {item}
                </li>
              </div>
              {selectedItem === item ? <img src={checkIcon} alt="selected icon" /> : null}
            </div>
          )
        })}
      </ul>
    </form>
  )
}
