import React, { useState } from 'react';
import closeIcon from '../../assets/images/x-close-gray-lg.svg';
import { Button } from '../Buttons/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import { restaurantSortTypes } from '../../types/restaurantSortTypes';
import RadioButtons from '../Selectors/RadioButtons';

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  selectedSortType: {
    id: number;
    name: string;
    sortBy?: restaurantSortTypes | undefined;
  } | null,
  setSelectedSortType: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    sortBy?: restaurantSortTypes | undefined;
  } | null>>,
  isPickup: boolean,
  setIsPickup: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function FilterModal({ setShow, selectedSortType, setSelectedSortType, isPickup, setIsPickup }: Props) {
  const [preSelectSortType, setPreSelectSortType] = useState<{ id: number, name: string, sortBy?: restaurantSortTypes } | null>(selectedSortType);
  const [preSelectedIsPickup, setPreSelectedIsPickup] = useState(isPickup);

  return (
    <div className='modalNotification' style={{ zIndex: 1000 }}>
      <div className='modalNotification-container modalNotification-container-attachDocument modalNotification-container-invite'>
        <div className='modalNotification-header gap-0'>
          <div className=''>
            <img
              src={closeIcon}
              alt="close icon"
              className='modalNotification-header-actions-close modalInvite-closeIcon'
              style={{ top: '0' }}
              onClick={() => {
                setShow(false);
              }}
            /> 
          </div>
          <div className='modalNotification-header-info'>
            <h3>Sort & filter</h3>
          </div>
        </div>
        <div className='modalNotification-main'>
          <div className='flex flex-column gap-8'>
            <p className='text-sm font-medium color-gray-700'>Sort by*</p>
            <Dropdown 
              content={[
                { id: 1, name: 'Relevance', sortBy: restaurantSortTypes.RELEVANCE },
                { id: 2, name: 'Cheapest', sortBy: restaurantSortTypes.CHEAPEST },
                { id: 3, name: 'Distance', sortBy: restaurantSortTypes.DISTANCE },
                { id: 4, name: 'Fastest', sortBy: restaurantSortTypes.FASTEST },
                { id: 5, name: 'Rating', sortBy: restaurantSortTypes.RATING }
              ]} 
              reactToFocus
              currentItem={preSelectSortType} 
              setCurrentItem={setPreSelectSortType} 
              withBorder 
              top='0'
              id='filterModal'
            />
          </div>
          <div className='flex flex-column gap-20'>
            <p className='text-sm font-medium color-gray-700'>Filter by</p>
            <div 
              className='radioButtons-button'
              onClick={() => setPreSelectedIsPickup(false)}
            >
              <div className={`radioButtons-button-circle-container ${!preSelectedIsPickup ? 'radioButtons-button-circle-container-selected' : ''}`}>
                <div className={`${!preSelectedIsPickup ? 'radioButtons-button-circle' : ''} `}></div>
              </div>
              <div className='radioButtons-button-content'>
                <h3 className='radioButtons-button-content-name'>Delivery</h3>
              </div>
            </div>
            <div 
              className='radioButtons-button'
              onClick={() => setPreSelectedIsPickup(true)}
            >
              <div className={`radioButtons-button-circle-container ${preSelectedIsPickup ? 'radioButtons-button-circle-container-selected' : ''}`}>
                <div className={`${preSelectedIsPickup ? 'radioButtons-button-circle' : ''} `}></div>
              </div>
              <div className='radioButtons-button-content'>
                <h3 className='radioButtons-button-content-name'>Pickup</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='modalNotification-actions'>
          <Button
            buttonType='regular'
            hierarchy='primary'
            size='lg'
            contentCenter
            fullWidth
            onClick={() => {
              setSelectedSortType(preSelectSortType);
              setIsPickup(preSelectedIsPickup);
              setShow(false)
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}
