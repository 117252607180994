import React, { useEffect, useState } from 'react'
import { FormInput } from '../Inputs/FormInput';
import { User } from '../../types/userForAddType';
import CheckBox from '../CheckBox/CheckBox';
import { el } from 'date-fns/locale';

interface Props {
  content: User[],
  setContent: React.Dispatch<React.SetStateAction<User[]>>,
  isDropdownOpen: boolean,
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>,
  label: string,
  placeholder: string,
  onFocus: () => void,
  alreadyAddedItems: User[],
  setAlreadyAddedItems: React.Dispatch<React.SetStateAction<User[]>>,
}

export default function DropdownWithAction({ content, setContent, isDropdownOpen, setIsDropdownOpen, label, placeholder, onFocus, alreadyAddedItems, setAlreadyAddedItems }: Props) {
  const newItemId = content.length + 1;
  const [otherInputIsOpen, setOtherInputIsOpen] = useState(false);

  useEffect(() => {
    if (!isDropdownOpen) {
      setOtherInputIsOpen(false);
    }
  }, [isDropdownOpen])
  
  return (
    <div 
      className='flex flex-column gap-12 fullWidth border-box px-5 py-5' 
      tabIndex={0} 
      onFocus={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDropdownOpen(true);
        onFocus();
      }}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setIsDropdownOpen(false);
        }
      }}
    >
      <FormInput 
        type='Users' 
        placeholder={placeholder}    
        label={label} 
        setUsersFromInput={setAlreadyAddedItems}
        usersFromInput={alreadyAddedItems}
      />
      {
        isDropdownOpen
          ? <div className='teamsDropdown'>
              {
                content.map(item => (
                  <div 
                    className={`teamsDropdown-item ${alreadyAddedItems.map(addedItem => addedItem.id).includes(item.id) ? 'teamsDropdown-item-selected' : ''}`} 
                    key={item.id}
                  >
                    <CheckBox 
                      item={{
                        id: item.id!,
                        name: item.name,
                        supportText: item.img
                      }}
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        
                        if (alreadyAddedItems.map(addedItem => addedItem.id).includes(item.id)) {
                          setAlreadyAddedItems(prevState => prevState.filter(addedItem => addedItem.id !== item.id))
                        } else {
                          setAlreadyAddedItems(prevState => [...prevState, item])
                        }
                      }} 
                      isSelected={alreadyAddedItems.map(addedItem => addedItem.id).includes(item.id)} 
                    />
                    <li
                      className={`teamsDropdown-item-name`}
                    >
                      {item.name}
                    </li>
                  </div>
                ))
              }
              <div 
                className={`teamsDropdown-item ${alreadyAddedItems.map(addedItem => addedItem.id).includes(newItemId) ? 'teamsDropdown-item-selected' : ''}`} 
                key={newItemId}
              >
                <CheckBox 
                  item={{
                    id: newItemId,
                    name: 'Other',
                    supportText: ''
                  }}
                  onClick={() => {                    
                    if (alreadyAddedItems.map(addedItem => addedItem.id).includes(newItemId)) {
                      setAlreadyAddedItems(prevState => prevState.filter(addedItem => newItemId))
                    } else {
                      setOtherInputIsOpen(true);
                    }
                  }} 
                  isSelected={alreadyAddedItems.map(addedItem => addedItem.id).includes(newItemId)} 
                />
                <li
                  className={`teamsDropdown-item-name`}
                >
                  Other
                </li>
              </div>
              {
                otherInputIsOpen
                  ? <div className='fullWidth border-box px-5 py-5'>
                      <FormInput 
                        type={'InputWithActions'} 
                        placeholder={''}     
                        setShow={() => {
                          setOtherInputIsOpen(prevState => !prevState);
                        }}
                        fullWidth={true}
                        setUsersFromInput={setAlreadyAddedItems}
                        usersFromInput={alreadyAddedItems}   
                        onAddNewItem={(newItem) => {
                          newItem.id = newItemId;
                          setContent(prevState => [...prevState, newItem])
                        }}
                      />
                    </div>
                  : null
              }
            </div>
          : null
      }
    </div>
  )
}
