import React from 'react';
import eyeIcon from '../../assets/images/eye.svg';
import pencilIcon from '../../assets/images/edit-01.svg';
import userPlusIcon from '../../assets/images/user-plus-01.svg';
import deleteIcon from '../../assets/images/trash-01.svg';

interface Props {
  items: {id: number, img: string, text: string, onClick: (e?: any) => void}[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  top: string,
  right: string
}

export default function DotsModal({items, setIsOpen, right, top}: Props) {
  return (
    <div className='dotsVertical' style={{top: top, right: right}}>
      {
        items.map(item => 
          <div 
            className='dotsVertical-item' 
            key={item.id} 
            onClick={(e) => {
              item.onClick(e)
              setIsOpen(false)
            }}
          >
            <img src={item.img} alt="" />
            <p>{item.text}</p>
          </div>
        )
      }
    </div>
  )
}
