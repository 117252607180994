import React, { useEffect, useState } from 'react'
import MonthCalendar from '../../components/CalendarSchedule/MonthCalendar';
import moment, { Moment } from 'moment';
import arrowLeft from '../../assets/images/chevron-left-lightGray.svg';
import arrowRight from '../../assets/images/chevron-right-lightGray.svg';

export default function MobileCalendar({ year, setCurrentYear, contentIsScrolled }: {year: number, setCurrentYear: React.Dispatch<React.SetStateAction<number>>, contentIsScrolled: boolean}) {
  const [monthsForDisplay, setMonthForDisplay] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    const today = new Date();
    let currentMonth = 0;
    const months = [];

    for (currentMonth; currentMonth < 11; currentMonth++) {
      months.push(new Date(year, currentMonth));
    }

    setMonthForDisplay(months);
  }, [year]);

  return (
    <div className='flex flex-column gap-8 positionRelative'>
      <div className={`monthCalendar-changeYearHeader ${contentIsScrolled ? 'monthCalendar-changeYearHeader-scrolled' : ''}`}>
        <img src={arrowLeft} alt="" onClick={() => setCurrentYear(prevState => (prevState - 1))} style={{ cursor: 'pointer' }} />
        <p>{year}</p>
        <img src={arrowRight} alt="" onClick={() => setCurrentYear(prevState => (prevState + 1))} style={{ cursor: 'pointer' }} />
      </div>
      {
        monthsForDisplay.map(month => <MonthCalendar month={month} setSelectedDate={setSelectedDate} selectedDate={selectedDate} key={month.getMonth()} />)
      }
    </div>
  )
}
